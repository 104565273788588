import Home from 'Components/DashBoard/Home';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Dashboard = () => {
    useEffect(() => {
        window.scrollHandler();
    }, [])
    return (
        <Fragment >
            <LeftBar type="prematch" title="스포츠 (해외형)" />
            <Home />
            <Right type="main" />
        </Fragment>
    );
};

export default Dashboard;