import { dateFormation, isEmpty, showMessage } from 'Common';
import { api_url } from 'Common/keys';
import { request } from 'Common/request';
import { moreActions, pageActions } from 'Common/store';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const LotteryContents = () => {

    const dispatch = useDispatch();
    const page = useSelector(state => state.page)
    const history = useSelector(state => state.more.lottery.history)
    const leftCount = useSelector(state => state.more.lottery.leftCount)
    const [number, setNumber] = useState(-1);
    const [showResult, setShowResult] = useState(true)
    const getHistory = () => {
        dispatch(moreActions.getLotteryHistory({
            page: page.curPage,
            perPage: perPage
        }));
    }
    const getLottery = (all) => {
        setNumber("");
        dispatch(pageActions.onSetPageLoading(true));
        request.post(api_url + "/api/Lottery/scrap", { all })
            .then(data => {
                dispatch(pageActions.onSetPageLoading(false));
                showMessage(data.message);
                if (!data.status) {
                    let sum = 0;
                    for (let i = 0; i < data.history.length; i++)
                        sum += isEmpty(data.history[i].bonus)
                            ? 0
                            : data.history[i].bonus.balance.rolling;
                    const result = {
                        success: true,
                        bonus: sum
                    }
                    if (result.err) throw (result.err);
                    if (result.success) {
                        setShowResult(false);
                        setNumber(result.bonus);
                    }
                }
            })
    }

    useEffect(() => {
        getHistory()
    }, [page.curPage, perPage]);

    const onShowResult = () => {
        setShowResult(true);
        getHistory();
    }

    const onSetPage = (page) => {
        dispatch(pageActions.setPageData({
            page: Number(page),
            totalPage: page.totalPage
        }))
    }

    return (
        <div className='contents_center'>
            <div className='contents_center_info_full lottery-content'>
                <div className="title">복권</div>
                <div className="lottery-main h-100">
                    <div className="lottery_count">남은 복권&nbsp;&nbsp; <span className={`${leftCount > 0 ? "rouletteCount" : ""}`}>{leftCount}</span>&nbsp;&nbsp;개</div>
                    <div className="lottery-box">
                        <div className="lottery-box-img">
                            <img src="../images/common/lottery-box.png" style={{ width: "130px" }}></img>
                        </div>

                        <div className="lottery-result" >
                            {
                                showResult ? (number === -1 ? <span className="t-center">아래 [복권 사용]을 클릭하세요.</span>
                                    :
                                    <span className={`font-size-16 font-weight-400 ${isEmpty(number) || number == 0 ? "red-color" : "green-color"}`}>
                                        {isEmpty(number) || number == 0 ? "당첨이 없습니다." : "" + Number(number).toLocaleString() + "P가 당첨되었습니다."}
                                    </span>)
                                    : <Fragment>
                                        <div className="d-flex al-center" onClick={onShowResult}>
                                            <span>복권을 확인시려면 "클릭"해주세요</span>
                                            <img src="../images/common/pointer.png" className="" alt="" />
                                        </div>
                                    </Fragment>
                            }
                        </div>
                        <div className="lottery-btn-group ">
                            <div className="lottery-btn1 " onClick={() => getLottery(false)} style={{ backgroundColor: "#222222", color: "#ffffff" }}>복권 사용</div>
                            <div className="lottery-btn2 " onClick={() => getLottery(true)} style={{ backgroundColor: "rgb(228 133 63)", color: "#ffffff" }}>전체 복권 사용</div>
                        </div>
                    </div>
                </div>
                <div className="con_box00">
                    <div className="list_box" >
                        <div className="list_tr2" style={{ justifyContent: "center" }}>
                            <div className="list_title2" style={{width:"10%"}}>번호</div>
                            <div className="list_title2" style={{width:"60%"}}>사용일시</div>
                            <div className="list_title2" style={{width:"30%"}}>당첨내역</div>
                        </div>
                        {
                            !isEmpty(history) && history.map((list, index) => {
                                return (<div className="list_tr2" key={index} style={{ justifyContent: "center" }}>
                                    <div className="list2" style={{width:"10%"}}>{isNaN(page.curPage) ? 0 : (page.curPage - 1) * Number(page.perPage) + index + 1}</div>
                                    <div className="list2" style={{width:"60%"}}>
                                        <span className="text">{dateFormation(list.used_date)}</span>
                                    </div>
                                    <div className="list2" style={{width:"30%"}}>
                                        <span className="text">
                                            {!list.bonus ? "0" : Number(list.bonus.balance.rolling).toLocaleString()}P
                                        </span>
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </div>
                {
                    history.length !== 0 ?
                        <Pagenation
                            page={page.curPage}
                            totalPage={page.totalPage}
                            getPage={onSetPage} />
                        :
                        <div className='no_data'>내역이 없습니다</div>
                }

            </div>
        </div>
    );
};

export default LotteryContents;