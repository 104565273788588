import { ResizeSportsLiveIframe, history } from 'Common';
import { pageActions } from 'Common/store';
import SportsCenter from 'Components/Sports/SportsCenter';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const SportsLive = () => {

    const dispatch = useDispatch();

    const [showLeft, setShowLeft] = useState(false);
    const roomObj = {
        pageType: "toto",
        RoomType: "sport",
        sportType: "sport",
        gameType: "live",
        sportId: 'all',
        esportType: "all",
    }
    useEffect(() => {
        // props.ClearBetStore();
        // props.ClearSportsList();
        window.sportsScroll();
        const roomData = {
            MessageType: "RoomType",
            data: roomObj
        };
        if (window.ws.readyState === 1)
            window.ws.send(JSON.stringify(roomData));
        ResizeSportsLiveIframe();
        // props.SetPageInfo(roomObj);
    }, []);

    dispatch(pageActions.onSetPageInfo(roomObj));

    const onSetShowLeft = () => {
        setShowLeft(!showLeft);
    }

    return (
        <Fragment>
            <LeftBar onSetShowLeft={onSetShowLeft} showLeft={showLeft} type="live" title="스포츠 (라이브)" history={history} roomObj={roomObj} />
            <SportsCenter roomObj={roomObj} type="live" onSetShowLeft={onSetShowLeft} title="스포츠 (라이브)" />
            <Right type="live" isbetting={true} />
        </Fragment>
    );
};

export default SportsLive;