import PointContents from 'Components/Account/PointConvert/PointContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';


const Point = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar />
            <PointContents />
            <Right type="point" />
        </Fragment>
    );
};

export default Point;