import { resource_url } from 'Common/keys';
import React from 'react';
import { useSelector } from 'react-redux'


const Check = () => {
    const checkData = useSelector(state => state.page.checkData)
    return (
        <div data-v-623d66c4="" className="loading-back" style={{ display: checkData.check ? "flex" : "none" }}>
            <div className="padding-10-" 
                style={{
                    width:"60%", 
                    height:"75%",
                    minWidth:"320px",  
                    maxWidth:"640px", 
                    maxHeight:"90%", 
                    overflow:"auto", 
                    display:"block", 
                    backgroundColor:"#131313f0", 
                    padding: "15px",
                    paddingBottom:"30px", 
                    borderRadius: "15px"}}>
                <div className="d-block h-100 overflow-auto">
                    <div className="swal2-actions swal2-title- padding-10 text-align-center white-break-speace" id="swal2-title" style={{ fontSize: "16px"}}>{checkData.title}</div>
                    <div style={{textAlign:"center"}}>
                        { checkData.check && <img className="m-d-none" src={`${resource_url}/images/check/${ checkData.pc_imagename }`} style={{width:"50%", marginTop:"20px"}} alt=""/>}
                        { checkData.check && <img className="d-none m-d-flex" style={{margin:"auto", width:"50%"}} src={`${resource_url}/images/check/${ checkData.mobile_imagename }`} alt=""/>}
                    </div>
                    {/* <img className=" " src={`${resource_url}/images/check/${ !isMobilePage() ? checkData.pc_imagename : checkData.mobile_imagename }`} alt=""/> */}
                    <div className="swal2-actions white-break-speace" style={{display: "block", margin:"auto", textAlign:"center"}} dangerouslySetInnerHTML={{__html: checkData.content}}>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Check;