import Login from 'Components/Layout/Login';
import { useDispatch, useSelector } from 'react-redux'
import React, { Fragment, useState } from 'react';
import UserInfo from 'Components/Layout/UserInfo';
import Modal from 'Components/Modal/Modal';
import { betActions, miniActions, userActions } from 'Common/store';
import BettingCart from 'Components/Layout/BettingCart';
import { fixedNumber, isEmpty, toastr } from 'Common';
import { Domain, Telegram } from 'Utils';


const Right = (props) => {
    const dispatch = useDispatch();
    const { type } = props
    const [popup, setPopup] = useState(false);
    const [popup1, setPopup1] = useState(false);
    const [money, setMoney] = useState(0);
    const [betRate, setBetRate] = useState(fixedNumber(1));

    const onBetting = () => {
        setPopup(!popup);
    };

    const onPopup = () => {
        setPopup1(!popup1);
    }
    const Logout = () => {
        dispatch(userActions.logoutUser());
        onPopup();
    }
    const bet = useSelector(state => state.bet);
    let betList = !isEmpty(bet.bettedItems) ? bet.bettedItems : [];
    const minigame = useSelector(state => state.mini);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const levelBettingData = useSelector(state => state.sports.levelBettingData);
    const pageInfo = useSelector(state => state.page.pageInfo);
    const userdata = useSelector(state => state.user.data.user);
    const { showright, showleft } = useSelector(state => state.user);
    const userLevel = !isEmpty(userdata) ? (isNaN(Number(userdata.level)) ? 1 : Number(userdata.level)) : "";


    const DoBetting = () => {
        setPopup(!popup);
        if (!isAuthenticated)
            return toastr.warning("로그인하시고 이용해주세요.");
        if (isEmpty(money) || money === 0)
            return toastr.warning("머니를 선택해주세요.");
        if (type !== "minigame" && isEmpty(betList))
            return toastr.warning("배팅을 선택해주세요.");
        if (type === "minigame" && isEmpty(minigame.data))
            return toastr.warning("배팅을 선택해주세요.");

        // const userLevel = isNaN(Number(user.level)) ? 1 : Number(user.level);
        let betavailbleMessage = "";

        if (isEmpty(levelBettingData) || isEmpty(levelBettingData[userLevel - 1])) {
            return;
        } else {
            if (type !== "minigame") {
                if (type !== "virtual") {
                    if (betList.length === 1) {
                        const maxbetMoney =
                            levelBettingData[userLevel - 1][`${pageInfo.gameType}SingleMax`];
                        const minbetMoney =
                            levelBettingData[userLevel - 1][`${pageInfo.gameType}SingleMin`];
                        if (money < minbetMoney)
                            betavailbleMessage = "제한된 배팅금액보다 작게 배팅하셨습니다.";
                        if (money > maxbetMoney)
                            betavailbleMessage = "제한된 배팅금액보다 많게 배팅하셨습니다. ";
                    } else {
                        betList.forEach(list => {
                            // const leaguelevel = isNaN(Number(list.Fixture.League.level)) || Number(list.Fixture.League.level) === 0  ? 1 : Number(list.Fixture.League.level);
                            // //console.log(levelBettingData)
                            if (isEmpty(levelBettingData)) return;
                            // const minLevelbetMoney = levelBettingData[userLevel - 1][`${pageInfo.gameType}League${leaguelevel}`]
                            const maxbetMoney =
                                levelBettingData[userLevel - 1][`${pageInfo.gameType}MultiMax`];
                            const minbetMoney =
                                levelBettingData[userLevel - 1][`${pageInfo.gameType}MultiMin`];
                            if (money < minbetMoney)
                                return (betavailbleMessage =
                                    "제한된 배팅금액보다 작게 배팅하셨습니다.");
                            if (money > maxbetMoney)
                                return (betavailbleMessage =
                                    "제한된 배팅금액보다 많게 배팅하셨습니다. ");
                        });
                    }
                } else {
                    const maxbetMoney = levelBettingData[userLevel - 1][`betSingleMax`];
                    const minbetMoney = levelBettingData[userLevel - 1][`betSingleMin`];
                    if (money < minbetMoney)
                        betavailbleMessage = "제한된 배팅금액보다 작게 배팅하셨습니다.";
                    if (money > maxbetMoney)
                        betavailbleMessage = "제한된 배팅금액보다 많게 배팅하셨습니다. ";
                }

                if (!isEmpty(betavailbleMessage))
                    return toastr.warning(betavailbleMessage);

                setMoney(0);
                setBetRate(1);
                dispatch(betActions.doBetting({
                    money: money,
                    type: type
                }))
            } else {
                const maxbetMoney = levelBettingData[userLevel - 1][`miniSingleMax`];
                const minbetMoney = levelBettingData[userLevel - 1][`miniSingleMin`];
                if (money < minbetMoney)
                    betavailbleMessage = "제한된 배팅금액보다 작게 배팅하셨습니다.";
                if (money > maxbetMoney)
                    betavailbleMessage = "제한된 배팅금액보다 많게 배팅하셨습니다. ";

                if (!isEmpty(betavailbleMessage))
                    return toastr.warning(betavailbleMessage);

                setMoney(0);
                setBetRate(1);
                dispatch(miniActions.doMiniBetting(money, type));
            }
        }
    };

    const onCardClick = () => {
        dispatch(userActions.setShowRight(!showright))
    }
    const onCardLeftClick = () => {
        dispatch(userActions.setShowLeft(!showleft))
    }

    return (
        <Fragment>
            <div className={`z-index-99 modal-back ${showright && 'm-d-block'}`} onClick={onCardClick}></div>
            <div className={`z-index-99 modal-back show-left`} onClick={onCardLeftClick}></div>
            <div className="contents_right">
                {/* <div className="m_notice"><img src="images/notice_title.png" />입금시 계좌문의는 필수 입니다. 꼭 고객센터로 계좌 확인 후 연락주세요</div> */}
                {isAuthenticated && <div className="my_login_box">
                    {


                        <UserInfo onPopup={onPopup} />

                    }
                </div>}
                {
                    ((true) || (type === "minigame") || (type === "prematch") || (type === "live") || (type === "interior") || (type === "esports" || (type === "virtual"))) &&
                    <BettingCart
                        type={type}
                        bet={bet}
                        betList={betList}
                        minigame={minigame}
                        levelBettingData={levelBettingData}
                        money={money}
                        setMoney={setMoney}
                        pageInfo={pageInfo}
                        userdata={userdata}
                        onBetting={onBetting}
                        userLevel={userLevel}
                        betRate={betRate}
                        setBetRate={setBetRate}
                    />
                }
                <div className="right_img"><a><img src="images/right/right01.png" /></a></div>
                <div className="right_img"><a><img src="images/right/right02.png" /></a></div>
                <div className="right_img"><a><img src="images/right/right03.png" /></a></div>
            </div>
            {
                popup1 && <Modal contents="로그아웃 하시겠습니까?" popup={popup1} onAction={Logout} onPopup={onPopup} />
            }
            {
                popup && <Modal contents="베팅을 진행하시겠습니까?" popup={popup} onAction={DoBetting} onPopup={onBetting} />
            }
            {
                <Login />
            }
        </Fragment>
    );
};

export default Right;