import { betGames, lotusgame } from 'Utils/names';
import React from 'react';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

const Bet365Menu = (props) => {

    const betgametype = useSelector(state => state.sports.betgametype)
    const { match, type } = props;
    return (
        <ul>
            {
                    betgametype === "bet365" ?

                    betGames.map((list, index) => {
                        return (
                            <li key={index} className={type === list.id ? "active" : ""} title={list.name}>
                                <Link to={`${match}?type=${list.id}`}><i style={{ margin: "0 5px" }}><img data-v-534c1018="" src={list.img} alt={list.name} style={{ width: "20px" }} /></i>{list.name}</Link>
                            </li>
                        )
                    })
                    :
                    lotusgame.map((list, index) => {
                        return (
                            <li key={index} className={type === list.id ? "active" : ""} title={list.name}>
                                <Link to={`${match}?type=${list.id}`}><i style={{ margin: "0 5px" }}><img data-v-534c1018="" src={list.img} alt={list.name} style={{ width: "20px" }} /></i>{list.name}</Link>
                            </li>
                        )
                    })
                }
    </ul>
    );
};

export default Bet365Menu;