import { store } from "Common/store";



export const sportIds = [
  {
      name: "allcnt",
      kind: "전체",
      gameCount: 0,
      imgPath: "../images/swip/icon_all.png",
      id: "all",
  },
  {
      name: "soccer",
      kind: "축구",
      gameCount: 0,
      imgPath: "../images/swip/icon_football.png",
      id: 6046,
  },
  {
      name: "hockey",
      kind: "아이스하키",
      gameCount: 0,
      imgPath: "../images/swip/icon_ice_hockey.png",
      id: 35232
  },
  {
      name: "basketball",
      kind: "농구",
      gameCount: 0,
      imgPath: "../images/swip/icon_basketball.png",
      id: 48242,
  },
  {
      name: "volleyball",
      kind: "배구",
      gameCount: 0,
      imgPath: "../images/swip/icon_volleyball.png",
      id: 154830,
  },
  {
      name: "baseball",
      kind: "야구",
      gameCount: 0,
      imgPath: "../images/swip/icon_baseball.png",
      id: 154914,
  },
  {
      name: "mma",
      kind: "MMA",
      gameCount: 0,
      imgPath: "../images/swip/icon_mma.png",
      id: 154919,
  },
  {
      name: "tabletennis",
      kind: '탁구',
      gameCount: 0,
      id: 265917,
      imgPath: '../images/swip/jongmok_Tabletennis-ico.png'
  },
  {
      name: "football",
      kind: '미식축구',
      id: 131506,
      gameCount: 0,
      imgPath: '../images/swip/rugby-league-ico.png'
  },
  {
      name: "tennis",
      kind: '테니스',
      id: 54094,
      gameCount: 0,
      imgPath: '../images/swip/jongmok_Tennis.png'
  },
  {
      name: "cricket",
      kind: '크리케트',
      id: 452674,
      gameCount: 0,
      imgPath: '../images/swip/jongmok_Baseball.png'
  },
  {
      kind: "도타 2",
      gameCount: 1,
      imgPath: "../images/swip/icon_dota_2.png",
      id: "dota2",
      name: "Dota",
      name_other : "도타"
  },
  {
      id: 'leagueoflegends_wildlift',
      name: 'Wild Rift',
      name_other : "와일드리프트",
      kind: '리그오브레전드 와일드리프트',
      gameCount: 0,
      //   label1: '와일드리프트',
      imgPath: "../images/swip/game_ico_leagueoflegends_wildlift.png",
  },
  {
      kind: "리그 오브 레전드",
      gameCount: 0,
      imgPath: "../images/swip/icon_league_of_legends.png",
      id: "leagueoflegends",
      name: "League of legends",
      name_other : "League of legends"
  },
  {
      kind: "오버워치",
      gameCount: 0,
      imgPath: "../images/swip/icon_overwatch_2.png",
      id: "overwatch",
      name: "Overwatch",
      name_other : "오버워치"
  },
  {
      kind: "스타크래프트 2",
      gameCount: 0,
      imgPath: "../images/swip/icon_starcraft_2.png",
      id: "starcraft2",
      name: "Starcraft",
      name_other : "스타크래프트"
  },
  {
      kind: "워크래프트",
      gameCount: 0,
      imgPath: "../images/swip/icon_warcraft_iii.png",
      id: "warcraft",
      name: "Warcraft",
      name_other : "워크래프트"
  },
  {
      id: 'ageofempire',
      name: 'Age of Empires II',
      name_other : "에이지 오브 엠파이어",
      gameCount: 1,
      kind: '에이지 오브 엠파이어',
      imgPath: "../images/swip/game_ico_ageofempire.png",

  },
  {
      id: 'counterstrike',
      name: 'Counter-Strike',
      gameCount: 5,
      kind: '카운터 스트라이크',
      imgPath: "../images/swip/game_ico_counterstrike.png",
      name_other : "CS"
  },
  {
      id: 'pentastorm',
      name: 'Pentastorm',
      kind: '펜타스톰',
      gameCount: 0,
      imgPath: "../images/swip/game_ico_pentastorm.png",
  },
  {
      id: 'rainbowsix',
      name: 'Rainbow',
      name_other : "Rainbow",
      kind: '레인보우6',
      gameCount: 0,
      imgPath: "../images/swip/game_ico_rainbowsix.png",
  },
  {
      id: 'soccerbattle',
      name: 'Soccerbattle',
      name_other : "FIFA",
      kind: '축구배틀',
      gameCount: 0,
      imgPath: "../images/swip/game_ico_soccerbattle.png",
  },
  {
      id: 'valorant',
      name: 'Valorant',
      name_other : "VALORANT",
      kind: '발로란트',
      gameCount: 1,
      imgPath: "../images/swip/game_ico_valorant.png",
  },
  {
      id: 'nbabattle',
      name: 'NBA',
      name_other : "NBA",
      kind: 'NBA배틀',
      gameCount: 0,
      imgPath: "../images/swip/game_ico_nbabattle.png",
  },
]

export const betGames = [
  {
      name: "축구",
      id: "Soccer",
      img: "../images/swip/icon_virtual_football_pro.png",
      leagues: [
          {
              name: "World Cup",
              id: "worldCup"
          },
          {
              name: "Premiership",
              id: "premiership"
          },
          {
              name: "Superleague",
              id: "superleague"
          },
          {
              name: "Euro Cup",
              id: "euroCup"
          },
      ],
  },
  {
      name: "농구",
      id: "Basketball",
      img: "../images/swip/icon_basketball.png",
      leagues: [
          {
              name: "Waterport Arena",
              id: "waterportArena"
          },
          {
              name: "Baker Gardens",
              id: "bakerGardens"
          },
      ],
  },
  {
      name: "미식축구",
      id: "Football",
      img: "../images/swip/icon_american_football.png",
      leagues: [
          {
              name: "Fenton Field",
              id: "fentonField"
          },
          {
              name: "Bradwell Field",
              id: "bradwellField"
          },
      ],
  },
  {
      name: "테니스",
      id: "Tennis",
      img: "../images/swip/jongmok_Tennis.png",
      leagues: [
          {
              name: "Rockvill Open",
              id: "rockvillOpen"
          },
      ],
  },
  {
      name: "크리켓",
      id: "Cricket",
      img: "../images/swip/jongmok_Baseball.png",
      leagues: [
          {
              name: "Clayton Oval",
              id: "claytonOval"
          },
          {
              name: "Greenway Fields",
              id: "greenwayFields"
          },
      ],
  },
  {
      name: "다트",
      id: "Darts",
      img: "../images/swip/darts_icon.png",
      leagues: [
          {
              name: "The gateHouse",
              id: "theGateHouse"
          },
      ],
  },
  {
      name: "개경주",
      id: "Greyhounds",
      img: "../images/swip/icon_virtual_greyhounds.png",
      leagues: [
          {
              name: "Hillside Park",
              id: "hillSidePark"
          },
          {
              name: "Goldenhill Park",
              id: "goldenHillPark"
          },
      ],
  },
  {
      name: "경마",
      id: "Horse",
      img: "../images/swip/icon_virtual_horse_racing.png",
      leagues: [
          {
              name: "Festival Down",
              id: "festivalDowns"
          },
          {
              name: "Victoria Park",
              id: "victoriaPark"
          },
          {
              name: "Britannia Way",
              id: "britanniaWay"
          },
          {
              name: "National Park",
              id: "nationalPark"
          },
      ],
  },
  {
      name: "모터스포츠",
      id: "Motor",
      img: "../images/swip/icon_basketball.png",
      leagues: [
          {
              name: "Mitchell Circuit",
              id: "mitchellCircuit"
          },
      ],
  },
  {
      name: "스피드웨이",
      id: "Speedway",
      img: "../images/swip/speedway_icon.png",
      leagues: [
          {
              name: "Sterling Stadium",
              id: "sterlingStadium"
          },
      ],
  },
  {
      name: "트로팅",
      id: "Trotting",
      img: "../images/swip/icon_basketball.png",
      leagues: [
          {
              name: "Etruria Gardens",
              id: "etruriaGardens"
          },
      ],
  },
  {
      name: "사이클링",
      id: "Cycling",
      img: "../images/swip/cycling_icon.png",
      leagues: [
          {
              name: "Franklin Velodrome",
              id: "franklinVelodrome"
          },
      ],
  },
]

export const lotusgame = [
  {
    name: "축구",
    id: "Soccer",
    img: "../images/swip/icon_virtual_football_pro.png",
    leagues: [
      {
        name: "World Cup",
        id: "worldCup"
      },
      {
        name: "Premiership",
        id: "premiership"
      },
      {
        name: "Superleague",
        id: "superleague"
      },
      {
        name: "EuroCup",
        id: "euroCup"
      },
    ],
  },
  {
    name: "농구",
    id: "Basketball",
    img: "../images/swip/icon_basketball.png",
    leagues: [
      {
        name: "WaterportArena",
        id: "waterportArena"
      },
      {
        name: "BakerGardens",
        id: "bakerGardens"
      },
    ],
  },
  {
    name: "가상야구",
    id: "Baseball",
    img: "../images/swip/jongmok_Baseball.png",
    leagues: [
      {
        name: "BrandonField",
        id: "brandonField"
      },
      {
        name: "NorthStadium",
        id: "northStadium"
      },
    ],
  },

  {
    name: "개경주",
    id: "Greyhounds",
    img: "../images/swip/icon_virtual_greyhounds.png",
    style: { filter: "brightness(0.5)" },
    leagues: [
      {
        name: "HillsidePark",
        id: "hillsidePark"
      },
      {
        name: "GoldenhillPark",
        id: "goldenhillPark"
      },
      {
        name: "BennettPark",
        id: "bennettPark"
      },
    ],
  },
  {
    name: "경마",
    id: "Horse",
    img: "../images/swip/icon_virtual_horse_racing.png",
    style: { filter: "brightness(0.5)" },
    leagues: [
      {
        name: "FestivalDowns",
        id: "festivalDowns"
      },
      {
        name: "VictoriaPark",
        id: "victoriaPark"
      },
      {
        name: "BritanniaWay",
        id: "britanniaWay"
      },
      {
        name: "TrenthamPark",
        id: "trenthamPark"
      },
    ],
  },

]

export const getgameGrps = (type) => {
  switch (type) {
    case "EVOPowerball":
      return evogameGrps;

    case "nuguri":
      return nugurigameGrps;

    case "soccer":
      return soccergameGrps;

    case "baseball":
      return baseballgameGrps;

    case "squidgame":
      return squidgameGrps;

    default:
      return eosgameGrps
  }
}

export const prices = (type) => {
  switch (type) {
    case "EVOPowerball":
      return evoballPrice();

    case "nuguri":
      return nuguriPrice();

    case "soccer":
      return soccerPrice();

    case "baseball":
      return baseballPrice();

    case "squidgame":
      return squidPrice();

    default:
      return eosballPrice()
  }
}

export const eosgameGrps = [
  {
    label: '일반볼',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        name_other: '일반볼-홀',
        rate: 1.5,
        hasoption: false,
        odd_key: 'normal_odd'
      },
      {
        color: 'red',
        name: '짝',
        name_other: '일반볼-짝',
        rate: 1.5,
        hasoption: false,
        odd_key: 'normal_even'
      },
      {
        color: 'blue',
        name: '오버',
        name_other: '일반볼-오버',
        rate: 1.9,
        hasoption: true,
        option: '73~130',
        odd_key: 'normal_over'
      },
      {
        color: 'red',
        name: '언더',
        name_other: '일반볼-언더',
        rate: 1.9,
        hasoption: true,
        option: '00~72',
        odd_key: 'normal_under'
      }
    ]
  },
  {
    label: '게임 : 일반볼합 구간',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '대',
        rate: 1.5,
        hasoption: true,
        option: '81~130',
        odd_key: 'normal_big'
      },
      {
        color: 'green',
        name: '중',
        rate: 1.8,
        hasoption: true,
        option: '65~80',
        odd_key: 'normal_medium'
      },
      {
        color: 'red',
        name: '소',
        rate: 1.4,
        hasoption: true,
        option: '15~64',
        odd_key: 'normal_small'
      }
    ]
  },
  {
    label: '파워볼',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        name_other: '파워볼-홀',
        rate: 1.2,
        odd_key: 'power_odd'
      },
      {
        color: 'red',
        name: '짝',
        name_other: '파워볼-짝',
        rate: 1.2,
        odd_key: 'power_even'
      },
      {
        color: 'blue',
        name: '오버',
        name_other: '파워볼-오버',
        rate: 1.6,
        hasoption: true,
        option: '05~09',
        odd_key: 'power_over'
      },
      {
        color: 'red',
        name: '언더',
        name_other: '파워볼-언더',
        rate: 1.6,
        hasoption: true,
        option: '00~04',
        odd_key: 'power_under'
      }
    ]
  }
]


export const eosballPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.normal.evenOdd,
    rates.normal.evenOdd,
    rates.normal.overUnder,
    rates.normal.overUnder,
    rates.normal.big,
    rates.normal.medium,
    rates.normal.small,
    rates.power.evenOdd,
    rates.power.evenOdd,
    rates.power.overUnder,
    rates.power.overUnder
  ]
}

export const evogameGrps = [
  {
    label: '일반볼',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        name_other: '일반볼-홀',
        rate: 1.5,
        hasoption: false,
        odd_key: 'normal_odd'
      },
      {
        color: 'red',
        name: '짝',
        name_other: '일반볼-짝',
        rate: 1.5,
        hasoption: false,
        odd_key: 'normal_even'
      },
      {
        color: 'blue',
        name: '오버',
        name_other: '일반볼-오버',
        rate: 1.9,
        hasoption: true,
        option: '73~130',
        odd_key: 'normal_over'
      },
      {
        color: 'red',
        name: '언더',
        name_other: '일반볼-언더',
        rate: 1.9,
        hasoption: true,
        option: '00~72',
        odd_key: 'normal_under'
      }
    ]
  },
  {
    label: '일반볼합 구간',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '대',
        rate: 1.5,
        hasoption: true,
        option: '81~130',
        odd_key: 'normal_big'
      },
      {
        color: 'green',
        name: '중',
        rate: 1.8,
        hasoption: true,
        option: '65~80',
        odd_key: 'normal_medium'
      },
      {
        color: 'red',
        name: '소',
        rate: 1.4,
        hasoption: true,
        option: '15~64',
        odd_key: 'normal_small'
      }
    ]
  },
  {
    label: '파워볼',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        name_other: '파워볼-홀',
        rate: 1.2,
        odd_key: 'power_odd'
      },
      {
        color: 'red',
        name: '짝',
        name_other: '파워볼-짝',
        rate: 1.2,
        odd_key: 'power_even'
      },
      {
        color: 'blue',
        name: '오버',
        name_other: '파워볼-오버',
        rate: 1.6,
        hasoption: true,
        option: '05~09',
        odd_key: 'power_over'
      },
      {
        color: 'red',
        name: '언더',
        name_other: '파워볼-언더',
        rate: 1.6,
        hasoption: true,
        option: '00~04',
        odd_key: 'power_under'
      }
    ]
  }
]


export const evoballPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.normal.evenOdd,
    rates.normal.evenOdd,
    rates.normal.overUnder,
    rates.normal.overUnder,
    rates.normal.big,
    rates.normal.medium,
    rates.normal.small,
    rates.power.evenOdd,
    rates.power.evenOdd,
    rates.power.overUnder,
    rates.power.overUnder
  ]
}

export const nugurigameGrps = [
  {
    label: '홀짝',
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '홀',
        rate: 1.5,
        hasoption: false,
        odd_key: 'odd'
      },
      {
        color: 'mini_btn_type1',
        name: '짝',
        rate: 1.5,
        hasoption: false,
        odd_key: 'even'
      }
    ]
  },
  {
    label: '좌우',
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '좌',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'nugurileft'
      },
      {
        color: 'mini_btn_type1',
        name: '우',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'nuguriright'
      }
    ]
  },
  {
    label: '라인',
    iswide: true,
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '라인3',
        rate: 1.5,
        hasoption: true,
        option: '81~130',
        odd_key: 'line3'
      },
      {
        color: 'mini_btn_type1',
        name: '라인4',
        rate: 1.8,
        hasoption: true,
        option: '65~80',
        odd_key: 'line4'
      }
    ]
  }
]


export const nuguriPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.normal.odd,
    rates.normal.even,
    rates.normal.left,
    rates.normal.right,
    rates.normal.line3,
    rates.normal.line4
  ]
}

export const soccergameGrps = [
  {
    label: '키퍼',
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '좌',
        name_other: '키퍼-좌',
        rate: 1.5,
        hasoption: false,
        odd_key: 'keeper_left'
      },
      {
        color: 'mini_btn_type1',
        name: '우',
        name_other: '키퍼-우',
        rate: 1.5,
        hasoption: false,
        odd_key: 'keeper_right'
      }
    ]
  },
  {
    label: '키커',
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '좌',
        name_other: '키커-좌',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'kicker_left'
      },
      {
        color: 'mini_btn_type1',
        name: '우',
        name_other: '키커-우',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'kicker_right'
      }
    ]
  },
  {
    label: '골',
    iswide: true,
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '골',
        name_other: '골',
        rate: 1.5,
        hasoption: true,
        option: '',
        odd_key: 'goal_left'
      },
      {
        color: 'mini_btn_type1',
        name: '노골',
        name_other: '노골',
        rate: 1.8,
        hasoption: true,
        option: '',
        odd_key: 'goal_right'
      }
    ]
  }
]


export const soccerPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.keeper.keeper_left,
    rates.keeper.keeper_right,
    rates.kicker.kicker_left,
    rates.kicker.kicker_right,
    rates.goal.goal_left,
    rates.goal.goal_right
  ]
}

export const baseballgameGrps = [
  {
    label: '스트라이크',
    iswide: true,
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '좌',
        name_other: '스트라이크-좌',
        rate: 1.5,
        hasoption: true,
        option: '',
        odd_key: 'strikeleft'
      },
      {
        color: 'mini_btn_type3',
        name: '중',
        name_other: '스트라이크-중',
        rate: 1.8,
        hasoption: true,
        option: '',
        odd_key: 'strikemiddle'
      },
      {
        color: 'mini_btn_type1',
        name: '우',
        name_other: '스트라이크-우',
        rate: 1.4,
        hasoption: true,
        option: '',
        odd_key: 'strikeright'
      }
    ]
  },
  {
    label: '홈란',
    iswide: true,
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '좌',
        name_other: '홈란-좌',
        rate: 1.5,
        hasoption: true,
        option: '',
        odd_key: 'homerunleft'
      },
      {
        color: 'mini_btn_type3',
        name: '중',
        name_other: '홈란-중',
        rate: 1.8,
        hasoption: true,
        option: '',
        odd_key: 'homerunmiddle'
      },
      {
        color: 'mini_btn_type1',
        name: '우',
        name_other: '홈란-우',
        rate: 1.4,
        hasoption: true,
        option: '',
        odd_key: 'homerunright'
      }
    ]
  },
]


export const baseballPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.strike.left,
    rates.strike.right,
    rates.strike.middle,
    rates.homerun.left,
    rates.homerun.right,
    rates.homerun.middle,
  ]
}

export const squidgameGrps = [
  {
    label: '좌우',
    iswide: true,
    buttons: [
      {
        color: 'mini_btn_type2',
        name: '좌',
        rate: 1.5,
        hasoption: false,
        odd_key: 'left'
      },
      {
        color: 'mini_btn_type1',
        name: '우',
        rate: 1.5,
        hasoption: false,
        odd_key: 'right'
      }
    ]
  }
]


export const squidPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.normal.left,
    rates.normal.right,
  ]
}

export const minigameItems = [
  {
    category: 'eospowerball1',
    value: 1,
    name: 'EOS파워볼',
    url: 'eospowerball1'
  },
  {
    category: 'eospowerball2',
    value: 2,
    name: 'EOS파워볼',
    url: 'eospowerball2'
  },
  {
    category: 'eospowerball3',
    value: 3,
    name: 'EOS파워볼',
    url: 'eospowerball3'
  },
  {
    category: 'eospowerball4',
    value: 4,
    name: 'EOS파워볼',
    url: 'eospowerball4'
  },
  {
    category: 'eospowerball5',
    value: 5,
    name: 'EOS파워볼',
    url: 'eospowerball5'
  },
  {
    category: 'EVOPowerball',
    value: "EVOPowerball",
    name: 'EVO파워볼',
    url: 'EVOPowerball'
  },
  {
    category: 'nuguri',
    value: 'nuguri',
    name: '너구리',
    url: 'nuguri'
  },
  {
    category: 'soccer',
    value: 'soccer',
    name: '축구',
    url: 'soccer'
  },
  {
    category: 'baseball',
    value: 'baseball',
    name: '야구',
    url: 'baseball'
  },
  {
    category: 'squidgame',
    value: 'squidgame',
    name: '오징어',
    url: 'squidgame'
  }
];