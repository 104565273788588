import { convertClass, convertNameKor, convertResultType, convertTeamName, convertTypeString, dateFormation, fixedNumber, getImgPath, getMarketDetail, getSportId, isEmpty, onErrorImg } from 'Common';
import { resource_url } from 'Common/keys';
import React, { Fragment } from 'react';



const SportsHistory = (props) => {

    const { history, index, setSaveId, onPopup, active } = props;
    const CancelBetting = (id) => {
        onPopup();
        setSaveId(id);
      }
    const list = (!isEmpty(history) && !isEmpty(history.data) && !isEmpty(history.data.odds)) ? history.data.odds : ""
    return (
        <div className="odds_item odd_item_country">
            {
                !isEmpty(list) && list.map((odd, oddIndex) => {
                    const imgPath = resource_url + "/images/locations/" + odd.Fixture.League.location.image
                    const sportsImg = getImgPath(odd)
                    const hometeam = isEmpty(odd.Fixture.Participants) ? "" : convertNameKor(odd.Fixture.Participants[0]);
                    const awayteam = isEmpty(odd.Fixture.Participants) ? "" : convertNameKor(odd.Fixture.Participants[1]);
                    const time = odd.Fixture.StartDate
                    let odd_name = isEmpty(odd.betInfo) ? '' : odd.betInfo.Name;
                    odd_name = convertTeamName(odd_name);
                    return (
                        <Fragment key={oddIndex}>
                            <div className="odds_item_league">
                                <div className="odds_item_league_l">
                                    <div className="otll_img"><img src={imgPath} onError={onErrorImg} className="otll_img22" /></div>
                                    <div className="otll_text">
                                        <span className="otll_text_country">{convertNameKor(odd.Fixture.League.location)} / {convertNameKor(odd.Fixture.League)}</span>
                                    </div>
                                </div>
                                <div className="odds_item_league_r"><img src={sportsImg} className="otll_img11" /></div>
                            </div>
                            <div className="odds_item_info">
                                <div className="odds_item_info_l">
                                    <div className="oiil_team">
                                        <span className="team" style={{ color: "#ff5d5d" }}>{hometeam}</span>
                                        <span className="vs">VS</span>
                                        <span className="team" style={{ color: "#4bffff" }}>{awayteam}</span>
                                    </div>
                                    <div className="oiil_time">{dateFormation(time, 11)}</div>
                                </div>
                            </div>
                            <div className="odds_item_game_box1">
                                <div className="odds_item_game">
                                    <div className="odds_item_game_type"><div style={{ justifyContent: "center" }}>베팅상세</div></div>
                                    <div className="odds_item_game_bet hover_none">
                                        <div className="history_detail">
                                            <span className="home">{odd.market_name_kor} / {getMarketDetail(odd, odd_name) + " " + Number(odd.rate).toFixed(2)}</span>
                                            <em className = {` ${convertClass(odd.gameResult)}`}>{convertResultType(odd.gameResult)}</em>
                                        </div>
                                    </div>
                                    {
                                        !isEmpty(odd.bet_result) && odd.bet_result.map((result, index) => {
                                            const resultPrice = result.Price.find(price => price.version === -1);
                                            const winIndex = odd.bet_result.findIndex(result => result.Status === 2);
                                            return ((result.Status === 2) || ((result.Status === 6) && (winIndex < 0))) &&(
                                                <Fragment key={index}>
                                                     <div className="odds_item_game_type"><div style={{ justifyContent: "center" }}>경기결과</div></div>
                                                    <div className="odds_item_game_bet hover_none" key={index}>
                                                        <div className="history_detail">
                                                            <span className="home">{getMarketDetail(odd, result.Name) + " 승 " +
                                                                fixedNumber(isEmpty(resultPrice) ?
                                                                    (isEmpty(result.Price[0]) ? 0 : result.Price[0].Price)
                                                                    : resultPrice.Price)}</span>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Fragment>
                    )
                })
            }
            <div className="bet_history_total">
                <div className="m_bet_info"><span className="icon_info">베팅시간</span>{(!isEmpty(history) && !isEmpty(history.data)) ? dateFormation(history.data.created_date, 10) : ""}</div>
                <div className="m_bet_info"><span className="icon_info">베팅금액</span>{(!isEmpty(history) && !isEmpty(history.data)) ? Number(history.data.balance.bet).toLocaleString() : ""}</div>
                <div className="m_bet_info"><span className="icon_info">전체배당</span>{(!isEmpty(history) && !isEmpty(history.data)) ? fixedNumber(Number(history.data.rate)) : ""}</div>
                <div className="m_bet_info"><span className="icon_info">예상적중금액</span>{(!isEmpty(history) && !isEmpty(history.data)) ? Math.floor((fixedNumber(Number(history.data.rate)) * Number(history.data.balance.bet))).toLocaleString() : ""}</div>
                <div className="m_bet_info"><span className="icon_info">적중금액</span>{(!isEmpty(history) && !isEmpty(history.data)) ? Number(history.data.balance.win).toLocaleString() : ""}</div>
                <div className="m_bet_info"><span className="icon_info">적중여부</span><span className = {` ${convertClass(history.data.type)}`}>{(!isEmpty(history) && !isEmpty(history.data)) ? convertTypeString(history.data.type) : ""}</span></div>
                <div className="m_bet_info">
                    <span className="icon_info">취소</span>
                    {
                        (!isEmpty(history) && !isEmpty(history.data))  && (((history.data.type === 'waiting') && (active != "live") && (history.data.status)) && 
                        <i className="fa-solid fa-trash-can" onClick={() => CancelBetting(history._id)}></i>)
                    }
                </div>
            </div>
        </div>
    );
};

export default SportsHistory;