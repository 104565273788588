import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './user.slice';
import { betReducer } from './bet.slice';
import { miniReducer } from './mini.slice';
import { moreReducer } from './more.slice';
import { pageReducer } from "./page.slice";
import { sportsReducer } from './sports.slice';

export * from './user.slice';
export * from './bet.slice';
export * from './mini.slice';
export * from './more.slice';
export * from "./page.slice";
export * from "./sports.slice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        bet: betReducer,
        mini: miniReducer,
        more: moreReducer,
        page: pageReducer,
        sports: sportsReducer 
    },
});