import React from 'react';

const CouponItem = (props) => {
    const {data, onActiveId} = props;
    return (
        <div className="box_coupon margin-30px-top">
            <div className="coupon_container" onClick={() => onActiveId(data._id)}>
                <div className="lottery_result">
                    <strong className="h-100- padding-15px-top-" style={{ textAlign: "center", fontSize: "2rem" }}>
                        {data.name}
                        {/* <br /> */}
                        <br />
                        <span className="font-size-16 font-weight-400">{data.type}</span>
                        <br />
                        <span className="font-size-16 font-weight-400">{data.startTime} ~ {data.endTime}</span>
                        <br />
                        <span className={`font-size-16 font-weight-400 `}>
                            {data.amount}
                        </span>
                    </strong>
                </div>
            </div>
        </div>
    );
};

export default CouponItem;