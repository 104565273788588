import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty, showMessage, toastr } from 'Common';
import { request } from 'Common/request';
import { pageActions } from './page.slice';
import { api_url } from 'Common/keys';
import { store, userActions } from '.';


// create slice

const name = 'mini';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const miniActions = { ...slice.actions, ...extraActions };
export const miniReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        type: 0,
        bettingList: [],      // minigame betting list data
        data: {
            odd_key: '',
            rate: 0,
            name: '-'
        },
        rates: {
            normal: {
                evenOdd: 1,
                overUnder: 1,
                big: 1,
                medium: 1,
                small: 1,
                odd: 1,
                even: 1,
                nugurileft: 1,
                nuguriright: 1,
                line3: 1,
                line4: 1,
                left: 1,
                right: 1,
            },
            power: {
                evenOdd: 1,
                overUnder: 1
            },
            strike: {
                left: 1,
                right: 1,
                middle: 1
            },
            homerun: {
                left: 1,
                right: 1,
                middle: 1
            },
            kicker: {
                kicker_left: 1,
                kicker_right: 1
            },
            keeper: {
                keeper_left: 1,
                keeper_right: 1
            },
            goal: {
                goal_left: 1,
                goal_right: 1
            }
        },
        btn_status: {
            normal_evenodd: true,
            normal_overunder: true,
            normal_sum: true,
            power_evenodd: true,
            power_overunder: true,
            normal_leftright: true,
            normal_line: true,
            normal_strike: true,
            normal_homerun: true,
            strike_homerun: true,
            normal_kicker: false,
            normal_keeper: false,
            normal_goal: false,
            normal_baccarat: true,
            normal_gostop: true,
            normal_sotda: true,
         },
        state: {},
        leftTime: "",
        miniCheck: true,
    }
}

function createReducers() {
    return {
        setBetData,
        getPowerBallBettingList,
        setMiniRates,
        setBetType,
        setMiniCheck,
        SetBtnStatus
    };

    function SetBtnStatus(state,action)
    {
        state.data = action.payload.btn_status
    }

    function setBetData(state, action) {
        state.data = action.payload;
    }

    function setBetType(state, action) {
        state.type = action.payload;
    }

    function setMiniCheck(state, action) {
        state.miniCheck = action.payload;
    }

    function getPowerBallBettingList(state, action) {
        state.bettingList = action.payload;
    }

    function setMiniRates(state, action) {
        state.rates = action.payload.rates;
        state.state = action.payload.state;
        state.leftTime = action.payload.leftTime;
        state.btn_status =  action.payload.btn_status
    }

}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        doMiniBetting: doMiniBetting(),
        clearMiniBetStore: clearMiniBetStore(),
        getBettingList: getBettingList(),
    };

    function clearMiniBetStore() {
        return createAsyncThunk(
            `${name}/clearMiniBetStore`,
            async function (data, { dispatch }) {
                dispatch(miniActions.setBetData({
                    odd_key: '',
                    rate: 0,
                    name: '-'
                })
                )
            }
        )
    }

    function getBettingList() {
        return createAsyncThunk(
            `${name}/getBettingList`,
            async function (data, { dispatch }) {
                try {
                    request.post(api_url + '/api/Minigame/getBettingHistory', data)
                        .then(data => {
                            if (!data.status) {
                                dispatch(miniActions.getPowerBallBettingList(data.bets));
                                dispatch(pageActions.setPageData(data));
                            }
                        })
                } catch (error) {
                    console.error(error);


                }
            }
        );
    }

    function doMiniBetting() {
        return createAsyncThunk(
            `${name}/doMiniBetting`,
            async function (amount, { dispatch }) {
                const { data, type } = store.getState().mini;
                const page = store.getState().page;
                if (isEmpty(data.odd_key) || isEmpty(data.rate) || data.name === '-') {
                    return toastr.warning('선택해주세요.')
                }

                dispatch(miniActions.clearMiniBetStore());
                try {
                    request.post(api_url + '/api/Minigame/betting',
                        {
                            type: (type).toString(),
                            amount: amount,
                            odd_key: data.odd_key,
                            name: data.name
                        })
                        .then(data => {
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(miniActions.clearMiniBetStore());
                                dispatch(miniActions.getBettingList({ type: type.toString(), page: 1, perPage: page.perPage, gameResult: "" }));
                                dispatch(userActions.getUserInfo());
                            }
                        })
                } catch (error) {
                    console.error(error);


                }
            }
        );
    }
}