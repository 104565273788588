import { useDispatch, useSelector } from 'react-redux';
import { getGameLaunch, getGameList, getImages, getLobbylist, getTitle, getlistImgPath, getlistOverImgPath, isEmpty, toastr } from "Common";
import { useEffect, useMemo, useState } from "react";
import { pageActions } from 'Common/store';
//import Barnner from 'Components/common/Barnner';

const casinoBarnner = "../images/visual_casino.jpg"
const slotBarnner = "../images/visual_slot.jpg"

const SlotCasinoContent = (props) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const userData = useSelector(state => state.user.data);

    const { type } = props;
    const [lists, setLists] = useState([]);
    const [gameLists, setGameLists] = useState([]);
    const [imgLists, setImgLists] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    // const [api, setApi] = useState("lobby");

    const [search, setSearch] = useState("");

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearch(value);
    }

    const getLists = () => {
        const { type } = props;
        dispatch(pageActions.onSetPageLoading(true));
        const params = {
            type: type,
            token: userData.loginId,
            username: userData.loginId,
        }
        getLobbylist(params).then(data => {
            // setApi(data.api);
            setLists(data.lobbyList);
            dispatch(pageActions.onSetPageLoading(false));
        })
    }

    const onClickOpenModal = (list) => {
        const { type } = props;
        if (!isAuthenticated)
            return toastr.warning("로그인하시고 이용해주세요.");
        dispatch(pageActions.onSetPageLoading(true));
        setGameLists([]);
        const params = {
            ...list,
            type: type,
            username: userData.loginId,
            // vendor: list.vendor,
            // api: api
        }

        getGameList(params).then(data => {
            // setApi(data.api);
            getImages(data.result, data.api)
                .then((images) => {
                    setImgLists(images);
                    setGameLists(data.result);
                    setOpenModal(true);
                    dispatch(pageActions.onSetPageLoading(false));
                });

        })
    }

    const onClickCloseModal = () => {
        setOpenModal(false);
    }

    const onClickList = (list) => {
        dispatch(pageActions.onSetPageLoading(true));
        // console.log(localStorage);
        const params = {
            ...list,
            type: type,
            username: userData.loginId,
            ipADD: localStorage.getItem('client-ip')
        }
        getGameLaunch(params);
    }

    useEffect(() => {
        if (isAuthenticated && !isEmpty(userData)) {
            getLists();
        }

    }, [userData]);



    const normalImage = `basic.png`;
    const normalHoverImage = `basic.png`;
    const detailNormalImage = `basic1.jpg`;
    const modalImage = "AstiLogo_.jpg";

    const getNormalImg = e => {
        // console.log(e.target.src);
        e.target.src = `../images/${type}/${normalImage}`;
    };
    const getDetailNormalImg = e => {
        // console.log(e.target.src);
        e.target.src = `../images/${type}/${detailNormalImage}`;
    };

    const getNormalOverImg = (e) => {
        e.target.src = `../images/${type}/${normalHoverImage}`
        // e.target.style.display = "none";
    }

    const getModalImg = e => {
        // console.log(e.target.src);
        e.target.src = `../images/${type}/${normalImage}`;
    };

    const img = useMemo(() => type === "Casino" ? casinoBarnner : slotBarnner, [type])

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                {/* <img src={img} /> */}
                {type == "Casino" ?
                    <div className="title" style={{ marginBottom: "5px" }}><div className='slot_tit'><img src='../images/casino_icon.png' />정식카지노&nbsp;</div><span className='slot_tit_text'>총 12 개의 라이브 카지노 게임 제공사가 준비되어 있습니다.</span></div>
                    : <div className="title" style={{ marginBottom: "5px" }}>
                        <div className='slot_tit' ><img src='../images/slot_icon.png' />슬롯머신&nbsp;</div>
                        <span className='slot_tit_text'>공정성과 재미가 보장되는 1000여가지 메이저 슬롯머신</span></div>
                }

                {
                    !openModal ?
                        <div className="con_box10" style={{ backgroundColor: "unset" }}>
                            <div className={type == "Casino" ? "game_list" : "game_list_slot"}>
                                <ul>
                                    {
                                        lists.map((list, index) => {
                                            return (
                                                <li key={index} onClick={
                                                    type === "Slot"
                                                        ? () => onClickOpenModal(list)
                                                        : () => onClickList(list)
                                                }>
                                                    <div className="game_wrap">
                                                        <img className="model slot_list_img" src={getlistImgPath(list)} onError={getNormalImg} alt="" />
                                                        {/* <img className="slot_list_over_img" src={getlistOverImgPath(list)} alt="" onError={getNormalOverImg} /> */}
                                                        {/* <div className="txt_wrap">
                                                        <div className="sub">
                                                            <div className='sub_title'>
                                                                {getTitle(list)}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {/* <li><div><img src="images/slot01.png" /></div></li> */}
                                </ul>
                            </div>
                        </div>
                        :
                        <div className="justify-content-center slot-modal" style={{ listStyleType: "none" }}>
                            <div className='slot_title_back' onClick={onClickCloseModal}>뒤로가기</div>
                            <div className="slot-search ">
                                <input className="slot-search-input "
                                    name="search" type="text"
                                    placeholder="검색"
                                    value={search} onChange={onChange} />
                            </div>
                            <div className="con_box10 h-100" style={{ backgroundColor: "unset" }}>
                                <div className="game_list_slot">
                                    <ul>
                                        {
                                            gameLists.filter(list => {
                                                const patt = new RegExp(search, "i");
                                                return patt.test(getTitle(list)) || isEmpty(search);
                                            })
                                                .map((list, index) => {
                                                    const imgIndex = imgLists.findIndex(img => img.src.toLocaleLowerCase() === encodeURI(list.image).toLocaleLowerCase());
                                                    if (imgIndex > -1) {
                                                        return (
                                                            <li className='detail_slot_image' key={index} onClick={() => onClickList(list)} >
                                                                <div style={{ width: "100%" }}>
                                                                    <img style={{ maxWidth: "100%", aspectRatio: "28 / 20", width: "100%" }} src={imgLists[imgIndex].src} onError={getDetailNormalImg} alt="" />
                                                                </div>
                                                                <p className="text-center">{getTitle(list)}</p>
                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return "";
                                                    }
                                                })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                }


            </div>
        </div>
    );
};

export default SlotCasinoContent;