import { convertNameKor, history, isEmpty, onErrorImg } from 'Common';
import { resource_url } from 'Common/keys';
import { pageActions, sportsActions } from 'Common/store';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

const Locations = (props) => {

    const dispatch = useDispatch();
    const storeLeague = useSelector(state => state.sports.league)
    const { list, type, itemVal, typeActive, sportId, Id, view } = props;
    const [active, setActive] = useState(false);

    const onLeague = (league) => {
        if (storeLeague != league) {
            dispatch(sportsActions.clearSportsList());
        }
        dispatch(sportsActions.setSportId(sportId));
        dispatch(sportsActions.setLeague(league));
        dispatch(sportsActions.setLocation(Id))
        dispatch(pageActions.setPageData({ page: 1, totalPage: 50 }))
        if (history.location.pathname == "/") {
            history.navigate('/sportsprematch?type=1');
        }
    }

    const onActive = () => {
        setActive(!active)
        if (type === 'esports') {
            dispatch(sportsActions.clearSportsList());
            dispatch(sportsActions.setSportId(sportId));
            dispatch(sportsActions.setLeague(Id));
            dispatch(sportsActions.setLocation("all"))
            dispatch(pageActions.setPageData({ page: 1, totalPage: 50 }))
        }
    }

    return (
        <Fragment>
            <div className="country_frame" onClick={onActive}>
                <img src={isEmpty(list.image) ? props.imgPath : (resource_url + "/images/locations/" + list.image)} className="sports_country_img" onError={onErrorImg} />{convertNameKor(list)}<span className="sports_country_odd">({Number(list.count).toLocaleString()})</span>
                <span className="sports_country_arrow">
                    <i className="fa-solid fa-angle-down"></i>
                </span>
            </div>
            {
                (!isEmpty(list.data) && active) && list.data.map((item, index) => {
                    return (

                        <div className="country_frame01" key={index} onClick={() => { onLeague(item.id); view(); }} >
                            <div className="country"><span className="name">{convertNameKor(item)}</span>{Number(item.count).toLocaleString()}</div>
                        </div>
                    )
                })
            }
        </Fragment>
    );
};

export default Locations;