import React from 'react';

const DepositMethod = () => {
    return (
        <div className="white_box_h" >
            {/* <div className="s_title1">충전방법</div> */}
            <div className="write_tr" >
                {/* <div className="write_title">충전안내</div> */}
                <div className="write_basic" style={{background:"rgb(48, 48, 48)"}}>
                   ※ 가입하실때 기입한 입금자명으로 입금하셔야 처리됩니다.<br />
                   ※ 은행 점검시간동안은 입금이 지연 될 수 있습니다.<br />
                   ※ 계좌문의후 하단 계좌정보나 고객센터 통해서 확인 가능합니다.<br />
                   ※ 입금계좌가 수시로 변경되기때문에 꼭 "계좌문의" 후 신청바랍니다.<br />
                   
                </div>
            </div>
        </div>
    );
};

export default DepositMethod;