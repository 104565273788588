import { ConvertMinigameType, convertClass, convertMinigameName, convertTypeString, dateFormation, isEmpty } from 'Common';
import { miniActions } from 'Common/store';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

const MinigameHistories = (props) => {

    const dispatch = useDispatch();
    const page = useSelector(state => state.page)
    let minigameList = useSelector(state => state.mini.bettingList)
    const { type } = props;

    const GetMiniLists = (page) => {
        dispatch(miniActions.getBettingList({
            type: type,
            page: page,
            perPage: perPage,
        }))
    }

    useEffect(() => {
        GetMiniLists(1)
    }, [type])


    return (
        <div className="con_box10">
            <div className="mini_game_bet_box">
                <div className="mini_game_bet_title"> {ConvertMinigameType(type)} 베팅내역</div>
                <div className="mini_game_bet_list_top">
                    <span>회차</span>
                    <span>베팅시간</span>
                    <span>게임명</span>
                    <span>베팅금액</span>
                    <span>배당율</span>
                    <span>당첨금</span>
                    <span>베팅결과</span>
                </div>
                {
                    !isEmpty(minigameList) && minigameList.map((list, index) => {
                        return (
                            <div className="mini_game_bet_list" key={index}>
                                <span>{list.round}</span>
                                <span>{dateFormation(list.created_date, 10)}</span>
                                <span>{convertMinigameName(list.gameType)}</span>
                                <span>{Number(list.balance.bet).toLocaleString()}</span>
                                <span><span className="font05">{list.odd.rate}</span></span>
                                <span><span className="font07">{Number(list.balance.win).toLocaleString()}</span></span>
                                <span><span className={convertClass(list.type)}>{convertTypeString(list.type)}</span></span>
                            </div>
                        )
                    })
                }
            </div>
            {
                isEmpty(minigameList) ?
                    <div className='no_data'>내역이 없습니다</div>
                    :
                    <Pagenation
                        page={page.curPage}
                        totalPage={page.totalPage}
                        getPage={GetMiniLists}
                    />
            }
        </div>
    );
};

export default MinigameHistories;