import React, { useEffect, useState } from 'react';
import MinigameMenu from './MinigameMenu';
import { Link } from 'react-router-dom';
import { getMinigameUrl, history } from 'Common';
import qs from 'querystringify';
import MinigameLive from './MinigameLive';
import MinigameBetting from './MinigameBetting';
import MinigameHistories from './MinigameHistories';


const minigames = [
    {
        value: '1',
        name: "EOS 1분",
        img:"../images/minigame/1.png",
        hoverImage: "../images/minigame/1_hover.png"
    },
    {
        value: '2',
        name: "EOS 2분",
        img:"../images/minigame/2.png",
        hoverImage: "../images/minigame/2_hover.png"
    },

    {
        value: '3',
        name: "EOS 3분",
        img:"../images/minigame/3.png",
        hoverImage: "../images/minigame/3_hover.png"
    },

    {
        value: '4',
        name: "EOS 4분",
        img:"../images/minigame/4.png",
        hoverImage: "../images/minigame/4_hover.png"
    },

    {
        value: '5',
        name: "EOS 5분",
        img:"../images/minigame/5.png",
        hoverImage: "../images/minigame/5_hover.png"
    },
    {
        value: 'EVOPowerball',
        name: "EVO파워볼",
        img:"../images/minigame/6.png",
        hoverImage: "../images/minigame/6_hover.png"
    },
    {
        value: 'nuguri',
        name: "너구리",
        img:"../images/minigame/7.png",
        hoverImage: "../images/minigame/7_hover.png"
    },

    {
        value: 'soccer',
        name: "축구",
        img:"../images/minigame/8.png",
        hoverImage: "../images/minigame/8_hover.png"
    },

    {
        value: 'baseball',
        name: "야구",
        img:"../images/minigame/9.png",
        hoverImage: "../images/minigame/9_hover.png"
    },

    {
        value: 'squidgame',
        name: "오징어",
        img:"../images/minigame/10.png",
        hoverImage: "../images/minigame/10_hover.png"
    },
]

const MinigameContents = () => {

    const [url, setUrl] = useState("")

    const query = qs.parse(history.location.search);
    const type = query.type
    useEffect(() => {
        let param = type;
        getMinigameUrl(param).then(url => setUrl(url)).catch(err => console.log(err));
    }, [type])
  
    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                { type == 0 ?
                    <>
                        <div className="title" style={{ marginBottom: "5px" }}><img src='../images/mini_icon.png' />미니게임<span style={{ fontSize: "15px", fontWeight: "bold", color: "white" }}>총 10 개의 미니게임이 준비되어 있습니다.</span></div>
                        <div className="con_box10" style={{backgroundColor:"unset"}}>

                            <ul className='mini_game_lists'>
                                {
                                    minigames.map((list, index) => {
                                        return (
                                            <li key={index} className='mini_game' >
                                                <Link to={`?type=${list.value}`} className="game_wrap" style={{border:"unset"}}>
                                                    <img className="model custom_image" src={list.img} alt="" />
                                                    <img className="model custom_hover_image" src={list.hoverImage} alt="" />
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                                {/* <li><div><img src="images/slot01.png" /></div></li> */}
                            </ul>
                        </div>
                    </>
                :
                <>
                    <MinigameMenu type={type} />
                    <div className="con_box10">
                        <div className="mini_game_zone">
                            <MinigameLive url={url} type={type} />
                        </div>
                        <MinigameBetting type={type} />
                    </div>
                    <MinigameHistories type={type} />
                </>
                }
            </div>
        </div>
    );
};

export default MinigameContents;