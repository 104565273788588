import { checkLoginIdstr, isEmpty, toastr } from 'Common';
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from 'Common/store';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from "react-router-dom";

const LoginPage = () => {

    const dispatch = useDispatch();
    const loginError = useSelector(state => state.user.loginError);
    const captchaData = useSelector(state => state.user.captchaData);
    const captchaImg = !isEmpty(captchaData) ? captchaData.image :  '';
    let captchaId = !isEmpty(captchaData) ? captchaData.captchaId : "";
    const [inputs, setInputs] = useState({
        loginId: "",
        loginPwd: "",
        captcha: ""
    })

    const inputRef = useRef();

    const { loginId, loginPwd, captcha } = inputs;

    const onChange = (e) => {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const onCaptcha = () => {
        dispatch(userActions.getCaptcha());
    }

    const onReset = () => {
        setInputs({
            loginId: "",
            loginPwd: "",
            captcha: ""
        })
    }

    useEffect(() => {
        inputRef.current.focus();
        onCaptcha();
    }, [])

    const captchaRefresh = (e) => {
        e.preventDefault()
        onCaptcha();
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (checkLoginIdstr(loginId)) {
            return toastr.warning("아이디에 특수문자를 포함할수 없습니다.")
        }
        if (isEmpty(loginId)) {
            return toastr.warning("아이디를 입력해주세요.")
        }
        if (isEmpty(loginPwd)) {
            return toastr.warning("비밀번호를 입력해주세요.")
        }
        if (isEmpty(captcha)) {
            return toastr.warning("보안문자를 입력해주세요.")
        }
        let params = {
            loginId,
            loginPwd,
            captchaId,
            captcha
        }
        dispatch(userActions.loginUser(params));
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    }
    return (
        <Fragment>

            <div className="contents_center_up" style={{ width: "100%", position: "relative", height: "100vh" }}>
                <div className='login_container'>
                    <div className="contents_center_in contents_center_login" onKeyDown={onEnter}>
                        <div className="logoimg_wrap">
                            <div className="logo_img" >
                                <img src="../images/logo.png" alt="logo" style={{ width: "65%" }} />
                            </div>
                        </div>
                        <div className="ACE_login">
                            <ul>
                                <li><input ref={inputRef} name="loginId" type="text" className="login_input1" placeholder="아이디를 입력하세요." onChange={onChange} /></li>
                                <li><input name="loginPwd" type="password" className="login_input1" placeholder="비밀번호를 입력하세요." onChange={onChange} /></li>
                                <li className='d-flex' style={{border:"1px solid #121212"}} ><img src={captchaImg} className='captchaImg'/><div className='captcha_refresh' onClick={captchaRefresh}><i className='fa fa-refresh'></i></div></li>
                                <li><input name="captcha" type="text" className="login_input1" placeholder="보안문자를 입력하세요." onChange={onChange} /></li>
                            </ul>
                        </div>
                        <div className="con_box10">
                            <div className="btn_wrap_center">
                                <ul>
                                    <li style={{ marginRight: "5px" }} onClick={e => onSubmit(e)}><a ><span className="btn2_1">로그인</span></a></li>
                                    <li style={{ marginLeftt: "5px" }} ><Link to="/signup"><span className="btn2_2">회원가입</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LoginPage;