import RouletteContents from 'Components/Events/RouletteContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Roulette = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar />
            <RouletteContents />
            <Right type="roulette"/>
        </Fragment>
    );
};

export default Roulette;