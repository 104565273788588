export const betGameAllowMarket = [
  {
    name: "Soccer",
    id: "Soccer",
    allowMarkets: [
      {
        name: "wintielose",
        market: "wintielose",
      },
    ]
  },
  {
    name: "Basketball",
    id: "Basketball",
    allowMarkets: [
      {
        name: "homeaway",
        market: "homeaway",
      },
      {
        name: "underover",
        market: "underover",
      },
    ]
  },
  {
    name: "Football",
    id: "Football",
    allowMarkets: [
      {
        name: "wintielose",
        market: "wintielose",
      },
    ]
  },
  {
    name: "Tennis",
    id: "Tennis",
    allowMarkets: [
      {
        name: "homeaway",
        market: "homeaway",
      },
    ]
  },
  {
    name: "Cricket",
    id: "Cricket",
    allowMarkets: [
      {
        name: "wintielose",
        market: "wintielose",
      },
    ]
  },
  {
    name: "Darts",
    id: "Darts",
    allowMarkets: [
      {
        name: "homeaway",
        market: "homeaway",
      },
    ]
  },
  {
    name: "Greyhounds",
    id: "Greyhounds",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Horse",
    id: "Horse",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Motor",
    id: "Motor",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Speedway",
    id: "Speedway",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Trotting",
    id: "Trotting",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Cycling",
    id: "Cycling",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
]


export const lotusGameAllowMarket = [
  {
    name: "Soccer",
    id: "Soccer",
    allowMarkets: [
      {
        name: "wintielose",
        market: "wintielose",
      },
      {
        name: "handicap",
        market: "handicap",
      },
      {
        name: "underover",
        market: "underover",
      },
    ]
  },
  {
    name: "Basketball",
    id: "Basketball",
    allowMarkets: [
      {
        name: "homeaway",
        market: "homeaway",
      },
      {
        name: "underover",
        market: "underover",
      },
      {
        name: "handicap",
        market: "handicap",
      },
    ]
  },  
  {
    name: "Horse",
    id: "Horse",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Greyhounds",
    id: "Greyhounds",
    allowMarkets: [
      {
        name: "oneteam",
        market: "oneteam",
      },
    ]
  },
  {
    name: "Baseball",
    id: "Baseball",
    allowMarkets: [
      {
        name: "wintielose",
        market: "wintielose",
      },
      {
        name: "underover",
        market: "underover",
      },
    ]
  },
]