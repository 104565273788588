import MinigameContents from 'Components/Minigame/MinigameContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Minigame = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar type="minigame"/>
            <MinigameContents/>
            <Right type="minigame"/>
        </Fragment>
    );
};

export default Minigame;