import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { pageActions, store, userActions } from '.';
import { isEmpty, showMessage, toastr } from 'Common';
import { SiteName, perPage } from 'Utils';
import { request } from 'Common/request';
import { api_url } from 'Common/keys';
import axios from "axios"



const name = 'more';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const moreActions = { ...slice.actions, ...extraActions };
export const moreReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        language: "한국어",
        slideBar: true,
        exchangeSetting: {},
        message: {
            notes: [],
            unreadNotes: []
        },
        deposit: {
            data: [],
            bonusList: []
        },
        Fivedeposit: {
            data: []
        },
        withdraw: {
            data: []
        },
        Fivewithdraw: {
            data: []
        },
        pointHistory: [],
        pointExchange: {
            data: [],
            sum: {
                today: 0,
                thisMonth: 0,
                lastMonth: 0
            }
        },
        customerCenter: {
            data: []
        },
        dailyCheck: {
            histories: [],
            currentMonthCount: 0,
            depositLimit: 0,
            aDayMoney: 0,
            bDayMoney: 0,
            cDayMoney: 0,
            attendance: 0
        },
        lottery: {
            history: [],
            leftCount: 0
        },
        roulette: {
            history: [],
            leftCount: 0,
            roulette: {},
            bonus: {}
        },
        coupon: {
            list: []
        },
        event: {
            types: [],
            posts: []
        },
        notice: {
            notices: []
        },
        depositWithdrawStatusList: [],
        histories: [],
        partners: [],
        historyType: "prematch"
    }
}

function createReducers() {
    return {
        setChangeLanguage,
        changeSlideStatus,
        setMessageData,
        getDepositData,
        getWithdrawData,
        setPointHistory,
        setExchangeData,
        getDepositSum,
        getWithdrawSum,
        getHistories,
        getPartners,
        setQuestionList,
        setDailyCheckHistory,
        getDailyCheckInfo,
        getLotteryData,
        getRouletteData,
        setRouletteRules,
        setCouponList,
        getEventType,
        getEventList,
        getNotices,
        getRouletteBonus,
        getFiveDepositData,
        getFiveWithdrawData,
        setBonusList,
        setExchangeSetting,
        setDepositWithdrawStatus

    };
    function setBonusList(state, action) {
        state.deposit.bonusList = action.payload
    }

    function setChangeLanguage(state, action) {
        state.language = action.payload
    }

    function changeSlideStatus(state, action) {
        state.slideBar = action.payload
    }

    function setMessageData(state, action) {
        state.message.notes = action.payload.notes
        state.message.unreadNotes = action.payload.unreadNotes
    }

    function getFiveDepositData(state, action) {
        state.Fivedeposit.data = action.payload
    }
    function getDepositData(state, action) {
        state.deposit.data = action.payload
    }
    function getFiveWithdrawData(state, action) {
        state.Fivewithdraw.data = action.payload
    }

    function getWithdrawData(state, action) {
        state.withdraw.data = action.payload
    }

    function setPointHistory(state, action) {
        state.pointHistory = action.payload
    }

    function setExchangeData(state, action) {
        state.pointExchange.data = action.payload
    }

    function getDepositSum(state, action) {
        state.deposit.sum = action.payload
    }

    function getWithdrawSum(state, action) {
        state.withdraw.sum = action.payload
    }

    function getHistories(state, action) {
        state.histories = action.payload.bets
        state.historyType = action.payload.type

    }

    function getPartners(state, action) {
        state.partners = action.payload
    }

    function setQuestionList(state, action) {
        state.customerCenter.data = action.payload
    }

    function setDailyCheckHistory(state, action) {
        state.dailyCheck.depositLimit = action.payload.depositLimit
        state.dailyCheck.aDayMoney = action.payload.aDayMoney
        state.dailyCheck.bDayMoney = action.payload.bDayMoney
        state.dailyCheck.cDayMoney = action.payload.cDayMoney
        state.dailyCheck.currentMonthCount = action.payload.currentMonthCount
        state.dailyCheck.histories = action.payload.history
    }

    function getDailyCheckInfo(state, action) {
        state.dailyCheck.attendance = action.payload
    }

    function getLotteryData(state, action) {
        state.lottery.history = action.payload.history
        state.lottery.leftCount = action.payload.leftCount
    }

    function getRouletteData(state, action) {
        state.roulette.history = action.payload.history
        state.roulette.leftCount = action.payload.leftCount
        state.roulette.bonus = action.payload.bonus
    }

    function getRouletteBonus(state, action) {
        state.roulette.bonus = action.payload.bonus
    }

    function setRouletteRules(state, action) {
        action.payload.roulette.list.sort((a, b) => {
            return a.bonus.balance.rolling - b.bonus.balance.rolling
        })
        state.roulette.roulette = action.payload.roulette

        // return produce(action, draft => {
        //     debugger

        //     debugger
        //   });
    }

    function setCouponList(state, action) {
        state.coupon.list = action.payload
    }

    function getEventType(state, action) {
        state.event.types = action.payload
    }

    function getEventList(state, action) {
        state.event.posts = action.payload
    }

    function getNotices(state, action) {
        state.notice.notices = action.payload
    }

    function setExchangeSetting(state, action) {
        state.exchangeSetting = action.payload.data;
    }
    function setDepositWithdrawStatus(state, action) {
        state.depositWithdrawStatusList = action.payload;
    }
}

function createExtraActions() {
    return {
        getMessageData: getMessageData(),
        onGetEventList: onGetEventList(),
        getNoticeList: getNoticeList(),
        getDepositList: getDepositList(),
        doDeposit: doDeposit(),
        DoQuestion: DoQuestion(),
        getQuestionList: getQuestionList(),
        confirmMessage: confirmMessage(),
        getExchangeData: getExchangeData(),
        doExchange: doExchange(),
        deleteExchange: deleteExchange(),
        getBettingHistories: getBettingHistories(),
        getPointHistory: getPointHistory(),
        getDailyCheckHistory: getDailyCheckHistory(),
        usingCoupon: usingCoupon(),
        getCouponList: getCouponList(),
        getLotteryHistory: getLotteryHistory(),
        getRouletteHistory: getRouletteHistory(),
        getRouletteRules: getRouletteRules(),
        doRoulette: doRoulette(),
        getFivedayDepositList: getFivedayDepositList(),
        delDepositList: delDepositList(),
        getWithdrawList: getWithdrawList(),
        getFivedayWithdrawList: getFivedayWithdrawList(),
        delWithdrawList: delWithdrawList(),
        doFiveDeposit: doFiveDeposit(),
        doFiveWithdraw: doFiveWithdraw(),
        DeleteMessage: DeleteMessage(),
        GetBonusList: GetBonusList(),
        GetExchangeSetting: GetExchangeSetting(),
        getDepositWithdrawStatus: getDepositWithdrawStatus()
    }


    function GetExchangeSetting() {
        return createAsyncThunk(
            `${name}/GetExchangeSetting`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true))
                    axios
                        .post(api_url + "/api/Money/getExchangeSetting", {})
                        .then(res => {
                            dispatch(pageActions.onSetPageLoading(false))
                            if (!res.data.success) {
                                return toastr.warning("잠시후 다시 시도해주세요.")
                            }
                            dispatch(moreActions.setExchangeSetting(res.data.data))
                            dispatch(pageActions.setPageData(res.data));
                        })
                        .catch(err => {
                            console.error('err: ', err);
                            dispatch(pageActions.onSetPageLoading(false))
                            if (err.response && err.response.status === 401) {
                                dispatch(userActions.logoutUser())
                            }
                            else toastr.warning("잠시후 다시 시도해주세요.");
                        });
                }
                catch (error) {
                    dispatch(pageActions.onSetPageLoading(false))
                    console.log(error)
                }
            }
        )
    };
    function GetBonusList() {
        return createAsyncThunk(
            `${name}/getMessageData`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    axios
                        .post(api_url + "/api/Money/getBonusList", {})
                        .then(res => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(res.data.message);
                            if (!res.data.status) {
                                dispatch(moreActions.setBonusList(res.data.bonus_list))
                            }
                        })
                        .catch(err => {
                            console.error('err: ', err);
                            dispatch(pageActions.onSetPageLoading(false));
                            if (err.response && err.response.status === 401) {
                                dispatch(userActions.logoutUser());
                            }
                            else toastr.warning("잠시후 다시 시도해주세요.");
                        });
                }
                catch (error) {
                    dispatch(pageActions.onSetPageLoading(false))
                    console.log(error)
                }

            }
        )
    }

    function getMessageData() {
        return createAsyncThunk(
            `${name}/getMessageData`,
            async function (params, { dispatch }) {
                if (!params.noneLoading) {
                    dispatch(pageActions.onSetPageLoading(true));
                }
                try {
                    request.post(api_url + "/api/notes/getList", { ...params, type: SiteName })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(pageActions.onSetMessagePage(data));
                            if (!params.unSetPage) {
                                dispatch(pageActions.setPageData(data));
                            }
                            if (isEmpty(data.unreadNotes)) dispatch(pageActions.onSetMessagePage(false));
                            else dispatch(pageActions.onSetMessagePage(true));
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false))
                    console.error(error);
                }
            }
        );
    }

    function onGetEventList() {
        return createAsyncThunk(
            `${name}/onGetEventList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Post/list", {
                        ...params,
                        type: SiteName
                    })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.getEventList(data.list));
                                dispatch(pageActions.setPageData(data));
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )

    };
    function getDepositWithdrawStatus() {
        return createAsyncThunk(
            `${name}/getDepositWithdrawStatus`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Money/getRealTimeExchangeData")
                        .then(data => {
                            if (data.success) {
                                dispatch(moreActions.setDepositWithdrawStatus(data.exchange_list));
                            }
                            showMessage(data.message);
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )

    };

    function getNoticeList() {
        return createAsyncThunk(
            `${name}/getNoticeList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Notice/list", { ...params, type: SiteName })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.getNotices(data.list));
                                dispatch(pageActions.setPageData(data));
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getMessageData() {
        return createAsyncThunk(
            `${name}/getMessageData`,
            async function (params, { dispatch }) {
                try {
                    if (!params.noneLoading) {
                        dispatch(pageActions.onSetPageLoading(true));
                    }
                    request.post(api_url + "/api/notes/getList", { ...params, type: SiteName })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.setMessageData(data));
                            if (!data.unSetPage) {
                                dispatch(pageActions.setPageData(data));
                            }
                            if (isEmpty(data.unreadNotes)) dispatch(pageActions.onSetMessagePage(false));
                            else dispatch(pageActions.onSetMessagePage(true));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function DeleteMessage() {
        return createAsyncThunk(
            `${name}/DeleteMessage`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/notes/noteRemove", {})
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getMessageData({
                                page: 1,
                                perPage: perPage
                            }));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getDepositList() {
        return createAsyncThunk(
            `${name}/getDepositList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/getHistory", {
                        typeStr: params.typeStr,
                        page: params.page,
                        perPage: store.getState().page.perPage
                    })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getDepositData(data.histories));
                            dispatch(moreActions.getDepositSum(data.sum));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getWithdrawList() {
        return createAsyncThunk(
            `${name}/getDepositList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/getHistory", {
                        typeStr: params.typeStr,
                        page: params.page,
                        perPage: store.getState().page.perPage
                    })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getWithdrawData(data.histories));
                            dispatch(moreActions.getWithdrawSum(data.sum));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    //5일동안의 입출금 내역목록
    function getFivedayDepositList() {
        return createAsyncThunk(
            `${name}/getFivedayDepositList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/getRoyceHistory", {
                        typeStr: params.typeStr,
                        page: params.page,
                        perPage: params.perPage
                    })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getFiveDepositData(data.histories));
                            dispatch(moreActions.getDepositSum(data.sum));
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getFivedayWithdrawList() {
        return createAsyncThunk(
            `${name}/getFivedayDepositList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/getRoyceHistory", {
                        typeStr: params.typeStr,
                        page: params.page,
                        perPage: params.perPage
                    })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getFiveWithdrawData(data.histories));
                            dispatch(moreActions.getWithdrawSum(data.sum));
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };


    function doDeposit() {
        let isLoadingNow = false;
        return createAsyncThunk(
            `${name}/doDeposit`,
            async function (params, { dispatch }) {
                try {
                    const newParams = {
                        amount: params.money,
                        typeStr: params.typeStr,
                        withdrawPassword: params.withdrawPassword,
                        bonus: params.depositBonus,
                        secretKey: params.secretKey
                    }
                    isLoadingNow = true;
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/exchange", newParams)
                        .then(data => {
                            setTimeout(() => { isLoadingNow = false }, 500);
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getDepositList(newParams));
                            // dispatch(moreActions.getFivedayDepositList(newParams));
                            // dispatch(moreActions.getFivedayWithdrawList(newParams));
                            dispatch(userActions.getUserInfo());
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function doFiveDeposit() {
        let isLoadingNow = false;
        return createAsyncThunk(
            `${name}/doFiveDeposit`,
            async function (params, { dispatch }) {
                try {
                    // debugger
                    const newParams = {
                        amount: params.money,
                        typeStr: params.typeStr,
                        bonus: params.bonus,
                        secretKey: params.secretKey
                    }
                    isLoadingNow = true;
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/exchange", newParams)
                        .then(data => {
                            setTimeout(() => { isLoadingNow = false }, 500);
                            dispatch(pageActions.onSetPageLoading(false));
                            if (!data.status) {
                                if (newParams.typeStr === "deposit") {
                                    toastr.success(`${newParams.amount}원 입금신청되었습니다.`);
                                } else {
                                    toastr.success(`${newParams.amount}원 환전신청되었습니다.`);
                                }
                                dispatch(moreActions.getFivedayDepositList({
                                    typeStr: params.typeStr,
                                    page: 1,
                                    perPage: perPage
                                }));
                                dispatch(moreActions.GetBonusList());
                                dispatch(userActions.getUserInfo());
                                return;
                            }
                            showMessage(data.message)
                            dispatch(moreActions.getDepositList(newParams.typeStr, 1));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function doFiveWithdraw() {
        let isLoadingNow = false;
        return createAsyncThunk(
            `${name}/doDeposit`,
            async function (params, { dispatch }) {
                try {
                    const newParams = {
                        amount: params.money,
                        typeStr: params.typeStr,
                        withdrawPassword: params.withdrawPassword,
                        bonus: params.depositBonus,
                        secretKey: params.secretKey
                    }
                    isLoadingNow = true;
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/exchange", newParams)
                        .then(data => {
                            setTimeout(() => { isLoadingNow = false }, 500);
                            dispatch(pageActions.onSetPageLoading(false));
                            if (!data.status) {
                                if (newParams.typeStr === "deposit") {
                                    toastr.success(`${newParams.amount}원 입급신청되었습니다.`);
                                } else {
                                    toastr.success(`${newParams.amount}원 환전신청되었습니다.`);
                                }
                                dispatch(moreActions.getFivedayWithdrawList({
                                    typeStr: params.typeStr,
                                    page: 1,
                                    perPage: perPage
                                }));
                                dispatch(moreActions.GetBonusList());
                                dispatch(userActions.getUserInfo());
                                return;
                            }
                            showMessage(data.message);
                            dispatch(moreActions.getWithdrawList(newParams.typeStr, 1));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function delDepositList() {
        return createAsyncThunk(
            `${name}/delDepositList`,
            async function (params, { dispatch }) {
                try {
                    const newprams = {
                        typeStr: params.typeStr,
                        page: 1,
                        perPage: perPage
                    }
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/deleteHistory", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getDepositList(params.typeStr, 1));
                            dispatch(moreActions.getFivedayDepositList(newprams));
                            dispatch(userActions.getUserInfo());
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function delWithdrawList() {
        return createAsyncThunk(
            `${name}/delDepositList`,
            async function (params, { dispatch }) {
                try {
                    const newprams = {
                        typeStr: params.typeStr,
                        page: 1,
                        perPage: perPage
                    }
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/deleteHistory", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getWithdrawList(params.typeStr, 1));
                            dispatch(moreActions.getFivedayWithdrawList(newprams));
                            dispatch(userActions.getUserInfo());
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function DoQuestion() {
        return createAsyncThunk(
            `${name}/DoQuestion`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Question/send", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    }

    function getQuestionList() {
        return createAsyncThunk(
            `${name}/getQuestionList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Question/getList", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.setQuestionList(data.questions));
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function confirmMessage() {

        return createAsyncThunk(
            `${name}/confirmMessage`,
            async function (params, { dispatch }) {
                const { unreadNotes: notes } = store.getState().more.message;

                const noteIndex = notes.findIndex(
                    item => item.new === true && item._id === params
                );

                if (params != "all" && noteIndex < 0) return;

                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/notes/getContent", { id: params })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            const pageData = store.getState().page;
                            dispatch(
                                moreActions.getMessageData({ page: pageData.curPage, perPage: pageData.perPage, noneLoading: true })
                            );
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getExchangeData() {
        return createAsyncThunk(
            `${name}/getExchangeData`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/getRollingHistory", { page: params.page, perPage: store.getState().page.perPage })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.setExchangeData(data.histories));
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function doExchange() {
        return createAsyncThunk(
            `${name}/doExchange`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/rollingChange", { amount: params })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getExchangeData(store.getState().page.curPage));
                            dispatch(userActions.getUserInfo());
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function deleteExchange() {
        return createAsyncThunk(
            `${name}/deleteExchange`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/deleteRollingHistory", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.getExchangeData(data.type));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getBettingHistories() {
        return createAsyncThunk(
            `${name}/getBettingHistories`,
            async function (params, { dispatch }) {
                try {
                    if (params.clear) {
                        dispatch(moreActions.getHistories({ bets: [] }));
                    }
                    dispatch(pageActions.onSetPageLoading(true));
                    let url = api_url;
                    switch (params.type) {
                        case "interior":
                        case "home_prematch":
                        case "prematch":
                        case "live":
                        case "esport":
                            url = url + "/api/Sports/getBettingHistory";
                            break;

                        case "minigame":
                            url = url + "/api/Minigame/getBettingHistory";
                            break;

                        case "betgame365":
                            url = url + "/api/Betgame/getBettingHistory";
                            break;

                        case "casino":
                        case "slot":
                            url = url + "/api/Slot/getBettingHistory";
                            break;
                        default:
                            break;
                    }
                    if (params.type) {
                        request.post(url, params)
                            .then(data => {
                                dispatch(pageActions.onSetPageLoading(false));
                                if (!data.status) {
                                    dispatch(moreActions.getHistories(data));
                                    dispatch(pageActions.setPageData(data));

                                } else {
                                    showMessage(data.message);

                                }
                            })
                    }
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getPointHistory() {
        return createAsyncThunk(
            `${name}/getPointHistory`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Money/getPointHistory", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            dispatch(moreActions.setPointHistory(data.histories));
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getDailyCheckHistory() {
        return createAsyncThunk(
            `${name}/getDailyCheckHistory`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Attendance/history", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.setDailyCheckHistory(data));
                            }
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getCouponList() {
        return createAsyncThunk(
            `${name}/getCouponList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Coupon/list", {})
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.setCouponList(data.list));
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function usingCoupon() {
        return createAsyncThunk(
            `${name}/useCoupon`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Coupon/use", { id: params })
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.getCouponList());
                                dispatch(userActions.getUserInfo());
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getLotteryHistory() {
        return createAsyncThunk(
            `${name}/getLotteryHistory`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Lottery/history", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.getLotteryData(data));
                                dispatch(userActions.getUserInfo());
                            }
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getRouletteHistory() {
        return createAsyncThunk(
            `${name}/getRouletteHistory`,
            async function (params, { dispatch }) {
                try {
                    if (params.loading) {
                        dispatch(pageActions.onSetPageLoading(true));
                    }
                    request.post(api_url + "/api/Roulette/history", params)
                        .then(data => {
                            if (params.loading) {
                                dispatch(pageActions.onSetPageLoading(false));
                            }
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.getRouletteData(data));
                            }
                            dispatch(pageActions.setPageData(data));
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function getRouletteRules() {
        return createAsyncThunk(
            `${name}/getRouletteRules`,
            async function (params, { dispatch }) {
                const pageData = store.getState().page
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Roulette/info", {})
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.setRouletteRules(data));
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

    function doRoulette() {
        return createAsyncThunk(
            `${name}/doRoulette`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Roulette/open", {})
                        .then(data => {
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(moreActions.getRouletteBonus(data.history))
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    };

}
