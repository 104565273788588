import { convertClass, convertTypeString, dateFormation, isEmpty } from 'Common';
import React from 'react';

const CasinoHistory = (props) => {

    const { history, page, index } = props
    return (
        <div className="odds_item odd_item_country" key={index}>
            <div className="bet_history_total">
                <div className="m_bet_info_slot"><span className="icon_info">베팅시간</span>{isEmpty(history) ? '' : dateFormation(history.created_date, 10)}</div>
                <div className="m_bet_info_slot"><span className="icon_info">베팅금액</span>{isEmpty(history.balance.bet) ? '' : Number(Math.abs(history.balance.bet)).toLocaleString()}</div>
                <div className="m_bet_info_slot"><span className="icon_info">적중금액</span>{isEmpty(history.balance.win) ? '' : Number(history.balance.win).toLocaleString()}</div>
                <div className="m_bet_info_slot"><span className="icon_info">적중여부</span><span className={`font03 ${convertClass(history.type)}`}>{convertTypeString(history.type)}</span></div>
            </div>
        </div>
    );
};

export default CasinoHistory;