import AttnedanceContents from 'Components/Events/AttnedanceContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';


const Attendance = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar />
            <AttnedanceContents />
            <Right type="attendance" />
        </Fragment>
    );
};

export default Attendance;