import HelpContents from 'Components/Account/Help/HelpContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Help = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar/>
            <HelpContents />
            <Right type="help"/>
        </Fragment>
    );
};

export default Help;