import { fixedNumber, getMarketDetail, isEmpty } from 'Common';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const bettingStyle = { transition: "all 300ms", overflow: 'hidden', cursor: "pointer" };

const BetItem = (props) => {

    const dispatch = useDispatch();
    const baselineSetting = useSelector(state => state.sports.baselineSetting)
    const pageInfo = useSelector(state => state.page.pageInfo)
    const { item, itemIndex, betList, originSportslist, onBetItem, checkBetted } = props;

    const { sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting } = baselineSetting;

    const rateValue = useMemo(() => {
        const sportId = originSportslist.Fixture.League.sport.Id;
        let sportsRateIndex = -1;
        let sportRate = {}
        if (pageInfo.RoomType === "sport") {
            if (pageInfo.gameType === "live") {
                sportsRateIndex = isEmpty(sportLiveRateSetting) ? -1 : sportLiveRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? sportLiveRateSetting[sportsRateIndex] : {};
            }
            else {
                sportsRateIndex = isEmpty(sportPrematchRateSetting) ? -1 : sportPrematchRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? sportPrematchRateSetting[sportsRateIndex] : {};
            }


            if (!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)) {
                return {
                    min: Number(sportRate.min),
                    max: Number(sportRate.max),
                }
            }
            else {
                return {}
            }
        }
        else {
            if (pageInfo.gameType === "live") {
                sportsRateIndex = isEmpty(esportLiveRateSetting) ? -1 : esportLiveRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? esportLiveRateSetting[sportsRateIndex] : {};
            }
            else {
                sportsRateIndex = isEmpty(esportPrematchRateSetting) ? -1 : esportPrematchRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? esportPrematchRateSetting[sportsRateIndex] : {};
            }


            if (!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)) {
                return {
                    min: Number(sportRate.min),
                    max: Number(sportRate.max),
                }
            }
            else {
                return {}
            }
        }
    }, [pageInfo.gameType, sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting]);

    const [nowRate, setNowRate] = useState(item.Price[0].Price);

    const setTimeRate = () => {
        setTimeout(() => {
            setNowRate(item.Price[0].Price);
        }, 4600)
    }
    useEffect(() => {
        if (nowRate !== item.Price[0].Price) {
            setTimeRate();
        }
    }, [item])

    useEffect(() => {
        return () => {
            clearTimeout(setTimeRate());
        }
    }, [])

    const team = getMarketDetail(originSportslist, item.Name);
    let minRate = 1;
    let maxRate = 10;
    if (pageInfo.gameType === "live") {
        if (pageInfo.sportType === "esport") {
            minRate = !isEmpty(baselineSetting.eliveminrate) ? baselineSetting.eliveminrate : 1
            maxRate = !isEmpty(baselineSetting.eliveminrate) ? baselineSetting.elivemaxrate : 1
        }
        else {
            minRate = !isEmpty(baselineSetting.liveminrate) ? baselineSetting.liveminrate : 1
            maxRate = !isEmpty(baselineSetting.liveminrate) ? baselineSetting.livemaxrate : 1
        }
    }
    if (isEmpty(team)) return "";
    return (
        <span className={checkBetted(item) ? "bet_on" : ""} id={item.Id} key={itemIndex} style={{ ...bettingStyle, height: "36px", position: "relative" }} >
            <div className='gameLists' onClick={() => onBetItem(item, betList, itemIndex)}>
                {
                    team === "-" ?
                        <span style={{ width: "100%", textAlign: "center" }}>-</span>
                        :
                        <Fragment>
                            <em className="team_l" style={{ width: "80%" }}>
                                {
                                    team.length < 10 ?
                                        <span>{team}</span>
                                        :
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <span className='pre_itemname' style={{ margin: "0" }}>{team.slice(0, team.length - 8)}</span>
                                            <span style={{ overflow: "visible", margin: "0" }}>{team.slice(team.length - 8)}</span>
                                        </div>

                                }
                                {/* <i className="text-allocation margin-5px-left">{(isEmpty(betList.Line) ? item.Line : "")}</i> */}
                            </em>
                            {
                                item.Price[0].Price === "-" ?
                                    <span className={`team_r`} style={{ width: "45px" }}>-</span>
                                    :
                                    <span className={`team_r ${(item.Price[0].Price > nowRate) && "rate_up_effect "} ${(item.Price[0].Price < nowRate) && " rate_down_effect "} score_color`} style={{ width: "45px" }}>{fixedNumber(item.Price[0].Price)}</span>
                            }
                        </Fragment>
                }
            </div>
            {
                (
                    ((item.Price[0].status > 3) ||
                        (item.Status == 10)) ||
                    (!isEmpty(rateValue) &&
                        ((item.Price[0].Price > rateValue.max) || (item.Price[0].Price < rateValue.min))
                    ) ||
                    (fixedNumber(item.Price[0].Price) < minRate) ||
                    ((
                        (pageInfo.gameType === "live") &&
                        (fixedNumber(item.Price[0].Price) > maxRate)
                    ))
                ) &&
                <div className="betting-block" style={{ display: "flex" }}>
                    <div className="lock">
                        <span className="icon-lock"></span>
                    </div>
                </div>
            }
        </span>
    );
};


export default BetItem;
