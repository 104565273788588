
import { Carousel } from 'rsuite';

const bannerList = [
  {
      img: "../images/banner/banner1.png"
  },
  {
      img: "../images/banner/banner2.png"
  },
  {
      img: "../images/banner/banner3.png"
  },
  {
      img: "../images/banner/banner4.png"
  },
]
const CarouselComponent = () => {
  return (
    <Carousel autoplay autoplayInterval={2000} shape="dot" className="custom-slider">
      {
        bannerList.map((list, index) => {
          return (
            <img src={list.img} alt="" key={index} />
          )

        })
      }
    </Carousel>
  )
};

export default CarouselComponent;