import { convertBetHistoryDetail, convertBetMarketName, convertClass, convertTypeString, dateFormation, getBetGameLeague, getBetGameName, isEmpty } from 'Common';
import React, { Fragment } from 'react';

const BetGameHistory = (props) => {

    const { history, page, index } = props
    const betgametype = history.service;
    return (
        <div className="odds_item odd_item_country" key={index}>
            {
                (!isEmpty(history) && !isEmpty(history.odds)) && history.odds.map((odd, oddKey) => {
                    return (
                        <Fragment>
                            <div className="odds_item_league">
                                <div className="odds_item_league_l">
                                    <div className="otll_img"></div>
                                    <div className="otll_text">
                                        <span className="otll_text_country">{isEmpty(history) ? '' : getBetGameName(odd.key.split("_")[0], betgametype)} <br/>{ getBetGameLeague(odd.key.split("_")[0], odd.key.split("_")[2], betgametype)}</span>
                                    </div>
                                </div>
                                <div className="odds_item_league_r"></div>
                            </div>
                            <div className="odds_item_game_box1">
                                <div className="odds_item_game">
                                    <div className="odds_item_game_type"><div style={{ justifyContent: "center" }}>베팅상세</div></div>
                                    <div className="odds_item_game_bet hover_none">
                                        <div className="history_detail"><span className="home">{`${odd.odd_name} VS ${odd.odd_name_front}`}
                        {convertBetMarketName(odd.market_name)} - 
                        {convertBetHistoryDetail( odd) + (!isEmpty(odd.rank) && (odd.rank != 0) ? ` (${odd.rank}등)` : "")}({Number(odd.rate).toFixed(2)})</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="bet_history_total">
                                <div className="m_bet_info_slot"><span className="icon_info">베팅시간</span>{isEmpty(history.created_date) ? '' : dateFormation(history.created_date, 10)}</div>
                                <div className="m_bet_info_slot"><span className="icon_info">베팅금액</span>{isEmpty(history.balance) ? '' : Number(history.balance.bet).toLocaleString()}</div>
                                <div className="m_bet_info_slot"><span className="icon_info">적중금액</span>{isEmpty(history.balance) ? '' : Number(history.balance.win).toLocaleString()}</div>
                                <div className="m_bet_info_slot"><span className="icon_info">적중여부</span><span className={`font03 ${convertClass(history.type)}`}>{convertTypeString(history.type)}</span></div>
                            </div>
                        </Fragment>
                    )
                }
                )
            }
        </div>
    );
};

export default BetGameHistory;