import MessageContents from 'Components/Account/Message/MessageContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Message = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar/>
            <MessageContents/>
            <Right type="message"/>
        </Fragment>
    );
};

export default Message;