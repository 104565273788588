import { isEmpty } from 'Common';
import { resource_url } from 'Common/keys';
import { moreActions } from 'Common/store';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const resDetail = {
    title: "",
    content: ""
}
const EventContents = () => {

    const dispatch = useDispatch();
    const events = useSelector(state => state.more.event.posts)
    const page = useSelector(state => state.page);
    const [active, setActive] = useState(0);
    const [showEvent, setShowEvent] = useState(false);

    const getQuestions = (page = 1) => {

        let params = {
            page: page.curpage,
            perPage: 100,
        }
        dispatch(moreActions.onGetEventList(params))
    }

    useEffect(() => {
        getQuestions(1)
    }, [perPage])


    const onActive = (value, title, content) => {
        resDetail.title = title;
        resDetail.content = content;
        if (active === value) {
            setActive(-1)
        }
        else {
            setActive(value)
        }
        // setNotice(notices[index]);
        // if(active === value)
        //     setActive(-1)
        // else 
        setActive(value)
        onShowEvent();
    }

    const onShowEvent = () => {
        setShowEvent(!showEvent)
    }

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title" style={{marginBottom:"5px"}}>이벤트</div>
                {
                    !showEvent ?
                        <Fragment>
                            <div className="con_box10" style={{backgroundColor:"unset"}}>
                                <div className="event_list">
                                    <ul>
                                        {
                                            !isEmpty(events) && events.map((list, index) => {
                                                let imgUrl = resource_url + "/images/events/" + list.image
                                                return (

                                                    <li onClick={() => onActive(index, list.title, list.content)} key={index}>
                                                        <a>
                                                           <p style={{borderRadius:"10px"}} > <img src={imgUrl} /></p>
                                                            <div className="event_title" dangerouslySetInnerHTML={{ '__html': list.title }}></div>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            {
                                isEmpty(events) 
                                    &&
                                    <div className="no_data" style={{ textAlign: "center" }}>내역이 없습니다.</div>
                            }
                        </Fragment>
                        :
                        <Fragment>
                            <div className="con_box10">
                                <div className="list_box">
                                    <div className="list_tr4 trfirst">
                                        <div className="list_title4 m-d-flex" style={{ width: "100%" }}  dangerouslySetInnerHTML={{ '__html': resDetail.title }}></div>
                                    </div>
                                    <div className="list_tr4">
                                        <div className="list_notice4 d-block m-d-block" style={{ width: "100%" }} dangerouslySetInnerHTML={{ '__html': resDetail.content }}></div>
                                    </div>
                                </div>
                                <div className="bottom_event"><a onClick={onShowEvent}><span className="btn2_2">목록</span></a></div>
                            </div>
                        </Fragment>
                }

            </div>
        </div>
    );
};

export default EventContents;