import { fixedNumber, getBetCard, getMarketRate, isEmpty, no_comma, toastr } from 'Common';
import { betActions, miniActions, userActions } from 'Common/store';
// import Modal from 'Components/Modal/Modal';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'



const BettingCart = (props) => {

    const { type, bet, betList, minigame, levelBettingData, money, setMoney, pageInfo, onBetting, userLevel, betRate, setBetRate } = props
    const dispatch = useDispatch();
    const bet_setting = useSelector(state => state.sports.bet_setting);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const sportsList = useSelector(state => state.sports.data,);
    const history = useSelector(state => state.more.histories);
    const userdata = useSelector(state => state.user.data.user);
    let { data: beted } = minigame
    useEffect(() => {
        if (type == "virtual") {
            let deleteList = []
            if (betList.length > 0) {

                betList.forEach(item => {
                    const selectIndex = sportsList.findIndex(list => list.key == item.eventKey)
                    if (selectIndex < 0) {
                        deleteList.push(item.betId)
                    }
                })
                deleteList.forEach(delItem => {
                    remove(delItem);
                })
            }
        }
    }, [sportsList])

    useEffect(() => {
        if (!isEmpty(betList)) {
            localStorage.setItem("bettingItem", JSON.stringify(betList));
        }
    }, [betList])

    useEffect(() => {

        setTimeout(() => {

            const localStorageBetItem = JSON.parse(localStorage.getItem("bettingItem"));
            const localStorageEventKey = localStorage.getItem("eventkey");
            const localStorageSportsId = localStorage.getItem("sportsId");
            if (!isEmpty(localStorageBetItem)) {
                dispatch(betActions.addOneBet({
                    sportId: localStorageSportsId,
                    eventKey: localStorageEventKey,
                    bettedItems: localStorageBetItem,
                    multiBetting: {}
                }))
            }
        }, 1000)
    }, [])

    useEffect(() => {
        return () => {
            removeAll();
        }
    }, [])

    const [showCard, setShowCard] = useState(false);
    const [maxMoney, setMaxMoney] = useState(0);
    const [minMoney, setMinMoney] = useState(0);
    const [maxGet, setGetMoney] = useState(0);
    const [multiFoldMsg, setMultiFoldMsg] = useState("");
    const [nonMultiFoldMsg, setNonMultiFoldMsg] = useState("");
    const [betPanel, setBetPanel] = useState(false);
    const [histories, setHistories] = useState([]);

    const onChange = e => {
        //console.log(isNaN(Number(e.target.value)));
        if (!isNaN(Number(no_comma(e.target.value))))
            setMoney(Number(no_comma(e.target.value)));
        else setMoney(0);
    };

    useEffect(() => {
        setHistories(history)
    }, [history])

    const GetBettingHistories = (page, type, clear = false) => {
        if (isEmpty(type)) type = "prematch";
        if (isAuthenticated) {
        }

    }

    useEffect(
        () => {
            onSetMaxMoney();
        },
        [type, levelBettingData, pageInfo.gameType, betList]
    );

    const onSetMaxMoney = () => {
        let maxbetMoney = 0;
        let minbetMoney = 0;
        let maxMoney = 0;
        if (isEmpty(levelBettingData) || isEmpty(levelBettingData[userLevel - 1])) {
            return;
        } else {
            if (type !== "minigame") {
                if (type !== "virtual") {
                    if (betList.length > 1) {
                        minbetMoney =
                            levelBettingData[userLevel - 1][`${pageInfo.gameType}MultiMin`];
                        maxbetMoney =
                            levelBettingData[userLevel - 1][`${pageInfo.gameType}MultiMax`];
                        maxMoney =
                            levelBettingData[userLevel - 1][
                            `${pageInfo.gameType}WinMultiMax`
                            ];
                    }
                    else {
                        minbetMoney =
                            levelBettingData[userLevel - 1][`${pageInfo.gameType}SingleMin`];
                        maxbetMoney =
                            levelBettingData[userLevel - 1][`${pageInfo.gameType}SingleMax`];
                        maxMoney =
                            levelBettingData[userLevel - 1][
                            `${pageInfo.gameType}WinSingleMax`
                            ];
                    }
                } else {
                    maxbetMoney = levelBettingData[userLevel - 1][`betSingleMax`];
                    minbetMoney = levelBettingData[userLevel - 1][`betSingleMin`];
                    maxMoney = levelBettingData[userLevel - 1][`betWinSingleMax`];
                }
            } else {
                maxbetMoney = levelBettingData[userLevel - 1][`miniSingleMax`];
                minbetMoney = levelBettingData[userLevel - 1][`miniSingleMin`];
                maxMoney = levelBettingData[userLevel - 1][`miniWinSingleMax`];
            }
        }

        setMaxMoney(maxbetMoney);
        setMinMoney(minbetMoney);
        setGetMoney(maxMoney);
    }

    const onReset = () => {
        setMoney(0);
    };

    const remove = betId => {
        dispatch(betActions.removeOneBet(betId))
    };

    const removeAll = () => {
        localStorage.removeItem("bettingItem")
        dispatch(betActions.clearBetStore([]))
        dispatch(miniActions.clearMiniBetStore())
    };



    useEffect(
        () => {
            onSetBetRateSecond();
        },
        [betList.length]
    );

    const onSetBetRateSecond = () => {
        if (type === "minigame") return;
        let price = 1;
        let bonusRate = 1;
        if (type === "virtual") {
            betList.forEach(element => {
                price = price * getMarketRate(element);
            });
        } else {
            let betLength = betList.length;
            let isBonus = false;
            betList.forEach(element => {
                let card = getBetCard(element, type);
                // //console.log( getBetCard(element, type), card)
                if (Number(card.rate) < 1.3) {
                    betLength--;
                }
                price = price * Number(card.rate);
                if (price > Number(bet.maxBetRate)) price = Number(bet.maxBetRate);
                // //console.log(price)
            });

            bet_setting.forEach(setting => {
                if (betLength >= Number(setting.fold)) {
                    setMultiFoldMsg(
                        `${setting.fold}폴더 보너스 배당이 ${setting.value
                        }배 적용되었습니다. `
                    );
                    isBonus = true;
                    bonusRate = Number(setting.value);
                }
            });

            if (!isBonus) {
                setMultiFoldMsg("");
            }

            if (betLength !== betList.length) {
                setNonMultiFoldMsg("1.3 이하의 배당율은 폴더로 인정되지 않습니다.");
            } else {
                setNonMultiFoldMsg("");
            }
        }
        setBetRate(price * bonusRate);
    }

    useEffect(
        () => {
            onSetBetRateFirst();
        },
        [minigame.data.odd_key]
    );

    const onSetBetRateFirst = () => {
        let price = 1;
        price = price * Number(minigame.data.rate);
        setBetRate(price);
    }
    useEffect(() => {
        GetBettingHistories(1, type, true);
    }, [betPanel]);

    const onShowCard = () => {
        setShowCard(!showCard);
    };

    const onSetMoney = value => {

        if (!isAuthenticated) {
            return toastr.warning("로그인하시고 이용해주세요")
        }
        else {
            let currentMoney = isEmpty(userdata) ? 0 : (isEmpty(userdata.balance) ? 0 : Math.floor(userdata.balance.money))
            let resultMoney = Math.floor(maxGet / fixedNumber(betRate))
            if (value === "max") {
                if (type !== "minigame") {
                    if (type !== "virtual") {
                        if (betList.length === 1) {
                            value = Math.min(currentMoney, resultMoney, levelBettingData[userLevel - 1][`${pageInfo.gameType}SingleMax`])
                        } else {
                            value = Math.min(currentMoney, resultMoney, levelBettingData[userLevel - 1][`${pageInfo.gameType}MultiMax`])
                        }
                    } else {
                        value = Math.min(currentMoney, resultMoney, levelBettingData[userLevel - 1][`betSingleMax`])
                    }
                } else {
                    value = Math.min(currentMoney, resultMoney, levelBettingData[userLevel - 1][`miniSingleMax`])
                }
                return setMoney(value);
            } else {
                setMoney(money + value);
            }
        }
    };

    const onRateUpdate = () => {
        onSetMaxMoney();
        onSetBetRateSecond();
    }

    return (
        <Fragment>
            <div className="cart_wrap">
                <div className="cart_box">
                    <div className="cart_title">BET SLIP
                        {
                            type !== "minigame" &&
                            <a className="cart_del" onClick={removeAll}>
                                <i className="fa-solid fa-trash-can" style={{ marginRight: "5px" }}></i>
                                전체삭제
                            </a>
                        }

                    </div>
                    <div className="cart_bet_wrap">
                        {
                            type === "minigame" ?
                                <div className="cart_bet">
                                    <div className="cart_bet_title">&nbsp;<div className="cart_bet_close"></div></div>
                                    <div className="cart_bet_info">
                                        <div className="cart_bet_info1">{beted.name}</div>
                                        <div className="cart_bet_info2">{beted.rate}</div>
                                    </div>
                                </div>
                                : !isEmpty(betList) ?
                                    <Fragment>
                                        {
                                            betList.map((list, index) => {
                                                let betCard = getBetCard(list, type);
                                                return (
                                                    <div className="cart_bet" key={index}>
                                                        <div className="cart_bet_title">{betCard.leagueName} - {betCard.marketName}
                                                            <div className="cart_bet_close">
                                                                <a onClick={() => remove(list.betId)}><i className="fa-solid fa-rectangle-xmark"></i></a>
                                                            </div>
                                                        </div>
                                                        <div className="cart_bet_info">
                                                            <div className="cart_bet_info1"><span className='red-color'>{betCard.homeTeam}</span> VS <span className='blue-color'>{betCard.awayTeam}</span></div>
                                                            <div className="cart_bet_info2">{betCard.rate}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Fragment>
                                    : <div className="cart_style1"><div style={{ marginBottom: "10px", background: "black", padding: "25px 0px", textAlign: "center", width: "100%", borderRadius: "5px" }}>{!isEmpty(betList) ? betList.length : "선택된 베팅내역이 없습니다"}</div></div>
                        }
                    </div>
                    <div className="con_box05" >
                        <table width="100%" border="0" align="center" cellSpacing="0" cellPadding="0">
                            <thead></thead>
                            <tbody>
                                {/* {
                                    type !== "minigame" &&
                                    <tr>
                                        <td className="cart_style1"><div style={{  marginBottom:"10px",background:"black",padding:"25px 0px",textAlign:"center", width:"100%"}}>{!isEmpty(betList) ? betList.length :"선택된 베팅내역이 없습니다"}</div></td>
                                    </tr>
                                } */}
                                <tr>
                                    <td className="cart_style1">
                                        <div style={{ margin: "auto 3px", whiteSpace: "nowrap" }}>
                                            베팅금액
                                            <img src="../images/top_icon/withdraw_on.png" style={{ marginLeft: "10px" }} alt="" />
                                        </div>
                                        <span className="cart_style2" >
                                            <input type="text" value={isEmpty(money) ? "0" : Number(money).toLocaleString()} className="cart_input" onChange={onChange} />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="cart_btn_box">
                        <ul>
                            <li>
                                <a onClick={() => onSetMoney(5000)} style={{ cursor: "pointer" }}>
                                    <img className='custom_image' src='../images/main_game/off/right/5천.png' />
                                    <img className='custom_hover_image' src='../images/main_game/on/right/5천.png' />
                                </a>
                            </li>
                            <li>
                                <a onClick={() => onSetMoney(10000)} style={{ cursor: "pointer" }}>
                                    <img className='custom_image' src='../images/main_game/off/right/1만.png' />
                                    <img className='custom_hover_image' src='../images/main_game/on/right/1만.png' />
                                </a>
                            </li>
                            <li>
                                <a onClick={() => onSetMoney(50000)} style={{ cursor: "pointer" }}>
                                    <img className='custom_image' src='../images/main_game/off/right/5만.png' />
                                    <img className='custom_hover_image' src='../images/main_game/on/right/5만.png' />
                                </a>
                            </li>
                            <li>
                                <a onClick={() => onSetMoney(100000)} style={{ cursor: "pointer" }}>
                                    <img className='custom_image' src='../images/main_game/off/right/10만.png' />
                                    <img className='custom_hover_image' src='../images/main_game/on/right/10만.png' />
                                </a>
                            </li>
                            <li>
                                <a onClick={() => onSetMoney(500000)} style={{ cursor: "pointer" }}>
                                    <img className='custom_image' src='../images/main_game/off/right/50만.png' />
                                    <img className='custom_hover_image' src='../images/main_game/on/right/50만.png' />
                                </a>
                            </li>
                            {/* <li><a onClick={() => onSetMoney(1000000)} style={{ cursor: "pointer" }}><span className="cart_btn1">1,000,000</span></a></li> */}
                            <li>
                                <a onClick={() => onSetMoney("max")} style={{ cursor: "pointer" }}>
                                    <img className='custom_image' src='../images/main_game/off/right/MAX.png' />
                                    <img className='custom_hover_image' src='../images/main_game/on/right/MAX.png' />
                                </a>
                            </li>
                            {/* <li className='w-50'><a onClick={onReset} style={{ cursor: "pointer" }}><span className="cart_btn1">RESET</span></a></li> */}
                            <li className="w100" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "39px" }}>
                                <a onClick={onRateUpdate} style={{ cursor: "pointer", height: "auto" }}>
                                    <img className='custom_image' style={{ width: "100%", height: "auto" }} src='../images/main_game/off/right/배당갱신.png' />
                                    <img className='custom_hover_image' style={{ width: "100%", height: "auto" }} src='../images/main_game/on/right/배당갱신.png' />
                                </a>
                                <a onClick={onBetting} style={{ cursor: "pointer", height: "auto" }}>
                                    <img className='custom_image' style={{ width: "100%", height: "auto" }} src='../images/main_game/off/right/배팅하기.png' />
                                    <img className='custom_hover_image' style={{ width: "100%", height: "auto" }} src='../images/main_game/on/right/배팅하기.png' />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="cart_info_box">
                        <div className="cart_info">총배당율<span className="cart_style2">{fixedNumber(betRate)}</span></div>
                        <div className="cart_info">예상당첨금<span className="cart_style2">{Number(money * fixedNumber(betRate)).toLocaleString()}</span></div>
                        <div className="cart_info">최소 베팅금액<span>{!isEmpty(minMoney) && minMoney.toLocaleString()}</span></div>
                        <div className="cart_info">최대 베팅금액<span>{!isEmpty(maxMoney) && maxMoney.toLocaleString()}</span></div>
                        <div className="cart_info">최대 당첨금액<span>{!isEmpty(maxGet) && maxGet.toLocaleString()}</span></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BettingCart;