import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";
import { isEmpty, toastr } from "Common";
import Popup from "Components/Others/Popup";


export { Protected };

function Protected({ children, isPublic, isMessagePage, messageRouter, value}) {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)
  const gameCheck = useSelector(state => state.page.gameCheck);
  const messagePage = useSelector(state => state.page.messagePage);
  const user = useSelector(state => state.user.data.user);
  const role = (!isEmpty(user) && !isEmpty(user.role)) ? user.role : 0

  if (isAuthenticated) {
    if (messagePage && !isMessagePage) {
      toastr.warning("쪽지를 먼저 확인하셔야 합니다.")
      return (
       <Navigate to={messageRouter} replace />
      );
    }

    if (!isEmpty(value) && role < 4) {
      if (gameCheck[value]) {
        toastr.warning("점검중입니다.");
        return <Navigate to="/" replace />;
      }
    }
    return (
      <>
        <Popup />
        <Header notShow={value === "login" || value === "signup"}/>
        <div className="contents_wrap">
          {children}
        </div>
        <Footer notShow={value === "sport_prematch" || value === "sport_live" || value === "esport_prematch" || value === "login" || value === "signup"} />
      </>
    )

  } else if (isPublic) {
    return (
      <Fragment>
        <Popup/>
        <Header notShow={value === "login" || value === "signup"}/>
        <div className="contents_wrap">
          {children}
        </div>
        <Footer notShow={value === "login" || value === "signup"}/>
      </Fragment>
    );
  }
  return <Navigate to="/" replace />;
}
