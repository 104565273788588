import { dateFormation, isEmpty } from 'Common';
import { moreActions } from 'Common/store';
import Modal from 'Components/Modal/Modal';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';



const PointConvertHistory = (props) => {

    const dispatch = useDispatch();
    const { popup, onPopup, onDeletPointExchagneHistory } = props
    const user = useSelector(state => state.user.data.user);
    const lists = useSelector(state => state.more.pointExchange.data);
    const page = useSelector(state => state.page)

    const getLists = (page = 1) => {
        dispatch(moreActions.getExchangeData({
            page: page,
            perPage: perPage
        }))
    }

    useEffect(() => {
        getLists(1)
    }, [perPage])

    const onAction = () => {
        onDeletPointExchagneHistory(false)
    }


    return (
        <Fragment>

            <div className="con_box00">
                <div className="list_box">
                    <div className="over_list">
                        <div className="list_tr2 trfirst">
                            <div className="list_title2">번호</div>
                            <div className="list_title2">포인트</div>
                            <div className="list_title2">내역</div>
                            <div className="list_title2">처리일자</div>
                            <div className="list_title2">삭제</div>
                        </div>
                        {
                            !isEmpty(lists) && lists.map((list, index) => {
                                return (
                                    <div className="list_tr2" key={index}>
                                        <div className="list2">{(page.curPage - 1) * 10 + index + 1}</div>
                                        <div className="list2">{isEmpty(list) ? 0 : Math.floor(list.balance.after_money).toLocaleString()}</div>
                                        <div className="list2"><span className="font11">{isEmpty(list) ? "" : list.balance.amount}</span></div>
                                        <div className="list2">{dateFormation(list.date, 10)}</div>
                                        <div className="list2"><a ><span className="btn1_2" onClick={() => onPopup(list._id)} style={{ width: "100%" }}>삭제</span></a></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    isEmpty(lists) ?
                        <>
                            <div className='no_data'>내역이 없습니다</div>
                        </>
                        :
                        <Pagenation
                            page={page.curPage}
                            totalPage={page.totalPage}
                            getPage={getLists}
                        />
                }
            </div>
            {
                popup && <Modal contents="삭제하시겠습니까?" popup={popup} onAction={onAction} onPopup={onPopup} />
            }

        </Fragment>
    );
};

export default PointConvertHistory;