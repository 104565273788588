import { history, isEmpty } from 'Common';
import { pageActions } from 'Common/store';
import SportsCenter from 'Components/Sports/SportsCenter';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux'

const SportsDomestic = () => {

    const dispatch = useDispatch()

    const [ showLeft, setShowLeft] = useState(false);
    const roomObj = {
        pageType: "toto",
        RoomType: "sport",
        sportType: "sport",
        gameType: "prematch",
        sportId: 'all',
        esportType: "all",
        prematchType: "interior",
    }
    const roomData = {
        MessageType: "RoomType",
        data: roomObj
      };
      if(window.ws.readyState === 1)
        window.ws.send(JSON.stringify(roomData));

      useEffect(() => {
        dispatch(pageActions.onSetPageInfo(roomObj));
        window.sportsScroll();
      });


    const onSetShowLeft = () => {
        setShowLeft(!showLeft);
    }

    return (
        <Fragment>
            <LeftBar onSetShowLeft={onSetShowLeft} showLeft={showLeft} type="interior" title="스포츠 (국내형)" history={history} roomObj={roomObj}/>
            <SportsCenter roomObj={roomObj} type="interior" onSetShowLeft={onSetShowLeft} title="스포츠 (국내형)"/>
            <Right type="sports" isbetting={true}/>
        </Fragment>
    );
};

export default SportsDomestic;