import { dateFormation, isEmpty, isMobilePage } from 'Common';
import { moreActions, pageActions, userActions } from 'Common/store';
import Winwheel from 'Components/Others/Winwheel';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'



const RouletteContents = (props) => {
    const dispatch = useDispatch();
    const roulette = useSelector(state => state.more.roulette)
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const page = useSelector(state => state.page);
    const history = isEmpty(roulette) || isEmpty(roulette.history) ? [] : roulette.history;

    const [theWheel, setTheWheel] = useState({})
    const [winMoney, setWinMoney] = useState(0);
    const [allowRoulette, setAllowRoulette] = useState(true);

    const getHistory = (page = 1) => {
        dispatch(moreActions.getRouletteHistory({
            page: page,
            perPage: perPage
        }))
    }

    useEffect(() => {
        let rouletteList = roulette.roulette.list
        if (isEmpty(rouletteList)) return;
        let theWheel = getWinWheelObject(getFixedRouletteArray(rouletteList));
        setTheWheel(theWheel);
    }, [roulette.roulette.list])

    const getWinWheelObject = data => {
        const mode = isMobilePage();
        return new Winwheel({
            'numSegments': data.length,     // Specify number of segments.
            'outerRadius': mode ? 180 : 260,   // Set outer radius so wheel fits inside the background.
            'textFontSize': mode ? 15 : 28,    // Set font size as desired.
            'segments':        // Define segments including colour and text.
                data,
            'animation':           // Specify the animation to use.
            {
                'type': 'spinToStop',
                'duration': 5,     // Duration in seconds.
                'spins': 10,     // Number of complete spins.
            }
        })
    }

    const getColor = (index) => {
        switch (index) {
            case 0: {
                return '#afaaaa';
            }
            case 1: {
                return '#ff740e';
            }
            case 2: {
                return '#D13D2C';
            }
            case 3: {
                return '#1356BA';
            }
            case 4: {
                return '#682BC3';
            }
            case 5: {
                return '#F862CE';
            }
            case 6: {
                return '#9BF33C';
            }
            case 7: {
                return '#ff740e';
            }
            default:
                return;
        }
        return
    }

    // const getColor = (index) => {
    //     switch (index) {
    //       case 0: {
    //         return '#DA5858';
    //       }
    //       case 1: {
    //         return '#FFF';
    //       }
    //       default:
    //         return;
    //     }
    //   }

    const getTextColor = (index) => {
        switch (index) {
            case 0: {
                return '#FFF';
            }
            case 1: {
                return '#0F0080';
            }
            default:
                return;
        }
    }

    const sortRouletteList = data => {
        let rouletteList = isEmpty(data) ? [] : data;
        rouletteList.sort((a, b) => {
            return b.bonus.balance.rolling - a.bonus.balance.rolling
        })
        return rouletteList;
    }

    const resetWheel = () => {
        let retheWheel = theWheel;
        retheWheel.stopAnimation(false);  // Stop the animation, false as param so does not call callback function.
        retheWheel.rotationAngle = 0;     // Re-set the wheel angle to 0 degrees.
        retheWheel.draw();                // Call draw to render changes to the wheel.
        setTheWheel(retheWheel);
    }

    const getFixedRouletteArray = data => {
        let resData = [];
        data.forEach((element, index) => {
            resData.push({
                'fillStyle': getColor(index % 2),
                'text': Number(element.bonus.balance.rolling).toLocaleString() + 'P',
                // 'textFillStyle': getTextColor(index % 2)
                'textFillStyle': "#fff"
            })
        });
        // 
        return resData;
    }



    const StartSpin = () => {
        let changeTheWheel = theWheel;
        resetWheel()
        setWinMoney(0);
        setAllowRoulette(true);
        setTheWheel(changeTheWheel)
        dispatch(moreActions.doRoulette())
    }
    useEffect(() => {
        if (isEmpty(roulette.bonus)) return;
        if (allowRoulette) {
            let changeTheWheel = theWheel;
            let loading = false
            const index = roulette.roulette.list.findIndex(item => item.bonus.balance.rolling === roulette.bonus.balance.rolling);
            let stopAt = changeTheWheel.getRandomForSegment(index + 1);
            changeTheWheel.animation.stopAngle = stopAt;
            changeTheWheel.startAnimation();
            setTimeout(() => {
                getHistory();
                dispatch(userActions.getUserInfo());
                setWinMoney(roulette.bonus.balance.rolling);
                setAllowRoulette(false);
            }, 5000);
        }

    }, [allowRoulette, roulette.bonus])
    useEffect(() => {
        getHistory();
        dispatch(moreActions.getRouletteRules())

    }, [page.perPage, page.curPage])

    const onSetPage = (page) => {
        dispatch(pageActions.setPageData({
            page: Number(page),
            totalPage: page.totalPage
        }))
    }

    const canvasSize = isMobilePage() ? 360 : 536;
    return (
        <div className='contents_center'>
            <div className='contents_center_info_full'>
                <div className="title">룰렛</div>
                <div className="roulette_box" >
                    <div className="titlebox">
                        <div className="roulette_label" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                            남은 룰렛이벤트&emsp;
                            <span id="roulette_coupon" className={`${roulette.leftCount > 0 ? "rouletteCount" : ""}`}>{isEmpty(roulette.leftCount) ? 0 : roulette.leftCount}</span>개
                        </div>
                    </div>

                    <section id="roulette">
                        <canvas id="canvas" className="roulette_rotate_plate" width={canvasSize} height={canvasSize}></canvas>
                        <div className="shadow_box"></div>
                        <img className="roulette_center_cover" id="roulette_rotate_plate" src="../images/more/roulette_center_cover.png" alt="" />
                        <img className="roulette_center_cover roulette_center_cover_ring" src="../images/more/roulette_center_cover_ring.png" alt="" />
                        <img className="roulette_arrow" src="../images/more/roulette_arrow.png" alt="" />
                        <div className="result_point">
                            <div className="label">당첨포인트</div>
                            <div className="value" >{Number(winMoney).toLocaleString()}</div>
                            <div className="label">P</div>
                        </div>
                        <div className="btn_play" onClick={e => { e.preventDefault(); StartSpin(); }}>룰렛돌리기<div className="effect"></div></div>
                    </section>
                </div>
                <div className="con_box00">
                    <div className="list_box">
                        <div className="list_tr2" style={{ justifyContent: "center" }}>
                            <div className="list_title2" style={{width:"10%"}}>번호</div>
                            <div className="list_title2" style={{width:"60%"}}>사용일시</div>
                            <div className="list_title2" style={{width:"30%"}}>당첨내역</div>
                        </div>
                        {
                            history.map((item, index) => {
                                return (
                                    <div className="list_tr2" style={{ justifyContent: "center" }} key={index}>
                                        <div className="list2" style={{width:"10%"}}>{isNaN(page.curPage) ? 0 : (page.curPage - 1) * 10 + index + 1}</div>
                                        <div className="list2" style={{width:"60%"}}>{dateFormation(item.used_date)}</div>
                                        <div className="list2" style={{width:"30%"}}>{!item.bonus ? "0" : Number(item.bonus.balance.rolling).toLocaleString()}P</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    history.length !== 0 ?
                        <Pagenation
                            page={page.curPage}
                            totalPage={page.totalPage}
                            getPage={onSetPage} />
                        :
                        <div className='no_data'>내역이 없습니다</div>
                }

            </div>
        </div>

    );
};

export default RouletteContents;