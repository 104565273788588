import { dateFormation, isEmpty, showMessage, toastr } from 'Common';
import { request } from 'Common/request';
import { moreActions, pageActions } from 'Common/store';
import React, { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
// import Quill from './Quill';
import { api_url } from 'Common/keys';
import { perPage } from 'Utils';
import Pagenation from 'Components/common/Pagenation';


const resDetail = {
    title: "",
    question: "",
    answer: "",
    created_date: ""
}
const HelpContents = (props) => {

    const dispatch = useDispatch();
    const lists = useSelector(state => state.more.customerCenter.data);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const page = useSelector(state => state.page);
    const [showHis, setShowHis] = useState(false);
    const [showDetail, setShowDetail] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [selected, setSelected] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [inputs, setInputs] = useState({
        title: '',
        question: ''
    });

    const { title, question } = inputs;

    const onChange = (e) => {
        const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
        setInputs({
            ...inputs, // 기존의 input 객체를 복사한 뒤
            [name]: value // name 키를 가진 값을 value 로 설정
        });
    };

    const onReset = () => {
        setInputs({
            title: '',
            question: '',
        })
    };

    const DoQuestion = (account) => {
        let qTitle = account ? "빠른계좌문의합니다" : title;
        let qQuestion = account ? "빠른계좌문의합니다" : question;
        const params = {
            title: qTitle,
            question: qQuestion
        }
        // console.log(account, qTitle, qQuestion);
        if (isEmpty(qTitle))
            return toastr.warning('제목을 입력해주세요.');
        if (isEmpty(qQuestion))
            return toastr.warning('내용을 입력해주세요.');
        if (!isAuthenticated)
            return toastr.warning('로그인하시고 이용해주세요.');
        dispatch(pageActions.onSetPageLoading(true));
        request.post(api_url + "/api/Question/send", params)
            .then((data) => {
                dispatch(pageActions.onSetPageLoading(false));
                showMessage(data.message);
                setShowInput(false);
                setSelected({});
                GetQuestionList(1);
                setShowHis(!showHis);
            });
    }

    const GetQuestionList = page => {
        dispatch(moreActions.getQuestionList({
            page: page,
            perPage: perPage
        }))
    }

    const onSelect = (index) => {
        if (index === selectedIndex) {
            setSelectedIndex(-1);
        }
        else {
            setSelectedIndex(index);
        }
    }

    const onShowContent = (questionData) => {
        setSelected(questionData);
        setShowInput(false);
        setShowHis(!showHis);
    }

    const cancelQuestion = () => {
        setShowInput(false);
        setShowContent(false);
        setSelected({})
        setShowHis(!showHis);
    }

    const showQuestion = () => {
        setShowInput(true);
        setShowContent(false);
        setSelected({})
        setShowHis(!showHis);
    }

    useEffect(() => {
        GetQuestionList(1);
    }, [page.perPage])

    const showHistory = () => {
        setShowHis(!showHis);
    }

    const quill = useRef();
    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();

                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
            };

            reader.readAsDataURL(file);
        };
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        [imageHandler]
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];
    return (
        <Fragment>
            <div className="contents_center">
                <div className="contents_center_info_full">
                    <div className="title">고객센터</div>
                    <div className="con_box05" >
                        <div className="write_box write_title_top" style={{ background: "rgb(34, 34, 39)", padding: "28px 10px 0px" }}>
                            <div className="write_tr cf" style={{ display: "block", background: "rgb(34, 34, 39)" }}>

                                <div className="write_basic" style={{ padding: "0px", border: "unset",marginBottom:"10px" }}>
                                    <div className='customer_title'>제목</div>
                                    <input className="input1 w-100" size="115" name="title" placeholder='제목' value={title} onChange={onChange} />
                                </div>
                            </div>
                            <div className="write_tr cf" style={{ display: "block", background: "#222227" }}>
                                <div  style={{ height: "45px" }}><div style={{ borderTop: "1px solid rgb(115, 115, 115)" }}><div className='customer_title' style={{ marginTop: "20px" }}>내용</div></div></div>
                                <div style={{  display: "flex", justifyContent: "center",borderBottom:"1px solid rgb(115, 115, 115)" }}>
                                    <QuillEditor
                                        ref={(el) => (quill.current = el)}
                                        className={"form-control"}
                                        style={{ marginBottom:"30px", minHeight: "300px", minWidth: "100%", height: "auto", padding: "0px", overflow: "hidden", color: "block", borderTop: "1px solid black" }}
                                        theme="snow"
                                        value={question}
                                        formats={formats}
                                        modules={modules}
                                        onChange={(value) => setInputs({ ...inputs, question: value })}
                                    /></div>
                                {/* <div className="write_basic">
                                    <textarea cols="115" rows="20" className="input2 w-100" name='question' value={question} onChange={onChange} ></textarea>
                                    {/* <Quill
                                        style={{ minHeight: "300px", height: "auto", border: "1px solid rgb(84 84 84)" }}
                                        value={question}
                                        setValue={(value) => setInputs({ ...inputs, question: value })}
                                    /> 
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="con_box10" >
                        <div className="btn_wrap_center">
                            <ul className="display_flex">
                                <li onClick={() => DoQuestion(true)}><a><span className="btn2_1">빠른계좌문의</span></a></li>
                                <li onClick={() => DoQuestion(false)}><a><span className="btn2_2">문의요청</span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="con_box10" style={{ margin: "unset" }}>
                        <div className="list_box">
                            <div className="note_tr_title">
                                <span>번호</span>
                                <span className="titleTd" style={{textAlign:'center'}}>제목</span>
                                <span>상태</span>
                                <span>작성일</span>
                            </div>
                            {
                                !isEmpty(lists) && lists.map((list, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className="note_tr_list" onClick={() => { onShowContent(list); onSelect(index); }}>
                                                <span>{isNaN(page.curPage) ? 0 : (page.curPage - 1) * Number(perPage) + index + 1}</span>
                                                <span className="titleTd" >{list.title}</span>
                                                <span><span className={` ${!list.status ? "division2" : "division1"}`}>{!list.status ? "대기중" : "답변완료"}</span></span>
                                                <span>{dateFormation(list.created_date, 10)}</span>
                                            </div>
                                            <div className="hide_note" style={{ display: selectedIndex !== index ? "none" : "block" }}>
                                                {
                                                    isEmpty(list.answer) ?
                                                        <span className='font05'>답변이 없습니다</span>
                                                        :
                                                        <Fragment>
                                                            <div dangerouslySetInnerHTML={{ '__html': list.answer }}></div><br />
                                                            --------------------------------------------------------<br />
                                                            <span className="font05">관리자 답변 / 작성일 : {dateFormation(list.answered_date, 10)}</span><br />
                                                            답변이 만료되었습니다.
                                                        </Fragment>
                                                }

                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        !isEmpty(lists) ?
                            <Pagenation
                                page={page.curPage}
                                totalPage={page.totalPage}
                                getPage={GetQuestionList}
                            />
                            :
                            <div className="no_data" style={{ textAlign: "center" }}>내역이 없습니다.</div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default HelpContents;