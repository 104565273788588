import { ResizeESportsIframe, ResizeSportsLiveIframe, fixBetsData, getMatchInfo, groupMarkets, isEmpty, onErrorImg, sortMarkets } from 'Common';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import MarketItem from './MarketItem';

const marketNames = require('../../Common/JSON/market.json')
const eventPartNames = require('../../Common/JSON/eventpart')


const Market = (props) => {

    const dispatch = useDispatch();
    const activeId = useSelector(state => state.sports.activeId)
    const data = useSelector(state => state.sports.data)
    const url = useSelector(state => state.sports.url)
    const pageInfo = useSelector(state => state.page.pageInfo)
    const marketNameList = useSelector(state => state.marketNameList)

    const eventpartNameList = useSelector(state => state.user.eventpartNameList);

    const sportsMarketInfo = useSelector(state => state.sports.sportsMarketInfo)

    const [active, setActive] = useState("allMarket")

    useEffect(() => {
        setActive("allMarket");
    }, [activeId]);
    const { type, showMarkets, onShowMatch } = props;

    let kind_Id = "";
    //let sportsMarketInfo
    if (!isEmpty(sportsMarketInfo)) {
        kind_Id = type === 'esports' ? sportsMarketInfo.Fixture.League.sport.oddsId : sportsMarketInfo.Fixture.League.sport.Id
    }



    let originSportslist = !isEmpty(sportsMarketInfo) ? sortMarkets(sportsMarketInfo, kind_Id) : {};

    let matchInfo = getMatchInfo(originSportslist);
    // debugger
    let goruplist = groupMarkets(originSportslist);
    if (!isEmpty(goruplist)) {
        goruplist.forEach(element => {
            goruplist[0].data = goruplist[0].data.concat(element.data);
        });
    }



    const getIfreamUrl = (id) => {
        let pattern = new RegExp("(FixtureId)", "i")
        // console.log(id)
        let newUrl = String(url).replace(pattern, id);
        return newUrl;
    }

    useEffect(() => {
        ResizeSportsLiveIframe();
        ResizeESportsIframe();
    }, [url])


    return (
        <div className="sportsScroll contents_center_info_market" style={{ display: showMarkets ? "block" : "" }}>
            <div className="m_list_view_btn" onClick={onShowMatch}><span><i className="fa-solid fa-angles-left"></i> 리스트 보기</span></div>
            <div className="bet_inner_in_country">
                {/* <div className="odds_item_league">
                    <div className="odds_item_league_l">
                        <div className="otll_img"><img src={matchInfo.imgPath} className="otll_img22" onError={onErrorImg} /></div>
                        <div className="otll_text">
                            <span className="otll_text_country">{matchInfo.locationName} / {matchInfo.leagueName}</span>
                        </div>
                    </div>
                    <div className="odds_item_league_r"><img src={matchInfo.sportImg} className="otll_img11" /></div>
                </div> */}
                <div className="topInfo pc">
                    <div className="team al" style={{ color: "#ff5d5d" }}>{matchInfo.homeTeam}</div>
                    <img src="../images/sport/vs.png" />
                    <div className="team ar" style={{ color: "#4bffff" }}>{matchInfo.awayTeam}</div>
                </div>
                {pageInfo.gameType === "live" && <div id="sportsLive_iframe_parent" className="" style={{ height: '100%' }}>
                    {isEmpty(url) ? "" : <iframe
                        className="frameScale"
                        scrolling="no"
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        // src={getIfreamUrl(!isEmpty(originSportslist) ? originSportslist.FixtureId : "")}
                        src={url}
                        title={`${"sportsLive"}Iframe`}
                    />}
                </div>}
                <div className="con_box10">
                    <div className="sports_bet_tab">
                        <ul className='overflowx'>
                            {
                                !isEmpty(goruplist) && goruplist.map((market, index) => {
                                    return (
                                        <li className={market.type !== active ? "" : "active"} onClick={() => setActive(market.type)} key={index}>
                                            <a>{market.name_kor}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <ul className="dropdown3">
                    {
                        !isEmpty(goruplist.filter(item => { return item.type == active })[0]) ? (
                            isEmpty(goruplist.filter(item => { return item.type == active })[0].data) ?
                                <li className="padding-20px-all text-dark-gray" style={{ border: 'none' }}>
                                    <div className="no_market" >사용할 수 있는 마켓이 없습니다.</div>
                                </li>
                                :
                                goruplist.filter(item => { return item.type === active })[0].data.map((item, index) => {
                                    const activeGroup = goruplist.filter(item => { return item.type === active })[0].data;
                                    // let cnt = 0, betItemList = [];
                                    // for (let i = 0; i < activeGroup.length; i++) {
                                    const groupItem = activeGroup[index];
                                    const fixedList = fixBetsData(groupItem.Providers[0], groupItem.Id, type, false, originSportslist);
                                    // betItemList.push([fixedList]);
                                    // cnt += fixedList.length;
                                    // }

                                    // ------------------------- 마켓네임, 이벤트파트 아이디 ------------------------- //
                                    let marketNameIndex;
                                    let markettitle = "";
                                    if (!isEmpty(marketNameList)) {
                                        marketNameIndex = marketNameList.findIndex(name => name.marketId == groupItem.Id);
                                        if ((marketNameIndex > -1)) {
                                            markettitle += marketNameList[marketNameIndex].translate + " ";
                                        }
                                    } else {
                                        marketNameIndex = marketNames.findIndex(name => name.id == groupItem.Id);
                                        if ((marketNameIndex > -1)) {
                                            markettitle += marketNames[marketNameIndex].translate + " ";
                                        }
                                    }
                                    let eventPartNameIndex;
                                    let eventParttitle = "";
                                    // const eventPartNameIndex = eventPartNames.findIndex(name =>  name.id == groupItem.eventPartId);

                                    if (!isEmpty(eventpartNameList)) {
                                        eventPartNameIndex = eventpartNameList.findIndex(name => name.eventpartId == groupItem.eventPartId);
                                        if ((eventPartNameIndex > -1)) {
                                            eventParttitle += eventpartNameList[eventPartNameIndex].kor_name;
                                        }
                                    }
                                    else {
                                        eventPartNameIndex = eventpartNameList.findIndex(name => name.eventpartId == groupItem.eventPartId);
                                        if ((eventPartNameIndex > -1)) {
                                            eventParttitle += eventPartNames[eventPartNameIndex].kor_name;
                                        }
                                    }

                                    // if((eventPartNameIndex > -1)){
                                    //     title += eventPartNames[eventPartNameIndex].kor_name;
                                    // }
                                    // ------------------------- 마켓네임, 이벤트파트 아이디 ------------------------- //

                                    return (
                                        <MarketItem
                                            betItemList={fixedList}
                                            originSportslist={originSportslist}
                                            market={item}
                                            key={index}
                                            hideTitle={index === 0 ? false : (groupItem.Id === activeGroup[index - 1].Id) && (groupItem.eventPartId === activeGroup[index - 1].eventPartId)}
                                            markettitle={markettitle}
                                            eventParttitle={eventParttitle}
                                            kind_Id={kind_Id}
                                            src={matchInfo.sportImg}
                                        />
                                    )

                                })
                        )
                            :
                            <li className="padding-20px-all text-dark-gray" style={{ border: 'none' }}>
                                <div className="no_market">사용할 수 있는 마켓이 없습니다.</div>
                            </li>
                    }
                </ul>
            </div>
        </div>
    );
};

export default Market;