export const MainMarketData = [ 
    69, // "Home Draw Away", "Who wins the match? There are 3 possibilities: \"home wins\", \"away wins\" \"draw\"."
    466, // "Home Away", "Home win or Away win, with money back on draw."
    112, // "Draw No Bet", "Home win or Away win, with money back on Draw."
    357, // "Home Draw Away With Score", "Match result (home win, away win or draw) for the remainder of the match/event part. Only goals/points scored after the given score will count. "
    358, // "Home Away With Score", "Match result (home wins, away wins) for the remainder of the match/event part. Only goals/points scored after the given score will count. "
    8, // "Home Draw Away With Handicap", "The participant will be given various lines of handicaps and it will be the final result that counts. This betting type has 3 outcomes, Home, Draw and Away."



    39, // "Game Handicap", "Player to win the most games, given a certain handicap."

    48, // "Asian Handicap", "The participant will be given various lines of handicaps. This betting type has only two outcomes, Home and Away, draw being excluded."

    60, // "Game Handicap With Draw", "Player to win the most games or draw, given a certain handicap."

    62, // "Points Handicap", "The participant will be given various lines of handicaps. This betting type has only two outcomes, Home and Away, draw being excluded."

    // 71, // "Set Handicap", "The participant will be given various lines of handicaps. This betting type has only two outcomes, Home and Away, draw being excluded."

    138, // "Corner Handicap With Draw", "Which team will take most corners, given a certain handicap."

    182, // "Corner Asian Handicap", "The team to take most corners, given a certain handicap."

    355, // "Asian Handicap With Score", "The winner of the remainder of the match, given a certain handicap. In case of a tie, the punter gets his money back. Only goals scored after the given score will count."

    365, // "Kills Handicap", "Team to get the most kills, given a certain handicap."

    369, // "Maps Handicap", "Which team will win the most maps, given a certain handicap."

    371, // "Frags Handicap", "Which team will get the most frags, given a certain handicap (frag= the difference between kills and deaths). "

    379, // "Round Handicap", "Which team will win the most rounds, given a certain handicap."

    451, // "Gold Handicap", "Bet on the team to have the higher amount of gold, given a certain handicap."

    452, // "Damage Handicap", "Bet on the team to have the higher amount of damage, given a certain handicap. In case of a tie, the punter gets his money back."

    491, // "Shots on Target Asian Handicap", "Team to take the most shots on target, given a certain handicap."

    492, // "Ball Possession Asian Handicap", "Team to get the higher possession of the ball, given a certain handicap as a percentage."

    494, // "Fouls Asian Handicap", "Team to take the most fouls, given a certain handicap."

    496, // "Offsides Asian Handicap", "Team to take the most offside positions, given a certain handicap."

    514, // "Cards Handicap With Draw", "Which team will take most cards, given a certain handicap. "

    515, // "Cards Asian Handicap", "The team to take most cards, given a certain handicap. "

    683, // "Shots Asian Handicap", "The team to take most shots, given a certain handicap."

    684, // "Throw-ins Asian Handicap", "The team to take most throw-ins, given a certain handicap."

    685, // "Goal Kicks Asian Handicap", "The team to take most goal kicks, given a certain handicap."

    736, // "Aces Asian Handicap", "The team to score most aces, given a certain handicap threshold."

    737, // "Double Faults Asian Handicap", "The team to commit more double faults, given a certain handicap threshold."

    809, // "Handicap and Totals", "Team to win given a certain handicap and the score is over or under a certain threshold."

    830, // "Towers Asian Handicap", "The team to take most towers given a certain handicap"

    831, // "Dragons Slain Asian Handicap", "The team to take most dragons given a certain handicap"

    839, // "Roshans Slain Asian Handicap", "The team to take the most roshans given a certain handicap"



    47, // "Over/Under", "End score is over or under a certain threshold."
    77, // "Team Score Over/Under", "The total (exact/ranged) number of goals scored by the named team."
    82, // "Scorings Over/Under In Time Range", "The number of goals scored within a certain time range is over or under a certain threshold."
    // 100, // "Sets Over/Under", "Is the number of sets over or under a certain threshold?"
    105, // "Games Won Over/Under", "The number of games won is over or under a certain threshold."
    109, // "Tie-Break Points Over/Under", "The total tie-break points is over or under a certain threshold."
    118, // "Corners Over/Under", "Is the number of corners over or under a certain threshold?"
    137, // "Corners Over/Under In Time Range", "The number of corners taken within a certain time range is over or under a certain threshold."
    143, // "Tries Over/Under", "The number of tries is over or under a certain threshold."
    155, // "Team Corners Odd/Even", "Will the total number of corners taken by the team be odd or even?"
    156, // "Team Corners Over/Under", "The number of corners taken by the team is over or under a certain threshold."
    159, // "Booking Points Over/Under", "The total number of booking points is over or under a certain threshold."
    160, // "Team Bookings Over/Under", "The total number of bookings received by a team is over or under a certain threshold."
    163, // "Matchbet And Totals", "Bet on home draw away and on the number of goals being over or under a certain threshold."
    179, // "Hits Over/Under", "The total number of hits is over or under a certain threshold."
    184, // "Yellow Cards Over/Under", "The number of yellow cards received in the match is over or under a certain threshold."
    185, // "Cards Over/Under", "The number of cards received in the match is over or under a certain threshold."
    186, // "Team Cards Over/Under", "The total number of cards received by a team in the match is over or under a certain threshold."
    193, // "Points Over/Under", "The number of points scored is over or under a certain threshold."
    194, // "Points Won Over/Under", "The number of points won by a certain player is over or under a certain threshold."
    200, // "Player Score Over/Under", "The number of goals/points scored by a player is over or under a certain threshold. "
    203, // "Throw-ins Over/Under", "The number of throw-ins is over or under a certain threshold."
    204, // "Offsides Over/Under", "The number of offsides is over or under a certain threshold."
    205, // "Substitutions Over/Under", "The number of substitutions is over or under a certain threshold."
    206, // "Fouls Over/Under", "The number of fouls is over or under a certain threshold."
    207, // "Shots On Target Over/Under", "The number of shots on target is over or under a certain threshold."
    208, // "Team Offsides Over/Under", "The total number of offsides for a team in the match is over or under a certain threshold."
    209, // "Team Substitutions Over/Under", "The total number of substitutions for a team in the match is over or under a certain threshold."
    210, // "Team Fouls Over/Under", "The total number of fouls for a team in the match is over or under a certain threshold."
    211, // "Team Shots On Target Over/Under", "The total number of shots on target for a team in the match is over or under a certain threshold."
    229, // "Wides Over/Under", "The total number of wides in the match is over or under a certain threshold."
    230, // "Boundaries Over/Under", "The total number of boundaries in the match is over or under a certain threshold."
    231, // "Fours Over/Under", "The total number of fours in the match is over or under a certain threshold."
    235, // "Penalties Taken Over/Under", "The number of penalties taken is over or under a certain threshold."
    236, // "Penalties Converted Over/Under", "The number of penalties converted into goals is over or under a certain threshold."
    237, // "Team Penalties Converted Over/Under", "The number of penalties converted into goals by the specified team is over or under a certain threshold."
    249, // "Pitcher Strikeouts Over/Under", "The number of strikeouts the named pitcher will record in the game is over or under a certain threshold. "
    250, // "Innings Pitched Over/Under", "The total number of innings a pither has completed is orver or under a certain threshold."
    252, // "Shortest Touchdown Scored", "The shortest touchdown scored in the match will be over or under a certain threshold. (Yardage)"
    253, // "Longest Touchdown Scored", "The longest touchdown scored in the match will be over or under a certain threshold. (Yardage)"
    254, // "Field Goals Over/Under", "The number of successful field goals is over or under a given threshold."
    255, // "Longest Field Goal Made", "The longest field goal made in the match will be over or under a certain threshold. (Yardage)"
    256, // "Touchdowns Over/Under", "The total number of touchdowns in the match is over or under a certain threshold."
    268, // "Offensive Yards Over/Under", "The total number of offensive yards in the match is over or under a certain threshold."
    269, // "Sacks Over/Under", "The total number of sacks in the match is over or under a certain threshold."
    278, // "Drop Goals Over/Under", "The number of drop goals in the match is over or under a certain threshold."
    281, // "180s Over/Under", "The number of 180s thrown in the match is over or under a certain threshold."
    283, // "Checkout Over/Under", "The checkout in the match is over or under a certain threshold."
    290, // "Centuries Over/Under", "The total number of centuries in the match is over or under a certain threshold."
    291, // "Player Centuries Over/Under", "The total number of centuries made by the named player in the match is over or under a certain threshold."
    306, // "Player 180s Over/Under", "The number of 180s thrown in the match by a player is over or under a certain threshold."
    307, // "Highest Break Over/Under", "The highest break made is over or under a certain threshold."
    311, // "Total Frames 3 Way", "The total number of frames played (over, under, exactly x frames) "
    322, // "Maps Over/Under", "The total number of maps played is over or under a certain threshold. "
    323, // "Barons Slain Over/Under", "The total number of barons slain is over or under a certain threshold. "
    324, // "Dragons Slain Over/Under", "The total number of dragons slain is over or under a certain threshold. "
    325, // "Roshans Slain Over/Under", "The total number of Roshans slain is over or under a certain threshold. "
    326, // "Towers Destroyed Over/Under", "The total number of towers destroyed is over or under a certain threshold. "
    327, // "Inhibitors Destroyed Over/Under", "The total number of inhibitors destroyed is over or under a certain threshold."
    328, // "Kills Scored Over/Under", "The total number of kills scored on the map is over or under a certain threshold."
    362, // "Team Runs Over/Under In Xth Over", "Number of runs scored by the team in the specified Over is over or under a certain threshold."
    367, // "Map Time Over/Under", "The map time is over or under a certain threshold."
    377, // "Rounds Over/Under", "The number of rounds played is over or under a certain threshold. "
    382, // "Headshots Over/Under", "The number of headshots is over or under a certain threshold."
    398, // "Team Touchdowns Over/Under", "The number of touchdowns scored by a team is over or under a certain threshold."
    407, // "Player Passing Yards Over/Under", "The amount of yards gained by a player on completed passes is over or under a certain threshold."
    408, // "Player Rush Yards Over/Under", "The amount of yards gained by a single player as the result of a rushing play is over or under a certain threshold."
    416, // "Double Chance & Over/Under", "Two outcomes can happen in the game with the bet still winning: 1X = “Home Team and Draw”, X2 = “Away Team and Draw”, or 12 = “Home Team or Away Team” and end score should be over or under a certain threshold."
    419, // "Both Teams To Score And Totals (4-way)", "Bet on both teams to score or not and the end score being over or under a certain threshold."
    440, // "Kills Over/Under In Time Range", "Bet on the number of kills within a certain time range is over or under a certain threshold."
    448, // "Highest Killing Spree Over/Under", "Bet on the highest killing spree to be over or under a certain threshold."
    449, // "Team Number Of Kills Over/Under", "Bet on the number of kills of a team to be over or under a certain threshold."
    450, // "Total Gold Over/Under", "Bet on the amount of total gold to be over or under a certain threshold."
    461, // "Team Rounds Over/Under", "The number of rounds won by the team is over or under a certain threshold."
    465, // "Team To Win Map & Total Rounds Played Over/Under", "Team to win the map number x and the total number of rounds played in the map are over or under a certain threshold."
    467, // "Player Turnovers Over/Under", "The number of turnovers of a player is over or under a certain threshold."
    468, // "Player Points, Assists and Rebounds Over/Under", "The number of points, assists and rebounds of a player is over or under a certain threshold."
    469, // "Player Assists Over/Under", "The number of assists of a player is over or under a certain threshold."
    470, // "Player Points and Assists Over/Under", "The number of points and assists of a player is over or under a certain threshold. "
    471, // "Player Rebounds Over/Under", "The number of rebounds of a player is over or under a certain threshold. "
    472, // "Player Three's Made Over/Under", "The number of three-point field goals made by a player is over or under a certain threshold. "
    473, // "Player Points and Rebounds Over/Under", "The number of points and rebounds of a player is over or under a certain threshold."
    474, // "Player Assists and Rebounds Over/Under", "The number of assists and rebounds of a player is over or under a certain threshold. "
    477, // "Player Blocks Over/Under", "The number of blocks made by a player is over or under a certain threshold."
    478, // "Player Steals and Blocks Over/Under", "The number of steals and blocks made by a player is over or under a certain threshold."
    479, // "Player Steals Over/Under", "The number of steals made by a player is over or under a certain threshold."
    // 498, // "Half Time/Full Time & Over/Under", "The winner of half time, full time and the end score being over or under a certain threshold. "
    500, // "Halves Goals Combo Over/Under", "The number of goals scored in each half is over or under a certain threshold."
    502, // "Player Passing Touchdowns Over/Under", "The number of passing touchdowns made by a player is over or under a certain threshold."
    503, // "Player Interceptions Over/Under", "The number of interceptions made by a player is over or under a certain threshold."
    504, // "Player Receiving Yards Over/Under", "The number of receiving yards of a player is over or under a certain threshold."
    505, // "Player Receptions Over/Under", "The number of receptions made by a player is over or under a certain threshold."
    506, // "Player Longest Reception Over/Under", "The longest reception made by a player is over or under a certain threshold of yards."
    507, // "Player Longest Pass Completion Over/Under", "The longest pass completion made by a player is over or under a certain threshold of yards."
    508, // "Player Rushing Attempts Over/Under", "The number of rushing attempts made by a player is over or under a certain threshold."
    509, // "Player Rushing And Receiving Yards Over/Under", "The number of rushing and receiving yards of a player is over or under a certain threshold."
    510, // "Player Kicking Points Over/Under", "The number of kicking points of a player is over or under a certain threshold."
    511, // "Team Field Goals Over/Under", "The number of field goals scored by a team is over or under a certain threshold."
    520, // "Team Yellow Cards Over/Under", "The number of yellow cards received by the team is over or under a certain threshold."
    533, // "Goals Over/Under", "The total number of goals is over or under a certain threshold. To be used for sports where default scoring unit is not Goal."
    537, // "Remaining Time Over/Under", "The remaining time at the end of the round is over or under than the specified threshold. Used for  Virtual Sports."
    538, // "Crushing Blows Over/Under", "The number of the crushing blows is over or under than the specified threshold. Used for Mortal Kombat"
    541, // "Player Crushing Blows Over/Under", "The number of crushing blows of a player is over or under than the specified threshold."
    542, // "Player Max Combo Over/Under", "The max combo of a player is over or under than the threshold."
    543, // "Max Combo Damage Over/Under", "The max combo damage done is over or under than the specified threshold. "
    548, // "Time Elapsed Over/Under", "The elapsed time at the start of the virtual round. Used for Virtual Rocket league"
    549, // "Total Demolitions Over/Under", "The total demolitions   in the chosen round. Used for Virtual Rocket League"
    561, // "Correct Round Played Over/Under", "The chosen round number is over or under a given threshold.Used for Virtual Sports."
    569, // "Player Strikes Hit Over/Under", "The total number of strikes hit by the player is over or under than the specified threshold."
    570, // "Player Strikes Attempts Over/Under", "The total number of strikes attempts made by a player is over or under than the specified threshold."
    571, // "Player Win and Health Points Remaining Over/Under", "Player to win and number of health points remaining to be over or under the specified threshold."
    572, // "Strikes Hit Over/Under", "The total number of strikes hit is over or under the specified threshold."
    573, // "Strikes Attempts Over/Under", "The total number of strikes attempted by both players is over or under the specified threshold. "
    574, // "Players Remaining Over/Under", "The amount of players that remain alive at the end of the round is over or under the specified threshold. "
    575, // "Team Players Remaining Over/Under", "The amount of players from a team remaining alive is over or under the specified threshold. "
    576, // "Team Previous Wins Over/Under", "The correct amount of previous rounds won by a team is over or under the specified threshold. "
    577, // "Team Wins After Match Over/Under", "The correct amount of rounds won by a team at the conclusion of the round (including the result of the current round) is over or under the specified threshold."
    588, // "Baskets Over/Under", "Total amount of baskets made is over or under a certain threshold."
    589, // "Shots Over/Under", "Total amount of shots made is over or under a certain threshold."
    590, // "Free Throws Made Over/Under", "Total amount of free throws made is over or under a certain threshold."
    591, // "Free Throw Attempts Over/Under", "Total amount of free throws attempted is over or under a certain threshold."
    592, // "Winning Margin Over/Under", "The margin the match is won by is over or under a certain threshold."
    593, // "3 Point Shots Over/Under", "Total amount of 3-point shots made is over or under a certain threshold."
    594, // "Team Baskets Over/Under", "Total amount of baskets made by a team is over or under a certain threshold."
    595, // "Team Shots Over/Under", "Total amount of shots made by the team is over or under a certain threshold. "
    612, // "Aces Over/Under", "The total number of aces is over or under a certain threshold."
    613, // "Double Faults Over/Under", "The total number of double faults is over or under a certain threshold."
    614, // "Player Double Faults Over/Under", "The total number of double faults made by a player is over or under a certain threshold. "
    615, // "Player Aces Over/Under", "The total number of aces served by the player is over or under a certain threshold."
    617, // "Cards Over/Under In Time Range", "The number of cards received within the specified time range is over or under a certain threshold."
    618, // "Free Kicks Over/Under In Time Range", "The number of free kicks taken within the specified time range is over or under a certain threshold. "
    619, // "Goal Kicks Over/Under In Time Range", "The number of goal kicks taken within the specified time range is over or under a certain threshold. "
    620, // "Throw-ins Over/Under In Time Range", "The number of throw-ins taken within the specified time range is over or under a certain threshold."
    633, // "Runs Over/Under In Xth Over", "Total number of runs in the specified over is over or under the given threshold. "
    634, // "Sixes Over/Under", "Total number of sixes is over or under the given threshold. "
    635, // "Run Outs Over/Under", "Total number of run outs is over or under the given threshold. "
    641, // "Team Sixes Over/Under", "The total number of sixes hit by the specified team. "
    651, // "Player Wickets Over/Under", "Bet on the total number of wickets taken by a bowler."
    652, // "Player Sixes Over/Under", "Bet on the total number of sixes scored by a batsman."
    653, // "Player Fours Over/Under", "Bet on the total number of fours scored by a bowler. "
    654, // "Medals Over/Under", "The number of medals received is over or under a certain threshold. "
    655, // "Gold Medals Over/Under", "The number of gold medals received is over or under a certain threshold."
    657, // "Player Shots Over/Under", "The number of shots scored by a player is over or under a certain threshold. "
    658, // "Player Shots On Target Over/Under", "The number of shots on target scored by a player is over or under a certain threshold. "
    659, // "Player Offsides Over/Under", "The number of offsides scored by a player is over or under a certain threshold."
    660, // "Player Fouls Suffered Over/Under", "The number of fouls suffered by a player is over or under a certain threshold."
    661, // "Player Fouls Made Over/Under", "The number of fouls made by a player is over or under a certain threshold."
    662, // "Player Tackles Over/Under", "The number of tackles made by a player is over or under a certain threshold."
    663, // "Player Passes  Over/Under", "The number of passes made by a player is over or under a certain threshold."
    664, // "Player Saves  Over/Under", "The number of saves made by a player is over or under a certain threshold."
    686, // "Team Throw-ins Over/Under", "The number of throw-ins taken by a team is over or under a certain threshold. "
    687, // "Team Goal Kicks Over/Under", "The number of goal kicks taken by a team is over or under a certain threshold. "
    688, // "Goal Kicks Over/Under", "The number of goal kicks is over or under a certain threshold. "
    695, // "3-Pointers Scored Over/Under", "The number of 3-pointers scored is over or under a certain threshold."
    696, // "Rebounds Over/Under", "The number of rebounds made is over or under a certain threshold."
    697, // "Blocks Over/Under", "The number of blocks made is over or under a certain threshold."
    698, // "Steals Over/Under", "The number of steals made is over or under a certain threshold. "
    699, // "Assists Over/Under", "The number of assists made is over or under a certain threshold. "
    700, // "Turnovers Over/Under", "The number of turnovers made is over or under a certain threshold. "
    701, // "Team 3-Pointers Scored Over/Under", "The number of 3-pointers scored by a team is over or under a certain threshold. "
    702, // "Player Free Throws Scored Over/Under", "The number of free throws scored by a player is over or under a certain threshold. "
    703, // "Player Free Throws Attempted Over/Under", "The number of free throws attempted by a player is over or under a certain threshold. "
    706, // "Team Rebounds Over/Under", "The number of rebounds made by a team is over or under a certain threshold."
    707, // "Team Blocks Over/Under", "The number of blocks made by a team is over or under a certain threshold."
    708, // "Team Steals Over/Under", "The number of steals made by a team is over or under a certain threshold. "
    709, // "Team Assists Over/Under", "The number of assists made by a team is over or under a certain threshold."
    710, // "Team Turnovers Over/Under", "The number of turnovers made by a team is over or under a certain threshold."
    717, // "Woodwork Hits Over/Under", "The number of woodwork hits is over or under a certain threshold."
    718, // "VAR Reviews Over/Under", "The number of VAR reviews is over or under a certain threshold."
    723, // "Player Aces And Double Faults Over/Under", "The number of aces and double faults made by a player is over or under a certain threshold. "
    724, // "Player First Serve In Percentage Over/Under", "The percentage of first serve made by a player is over or under a certain threshold. "
    725, // "Tie Breaks Over/Under", "The number of tie breaks is over or under a certain threshold."
    726, // "Match Duration Over/Under", "The match duration is over or under a certain threshold. For example, a 10 euro bet on Over 100.5 will be Lost if the match duration is 90 minutes, but if the match duration is 101 minutes, the bet will be Won."
    731, // "Team Challenges Over/Under", "The number of challenges made by a team is over or under a certain threshold."
    732, // "Team Aces Over/Under", "he number of aces made by a team is over or under a certain threshold."
    733, // "Team Block Points Over/Under", "The number of block points made by a team is over or under a certain threshold. "
    734, // "Block Points Over/Under", "The number of block points is over or under a certain threshold. "
    735, // "Challenges Over/Under", "The number of challenges made is over or under a certain threshold. "
    738, // "Player Breaks Over/Under", "The number of breaks made by a player is over or under a certain threshold."
    739, // "Player Block Points Over/Under", "The number of block points made by a player is over or under a certain threshold."
    741, // "Distance Covered Over/Under", "The distance covered is over or under a certain threshold. "
    754, // "Player Pass Completions Over/Under", "The number of pass completions made by a player is over or under a certain threshold. "
    757, // "Breaks Over/Under", "The number of breaks must be over or under a certain threshold "
    769, // "Player Kills Over/Under", "The number of kills scored by a player is over or under a certain threshold."
    770, // "Player KD Ratio Over/Under", "The ratio between kills and deaths for a player is over or under a certain threshold."
    771, // "Player Deaths Over/Under", "The number of deaths recorded by a player is over or under a certain threshold."
    777, // "KO's Over/Under", "The number of KO's is over or under a certain threshold."
    779, // "TKO's Over/Under", "The number of TKO's is over or under a certain threshold."
    781, // "Submission Holds Over/Under", "The number of submission holds is over or under a certain threshold."
    786, // "Multicorners Over/Under", "The total number of corners from the first half multiplied with the total number of corners from the second half."
    788, // "Pitcher Outs Over/Under", "How many outs will the pitcher score, over or under a certain threshold? "
    789, // "Player Runs Over/Under", "Player number of runs is over or under a certain threshold."
    790, // "Player Total Bases Over/Under", "Player number of total bases is over or under a certain threshold. Total bases refer to the number of bases gained by a batter through his hits."
    791, // "Player Stolen Bases Over/Under", "Player number of bases stolen is over or under a certain threshold. A stolen base occurs when a baserunner advances by taking a base to which he isn't entitled."
    792, // "Pitcher Earned Runs Over/Under", "How many earned runs will the pitcher score, over or under a certain threshold? Earned runs is any run that scores against a pitcher without the benefit of an error or a passed ball."
    793, // "Player Singles Over/Under", "Player number of singles hit is over or under a certain threshold. A single occurs when a batter hits the ball and reaches first base without the help of an intervening error or attempt to put out another baserunner."
    794, // "Player Doubles Over/Under", "Player number of doubles hit is over or under a certain threshold. A batter is credited with a double when he hits the ball into play and reaches second base without the help of an intervening error or attempt to put out another baserunner."
    795, // "Player Home Runs Over/Under", "Player number of home runs is over or under a certain threshold."
    796, // "Player Triples Over/Under", "Player number of triples hit is over or under a certain threshold. A triple occurs when a batter hits the ball into play and reaches third base without the help of an intervening error or attempt to put out another baserunner."
    797, // "Player Hits Over/Under", "Player number of hits is over or under a certain threshold."
    799, // "Player RBIs Over/Under", "Player number of runs batted in is over or under a certain threshold."
    800, // "Pitcher Hits Allowed Over/Under", "How many hits will the pitcher allow, over or under a certain threshold?"
    801, // "Pitcher Walks Allowed Over/Under", "How many walks will the pitcher allow, over or under a certain threshold? "
    812, // "Total Heroes Alive At The End Of The Map Over/Under", "The number of heroes alive at the end of the map."
    814, // "Total Champions Alive At The End Of The Map Over/Under", "The number of champions alive at the end of the map"
    802, // "Team Hits Over/Under", "Team total number of hits is over or under a certain threshold."
    816, // "Total Players To Get The First Baron Buff Over/Under", "The number of players that will get the first baron buff"
    819, // "Team Towers Destroyed Over/Under", "The team number of towers desotryed."
    841, // "Team To Win Map & Total Kills Scored Over/Under", "Team to win the map and the total number of kills scored are over or under a certain threshold."
    842, // "Barracks Destroyed Over/Under", "The number of barracks destroyed is over or under a certain threshold."
    846, // "Team Roshan Slain Over/Under", "The team number of roshans slain is over or under a certain threshold."
    847, // "Team Barracks Destroyed Over/Under", "The team number of barracks destroyed is over or under a certain threshold."
    858, // "Team Goals Over/Under", "The total number of goals scored by a team in the match is over or under a certain threshold."
    877, // "Matchbet or Over/Under", "Bet on home, draw, away or on the number of goals being over or under a certain threshold."
    882, // "To Win Both Halves And Over/Under", "Bet on the team to win both halves and on the number of goals being over or under a certain threshold."
    887, // "Matchbet And Team Score Over/Under", "Bet on home, draw or away and on the number of goals scored by a team to be over or under a certain threshold."
    
    
    7, // "// Winner", "The winner of a tournament."
    33, // "Winning Margin", "The match winner and the difference the match is won by. "
    9, // "Double Chance", "There can be 3 outcomes  in the game, with the bet still winning: 1X = “Home Team and Draw”, X2 = “Away Team and Draw”, 12 = “Home Team or Away Team”. "
    // 11, // "Half Time / Full Time", "The winner of half time and full time, respectively."
    96, // "Winner Of Time Range", "The winner of the specified time range."
    101, // "Game Winner", "Winner of the specified game."
    106, // "Games X And Y Winner", "The winner of games X and Y."
    108, // "Game Point Winner (Server)", "Will the server win the point?"
    198, // "Point Winner", "Winner of a certain point. "
    406, // "Top Nationality", "The winner of the competition played against adversaries with the same nationality."
    409, // "First Touchdown / Winner", "The player to score the first touchdown and the winner of the match. "
    410, // "Anytime Touchdown / Winner", "The player to score a touchdown and the winner of the match."
    459, // "Team To Draw The First Blood & Win The Map", "Bet on the team to have the first kill and to be the winner of the current map."
    628, // "Insure Bet", "Bet on the winner of the competition, with the possibility of money back if the participant is placed on insured positions."
    764, // "Match Winner and Points Won High/Low", "Player that wins the match and his score is low(7) or higher than 7. (Used for Virtual Table Tennis)"
    883, // "Half Time / Full Time Excluding", "The winner of half time and full time, excluding the specified result (i.e. Not 1/1 - outcome is WON if home team will not win the 1st and 2nd half)"
    889, // "Half Time/Full Time & Total Score", "The winner of half time and full time, respectively and the total ranged score of the period of play."




    
    25, // "Top Goalscorer", "The player to score the most goals."
    26, // "Relegation", "The participant is relegated from the league, i.e. is moved to a lesser (worse) league."
    27, // "Promotion", "The participant is promoted from the league, i.e. is moved to a greater (better) league."
    28, // "Top X", "The participant is among the X (>2) best in the tournament/event part."
    29, // "Last Goalscorer", "The last player to score a goal, if any."
    30, // "Anytime Goalscorer", "The player scores at least one goal."
    // 32, // "Total Score", "The total ranged score of the period of play."
    34, // "Scorecast", "The exact match score, e.g. (4:2), including the first player to score a goal."
    35, // "Odd/Even", "Is the number of goals scored in the match odd or even, with zero goals being considered even?"
    37, // "Qualification", "Bet on the participant to go through to the next round of the competition."
    // 45, // "Correct Score", "Predict the exact score at the end of the match/event part."
    49, // "Number Of Corners", "The (ranged) number of corners."
    57, // "Rock Bottom", "Which participant is on the last place in the tournament."
    64, // "Last Tryscorer", "The player to score the last try, if any (Rugby)."
    65, // "Pole Position", "The player (race driver) who will start the race from the front position."
    66, // "Fastest Lap", "The player to do ( e.g. run or driver ) the fastest lap."
    67, // "First To Retire", "The driver to retire first."
    68, // "First Lap Leader", "The driver to lead after the first lap."
    75, // "Last Team To Score", "The last team to score a goal."
    // 76, // "Both Teams To Score", "Will both teams score a goal? Goal/No Goal."
    // 78, // "Team Total Score", "The number of goals scored in the match by the named team."
    79, // "Clean Sheet", "The team will not have any goals scored against them, but will not necessarily win, the end score can be 0-0."
    80, // "Total Match Bookings", "The total number of card points given by the referee. "
    81, // "Team To Score Most In Time Range", "The team to score the most goals in a certain time range."
    83, // "Action To Score Goal X", "The action to score the Xth goal, if any."
    84, // "Action To Score X With Money Back On No Goal", "The action to score the Xth goal, with money back on no goal."
    85, // "Action To Occur First", "The action to occur first, if any (throw in, free kick, goal kick, corner-kick, goal)"
    87, // "Action To Occur Last", "The action to occur last, if any (throw in, free kick, goal kick, corner-kick, goal)."
    89, // "Team To Receive First Red Card", "The team to receive the first red card, if any."
    90, // "Team Number Of Penalties", "The number of penalties awarded to the named team."
    92, // "Number Of Teams To Score", "The number of teams to score."
    93, // "Time Range Of Team To Score Goal X", "The time range in which a team will score its Xth goal."
    94, // "Goal In Time Range", "Will a goal be scored in the specified time range?"
    95, // "Corner Kick In Time Range", "Will a corner kick occur in the specified time range?"
    97, // "Race To X Goals", "The team first to score X goals."
    98, // "Game Score", "The end score of a game."
    99, // "Game To Deuce", "Will there be a deuce (40-40) in the designated game?"
    102, // "Race To X Games", "The first participant to win X games."
    103, // "Tie-Break", "Will there be a tie-break in the match/set?"
    104, // "Game Score (Server)", "The end score of a game relative to the server. E.g.: Server wins to 0/15/30/40 or loses (break)."
    107, // "Game Win To Deuce", "Who will win the game after a deuce?"
    // 110, // "Total Sets", "The number of sets played."
    // 111, // "Set Points Needed To Win Range", "The number of set points that the winner of a certain set needs to win that set."
    // 112, // "Draw No Bet", "Home win or Away win, with money back on Draw."
    113, // "To Win Both Halves", "Team to win both halves of the match."
    // 114, // "To Win Either Half", "Team to win either half in the match. "
    115, // "To Win To Nil", "Which team can win the game & keep their opponents scoreless. "
    116, // "To Score X Or More Goals", "Which player will score X or more goals in the match?"
    117, // "Last Corner Kick", "The team to get the last corner kick, if any."
    119, // "Name The Finalists", "Which couple of participants will reach the final?"
    120, // "Most Sixes", "Which team will score the most sixes in this match?"
    121, // "Grand Slam", "The team which will manage to beat all the others during one year's competition (Rugby)."
    122, // "Triple Crown", "Team to win the Triple Crown honour in the Six Nations tournament (Rugby)."
    // 123, // "Half With Most Corners", "In which half will the most corners be taken?"
    124, // "Head To Head", "Which of the two participants will be placed higher in the race/round/etap/tournament?"
    125, // "Penalty Awarded", "Will there be a penalty awarded in the match/event part?"
    126, // "Penalty Scored", "Will there be a penalty awarded and scored in the match/event part?"
    128, // "Head To Head To Head", "Which of the three participants will be placed higher in the race/round/etap/tournament?"
    129, // "Most Corners", "Which team will take the most corners during the match/event part?"
    130, // "Race To X Points", "The first participant to score X points."
    131, // "Race To X Corners", "The team first to take X corners."
    132, // "Race To X Runs", "The team first to score X runs."
    133, // "Race To X Frames", "The participant first to win X frames."
    134, // "Team Clean Sheet", "Will the team keep a clean sheet? The team will not have any goals scored against them, but will not necessarily win, the end score can be 0-0."
    135, // "To Go To Overtime", "Will the match go to overtime?"
    136, // "Sending Off", "Will a player be shown a red card during the match?"
    139, // "To Score In Both Halves", "Team to score in both halves of the match."
    140, // "Goal In Both Halves", "Will there be a goal in both halves of the match?"
    141, // "Team To Win To Nil", "Will the team win and keep a clean sheet?"
    142, // "Team Odd/Even", "The team's total score in the match is odd or even. Zero is considered even."
    144, // "Home No Bet", "Bet on an away win or a draw. In the event of a home win the bet is void."
    145, // "Away No Bet", "Bet on a home win or a draw. In the event of an away win the bet is void."
    146, // "Most Bookings", "Bet on which team will get the most Bookings, if any."
    147, // "Highest Opening Partnership", "Which team will have the highest opening partnership?"
    // 148, // "Last Team To Score With Money Back On No Goal", "The team to score last. If no goal is scored, bets are refunded."
    149, // "Come From Behind And Win", "Participant to win the match. The participant must be losing at some point in the match and subsequently go on to win."
    150, // "Come From Behind And Draw", "Participant to come from behind and draw. The participant must be losing at some point in the match and subsequently go on to reach draw."
    151, // "Come From Behind And Win Or Draw", "Participant to come from behind and win or draw. The participant must be losing at some point in the match and subsequently go on to win or reach draw."
    152, // "Highest Scoring Event Part", "In which part of the event the score will be highest?"
    153, // "Corners Odd/Even", "Will the total number of corners in the match be odd or even?"
    154, // "Team Goal In Time Range", "Will the team score a goal in a certain time range?"
    157, // "To Go To Penalties", "Will the game be won after penalties?"
    158, // "A Substitute To Score", "Will a substitute score a goal in the match?"
    161, // "Team Number Of Corners", "The number of corners taken by the team."
    162, // "Team To Receive First Yellow Card", "The team to receive the first yellow card, if any."
    164, // "Number Of Cards", "The exact or ranged number of cards shown in the match."
    165, // "Yellow Cards Odd/Even", "The number of yellow cards shown in the match is odd or even."
    166, // "First Team Booking", "The team to receive the first booking, if any."
    167, // "Own Goal Scored", "Will either team score an own goal in the match?"
    168, // "Method Of Qualification", "By which method will the team qualify?"
    // 170, // "To Win At Least One Set", "Will the player win at least one set in the match?"
    171, // "Number Of Games", "The (ranged) number of games played."
    172, // "Team To Score", "Will the team score at least a goal in the match? "
    173, // "Hat-Trick Scored", "Will a hat-trick be scored during the match?"
    174, // "Method Of First Point Scored", "By which method will the first point(s) be scored? (Basketball)"
    175, // "First 3 Pointer", "The team to score the first 3 pointer, if any."
    176, // "First Free Throw", "The team to score the first free throw, if any."
    177, // "Team To Score In Both Halves", "Will the team score in both halves of the match?"
    // 178, // "To Go To Extra Innings", "Will the match go to extra innings?"
    180, // "Most Hits", "Team with most base hits."
    // 181, // "Xth Set Played", "Will there be an Xth set played in the match?"
    183, // "Most Yellow Cards", "Team to receive most yellow cards during the match."
    187, // "Kick Off", "Team to kick off the match."
    188, // "Team To Win Both Halves", "Will the named team win both halves of the match?"
    // 189, // "Team To Win Either Half", "Will the named team win either half of the match?"
    190, // "To Win Jump Ball", "Which team wins jump ball? (basketball)"
    // 191, // "To Lose The First Set And Win The Match", "Participant to lose the first set, but win the match."
    // 192, // "To Win The First Set And Win The Match", "Participant to win the first set and the match."
    195, // "Total Service Breaks", "The total number of service breaks."
    196, // "Game Of First Service Break", "The game number or the games range the first service break will happen in."
    197, // "First Service Game", "What will happen in the first service game? Will the player hold serve or will the serve be broken?"
    199, // "Margin Of Victory", "The difference the match is won by or any type of draw."
    201, // "First Foul", "The first participant to foul. A foul is a shot or action by the striker which is against the rules of the game."
    202, // "Last Team Booking", "The team to receive the last booking, if any."
    // 212, // "Total Points", "The total (ranged) number of points played."
    213, // "Most Throw-ins", "Team to get the most throw-ins in the match."
    // 214, // "Team With Highest Scoring Inning", "Team to have the highest score in any of the innings."
    // 215, // "Maximum Inning Total Score", "Which will be the highest score in an inning?"
    216, // "Win And Each Way", "An each-way bet is a wager consisting of two separate bets: a win bet and a place bet. Because it is two bets, you have to double your stake."
    217, // "Safety Car?", "Will there be a safety car deployed during the race?"
    218, // "To Reach Qualifying Session Three", "Driver to reach the last stage of the qualifying session."
    219, // "To Be Classified", "The drivers to be classified (finish the race) according to FIA rules."
    220, // "Not To Finish The Race", "The drivers not to be classified (not to finish the race) according to FIA rules."
    221, // "Hat-Trick (Pole Win Fastest Lap) ", "The driver to win the qualifying round, win the race and have the fast lap."
    222, // "Player Points Head To Head", "Which of the two players will score most in the match? "
    223, // "Fifty Scored?", "Will any batsman score 50 or more runs in the match?"
    224, // "Century Scored?", "Will any batsman score 100 or more runs in the match?"
    225, // "First Ball Dot Or Not", "Will first ball be a dot ball (a ball from which a run is not scored)? "
    226, // "Tied Match", "Will the match end with scores tied?"
    227, // "Completed Match", "Will there be a result declared in this limited overs match or will it be Abandoned or a No Result?"
    228, // "Most Fours", "Team to score most fours."
    232, // "To Win The Toss", "Team to win the coin toss (which determines which team bats first)."
    233, // "To Go To Sudden Death", "Will the game end in a sudden death round?"
    234, // "Team To Take Last Penalty", "Which team will take the last penalty?"
    238, // "Last Penalty Scored", "Will the last penalty be scored?"
    240, // "Straight Forecast ", "Predict who will be placed first and who will be placed second in the tournament(in that particular order). Also called Exacta."
    241, // "Reverse Forecast ", "Predict who will be placed first and second in the tournament(in any order). Costs twice the stake as it's practically two straight forecast bets. Also called Quinella."
    242, // "Tricast", "Predict who will be placed first, who will be placed second and who will be placed third in the tournament (in that particular order). Also called Trifecta."
    244, // "Double Podium Finish", "Team to have both cars finish in Top 3 (on the podium)."
    245, // "Double Points Finish", "Team to have both cars finish in Top 10 (in points)."
    246, // "Reverse Tricast", "Predict who will be placed first, second and third in the tournament (in any order)."
    247, // "First Try Converted", "Will the first try be converted?"
    248, // "First Scoring Team Wins", "Will the first team that scores also win the match?"
    251, // "Player Most Strikeouts", "Player to record the most strikeouts."
    257, // "To Score Longest Touchdown", "Which team will score the longest touchdown?"
    258, // "To Make Longest Field Goal", "Which team will make the longest field goal?"
    259, // "To Have Shortest Field Goal", "Which team will have the shortest field goal?"
    260, // "To Call First Timeout", "Which team will call the first timeout?"
    261, // "To Commit First Accepted Penalty", "Which team will commit the first accepted penalty?"
    262, // "To Have First Coach's Challenge", "Which team will have the first coach's challenge?"
    263, // "Special Team Or Defensive Touchdown Scored", "Will there be a special team or defensive touchdown scored in the match?"
    264, // "Field Goal Missed", "Will either team miss a Field Goal attempt in the match?"
    265, // "Two Point Conversion", "Will there be a 2 Point Conversion successfully made in the match?"
    266, // "3 Unanswered Scores", "Will either team score 3 unanswered times?"
    267, // "To Commit First Turnover", "Which team will commit the 1st turnover?"
    270, // "Last Scoring Team Wins", "Will the last team that scores also win the match?"
    271, // "Score In The Final 2 Minutes", "Will there be a score in the final 2 minutes?"
    273, // "Team To Score Last Try", "The team to score the last try, if any. "
    274, // "Drop Goal", "Will a drop goal be scored in the match?"
    275, // "Team To Score A Drop Goal", "Will the named team score a drop goal in the match?"
    276, // "Tries Odd/Even", "Will the total number of tries in the match be odd or even?"
    277, // "To Trail In Match", "Will the named team trail on points at any time during the match?"
    279, // "147 Break In Match", "Will there be a maximum/147 break in the match?"
    280, // "Most 180s", "Who will throw the most 180s in the match?"
    282, // "Race To X Legs", "The participant to first win X legs."
    284, // "Highest Checkout", "Who will record the highest checkout in the match?"
    285, // "170 Checkout?", "Will there be a 170 checkout in the match?"
    286, // "Player 170 Checkout?", "Will the named player record a 170  checkout in the match?"
    287, // "Player 3 Dart Average", "What will the quoted players 3 dart average be in this match?"
    288, // "Nine Dart Finish", "Will there be a nine dart finish in the match?"
    289, // "First 180", "Player to score the first 180."
    292, // "Number Of Centuries", "The total (exact/ranged) number of century breaks in the match."
    293, // "Last 180", "Player to score the last 180. "
    294, // "Player Number Of Centuries", "The total (ranged) number of century breaks scored by the player in the match."
    295, // "Foul Awarded", "Will a foul be awarded?"
    296, // "Number Of Red Cards", "The exact or ranged number of red cards shown in the match."
    297, // "Player Of The Tournament", "Who will be named best player of the tournament? (Golden Ball in World Cup events)"
    298, // "Young Player Of The Tournament", "Who will be named best young player of the tournament?"
    299, // "Best Goalkeeper", "Who will be named best goalkeeper of the tournament? (Golden Glove Award in World Cup events)"
    300, // "Highest Scoring Team", "Which will be the highest scoring team in the tournament?"
    301, // "Lowest Scoring Team", "Which will be the lowest scoring team in the tournament?"
    302, // "Team To Concede Most Goals", "Team to concede most goals in the tournament."
    303, // "Team To Concede Least Goals", "Team to concede least goals in the tournament."
    304, // "Team To Score Fastest Goal", "Team to score fastest goal in the tournament."
    305, // "Highest 3 Dart Average", "Which player will have the highest 3 dart average?"
    308, // "Highest Break 3 Band", "The points range of the highest break made."
    309, // "To Make The Highest Break", "Player to make the highest break."
    312, // "To Stay Up", "The team to avoid relegation."
    313, // "Top Promoted Team", "Which of the promoted teams will finish highest in the league? "
    314, // "Relegation Double", "Predict the combination of two teams that will be relegated. "
    315, // "Relegation Treble", "Predict the three teams that will be relegated. "
    316, // "Team To Score A Penta Kill", "Will a player in the named team register a penta kill?"
    317, // "Team To Score A Quadra Kill", "Will a player in the named team register a quadra kill?"
    318, // "Team To Win At Least One Map", "Will the named team win at least one map?"
    319, // "Both Teams To Slay A Baron", "Will both teams slay a baron?"
    320, // "Both Teams To Destroy An Inhibitor", "Will both teams destroy an inhibitor?"
    321, // "Both Teams To Slay A Dragon", "Will both teams slay a dragon?"
    329, // "Kills Scored Odd/Even", "The total number of kills scored on the map is odd or even."
    330, // "To Draw First Blood", "Team to draw the first blood."
    336, // "Most Kills", "Team to get the most kills."
    337, // "Race To X Kills", "First team to get X kills. "
    338, // "First Goal Before Minute X", "Will the 1st goal be scored before the given time? "
    339, // "Matchbet And Both Teams To Score (3-way)", "Bet on the result of the period of play (1x2), with the assumption both teams score."
    340, // "First Colour Potted", "The first colour legally potted scoring its own value (i.e. not taken as a free ball)."
    341, // "To Pot First Ball", "First player to pot a ball."
    342, // "To Pot Last Ball", "Last player to pot a ball."
    343, // "Century Break", "Will a century break be scored? A century break is a score of 100 points or more within one visit at the table without missing a shot."
    344, // "Player Century Break", "Will the named player score a century(100) break? "
    345, // "Player 50+ Break", "Will the named player score a 50+ break?"
    346, // "50+ Break", "Will a 50+ break be scored?"
    347, // "First Red Potted", "Which player will pot the first red?"
    348, // "Team To Score Goal X", "The team to score goal X, if any. "
    349, // "Team To Score Goal X With Money Back On No Goal", "The team to score goal X. If no goal X is scored, bets are refunded"
    350, // "Time Of Goal X", "The time (range) of the goal X to be scored, if any. "
    351, // "Time Of Team Goal X", "The time (range) when the named team will score goal X, if any. "
    352, // "Player To Score Goal X", "Who will score goal X, if any?"
    353, // "Team To Score Try X", "The team to score try X, if any."
    354, // "Player To Score Try X", "Who will score xth try, if any?"
    356, // "Corner X", "The team to take the Xth corner kick, if any."
    359, // "Goal X Before Minute Y", "Will goal number X be scored before the specified minute? "
    360, // "Team Goal X Before Minute Y", "Will the given team score their goal number X before the specified minute? "
    361, // "Team Runs Odd/Even In Xth Over", "Number of runs scored by the team in the specified Over is odd or even."
    363, // "Team Wicket In Xth Over", "Will a wicket fall in the specified Over? Team indicates the batting team."
    364, // "Team to Destroy Inhibitor X", "The team to destroy Inhibitor X."
    366, // "Team to Score Kill X", "The team to score the xth kill. "
    368, // "Team Number Of Maps Won", "The number of maps won by the team."
    370, // "Most Frags", "Team to get the most frags (frag= the difference between kills and deaths). "
    372, // "To Win by Elimination", "All the Counter-Terrorists were killed by the Terrorists team. "
    373, // "To Win by Defused", "The planted bomb was successfully defused, therefore the Counter-Terrorists won the round."
    374, // "To Win by Bombed", "The planted bomb was successfully detonated, therefore the Terrorists won the round. "
    375, // "To Win by Timeout", "The round is won by   running out of time (i.e Terrorists were unable to plant the bomb, given a certain time). "
    376, // "Rounds Odd/Even", "The number of rounds played is odd or even. "
    378, // "Race To X Rounds", "First team to win X rounds."
    380, // "Bomb Planted", "Will the Terrorists reach Counter's base and plant the bomb in that round? "
    383, // "Team To Win Both Pistol Rounds", "Will the team win both pistol rounds? (there are 2 rounds per map, round 1 and round 16) "
    384, // "Boxing Method Of Victory", "By which decision will the player win the match?"
    385, // "Round Betting", "When (round x) or by which method will the winning player win the match?"
    386, // "Grouped Round Betting", "When (round x-y) or by which method will the winning player win the match? "
    387, // "Fight Result", "The method the match will be won by."
    388, // "Player To Score a Knockdown", "Will the named player score a knockdown?"
    389, // "Player To be Knocked Down and Win", "The winning player has been knocked down and still won the match."
    390, // "Will Fight go the Distance?", "Will the match be decided after the normal time? (12 rounds)"
    // 391, // "Double Result", "The winner of half time and full time, respectively."
    // 392, // "To Finish In Top Half", "To finish in the top half of the tournament."
    393, // "To Finish In Bottom Half", "To finish in the bottom half of the tournament."
    394, // "First Team To Score", "Team to score first in the competition."
    395, // "First Score Method 3 Way", "The scoring type of the first point(s) scored. By Touch Down, Field Goal or Any Other."
    396, // "First Score Method 6 Way", "The method the team scores the first point(s). Team By Touch Down, Field Goal or Any Other."
    397, // "Safety Scored", "Type of scoring play that was scored in the competition."
    399, // "First Touchdown Scorer", "The first player to score a touchdown."
    400, // "Last Touchdown Scorer", "The last player to score a touchdown."
    401, // "Anytime Touchdown Scorer", "The player scores at least one touchdown. "
    402, // "To Score x Or More Touchdowns", "Player to score X or more touchdowns."
    403, // "Team First Touchdown Scorer", "Bet on the first player that scores a touchdown for his team. "
    404, // "Team Last Touchdown Scorer", "Bet on the last player that scores a touchdown for his team. "
    // 405, // "First Quarter / Half Time", "The winner of the first quarter and half time, respectively."
    411, // "Special Bets", null
    412, // "Matchflow", "Participant to score first and the match result: home, draw or away wins the match. Includes 'no goal'."
    413, // "Team Highest Scoring Event Part", "The part of the event with the highest number of points scored."
    414, // "Team To Make Substitution X", "The team to make the substitution number x."
    415, // "Double Chance & Both Teams To Score (6-way)", "Two outcomes can happen in the game with the bet still winning: 1X = “Home Team and Draw”, X2 = “Away Team and Draw”, or 12 = “Home Team or Away Team” and both teams should score a goal."
    417, // "Matchbet & Team To Score Goal X", "Bet on the result of the period of play (1x2) and home or away to score goal X, if any."
    // 418, // "Halftime Result & Both Teams To Score (6-way)", "Bet on the result of the first half and both teams to score a goal."
    420, // "Matchbet And Both Teams To Score (6-way)", "Bet on the result of the period of play (1x2) and both teams to score or not a goal."
    421, // "Action To Occur First (with cards)", "The action to occur first, if any (yellow card, goal, halftime whistle, red card, substitution)"
    422, // "Score Draw", "Bet on the end score being draw or not, with goals scored (0-0 is excluded)."
    423, // "Goal Before X Minutes", "Will a goal be scored before x minutes of play?"
    424, // "Goal After X Minutes", "Will a goal be scored after x minutes of play?"
    425, // "Teams To Score", "Bet on both, neither, only home or only away team to score in the match."
    // 426, // "First 2 Points Of Game X Of Set Y", "Bet on the result after the first 2 points of a game, draw included."
    // 427, // "Double Result (1st Set/Match)", "Bet on the participant that will win the first set and the one that will win the match (1/1, 2/1, 1/2, 2/2) "
    // 428, // "Any Set To Nil", "Bet on a participant to win a set and on the opponent being scoreless.  "
    // 429, // "Total Sets To Exceed The Score Limit", "Bet on the number of the sets that will exceed the score limit (i.e. for tennis is 6)"
    430, // "Team To Slay Baron X", "The team to slay the xth baron, if any."
    431, // "Team To Slay Dragon X", "The team to slay the xth dragon, if any. "
    432, // "Team To Slay Roshan X", "The team to slay the xth roshan, if any. "
    433, // "Team To Destroy Tower X", "The team to destroy the xth tower, if any. "
    434, // "Team To Slay Worker X", "The team to slay the xth worker, if any. "
    435, // "Maps Odd/Even", "Will the total number of maps played be odd or even? "
    436, // "Team To Win Round X", "Bet on the team to win the xth round, if any."
    437, // "Team To Destroy Barrack X", "Bet on the team to destroy the xth barack, if any."
    438, // "Godlike Streak", "Will there be a godlike streak in the match? (godlike reffers to the same player to score 9 kills without dying)"
    439, // "Courier Kill", "Will there be a courier kill in the match?"
    441, // "First Active Rune Type", "Bet on the first active rune type (arcane, haste, invisibility, double damage, illusion, regeneration)"
    442, // "Killing Spree Before Minute X", "Will there be a killing spree before minute X? (killing spree= same player scores 3 kills without dying)"
    443, // "First Dragon Type", "Bet on the first dragon type (infernal, mountain, ocean, cloud) in a game of LOL."
    444, // "Largest Multi Kill", "Bet on the largest multi kill."
    445, // "Triple Kill", "Bet on a player to score a triple kill."
    446, // "Time of First Blood", "Bet on the time interval of the first blood."
    447, // "Time of First Tower Destroyed", "Bet on the time interval of the first tower destroyed."
    453, // "Mega Creeps", "Will mega creeps be spawned in the current map?"
    454, // "Knife Kill", "Will there be a knife kill?"
    455, // "Ace", "Will one team member kill all the opponents in a round? / Will an ace be served in the specified part of the match? (Tennis)"
    456, // "Molotov Kill", "Will there be a molotov kill? (molotov= terrorist exclusive grenade, in CS:GO)"
    457, // "Grenade Kill", "Will there be a grenade kill?"
    458, // "Team To Slay Rift Herald", "Bet on the team to slay Rift Herald, if any.(Rift Herald= neutral monster spawned once per game)"
    460, // "Team To Win Both Pistol Rounds & The Map", "Bet on the team to win both pistol rounds and the map.(pistol rounds refers to round 1 and round 16)"
    462, // "Team To Win Map & Over X Rounds Played", "Team to win the map number x and the total number of rounds played in the map are over a given threshold."
    463, // "Quadra Kill", "Will there be a quadra kill scored? (quadra kill - the act of killing 4 champions within 10 seconds of each other)"
    464, // "Penta Kill", "Will there be a penta kill scored? (penta kill - the act of killing 5 champions within 10 seconds of each other - 30 seconds to penta kill after a quadra kill if no enemy respawns)"
    475, // "Player To Score First Basket", "The first player to score a field goal or a free throw."
    476, // "Player To Record A Triple Double", "Bet on a player to accumulate a double-digit number in three of the five categories in a game: points,rebounds,assists,steals and blocked shots."
    480, // "Player Triple Double", "Will the selected player perform a triple double?  (i.e. accumulate or not a double-digit number in three of the five categories in a game: points,rebounds,assists,steals and blocked shots)."
    481, // "Player Double Double", "The nominated player will accumulate or not a double-digit number in two of the five categories in a game: points,rebounds,assists,steals and blocked shots."
    482, // "Action To Occur First in 1 Minute Time Range", "The action to occur first in a certain time range, if any (goals,goal-kick,corners,throw-in,free-kick) "
    483, // "Action To Occur First in 5 Minutes Time Range", "The action to occur first in a certain time range (goals,cards,corners,penalty,none) "
    484, // "Action To Occur in 1 Minute Time Range", "The match action to occur or not in a certain time range (goals,corners,free-kick,goal-kick,throw-in)"
    485, // "Action To Occur in 5 Minutes Time Range", "The match action to occur or not in a certain time range (goals,corners,penalty)"
    486, // "To Score a Penalty", "A penalty was awarded and scored by either the home or the away participant. The bet is lost if no penalty is awarded."
    487, // "To Miss a Penalty", "A penalty was awarded and missed by either the home or the away participant. The bet is lost if no penalty is awarded."
    488, // "Multi Bet Exact Score", "Bet on the final score to be equal with one of the listed results."
    489, // "Multi Goals", "The number of goals scored is between a certain range of goals or other than the specified range."
    490, // "Most Shots on Target", "Team to take the most shots on target. "
    493, // "Most Fouls", "Team to take the most fouls."
    495, // "Most Offsides", "Team to take the most offside positions."
    // 501, // "To Win At Least Two Sets", "Will the player win at least two sets in the match?"
    512, // "Cards Odd/Even", "Will the total number of cards awarded in the match be odd or even? "
    516, // "Team Red Card", "Will the team be awarded with a red card?"
    517, // "Team Number of Cards", "The number of cards taken by the team. "
    518, // "Time of Card X", "When will card X be awarded taken into consideration a certain time range."
    521, // "Time of Corner X", "When will corner X be awarded taken into consideration a certain time range. "
    522, // "Card Shown In Time Range", "Will a card be awarded in a certain time range of x minutes?"
    523, // "Team Corner in Time Range", "Will the team be awarded with a corner in a specific time range of x minutes?"
    524, // "Race To X Goals 2-Way", "The first team to score X goals. If neither team reaches that given number of goals, the bet is void."
    525, // "Last Corner Kick 2-Way", "The team to receive the last corner kick. Bet is void in case of no corner kick."
    526, // "Race To X Points 2-Way", "The first participant to score X points. If neither team reaches that given number of points, the bet is void."
    527, // "Race To X Corners 2-Way", "The first team to take X corners.  If neither team reaches that given number of corners, the bet is void."
    528, // "Team To Score Last Try 2-Way", "The team to score the last try.  Bet is void in case of no try."
    529, // "Corner X 2-Way", "The team to take the Xth corner kick. If there's no Xth corner kick, the bet is void."
    530, // "Team To Score Try X 2-Way", "The team to score try X. If there's no Xth try, the bet is void."
    532, // "eXchange Rate Increase/Decrease", null
    534, // "Correct Round Played", "What round or round interval will be chosen to be played from the whole match"
    536, // "Longest Combo", "The participant to score the longest combo hits or draw. Used for Mortal Kombat"
    539, // "Fatality or Brutality ", "Will there be a fatality or brutality in the selected round?"
    540, // "Prior Wins", "How many rounds has a player already won in the match."
    544, // "Correct Kills Score", "The correct kills score by any team with the option \"any other\". Used for  Virtual Sports."
    545, // "Team to Take First Shot", "The team who will take the first shot"
    546, // "First Shot Success", "Will the first shot made be successful?"
    547, // "Team Starting Score", "The team starting score, where '3+' means \"3 goals or more\". Used for Virtual Rocket League"
    550, // "Player to be Booked", "The player to be shown a card at any time during the match (any card, yellow, red, yellow/red)."
    551, // "First Player to be Booked", "The first player to receive any card (yellow, red, yellow/red)."
    552, // "Player to be Sent Off", "The player who receives a red card and is required to leave the field of play. "
    553, // "Player to Score in Both Halves", "The player to score in both halves of the match."
    554, // "Player To Score a Header", "The player to score with a header during the match."
    555, // "Player To Score from Outside the Penalty Area", "The player to score during the match from outside the penalty area."
    556, // "First Team Goalscorer", "The player to score the first goal for their respective team."
    557, // "Player To Score and To Be Carded", "The player to score and receive a card at any time during the match."
    558, // "Player To Score in Time Range", "The player to score a goal in the specified time range of the match."
    559, // "Player To Give an Assist", "The player to assist a goal at any time during the match."
    560, // "Player To Score and Give an Assist", "The player who will assist a goal and also score at any time during the match."
    562, // "First Attack", "The participant that will win the first attack. Used for Virtual Sports. "
    563, // "Perfect Win", "One of the participants won the game without been hit.Used for Virtual Sports."
    564, // "Wins Display - Left", "The total number of round wins of the first participant prior to the round that is fought now.Used for Virtual Sports."
    565, // "Wins Display - Right", "The total number of round wins of the second participant prior to the round that is fought now.Used for Virtual Sports."
    566, // "Highest Total Strikes Hit", "The participant with highest total strikes hit."
    567, // "Highest Total Strikes Attempted", "Participant with highest total strikes attempted."
    568, // "Method Of Knockout", "By which method will the knockout be delivered? "
    578, // "Match End Reason", "The reason for the team winning the round/match."
    579, // "Team Win", "The team that wins: Counter Terrorist or Terrorist "
    580, // "Players Remaining - Ranged", "The ranged number of players remaining alive at the end of the round."
    581, // "Team Players Remaining - Ranged", "The ranged number of players from the named team remaining alive at the end of the round."
    582, // "Team Players Remaining - Exact", "The exact number of players from the named team remaining alive at the end of the round."
    583, // "Team Previous Wins - Exact", "The exact amount of previous rounds won by the named team."
    584, // "Team Previous Wins - Ranged", "The ranged amount of previous rounds won by the named team."
    585, // "Team Wins After Match - Ranged", "The correct ranged amount of rounds won by a team at the conclusion of the round including the result of the current round."
    586, // "Team Kills - Ranged", "The ranged amount of kills scored by the named team."
    587, // "Team Kills - Exact", "The exact amount of kills scored by the named team."
    596, // "Most Shots", "Team to take the most shots during the match. "
    597, // "Most 3 Point Shots Made", "Team to make most 3-point shots during the match. "
    598, // "Most Baskets", "Team to make most baskets during the match. "
    599, // "Number Of Baskets", "The number of baskets made during the match. "
    600, // "Number Of Shots", "The number of shots made during the match. "
    601, // "Team Number Of Shots", "The number of shots made by the team during the match. "
    602, // "Number Of 3 Point Shots", "The number of 3 point shots made during the match. "
    603, // "Number Of Fouls", "The number of fouls made during the match. "
    604, // "Team Successful First Shot", "Will the first shot be successful?"
    605, // "Toss Up Possession", "Team that wins possession from the jumpball at the beginning of the match. "
    606, // "Shot Success Rate", "Percentage of successful shots."
    607, // "Shot Success Rate - Ranged", "Ranged percentage of successful shots."
    608, // "Most Aces 2-Way", "Player with the highest number of aces, no draw option."
    609, // "Most Aces 3-Way", "Player with the highest number of aces, or draw."
    610, // "Most Double Faults 2-Way", "Player to make most double faults, no draw."
    611, // "Most Double Faults 3-Way", "Player to make most double faults, or draw. "
    616, // "Double Fault", "Will there be  double fault?"
    621, // "Ace In Point X", "Will the specified point be won by serving an ace?"
    622, // "Double Fault In Point X", "Will there be a double fault when serving for the specified point?"
    623, // "Both Teams to Score in Both Halves", "Will both teams score a goal in both halves of the match?"
    // 624, // "Half Time Or Full Time", "Bet on home/away team to win either 1st half or full time, or score draw in either 1st half or full time."
    626, // "Home Away & Total Score", "Bet on home or away win and total (ranged) score in the period of play."
    627, // "Win And Each Way Without Favourite", "Each-way betting without the favourite participant."
    629, // "Finish Exactly", "Bet on the participant to finish on the exact selected position."
    // 630, // "Half Time/Full Time & Both Teams To Score", "Bet on winner of half time, full time and both teams to score a goal (GG/NG)."
    // 631, // "1st half vs 2nd half", "Number of goals in the 1st half vs number of goals in the 2nd half."
    632, // "Team Penalty X Scored", "Will the team score or miss the specified penalty?"
    636, // "Most Run Outs", "Bet on which team creates the most run-outs whilst fielding. "
    637, // "Xth Wicket Method", "In which way will the specified Wicket be taken - Caught, Bowled, LBW, Run Out, Stumped and Any Other (includes Sent Off/Retired Out). "
    638, // "Team Of Top Batsman", "Which team will have the top batsman. "
    639, // "Xth Wicket Method 2-Way", "In which way will the specified Wicket be taken - Caught or Not Caught. "
    640, // "Runs at Fall of Xth Team Wicket", "The total runs scored by the team before the fall of the specified wicket. "
    642, // "Team Xth Wicket Method", "In which way will the specified Wicket be taken by the named team - Caught, Bowled, LBW, Run Out, Stumped and Any Other (includes Sent Off/Retired Out)."
    643, // "Team Xth Wicket Method 2-Way", "In which way will the specified Wicket be taken by the named team - Caught or Not."
    644, // "Player to Score Most Sixes", "Bet on the player to score most sixes during the match."
    645, // "Player of the Match", "Bet on the player to be officially declared Player of the Match."
    646, // "To Score a Fifty", "Bet on the player to score a fifty in the match."
    647, // "To Score a Century", "Bet on the player to score a century in the match."
    648, // "Top Team Batsman", "Bet on the top batsman for the named team."
    649, // "Top Team Bowler", "Bet on the top bowler (wicket taker) for the named team."
    650, // "Player Performance", "Bet on the total points scored by the named player. Run = 1pt. Catch = 10pts. Wicket = 20pts. Stumping = 25pts."
    665, // "Player To be Substituted", "The player will be substituted in match."
    666, // "Player To Score A Penalty  ", "The player will score at least one penalty in match."
    667, // "Player To Score an Own Goal", "The player will score an own goal during the match."
    668, // "Player To Save a Penalty", "The player will save a penalty during the match."
    669, // "Player To Hit Woodwork", "The player will hit the woodwork with the ball at least one time during the match."
    // 670, // "Player To Have a Shot on Target in Each Half", "The player will have at least one shot on target in each half."
    673, // "Player To Outscore Opposition", "The player will score more goals compared to the opposing team."
    // 674, // "Player To Score in 2nd Half Stoppage Time", "The player will score a goal in the stoppage time of second half."
    675, // "Player To Score Free Kick", "The player will convert a free kick into a goal during the match."
    676, // "Player To Score Inside the 6 Yard Box", "The player will score a goal while within the six yard box."
    677, // "Player To Score with Right Foot", "The player will score a goal with the right foot in match."
    678, // "Player To Score with Left Foot", "The player will score a goal with the left foot."
    679, // "Player Not to Score", "The player will not score a goal in match."
    680, // "First Player Shot", "The first player to score a shot in the match."
    681, // "First Player Shot on Target", "The first player to score a shot on target in the match."
    682, // "Player To Get A Yellow Card", "The player will be awarded with a yellow card in the match."
    689, // "Most Goal Kicks", "Which team will take the most goal kicks during the match?"
    690, // "Shot On Target X 2-Way", "The team to take the Xth shot on target, if any."
    691, // "Offside X 2-Way", "The team to be caught in the Xth offside, if any."
    692, // "Goal Kick X 2-Way", "The team to take the Xth goal kick, if any."
    693, // "3-way (0% margin)", "Who wins the match? with three possibilities: \"home wins\", \"away wins\" or \"draw\"."
    694, // "2-way (0% margin)", "Home win or Away win, with money back on draw."
    704, // "To Go To 2nd Overtime", "Will the match go to 2nd overtime? "
    711, // "Any Team to Score 100+ Points", "Will any team be able to score 100 or more points? "
    712, // "Technical Foul in Match", "Will there be any technical foul in the match? "
    713, // "Top Scorer", "The player with the highest number of points (Dead heat rule should apply)."
    714, // "Top Rebounder", "The player with the highest number of rebounds (Dead heat rule should apply)."
    715, // "Top Assistman", "The player with the highest number of assists (Dead heat rule should apply)."
    716, // "Last Point Scorer", "The player who will score last in the match."
    719, // "Player To Score First or Last", "The player scores first goal or last goal."
    720, // "Method of Last Goal", "By which method will the last goal be scored? Includes No Goal."
    721, // "Total Added Time", "The total ranged added time to the period of play."
    722, // "Method of Goal X", "Method the specified goal will be scored by.(Shot, Header, Penalty, Own Goal, Free Kick, No Goal)"
    727, // "Player to Break Serve", "Will the player score at least one break serve in the match?"
    728, // "Service Break X 2-Way", "Player to take the Xth break in the match."
    729, // "Last Game In Match", "What type of game will the last game be?(Tennis)"
    730, // "Top Blocker", "The player with the highest number of blocks made (Dead heat rule should apply)(volleyball)."
    740, // "Throw-In X 2-Way", "The team to take the Xth throw-in, with money back on no throw-in. "
    742, // "To Lift the Trophy", "Team to win the trophy."
    743, // "Both Teams to Score or Over X Goals in Match", "Both teams will score or the number of goals is over a certain threshold."
    744, // "Team to Make Xth Score", "The team to make xth score."
    745, // "Method of Xth Score", "By which method will score Xth be made? 'Any Other’ relates to any scoring method other than Touchdown or Field Goal."
    746, // "Team Method of Xth Score", "By which method will the named team make the Xth score? 'Any Other’ relates to any scoring method other than Touchdown or Field Goal."
    747, // "Xth Touchdown Scorer", "Player to score Xth Touchdown."
    748, // "Team Xth Touchdown Scorer", "Player to score Xth Touchdown for his team."
    // 749, // "Team to Lead After Every Quarter", "Will the team lead after every quarter in the match?"
    // 750, // "Team to Win Every Quarter", "Will the team win every quarter in the match?"
    // 751, // "Team to Score in Every Quarter", "Will the team score in every quarter of the match?"
    752, // "Number of Field Goals", "The exact or ranged number of field goals made."
    753, // "Number of Touchdowns", "The exact or ranged number of touchdowns scored."
    755, // "Will It Be a Draw?", "Bet on the end score being draw or not."
    756, // "Team Number Of Booking Points", "The number of booking points received by the team."
    // 758, // "To Lead After Every Quarter", "Bet on the team to win after leading the game at the end of every quarter, if any. Also called 'Wire to Wire'."
    759, // "Longest Consecutive Streak", "Number of most consecutive points won in the match."
    761, // "Xth Score Method 6-Way", "The scoring type the team scores the xth point(s). Team By Touch Down, Field Goal or Any Other."
    762, // "First to Score and Race to 7 Points", "Bet on who will win the first point and first to score 7 points.(Used for Virtual Table Tennis)"
    763, // "First to Score, Race to 3 and Race to 7 Points", "Bet on the first player to score, to reach 3 points scored and to reach 7 points scored.(Used for Virtual Table Tennis)"
    765, // "Total Points Odd/Even High/Low", "The total number of points are odd low (7,9), odd high(11), even low(8,10) or even high(12,14,16). Used for Virtual Table Tennis."
    766, // "To Make 1st Down on Xth Drive", "1st down to happen in Xth Drive (a gain of a total of 10 or more yards within usually four downs giving the team the right to start a new series of downs)"
    767, // "Player Most Kills", "The player with the highest number of kills scored (Dead heat rule should apply)."
    768, // "Player Least Kills", "The player with the lowest number of kills scored (Dead heat rule should apply)."
    772, // "Team Total Rounds", "The number of rounds won in the match by the named team."
    773, // "Total Rounds", "The total (ranged) number of rounds played."
    774, // "Team Result of Xth Drive 4-Ways", "What is the result of the Xth drive? For market purpose turnover includes interception, fumble, turnover on downs."
    775, // "Team to Make 1st Down on Xth Drive", "Team xth drive to have a 1st down(a gain of a total of 10 or more yards within usually four downs giving the team the right to start a new series of downs)."
    776, // "Team Xth Drive, Yth Play to Be a First Down", "Team to make a 1st down in a specific play(a gain of a total of 10 or more yards within usually four downs giving the team the right to start a new series of downs)."
    778, // "Method Of Win 3-Way", "By which method will the round be won?"
    780, // "Fighter Method of Win 3-way", "By which method will the fighter win the round?"
    782, // "Method Of Win 8-Way", "By which method will the fight end. Includes the fighters with all possible methods."
    783, // "Method Of Win 4-Way", "By which method will the match be won?"
    784, // "Fight Result 4-Way", "How will the fight end? In a specific round or by points decision (E-Fighting)."
    785, // "Last Team Goalscorer", "The player to score the last goal for their respective team. "
    // 787, // "Both Teams To Score 1st Half / 2nd Half", "Both teams to score or not in the 1st Half and both teams to score or not in the 2nd half."
    798, // "Pitcher To Record The Win", "A pitcher receives a win when he is the pitcher of record when his team takes the lead for good. Is up to the judgment of the official scorer."
    803, // "Race To X Hits", "First team to reach X Hits."
    // 804, // "Home Team to Bat 9th Inning", "Home team is batting if they don't lead after top of the 9th inning."
    805, // "Both Teams To Score X Runs", "Both teams will score X number of runs in the match."
    806, // "Team to Score First and Win", "Team to score first and win the match."
    807, // "Home Run X Type", "What kind of home run X is scored in the match?"
    // 808, // "Lead After Inning X", "Which team will lead after xth inning."
    810, // "Most Kills 2-Way", "Home win or Away win, with money back on draw."
    811, // "Team to Destroy Most Sentry/Observer Wards", "The team who will destroy the most sentry/observer wards."
    813, // "Team With Most Bounty Runes Acquired", "The team that acquires most bounty runes"
    815, // "Team to Buy First BKB", "The team who will buy first BKB."
    817, // "Team to Buy First Blink Dagger", "The team who will buy first blink dagger"
    818, // "Team to Kill Xth Rift Scuttler", "The team who will kill the Xth Rift Scuttler"
    820, // "Team to Kill Xth Rift Herald", "The team who will kill the Xth Rift Herald"
    821, // "First Blood Occurs Before X Minutes", "Will first blood occur before X minutes?"
    822, // "Aegis Snatch", "Will the aegis be snatched? Aegis snatch occurs when a team kills roshan and the other team gets the aegis."
    823, // "Baron Stolen", "Will the baron be stolen? Baron steal occurs when the Baron is killed by the opposing team rather than the one that started it."
    824, // "Either Team To Get A Rampage", "Will any player kill at least 5 opposing heroes in a short time span?"
    825, // "Either Team To Get A Ultra Kill", "Will any player kill 4 opposing heroes in a short time span?"
    826, // "Divine Rapier Bought", "Will divine rapier be bought? Divine rapier is an item in Dota2 listed in base shop."
    827, // "First Tower And Win The Map", "The team who will destroy the first tower and win the map."
    828, // "First Courier Kill And Win The Map", "The team who kills the first courier and win the map"
    829, // "First Roshan And Win The Map", "The team who will kill the first Roshan and win the map"
    832, // "First Blood, First Tower And Win The Map", "The team who score the first blood, first tower and win the map"
    833, // "Towers Destroyed Odd/Even", "The number of towers destroyed will be odd or even"
    834, // "First Blood, Tower, Roshan And Win The Map", "The team who score the first blood, first tower, first Roshan and win the map"
    835, // "First Blood, Tower, First Courier Kill And Win The Map", "The team who score the first blood, first courier and win the map"
    836, // "Active Rune Type At Minute X", "Which type of rune will be spawn at minute x?"
    837, // "Team To Kill First Courier", "The team who will kill the first courier"
    838, // "To Have Higher Net Worth At Minute X", "The team who will have the higher net worth at minute X."
    840, // "Both Teams To Slay A Roshan", "Will both teams slay a Roshan?"
    843, // "Both Teams To Destroy A Barrack", "Will both teams destroy a barrack?"
    844, // "Team To Get X Kills", "Will the team get the X number of kills?"
    845, // "Xth Dragon Type", "What type of dragon x will be spawned? There are 5 types of dragons: Cloud, Infernal, Mountain, Ocean and Hextech."
    848, // "Map Total Time", "The time in which the map will end."
    849, // "Player To Score and Team To Win", null
    850, // "MMA Fight Result 5-Way", "The method the MMA match will be won by."
    851, // "MMA Fight Result 7-Way", "The method the MMA match will be won by."
    852, // "Winning By KO", "The winning method will be KO."
    854, // "Player Win In Round", "Will the player win the match in the respective round?"
    855, // "Home Team Wins or Both Teams To Score", null
    856, // "Away Team Wins or Both Teams To Score", null
    857, // "Draw or Both Teams To Score", null
    859, // "Both Teams To Get A Card", "Will both teams get a card?"
    860, // "Both Teams To Get At Least 2 Cards", "Will both teams get at least 2 cards?"
    861, // "Number Of Goals", "The (ranged) number of goals."
    862, // "Total Goals", "End score is over or under a certain threshold."
    863, // "Both Players to Win a Set", "Will both players win at least one set?"
    864, // "Lead After X Games", "Which player will lead after x games."
    865, // "Break Point", "Will there be at least one break point in game X?"
    866, // "Score After 3 Points", "Correct score after 3 points played in the game, e.g. 30:15"
    // 867, // "Matchbet and Both Players to Win a Set", "Bet on the result of the match with the assumption that both players win at least one set."
    869, // "Score After 2 Games", "Correct score after 2 games played in the set."
    870, // "Score After 4 Games", "Correct score after 4 games played in the set."
    871, // "Score After 6 Games", "Correct score after 6 games played in the set."
    872, // "Server to Win to 0 or 15", "Will the server win the game at 0 or 15?"
    873, // "Server to Win to 0, 15 or 30", "Will the server win the game to 0, 15 or 30?"
    874, // "Total Score Excluding", "The total ranged score of the period of play not to be scored."
    875, // "Both teams to score at least 2 goals", "Will both teams score at least 2 goals?"
    876, // "Matchbet or Both Teams To Score", "Bet on home, draw, away or both teams to score."
    878, // "To Win Both Halves and To Win To Nil", "Bet on the team who wins both halves and keep a clean sheet."
    879, // "Matchbet and Total Score", "Bet on home, draw or away and the total ranged score of the period of play."
    // 880, // "Total Score 1st Half/ 2nd Half", "The total ranged score for each half."
    // 881, // "Half Time / Full Time Double Chance", "Double chance result for the first half and double chance result for the 2nd half."
    884, // "Both Teams To Score and Highest Scoring Event Part", "Both teams to score and in which part of the event the score will be highest."
    885, // "Double Chance And Highest Scoring Event Part", "1X, X2, or 12 and in which part of the event the score will be highest."
    886, // "Double Chance & Total Score", "Bet on 1X, X2 or 12 and the total ranged score of the period of play."
    888, // "Matchbet And Highest Scoring Event Part", "Bet on home, draw, away and on the part of the event the score will be highest (used only for football)."
    890, // "Team Total Score 1st Half/2nd Half", "The number of goals scored by the named team in each half."
    891, // "Matchbet & Goal Scored In Both Halves", "Bet on the result of the period of play (1x2) & Will there be a goal in both halves of the match?"
    892, // "Correct Score Halftime & Fulltime", "Predict the exact score at halftime and at the end of the match."
    893, // "Matchbet & Time Of Goal X", "Bet on home, draw, away and when will the xth goal be scored."
    894, // "Score After 4 Points", "Correct score after 4 points played in the game, participant wins game to 0, leads 40-15 or score tied at 30-30."
    895, // "Correct Score Group", "Bet on the final score of the set to be equal with one of the listed results (used for tennis)."
    896, //"Correct Score Any Player", "Bet on the correct score of the set for any player (used for tennis/padel)."
    
    ]
    
    //------------------------------------------------------------------------------
    
    export let MainMarket = {
        name: "mainMarket",
        name_kor: "메인 마켓",
        data: MainMarketData
    }
    
    export const HalfData = [
        11,// "The winner of half time and full time, respectively."
        114,// "Team to win either half in the match. "
        123,// "In which half will the most corners be taken?"
        189,// "Will the named team win either half of the match?"
        391,// "The winner of half time and full time, respectively."
        392,// "To finish in the top half of the tournament."
        393,// "To finish in the bottom half of the tournament."
        418,// "Bet on the result of the first half and both teams to score a goal."
        498,// "The winner of half time, full time and the end score being over or under a certain threshold. "
        624,// "Bet on home/away team to win either 1st half or full time, or score draw in either 1st half or full time."
        630,// "Bet on winner of half time, full time and both teams to score a goal (GG/NG)."
        631,// "Number of goals in the 1st half vs number of goals in the 2nd half."
        670,// "The player will have at least one shot on target in each half."
        674,// "The player will score a goal in the stoppage time of second half."
        787,// "Both teams to score or not in the 1st Half and both teams to score or not in the 2nd half."
        880,// "The total ranged score for each half."
        881,// "Double chance result for the first half and double chance result for the 2nd half."
        883,// "The winner of half time and full time, excluding the specified result (i.e. Not 1/1 - outcome is WON if home team will not win the 1st and 2nd half)"
        889,// "The winner of half time and full time, respectively and the total ranged score of the period of play."
        890,// "The number of goals scored by the named team in each half."
        892,// "Predict the exact score at halftime and at the end of the match."
    ]
    
    export const Half = {
        name: "1st Half/2nd Half",
        name_kor: "전반전/후반전",
        data: HalfData
    }
    
    export const PeriodData = [
    ]
    
    export const Period = {
        name: "Period",
        name_kor: "피리어드별",
        data: PeriodData
    }
    
    export const QuarterData = [
        405,// "The winner of the first quarter and half time, respectively."
        749, // "Team to Lead After Every Quarter", "Will the team lead after every quarter in the match?"
        750, // "Team to Win Every Quarter", "Will the team win every quarter in the match?"
        751, // "Team to Score in Every Quarter", "Will the team score in every quarter of the match?"
        758, // "To Lead After Every Quarter", "Bet on the team to win after leading the game at the end of every quarter, if any. Also called 'Wire to Wire'."
    ]
    
    export const Quarter = {
        name: "Quarter",
        name_kor: "쿼터별",
        data: QuarterData
    }
    
    export const InningData =[
        178, // "To Go To Extra Innings", "Will the match go to extra innings?"
        214, // "Team With Highest Scoring Inning", "Team to have the highest score in any of the innings."
        215, // "Maximum Inning Total Score", "Which will be the highest score in an inning?"
        250, // "Innings Pitched Over/Under", "The total number of innings a pither has completed is orver or under a certain threshold."
        804, // "Home Team to Bat 9th Inning", "Home team is batting if they don't lead after top of the 9th inning."
        808, // "Lead After Inning X", "Which team will lead after xth inning."
    ]
    
    export const Inning = {
        name: "Inning",
        name_kor: "이닝별",
        data: InningData
    }
    
    export const SetData = [
        71, // "Set Handicap", "The participant will be given various lines of handicaps. This betting type has only two outcomes, Home and Away, draw being excluded."
        100, // "Sets Over/Under", "Is the number of sets over or under a certain threshold?" 
        110, // "Total Sets", "The number of sets played."
        111, // "Set Points Needed To Win Range", "The number of set points that the winner of a certain set needs to win that set."
        170, // "To Win At Least One Set", "Will the player win at least one set in the match?"
        181, // "Xth Set Played", "Will there be an Xth set played in the match?"
        191, // "To Lose The First Set And Win The Match", "Participant to lose the first set, but win the match."
        192, // "To Win The First Set And Win The Match", "Participant to win the first set and the match."
        426, // "First 2 Points Of Game X Of Set Y", "Bet on the result after the first 2 points of a game, draw included."
        427, // "Double Result (1st Set/Match)", "Bet on the participant that will win the first set and the one that will win the match (1/1, 2/1, 1/2, 2/2) "
        428, // "Any Set To Nil", "Bet on a participant to win a set and on the opponent being scoreless.  "
        429, // "Total Sets To Exceed The Score Limit", "Bet on the number of the sets that will exceed the score limit (i.e. for tennis is 6)"
        501, // "To Win At Least Two Sets", "Will the player win at least two sets in the match?"
        863, // "Both Players to Win a Set", "Will both players win at least one set?"
        867, // "Matchbet and Both Players to Win a Set", "Bet on the result of the match with the assumption that both players win at least one set."
    
    ]
    
    export const Set = {
        name: "Set",
        name_kor: "세트별",
        data: SetData
    }
    
    export const ExtraData = [
        32, // "Total Score", "The total ranged score of the period of play."
        45, // "Correct Score", "Predict the exact score at the end of the match/event part."
        76, // "Both Teams To Score", "Will both teams score a goal? Goal/No Goal."
        78, // "Team Total Score", "The number of goals scored in the match by the named team."
        148, // "Last Team To Score With Money Back On No Goal", "The team to score last. If no goal is scored, bets are refunded."
        212, // "Total Points", "The total (ranged) number of points played."
    ]
    
    export const Extra = {
        name: "extra",
        name_kor: "추가옵션",
        data: ExtraData
    }
    
    
    
    
    
    export const esportsmain = MainMarketData.concat(PeriodData, QuarterData, InningData, SetData, HalfData, ExtraData);
    
    
    export const esports1game = [
    ]
    
    export const esports2game = [
    ]
    
    export const esports3game = [
    ]
    
    export const EsportsMainMarkets = [
        esportsmain,
        // esports1game,
        // esports2game,
        // esports3game
    ]
    
    
    
    
    export let AllMarket = {
        name: "allMarket",
        name_kor: "전체",
        // data: MainMarketData.concat(HalfData, PeriodData, QuarterData, InningData, SetData, ExtraData)
        data: []
    }
    
    
    export const EsportsMain = {
        name: "mainMarket",
        name_kor: "메인 마켓",
        data: esportsmain   
    }
    
    export const Dota1 = {
        name: "game1",
        name_kor: "1게임",
        data: esports1game
    }
    
    export const Dota2 = {
        name: "game2",
        name_kor: "2게임",
        data: esports2game
    }
    
    export const Dota3 = {
        name: "game3",
        name_kor: "3게임",
        data: esports3game
    }
    
    
    export const oddsDotaMarkets = [
        AllMarket,
        EsportsMain,
        Dota1,
        Dota2,
        Dota3
    ]
    
    
    export const oddsLeagueOfLegendsaMarkets = [
        AllMarket,
        EsportsMain,
        Dota1,
    ]
    
    
    
    
    export const oddssoccerProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, QuarterData, InningData, SetData)
        },
        Half,
        Extra,
    ]
    
    export const oddsIceHockeylProvidedMarketIds = [
    
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, QuarterData, InningData, SetData, HalfData, ExtraData)
        },
        Period,
        Extra,
    ]
    
    export const oddsbasketballProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, PeriodData, InningData, SetData, HalfData, ExtraData)
        },
        Quarter,
    ]
    
    export const oddsfootballProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, PeriodData, InningData, SetData, HalfData)
        },
        Quarter,
        Extra
    ]
    
    export const oddsbaseballProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, PeriodData, QuarterData, SetData, HalfData)
        },
        Inning,
        Extra
    ]
    
    export const oddsvolleyballProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, PeriodData, QuarterData, InningData, HalfData)
        },
        Set,
        Extra
    ]
    
    export const oddsMMAProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, PeriodData, QuarterData, InningData, SetData, HalfData, ExtraData)
        },
    ]
    
    export const oddsDefaultProvidedMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: MainMarketData.concat(PeriodData, PeriodData, QuarterData, InningData, SetData, HalfData, ExtraData, HalfData)
        },
        Extra
    ]
    
    export const oddsDefaultEsportsMarketIds = [
        AllMarket,
        MainMarket = {
            name: "mainMarket",
            name_kor: "메인 마켓",
            data: esportsmain.concat(esports1game, esports2game, esports3game) 
        },
    ]