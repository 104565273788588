import { dateFormation, isEmpty, showMessage, toastr } from 'Common';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DatePicker } from 'rsuite';
// import 'rsuite/dist/datepicker.css';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { userActions } from 'Common/store';
import { request } from 'Common/request';
import { api_url } from 'Common/keys';



let isLoadingNow = false;
const SignupPage = (props) => {
    const { view, login, hide } = props;
    const dispatch = useDispatch();
    const bankList = useSelector(state => state.user.bankList)
    const [inputs, setInputs] = useState({
        code: '',
        loginId: '',
        loginPwd: '',
        loginPwd_confirm: '',
        name: '',
        bankName: '',
        accountNumber: '',
        masterName: '',
        withdrawPassword: '',
        withdrawPassword_confirm: '',
        tel: '',
        birthday: '',
        gender: '1',
    });
    const {
        loginId,
        loginPwd,
        loginPwd_confirm,
        name,
        bankName,
        accountNumber,
        masterName,
        withdrawPassword,
        withdrawPassword_confirm,
        tel,
        code,
        birthday,
        gender
    } = inputs;
    const [error, setError] = useState({});

    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
        dispatch(userActions.getBankName());
    }, []);

    const onChange = (e) => {
        const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
        setInputs({
            ...inputs, // 기존의 input 객체를 복사한 뒤
            [name]: value // name 키를 가진 값을 value 로 설정
        });
        setError('');
    };

    const onPickDate = (name, dateString) => {
        setInputs({
            ...inputs,
            birthday: dateFormation(dateString, 3)
        });
    }


    const onReset = () => {
        setInputs({
            code: '',
            loginId: '',
            loginPwd: '',
            loginPwd_confirm: '',
            name: '',
            bankName: '',
            accountNumber: '',
            masterName: '',
            withdrawPassword: '',
            withdrawPassword_confirm: '',
            tel: '',
            birthday: '',
            gender: '1',
            error: {}
        })
    };

    const onCheckDuplication = () => {
        if (isEmpty(loginId)) return toastr.warning('아이디를 입력하세요.');
        request.post(api_url + '/api/Users/checkLoginId', {
            loginId: loginId
        })
            .then(data => {
                if (data.status === 0) {
                    toastr.success('이용가능한 아이디입니다.');
                }
                else {
                    const { message } = data;
                    showMessage(message);
                }
            })
    }

    const Signup = e => {
        e.preventDefault();

        let params = {
            code,
            loginId,
            loginPwd,
            loginPwd_confirm,
            name,
            bankName,
            accountNumber,
            masterName,
            withdrawPassword,
            withdrawPassword_confirm,
            tel,
            birthday,
            gender
        }

        let err = {};
        if (isEmpty(loginId)) err.loginId = '아이디를 입력하세요.';
        if (isEmpty(name)) err.name = '닉네임을 입력하세요.';
        if (isEmpty(birthday)) {
            err.birthday = '생년월일을 입력하세요.';
        }
        if (isEmpty(gender)) {
            err.gender = '성별을 입력하세요.';
        }
        if (isEmpty(loginPwd)) err.loginPwd = '비번을 입력하세요.';
        if (isEmpty(loginPwd_confirm)) err.loginPwd_confirm = '비번확인을 입력하세요.';
        if (isEmpty(tel) || tel.length < 11) err.tel = '핸드폰번호를 입력하세요.';
        if (isEmpty(code)) err.code = '가입코드를 입력하세요.';
        if (isEmpty(withdrawPassword)) err.withdrawPassword = '환전비번을 입력하세요.';
        if (isEmpty(withdrawPassword_confirm)) {
            err.withdrawPassword_confirm = '환전비번확인을 입력하세요.';
        }
        if (withdrawPassword !== withdrawPassword_confirm) {
            err.withdrawPassword_confirm = '환전비번확인을 입력하세요.';
        }
        if (isEmpty(bankName)) err.bankName = '은행명을 입력하세요.';
        if (isEmpty(masterName)) err.masterName = '예금주를 입력하세요.';
        if (isEmpty(accountNumber)) err.accountNumber = '계좌번호를 입력하세요.';

        if (!isEmpty(err)) return setError(err);

        request.post(api_url + '/api/Users/signUp', params)
            .then(data => {
                const { status, errors, message } = data;
                if (!status) {
                    // onShowModal("")
                    showMessage(message);
                }
                else {
                    setError(errors);
                    console.log("error", error)
                }
            })
    }

    // const onClose = () => {
    //     props.close();
    // }

    // const onGoLogin = () => {
    //     props.goLogin();
    //     props.onShowRegister();
    // }
    const onHide = () => {
        hide()
    }
    const goLogin = () => {
        onHide();
        login();
    }
    return (
        <Fragment>
            <div className='background' style={{ display: view == true ? "" : "none" }} >
                <div className="contents_center" style={{ width: "100%", position: "relative", height: "100vh" }}>
                    <div className=" contents_center_register">
                        <div className="logoimg_wrap_regi">
                            <div className="logo_img" >
                                <img src="../images/logo.png" alt="logo" style={{ height: "70px" }} />
                            </div>
                        </div>
                        <span className="close_pop .fa-window-close" onClick={onHide}>
                            <img src='../images/close.png' style={{ width: "25px" }} />
                        </span>
                        {/* <div className="title_wrap"><div className="title">회원가입</div></div> */}
                        <div className="con_box00">
                            <div className="write_box_regi ">
                                <div className="login_row">
                                    <div className="label_title" style={{ width: "130px" }} >아이디</div>
                                    <div className=" mobile_">
                                        <input ref={inputRef} className="input1" type="text" name="loginId" value={loginId} placeholder="아이디를 입력하세요." onChange={onChange} style={{ height: "35px", width: "87%" }} />
                                        <span className="btn29_1 mobile_btn_1" onClick={(e) => { e.preventDefault(); onCheckDuplication() }}>중복확인</span>
                                        {error.loginId && (<div data-v-80eae4ca="" className="error-text error-text_style">{error.loginId}</div>)}
                                    </div>
                                </div>
                                <div className='d-flex' style={{ display: "block", justifyContent: "space-between" }}>
                                    <div className="login_row" style={{ width: "100%", marginRight: "10px" }}>
                                        <div className="label_title" style={{ width: "130px" }}> 닉네임</div>
                                        <div className="">
                                            <input className="input1" type="text" name="name" value={name} placeholder="닉네임을 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                            {error.name && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.name}</div>)}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="login_row" style={{ width: "50%", marginRight: "10px" }}>
                                            <div className="label_title" style={{ width: "130px" }}> 비밀번호</div>
                                            <div className="">
                                                <input type="password" className="input1" name="loginPwd" value={loginPwd} placeholder="비밀번호를 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                                {error.loginPwd && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.loginPwd}</div>)}
                                            </div>
                                        </div>
                                        <div className="login_row" style={{ width: "50%" }}>
                                            <div className="label_title" style={{ width: "130px" }}> 비밀번호 확인</div>
                                            <div className="">
                                                <input className="input1" type="password" name="loginPwd_confirm" value={loginPwd_confirm} placeholder="비밀번호를 다시 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                                {error.loginPwd_confirm && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.loginPwd_confirm}</div>)}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='d-flex' style={{ justifyContent: "space-between" }}>


                                </div>
                            </div>

                            <div className="login_row">
                                <div className="label_title" style={{ width: "130px" }}> 성별</div>
                                <div className="">
                                    <div className="d-flex radio-group align-items-center" style={{ display: "flex", justifyContent: "center" }}>
                                        <label className="radio-container">남성
                                            <input type="checkbox" checked={gender === "1"} name="gender" value="1" onChange={onChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="radio-container">여성
                                            <input type="checkbox" checked={gender === "0"} name="gender" value="0" onChange={onChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="login_row" style={{ width: "100%", marginRight: "10px" }}>
                                    <div className="label_title" style={{ width: "130px" }}> 핸드폰번호</div>
                                    <div className="">
                                        <input className="input1" type="text" name="tel" value={tel} maxLength={11} placeholder="숫자만 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                        {error.tel1 && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.tel1}</div>)}
                                        {error.tel2 && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.tel2}</div>)}
                                    </div>
                                </div>
                                <div className="login_row" style={{ width: "100%" }}>
                                    <div className="label_title" style={{ width: "130px" }}> 생년월일</div>
                                    <div className="" style={{ position: "relative" }}>
                                        <input className="input1" placeholder='생년월일을 입력하세요.' name='birthday' value={birthday} onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            value={new Date()}
                                            format="yyyy-MM-dd"
                                            name="time"
                                            onOk={(time) => onPickDate("time", time)}
                                            // onClean={() => clearDate("starttime")}
                                            showMeridian
                                        />
                                        {error.birthday && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.birthday}</div>)}
                                    </div>

                                </div>

                            </div>

                            <div className="login_row">
                                <div className="label_title" style={{ width: "130px" }}> 은행/계좌정보 *</div>
                                <div style={{ display: "flex" }}>
                                    <div className="bank_sign" style={{ position: "relative", display: "flex" }}>
                                        <input className="input1 width" type="text" />
                                        <select name="bankName" value={bankName} tabIndex="7" className="select  back_select margin-5px-bottom" onChange={onChange}>
                                            <option data-v-80eae4ca="" value="">은행선택</option>
                                            {
                                                !isEmpty(bankList) && bankList.map((list, index) => {
                                                    return (<option data-item-type={list.bankName} defaultValue={list.bankName} key={index}>{list.bankName}</option>)
                                                })
                                            }
                                        </select>

                                        <input className="input1" type="text" name="accountNumber" value={accountNumber} placeholder="수자만 입력하세요." onChange={onChange} style={{ height: "35px", marginLeft: "5px" }} />
                                        {error.accountNumber && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.accountNumber}</div>)}
                                        {error.bankName && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.bankName}</div>)}
                                    </div>

                                </div>
                            </div>

                            <div className='d-flex' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="login_row" style={{ width: "50%", marginRight: "10px" }}>
                                    <div className="label_title" style={{ width: "130px" }}> 예금주</div>
                                    <div className="">
                                        <input className="input1" type="text" name="masterName" value={masterName} placeholder="예금주를 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                        {error.masterName && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.masterName}</div>)}
                                    </div>
                                </div>
                                <div className="login_row" style={{ width: "50%" }}>
                                    <div className="label_title" style={{ width: "130px" }}> 추천코드</div>
                                    <div className="">
                                        <input className="input1" type="text" name="code" value={code} placeholder="추천코드를 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                        {error.code && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.code}</div>)}
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="login_row" style={{ width: "50%", marginRight: "10px" }}>
                                    <div className="label_title" style={{ width: "130px" }}> 환전 비밀번호</div>
                                    <div className="">
                                        <input className="input1" type="password" name="withdrawPassword" value={withdrawPassword} placeholder="출금 비밀번호를 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                        {error.withdrawPassword && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.withdrawPassword}</div>)}
                                    </div>
                                </div>
                                <div className="login_row" style={{ width: "50%" }}>
                                    <div className="label_title" style={{ width: "130px" }}> 환전 비밀번호 확인</div>
                                    <div className="">
                                        <input className="input1" type="password" name="withdrawPassword_confirm" value={withdrawPassword_confirm} placeholder="출금 비밀번호를 다시 입력하세요." onChange={onChange} style={{ height: "35px", width: "100%" }} />
                                        {error.withdrawPassword_confirm && (<div data-v-80eae4ca="" className="error-text  error-text_style">{error.withdrawPassword_confirm}</div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="con_box10">
                            <div className="btn_wrap_center">
                                <ul>
                                    <li style={{ marginRight: "5px" }} onClick={Signup}><a ><span className="login_btn2">회원가입</span></a></li>
                                    <li style={{ marginLeftt: "5px" }} ><span onClick={goLogin} className="login_btn1">로그인</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SignupPage;