import { isEmpty } from 'Common';
import { betActions, moreActions, pageActions } from 'Common/store';
import { perPage } from 'Utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import SportsHistory from './SportsHistory';
import Pagenation from 'Components/common/Pagenation';
import Modal from 'Components/Modal/Modal';
import MinigameHistory from './MinigameHistory';
import CasinoHistory from './CasinoHistory';
import BetGameHistory from './BetGameHistory';

const bethistoryTabs = [
  // {
  //     name: '스포츠 (국내형)',
  //     value: 'home_prematch'
  // },
  {
    name: '스포츠 (해외형)',
    value: 'prematch'
  },
  {
    name: '스포츠 (라이브)',
    value: 'live'
  },
  // {
  //     name: 'E-스포츠',
  //     value: 'esport'
  // },
  {
    name: '가상게임',
    value: 'betgame365'
  },
  {
    name: '미니게임',
    value: 'minigame'
  },
  {
    name: '카지노',
    value: 'casino'
  },
  {
    name: '슬롯',
    value: 'slot'
  }
]


const BHistoriesContents = () => {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)
  const curPage = useSelector(state => state.page.curPage)
  const totalPage = useSelector(state => state.page.totalPage)
  const bethistory = useSelector(state => state.more.histories)

  const [active, setActive] = useState("prematch");
  const [gameResult, setGameResult] = useState("")
  const [history, setHistory] = useState({});
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [showHis, setShowHis] = useState(false);
  const [histories, setHistories] = useState([]);
  const [saveId, setSaveId] = useState("")
  const [popup, setPopup] = useState(false);

  const onPopup = () => {
    setPopup(!popup);
  }


  useEffect(() => {
    if (active === "prematch" || active === "live" || active === "esport") {
      return;
    }
    else {
      dispatch(pageActions.setPerPage(30));
    }
  }, [active])

  useEffect(() => {
    setHistories(bethistory)
  }, [bethistory])

  const onHistory = (list, index) => {
    if (historyIndex === index) {
      setHistoryIndex(-1);
    }
    else {
      setHistoryIndex(index);
      setHistory(list);
    }
  }

  const GetBettingHistories = (page, type, clear = false) => {
    if (isEmpty(type)) type = active;
    if (isAuthenticated) {
      dispatch(moreActions.getBettingHistories({
        type: type,
        page: page,
        perPage: perPage,
        gameResult: gameResult,
        clear: clear
      }))
    }
  }

  const onClickTab = (tabActive) => {
    if (active === tabActive)
      return;
    setHistories([]);
    setHistory({});
    setActive(tabActive);
  }

  const onGameResult = (val) => {
    setHistory({});
    setGameResult(val);
  }

  useEffect(() => {
    setHistory({});
    setHistoryIndex(-1);
    GetBettingHistories(1, active, true);
  }, [isAuthenticated, gameResult, perPage])

  useEffect(() => {
    GetBettingHistories(1, active, true);
  }, [active])

  const showHisotry = () => {
    setShowHis(!showHis);
  }

  const cancelBetting = (id, type) => {
    // e.preventDefault();
    dispatch(betActions.cancelBetting({
      id: id,
      type: type
    }))
    onPopup();
  }

  return (
    <div className="contents_center">
      <div className="contents_center_info_full">
        <div className="title">베팅내역</div>
        <div className="con_box05">
          <div className="bethistory_tab">
            <ul>
              {
                bethistoryTabs.map((tab, index) => {
                  return (
                    <li className={tab.value !== active ? "" : "active"} key={index} onClick={() => onClickTab(tab.value)}>
                      <a>{tab.name}</a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className="con_box00">
          <div className="all_odds_box" style={{marginBottom: "1px"}}>
            {
              !isEmpty(histories) && histories.map((list, index) => {
                return (active === 'minigame' ? (
                  <MinigameHistory key={index} history={list} page={{ curPage, totalPage }} index={index} />
                ) : (
                  active === 'betgame365' ? (
                    <BetGameHistory key={index} history={list} page={{ curPage, totalPage }} index={index} />
                  ) : (
                    active === 'slot' || active === 'casino' || active === 'hotelcasino' ? (
                      <CasinoHistory key={index} history={list} page={{ curPage, totalPage }} index={index} />
                    ) : (
                      <SportsHistory key={index} history={list} page={{ curPage, totalPage }} setSaveId={setSaveId} onPopup={onPopup} active={active} index={index} />
                    ))
                ))
              })
            }
          </div>
        </div>
        {
          isEmpty(histories) ?
            <div className='no_data'>내역이 없습니다</div>
            :
            <Pagenation
              page={curPage}
              totalPage={totalPage}
              getPage={GetBettingHistories}
              perPage={5}
            />
        }
      </div>
      {
        popup && <Modal contents="취소하시겠습니까?" popup={popup} onAction={cancelBetting} onPopup={onPopup} />
      }
    </div>
  );
};

export default BHistoriesContents;