import Bet365Center from 'Components/Betgame/Bet365Center';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect, useState } from 'react';
import qs from 'querystringify';
import { history } from 'Common';

const Bet365 = (props) => {

    const [url, setUrl] = useState("")
    const [showLeft, setShowLeft] = useState(false);
    const query = qs.parse(history.location.search);
    const match = history.location.pathname;
    const type = query.type
    // const match = props.match.url;

    const onSetShowLeft = () => {
        setShowLeft(!showLeft);
    }

    useEffect(() => {
        window.scrollHandler();
      }, [])

    return (
        <Fragment>
            <LeftBar type="virtual" />
            <Bet365Center type={type} match={match} url={url}/>
            <Right type="virtual" />
        </Fragment>
    );
};

export default Bet365;