import { ResizeLotusIframe, dateFormation, getBetList, isEmpty } from 'Common';
import { betGameAllowMarket, lotusGameAllowMarket } from 'Common/betGameMarkets';
import { pageActions, sportsActions } from 'Common/store';
import { betGames, lotusgame } from 'Utils/names';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Bet365Menu from './Bet365Menu';
import Bet365MarketItem from './Bet365MarketItem';
import $ from "jquery";



const Bet365Center = (props) => {

    const dispatch = useDispatch();
    const sports = useSelector(state => state.sports)
    const gamelists = useSelector(state => state.sports.data)
    const url = useSelector(state => state.sports.url)
    const betgametype = useSelector(state => state.sports.betgametype)

    const { match, type } = props;


    const [showLeft, setShowLeft] = useState(false);
    const [betItem, setBetItem] = useState({});
    const [selectedList, setSelectedList] = useState({});
    const [betGame, setBetGame] = useState("Soccer");
    // const [lotus, setLotus] = useState("SOCCER");
    const [leagues, setLeagues] = useState([
        {
            name: "World Cup",
            id: "worldCup"
        },
        {
            name: "Premiership",
            id: "premiership"
        },
        {
            name: "Superleague",
            id: "superleague"
        },
        {
            name: "Euro Cup",
            id: "euroCup"
        },
    ]);
    const [league, setLeague] = useState("worldCup");
    const [list, setList] = useState("");
    const [iframeUrl, setIframeUrl] = useState("World Cup");
    const [markets, setMarkets] = useState([]);

    useEffect(() => {
        getSportsList()
        dispatch(sportsActions.setSportsList([]))
        dispatch(sportsActions.setActiveId(""))
        setMarkets([]);
    }, [])

    useEffect(() => {
        if (isEmpty(gamelists))
            setMarkets([]);
    }, [sports.data])


    const onBetGame = (id) => {
        if (betgametype === "bet365") {
            setBetGame(id);
            let index = betGames.findIndex(item => item.id === id);
            if (index < 0) index = 0;
            setLeagues(betGames[index].leagues);
            setLeague(betGames[index].leagues[0].id);
            setIframeUrl(betGames[index].leagues[0].name)
        }
        else {
            setBetGame(id);
            let index = lotusgame.findIndex(item => item.id === id);
            if (index < 0) index = 0;
            setLeagues(lotusgame[index].leagues);
            setLeague(lotusgame[index].leagues[0].id);
            setIframeUrl(lotusgame[index].leagues[0].name)
        }

    }

    const onLeague = (id) => {
        setLeague(id);
        let index = leagues.findIndex(item => item.id === id);
        if (index < 0) index = 0;
        setIframeUrl(leagues[index].name)
    }

    const onList = (key) => {
        if (isEmpty(gamelists[gamelists.findIndex((item) => item.key === key)])) return;
        let gameDate = new Date(gamelists[gamelists.findIndex((item) => item.key === key)].date);
        let dateNow = new Date()



        // if(gameDate < dateNow) {
        //     getSportsList();
        //     return toastr.warning("배팅시간이 지났습니다.");
        // }


        // console.log('betGame: ', betGame);
        if (isEmpty(gamelists)) return;

        setList(key);
        setSelectedList(gamelists[gamelists.findIndex((item) => item.key === key)]);
        let betMarkets = gamelists[gamelists.findIndex((item) => item.key === key)].markets;
        if (isEmpty(betMarkets)) return;
        let lists = [];
        // console.log('betMarkets: ', betMarkets);
        let allowMarkets = [];
        if (betgametype === "bet365") {
            allowMarkets = betGameAllowMarket.find(list => list.id === betGame).allowMarkets;
        }
        else {
            allowMarkets = lotusGameAllowMarket.find(list => list.id === betGame).allowMarkets;
        }
        // console.log('allowMarkets: ', allowMarkets, betGame);
        Object.keys(betMarkets).forEach(item => {
            let marketList = {}
            marketList.name = item;
            marketList.markets = betMarkets[item];
            if (betGame === "Soccer") {
                // item ==
                marketList.markets = marketList.markets.filter(market => {
                    const patt = /\[|,/i;
                    return !patt.test(market.home);
                })
            }
            lists.push(marketList);
            // if (!isEmpty(allowMarkets) && (allowMarkets.findIndex(market => market.name === item) > -1)) {
                // }
            });
            console.log('lists: ', lists);
        setMarkets(lists);
    }

    useEffect(() => {
        if (!isEmpty(sports.activeId)) {
            onList(sports.activeId);
        }
    }, [sports.activeId]);

    useEffect(() => {
        return (() => {
            setIframeUrl("")
        })
    }, []);



    const roomObj = {
        pageType: "virtual",
        sportType: betGame,
        gameType: "all",
        sportId: 'all',
        betGame: betGame,
        league: league,
    }

    const roomData = {
        MessageType: "RoomType",
        data: roomObj
    };



    // if(window.ws.readyState === 1)
    // window.ws.onmessage(JSON.stringify(data));

    ResizeLotusIframe();
    // if(betgametype === "bet365"){

    // }else{
    //     ResizeLotusIframe();
    // }
    dispatch(pageActions.setPageInfo(roomObj))

    const getSportsList = (data) => {
        const params = {
            ...roomObj,
            sportType: betGame,
            league: league
        }
        setMarkets([]);
        dispatch(sportsActions.getSportsList({param: params}))
    }

    useEffect(() => {
        getSportsList();
        if (window.ws.readyState === 1)
            window.ws.send(JSON.stringify(roomData));
    }, [betGame, league]);

    useEffect(() => {
        let betItem;
        if (betgametype === "bet365") {
            betItem = betGames.find(item => item.id === type)
        }
        else {
            betItem = lotusgame.find(item => item.id === type)
        }
        setBetItem(betItem);
        onBetGame(type)
        $(document).ready(function () {
            if (!isEmpty($("#vframe1")))
                $("#vframe1")[0].height = $("#vframe1")[0].offsetWidth * 9 / 16; //플레이어를 16:9비율로 맞춤
        });
    }, [type]);

    const onSetShowLeft = () => {
        setShowLeft(!showLeft);
    }

    const getIfreamUrl = (id) => {
        let pattern = new RegExp("leaguesName", "i")
        let newUrl = String(url).replace(pattern, id);
        return newUrl;
    }
    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="mini_game_tab bet365">
                    <Bet365Menu match={match} type={type} />
                </div>
                <div className="mini_game_tab_in bet365">
                    <ul>
                        {
                            leagues.map((item, index) => {
                                return (
                                    <li className={league === item.id ? "active" : ""} key={index}>
                                        <a onClick={() => onLeague(item.id)}>
                                            <div className="mgtt1">{item.name}</div>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="con_box10">
                    <div className="mini_game_zone">
                        <div id="betgame_iframe_parent" className="mini_game_left">
                            <iframe
                                id="vframe1"
                                className="frameScale"
                                scrolling="no"
                                frameBorder="0"
                                width="100%"
                                height="100%"
                                allowFullScreen="yes"
                                src={`${getIfreamUrl(iframeUrl)}&sw=700&sh=500`}
                                title="betgameIframe"
                                allow="autoplay"
                            />
                        </div>
                        <div className="mini_game_right">
                            <div className="right_game_title">
                                <div>
                                    <img src={isEmpty(betItem) ? "" : betItem.img} alt="" style={{ width: "20px" }} />
                                    &nbsp;&nbsp;{isEmpty(betItem) ? "" : betItem.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {isEmpty(selectedList) ? "" : getBetList(selectedList)}회차
                                </div>
                                <ul className="menu_virtual_time">
                                    {
                                        gamelists.map((item, index) => {
                                            return (
                                                <li className="" style={{ width: "fit-content" }} key={index} onClick={() => dispatch(sportsActions.setActiveId(item.key))}>
                                                    <button className={`overflow-hidden ${item.key === list && "active"}`}>{dateFormation(item.date, 10)}</button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <ul className="dropdown3">
                                {
                                    !isEmpty(markets) && markets.map((item, index) => {
                                        return (
                                            <Bet365MarketItem market={item} key={index} date={selectedList} betgametype={betgametype} getSportsList={getSportsList} />
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bet365Center;