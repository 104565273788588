import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { betActions, sportsActions, store } from '.';
import { isEmpty } from 'Common';


const name = "page";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const pageActions = { ...slice.actions, ...extraActions };
export const pageReducer = slice.reducer;

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        pageInfo: {
            pageType: 'unset', // toto, EOSPowerball1, EOSPowerball2, EOSPowerball3, EOSPowerball4, EOSPowerball5
            sportType: 'all',   // all, sport, esport, race
            gameType: 'all',   // all, live, prematch
            sportId: 'all',     // all, "1", "2", "3"....
            esportType: 'all'
        },
        perPage: 10,
        curPage: 1,
        totalPage: 1,
        isUp: false,
        loading: false,
        messagePage: false,
        rewordBonusPage: false,
        checkData: {},
        gameCheck: {},
        reconnectSetting: {}
    }
}

function createReducers() {
    return {
        setPageInfo,
        setPageData,
        setPageLoad,
        setPerPage,
        setCheckPage,
        setMessagePage,
        setGameCheck,
        setReconnectSetting,
        setScrollDirection,
        SetRewordBonusPage,
        SetCurrentPage
    };
    function SetCurrentPage(state,action)
    {
        state.curPage = action.payload.page
        state.totalPage = action.payload.totalPage
    }
    function SetRewordBonusPage(state,action)
    {
        state.rewordBonusPage = action.payload
    }
    function setPageInfo(state, action) {
        state.pageInfo = action.payload;
    }

    function setScrollDirection(state, action) {
        state.isUp = action.payload;
    }

    function setPageData(state, action) {
        state.curPage = (!isEmpty(action.payload) && !isEmpty(action.payload.page)) ? action.payload.page : 1;
        state.totalPage = (!isEmpty(action.payload) && !isEmpty(action.payload.totalPage)) ? action.payload.totalPage : 1;
    }

    function setPageLoad(state, action) {
        state.loading = action.payload;
    }

    function setPerPage(state, action) {
        state.perPage = action.payload;
    }
    function setCheckPage(state, action) {
        state.checkData = action.payload;
    }
    function setMessagePage(state, action) {
        state.messagePage = action.payload;
    }
    function setGameCheck(state, action) {
        state.gameCheck = action.payload;
    }
    function setReconnectSetting(state, action) {
        state.reconnectSetting = action.payload;
    }

}

function createExtraActions() {
    return {
        onSetPageInfo: onSetPageInfo(),
        onSetPerPage: onSetPerPage(),
        onSetPageLoading: onSetPageLoading(),
        onSetMessagePage: onSetMessagePage(),
        onSetReconnectSetting: onSetReconnectSetting(),
        onSetGameCheck: onSetGameCheck()
    }

    function onSetPageInfo() {
        return createAsyncThunk(
            `${name}/onSetPageInfo`,
            function (params, { getState, dispatch }) {
                let nowData = getState().page.pageInfo;
                if (nowData.sportType !== params.sportType || nowData.gameType !== params.gameType) {
                    dispatch(sportsActions.clearSportsList());
                    dispatch(betActions.clearBetStore());
                    // dispatch(pageActions.setPageInfo(params))
                }
                dispatch(pageActions.setPageInfo(params))
            }
        )

    }

    function onSetPageLoading() {
        return createAsyncThunk(
            `${name}/onSetPageLoading`,
            function (arg, { dispatch }) {
                dispatch(pageActions.setPageLoad(arg))
            }
        )

    }

    function onSetMessagePage() {
        return createAsyncThunk(
            `${name}/onSetMessagePage`,
            function (data, { dispatch }) {
                dispatch(pageActions.setMessagePage(data))
            }
        )
    }

    function onSetPerPage() {
        return createAsyncThunk(
            `${name}/onSetPerPage`,
            function (data, { dispatch }) {
                dispatch(pageActions.setPerPage(data))
            }
        )
    }
    function onSetReconnectSetting() {
        return createAsyncThunk(
            `${name}/onSetReconnectSetting`,
            function (data, { dispatch }) {
                dispatch(pageActions.setReconnectSetting(data))
            }
        )
    }
    function onSetGameCheck() {
        return createAsyncThunk(
            `${name}/onSetGameCheck`,
            function (data, { dispatch }) {
                dispatch(pageActions.setGameCheck(data))
            }
        )
    }

}