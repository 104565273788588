import { dateFormation, isEmpty } from 'Common';
import { moreActions } from 'Common/store';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

const PointHistoriesContents = () => {
    const dispatch = useDispatch();
    const page = useSelector(state => state.page)
    const user = useSelector(state => state.user.data.user);
    const lists = useSelector(state => state.more.pointHistory);
    console.log('pointHistory: ', lists);

    const getLists = (page = 1) => {
        dispatch(moreActions.getPointHistory({
            page: page,
            perPage: perPage
        }))
    }

    useEffect(() => {
        getLists(1)
    }, [perPage])

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">포인트내역</div>
                <div className="con_box00">
                    <div className="list_box">
                        <div className="over_list">
                            <div className="list_tr29 trfirst">
                                <div className="list_title2">사용포인트</div>
                                <div className="list_title2">이전포인트</div>
                                <div className="list_title2">보유포인트</div>
                                <div className="list_title2">내용</div>
                                <div className="list_title2">상세내용</div>
                                <div className="list_title2">처리일시</div>
                            </div>
                            {
                                !isEmpty(lists) && lists.map((list, index) => {
                                    return (
                                        <div className="list_tr29" key={index}>
                                            <div className="list2"><span className="font11">{isEmpty(list) ? 0 : list.amount.rolling}</span></div>
                                            <div className="list2">{(isEmpty(list) ? 0 : list.balance.rolling) - (isEmpty(list) ? 0 : list.amount.rolling)}</div>
                                            <div className="list2">{isEmpty(list) ? 0 : list.balance.rolling}</div>
                                            <div className="list2"><span className="division2">{list.memo.title}</span></div>
                                            <div className="list2" >{list.memo.description}</div>
                                            <div className="list2" >{dateFormation(list.date, 10)}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        isEmpty(lists) ?
                            <div className='no_data'>내역이 없습니다</div>
                            :
                            <Pagenation
                                page={page.curPage}
                                totalPage={page.totalPage}
                                getPage={getLists}
                            />
                    }
                </div>
            </div>
        </div>

    );
};

export default PointHistoriesContents;