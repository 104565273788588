import { isEmpty } from 'Common';
import { sportsActions } from 'Common/store';
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux'
import Detail from './Detail';
import Pagenation from 'Components/common/Pagenation';
import $ from "jquery";


const sports = [
    {
        kind: "축구",
        gameCount: 0,
        imgPath: "../images/swip/icon_football.svg",
        id: 6046,
    },
    {
        kind: "농구",
        gameCount: 0,
        imgPath: "../images/swip/icon_basketball.svg",
        id: 48242,
    },
    {
        kind: "배구",
        gameCount: 0,
        imgPath: "../images/swip/icon_volleyball.svg",
        id: 154830,
    },
    {
        kind: "야구",
        gameCount: 0,
        imgPath: "../images/swip/icon_baseball.svg",
        id: 154914,
    },
    {
        kind: "아이스하키",
        gameCount: 0,
        imgPath: "../images/swip/icon_ice_hockey.png",
        id: 35232
    },
    {
        kind: '테니스',
        gameCount: 0,
        id: 54094,
        imgPath: '../images/swip/jongmok_Tennis.svg'
    },
    {
        kind: '탁구',
        gameCount: 0,
        id: 265917,
        imgPath: '../images/swip/jongmok_Tabletennis-ico.svg'
    },
    {
        kind: '미식축구',
        gameCount: 0,
        id: 131506,
        imgPath: '../images/swip/rugby-league-ico.svg'
    },
    {
        kind: '크리케트',
        gameCount: 0,
        id: 452674,
        imgPath: '../images/swip/jongmok_Baseball.png'
    },
    {
        kind: "MMA",
        gameCount: 0,
        imgPath: "../images/swip/icon_mma.svg",
        id: 154919,
    },
]
const esports = [
    {
        kind: "도타 2",
        gameCount: 0,
        imgPath: "../images/swip/icon_dota_2.svg",
        id: "dota2",
    },
    {
        kind: "리그 오브 레전드",
        gameCount: 0,
        imgPath: "../images/swip/icon_league_of_legends.svg",
        id: "leagueoflegends",
    },
    {
        kind: "오버워치",
        gameCount: 0,
        imgPath: "../images/swip/icon_overwatch_2.svg",
        id: "overwatch",
    },
    {
        kind: "스타크래프트 2",
        gameCount: 0,
        imgPath: "../images/swip/icon_starcraft_2.png",
        id: "starcraft2",
    },
    {
        kind: "워크래프트",
        gameCount: 0,
        imgPath: "../images/swip/icon_warcraft_iii.png",
        id: "warcraft",
    },
    {
        id: 'ageofempire',
        gameCount: 0,
        kind: '에이지 오브 엠파이어',
        imgPath: "../images/swip/game_ico_ageofempire.png",

    },
    {
        id: 'counterstrike',
        gameCount: 0,
        kind: '카운터 스트라이크',
        imgPath: "../images/swip/game_ico_counterstrike.svg",
    },
    {
        id: 'leagueoflegends_wildlift',
        gameCount: 0,
        kind: '리그오브레전드 와일드리프트',
        //   label1: '와일드리프트',
        imgPath: "../images/swip/game_ico_leagueoflegends_wildlift.png",
    },
    {
        id: 'pentastorm',
        gameCount: 0,
        kind: '펜타스톰',
        imgPath: "../images/swip/game_ico_pentastorm.png",
    },
    {
        id: 'rainbowsix',
        gameCount: 0,
        kind: '레인보우6',
        imgPath: "../images/swip/game_ico_rainbowsix.png",
    },
    {
        id: 'soccerbattle',
        gameCount: 0,
        kind: '축구배틀',
        imgPath: "../images/swip/game_ico_soccerbattle.png",
    },
    {
        id: 'valorant',
        gameCount: 0,
        kind: '발로란트',
        imgPath: "../images/swip/game_ico_valorant.png",
    },
    {
        id: 'nbabattle',
        gameCount: 0,
        kind: 'NBA배틀',
        imgPath: "../images/swip/game_ico_nbabattle.png",
    },
]

const GameResultContents = (props) => {

    const dispatch = useDispatch();
    const perPage = useSelector(state => state.page.perPage);
    const curPage = useSelector(state => state.page.curPage);
    const totalPage = useSelector(state => state.page.totalPage);
    const locations = useSelector(state => state.sports.locations);
    const leagues = useSelector(state => state.sports.leagues);
    const gameList = useSelector(state => state.sports.gameList);
    // console.log('gameList: ', gameList);
    // let sortLocation = !isEmpty(locations) ? locations.sort((a, b) => a.name_kor.charCodeAt() - b.name_kor.charCodeAt()) : []
    // console.log('sortLocation: ', sortLocation);
    const [page, setPage] = useState(1);

    const [inputs, setInputs] = useState({
        sport_type: 'all',
        sportId: 'all',
        location: 'all',
        league: 'all',
        teamName: '',
        sportType: 'all',
        gameType: 'all'
    });

    const { sport_type, location, sportId, league, teamName } = inputs;

    useEffect(() => {
        setInputs({
            ...inputs, // 기존의 input 객체를 복사한 뒤
            league: "all" // name 키를 가진 값을 value 로 설정
        });
    }, [location]);

    let sportsList = []
    if (sport_type === 'all') {
        sportsList = [...sports, ...esports]
    } else {
        if (sport_type === 'prematch' || sport_type === 'live') {
            sportsList = sports;
        }
        else {
            sportsList = esports;
        }
    }

    const onChange = (e) => {
        const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
        if (name == 'sport_type') {
            $("#sport_id").val("all").trigger("change")
            setInputs({
                ...inputs, // 기존의 input 객체를 복사한 뒤
                [name]: value, // name 키를 가진 값을 value 로 설정
                "sportId": "all"
            });
        }
        else {
            setInputs({
                ...inputs, // 기존의 input 객체를 복사한 뒤
                [name]: value // name 키를 가진 값을 value 로 설정
            });
        }
    };

 

    useEffect(() => {
        getGameList();
    }, [page, perPage, sport_type, location, sportId, league])

    const getGameList = () => {
        dispatch(sportsActions.getGameResultList({
            ...inputs,
            sportType: sport_type === 'all' ? 'all' : sport_type === 'prematch' || sport_type === 'live' ? "sport" : "esport",
            esportType: sportId,
            sportId: sport_type === 'all' ? 'all' : sport_type === 'prematch' || sport_type === 'live' ? 'all' : "all",
            gameType: sport_type === 'all' ? 'all' : sport_type === 'eLive' || sport_type === 'live' ? "live" : "prematch",
            page: page,
            perPage: perPage,
            searchType: "team",
            searchValue: teamName
        }))
    }

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">경기결과</div>
                <div className="con_box05">
                    <div className="game_result_tab">
                        <ul>
                            <li className="active">
                            <label className="retult_search_title">스포츠타입 </label>
                            <select className="result_select result_sportstype" name="sport_type" onChange={onChange} value={sport_type}>
                                <option value="all">전체</option>
                                <option value="prematch">스포츠프리매치</option>
                                <option value="live">스포츠라이브</option>
                                <option value="ePrematch">E-스포츠 프리매치</option>
                                <option value="eLive">E-스포츠 라이브</option>
                            </select>
                            </li>
                            <li>
                            <label className="retult_search_title">종목 </label>
                            <select className="result_select" name="sportId" id="sport_id" onChange={onChange}>
                                <option value="all">전부</option>
                                {
                                    sportsList.map((list, index) => {
                                        return (
                                            <option value={list.id} key={index}>{list.kind}</option>
                                        )
                                    })
                                }
                            </select>
                            </li>
                            <li>
                            <label className="retult_search_title">지역 </label>
                            <select className="result_select" name="location" onChange={onChange}>
                                <option value="all">전부</option>
                                {
                                    locations
                                        .map(location => location)
                                        .sort((a, b) => a.name_kor.charCodeAt() - b.name_kor.charCodeAt())
                                        .map((reslocation, index) => {
                                            return (
                                                <option value={reslocation.Id} key={index}>{reslocation.name_kor}</option>
                                            )
                                        })
                                }
                            </select>
                            </li>
                            <li>
                            <label className="retult_search_title">리그 </label>
                            <select className="result_select" name="league" onChange={onChange}>
                                <option value="all">전부</option>
                                {
                                    leagues.filter(resleague => resleague.location.Id == location && (resleague.sport.Id == sportId || sportId === "all")).map((resleague, index) => {

                                        return (
                                            <option value={resleague.Id} key={index}>{resleague.name}</option>
                                        )
                                    })
                                }
                            </select>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="game_serch_box">팀명 : <input type="text" name="teamName" onChange={onChange}   className="input3" /><a href="#"><span className="btn1_1">검색</span></a></div>
                <div className="game_result_list_box">
                    <div className="game_result_list_title">
                        <ul>
                            <li>경기시각</li>
                            <li>지역 / 리그</li>
                            <li>경기</li>
                            <li>점수</li>
                            <li>상세</li>
                        </ul>
                    </div>
                    <Detail gameList={gameList}/>
                </div>
                {
                    isEmpty(gameList) ?
                        <div className='no_data'>내역이 없습니다</div>
                        :
                        <Pagenation
                            page={page}
                            totalPage={totalPage}
                            getPage={setPage}
                        />
                }

            </div>
        </div>
    );
};

export default GameResultContents;