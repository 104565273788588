import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { autoLogout, convertObj, history, isEmpty, isMobilePage, setAuthToken, showMessage, toastr } from 'Common';
import { api_url } from 'Common/keys';
import { request } from 'Common/request';
import { pageActions } from './page.slice';
import { moreActions, store } from '.';
import { processData } from 'Common/socket';
import axios from "axios";

const name = 'user';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        showAdvertisement: false,
        isAuthenticated: false,
        loginError: '',
        data: {},
        captchaData: {},
        popup: [],
        marketNameList: [],
        eventpartNameList: [],
        bankList: [
            {
                _id: "6568127bde886f2ff3e44664",
                bankName: "국민은행",
                status: true,
                img: "6568127bde886f2ff3e44664.png",
                __v: 0
            },
            {
                _id: "656815078aafa43c876bdfed",
                bankName: "SC제일은행",
                status: true,
                img: "656815078aafa43c876bdfed.png",
                __v: 0
            },
            {
                _id: "656815118aafa43c876be012",
                bankName: "광주은행",
                status: true,
                img: "656815118aafa43c876be012.png",
                __v: 0
            },
            {
                _id: "656815198aafa43c876be037",
                bankName: "농협은행",
                status: true,
                img: "656815198aafa43c876be037.png",
                __v: 0
            },
            {
                _id: "656815228aafa43c876be03d",
                bankName: "부산은행",
                status: true,
                img: "656815228aafa43c876be03d.png",
                __v: 0
            },
            {
                _id: "6568152a8aafa43c876be062",
                bankName: "새마을금고",
                status: true,
                img: "6568152a8aafa43c876be062.png",
                __v: 0
            },
            {
                _id: "656815318aafa43c876be087",
                bankName: "신한은행",
                status: true,
                img: "656815318aafa43c876be087.png",
                __v: 0
            },
            {
                _id: "6568153a8aafa43c876be0ac",
                bankName: "우리은행",
                status: true,
                img: "6568153a8aafa43c876be0ac.png",
                __v: 0
            },
            {
                _id: "6568153f8aafa43c876be0af",
                bankName: "우체국",
                status: true,
                img: "6568153f8aafa43c876be0af.png",
                __v: 0
            },
            {
                _id: "656815448aafa43c876be0d1",
                bankName: "전북은행",
                status: true,
                img: "656815448aafa43c876be0d1.png",
                __v: 0
            },
            {
                _id: "656815478aafa43c876be0d4",
                bankName: "제주은행",
                status: true,
                img: "656815478aafa43c876be0d4.png",
                __v: 0
            },
            {
                _id: "6568154b8aafa43c876be0f6",
                bankName: "카카오뱅크",
                status: true,
                img: "6568154b8aafa43c876be0f6.png",
                __v: 0
            },
            {
                _id: "656815568aafa43c876be11b",
                bankName: "하나은행",
                status: true,
                img: "656815568aafa43c876be11b.png",
                __v: 0
            },
            {
                _id: "6568155a8aafa43c876be11e",
                bankName: "한국씨티은행",
                status: true,
                img: "6568155a8aafa43c876be11e.png",
                __v: 0
            }
        ],
        // leftbar state변수들정의 
        showleft: false,
        showright: false
    }
}

function createReducers() {
    return {
        setCurrentUser,
        setShowAdvertisement,
        getLoginError,
        getPopupData,
        setBankList,
        setCaptcha,
        setShowLeft,
        setShowRight,
        setMarketName,
        setEventpartName
    };
    function setEventpartName(state, action) {
        // debugger
        state.eventpartNameList = action.payload
    }

    function setMarketName(state, action) {
        state.marketNameList = action.payload;
    }

    function setShowLeft(state, action) {
        state.showleft = action.payload
    }
    function setShowRight(state, action) {
        state.showright = action.payload
    }

    function setCurrentUser(state, action) {
        state.data = action.payload;
        state.isAuthenticated = !isEmpty(action.payload);
    }

    function setCaptcha(state, action) {
        state.captchaData = action.payload;
    }

    function setShowAdvertisement(state, action) {
        state.showAdvertisement = action.payload;
    }

    function getLoginError(state, action) {
        state.loginError = action.payload
    }

    function getPopupData(state, action) {
        state.popup = action.payload
    }

    function setBankList(state, action) {
        state.bankList = action.payload
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        changePassword: changePassword(),
        updateUser: updateUser(),
        loginUser: loginUser(),
        logoutUser: logoutUser(),
        getPublicPopup: getPublicPopup(),
        getPrivatePopup: getPrivatePopup(),
        getBankName: getBankName(),
        getUserInfo: getUserInfo(),
        getCaptcha: getCaptcha(),
        getMarketName: getMarketName(),
        getEventParttName: getEventParttName()
    };

    function getEventParttName() {
        return createAsyncThunk(
            `${name}/getEventParttName`,
            async function (params, { dispatch }) {
                try {
                    axios.post(api_url + "/api/Users/getEventPartData")
                        .then(res => {
                            if (res.data.success) {
                                store.dispatch(userActions.setEventpartName(res.data.data))
                            }
                        })
                        .catch(err => {
                            console.error('err:', err);
                            if (!store.getState().user.isAuthenticated) {
                                toastr.warning("잠시후 다시 시도해주세요.")
                            }
                        })
                }
                catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(error)
                }
            }
        )

    }


    function getMarketName() {
        return createAsyncThunk(
            `${name}/getMarketName`,
            async function (params, { dispatch }) {
                try {
                    axios.post(api_url + "/api/Users/getMarketData")
                        .then(res => {
                            if (res.data.success) {
                                store.dispatch(userActions.setMarketName(res.data.data))
                            }
                        })
                        .catch(err => {
                            console.error('err:', err);
                            if (!store.getState().user.isAuthenticated) {
                                toastr.warning("잠시후 다시 시도해주세요.")
                            }
                        })
                }
                catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(error)
                }
            }
        )

    }

    // Update user
    function changePassword() {
        return createAsyncThunk(
            `${name}/changePassword`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + '/api/Users/passwordChange', params)
                        .then(data => {
                            showMessage(data.message);
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.error(error);

                }
            }
        );
    }

    // Update user
    function updateUser() {
        return createAsyncThunk(
            `${name}/updateUser`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + '/api/Users/updateUserInfo', params)
                        .then(data => {
                            showMessage(data.message);
                            dispatch(userActions.getUserInfo());
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.error(error);


                }
            }
        );
    }


    //Captcha
    function getCaptcha() {
        return createAsyncThunk(
            `${name}/getCaptcha`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + '/api/Users/captcha')
                        .then(data => {
                            // resolve(res.data);
                            dispatch(userActions.setCaptcha(data));
                        })
                }
                catch (error) {
                    console.error(error);
                    dispatch(pageActions.onSetPageLoading(false));
                }
            }
        )
    }


    function loginUser() {

        let isLoadingNow = false;
        // if (isLoadingNow) {
        //     return new Promise(resolve => { });
        // }
        return createAsyncThunk(
            `${name}/loginUser`,
            async function (params, { dispatch }) {
                const userData = {
                    ...params,
                    platform: isMobilePage() ? "MOBILE" : "PC",
                    ipADD: localStorage.getItem('client-ip')
                }
                dispatch(pageActions.onSetPageLoading(true));
                isLoadingNow = true;
                try {
                    request.post(api_url + '/api/Users/login', userData)
                        .then(data => {

                            setTimeout(() => { isLoadingNow = false }, 500);
                            dispatch(pageActions.onSetPageLoading(false));
                            if (data.status === 1) {
                                showMessage(data.message)
                                dispatch(userActions.getCaptcha())
                                dispatch(userActions.getLoginError('아이디 혹은 비번이 맞지 않습니다.'));
                                return;
                            }
                            if (data.status === 2) {
                                dispatch(userActions.getCaptcha())
                                return processData("repair_site", convertObj(data.message.warning));

                            }
                            const { token, adminToken, user, popups } = data;
                            localStorage.setItem('jwtToken', token);
                            setAuthToken(token);
                            dispatch(userActions.getUserInfo());
                            dispatch(userActions.setCurrentUser(data));

                            toastr.success(`${user.name}님을 환영합니다.`);
                            history.navigate('/');
                            autoLogout();
                        })
                } catch (error) {
                    console.error(error);
                    dispatch(pageActions.onSetPageLoading(false));
                }
            }
        );
    }

    function getUserInfo() {
        return createAsyncThunk(
            `${name}/getUserInfo`,
            async function (params, { dispatch }) {
                request.post(api_url + '/api/Users/getMyData', params)
                    .then(data => {
                        showMessage(data.message);
                        if (data.status) {
                            return dispatch(userActions.logoutUser());
                        }
                        dispatch(userActions.setCurrentUser(data));
                        // dispatch(moreActions.getMessageData({ page: 1, perPage: store.getState().page.perPage, unSetPage: true, noneLoading: true }));
                        if (!isEmpty(data.repair_site)) {
                            processData(convertObj(data.repair_site).MessageType, convertObj(data.repair_site));
                        }
                        if (!isEmpty(data.bet_setting)) {
                            processData(convertObj(data.bet_setting).MessageType, convertObj(data.bet_setting));
                        }
                        if (!isEmpty(data.gameCnt)) {
                            processData(convertObj(data.gameCnt).MessageType, convertObj(data.gameCnt));
                            //console.log('convertObj(data.gameCnt).MessageType, convertObj(data.gameCnt): ', convertObj(data.gameCnt).MessageType, convertObj(data.gameCnt));
                        }
                        if (!isEmpty(data.market_setting)) {
                            processData(convertObj(data.market_setting).MessageType, convertObj(data.market_setting));
                        }
                        if (!isEmpty(data.game_setting)) {
                            processData(convertObj(data.game_setting).MessageType, convertObj(data.game_setting));
                        }
                        if (!isEmpty(data.baseline_setting)) {
                            processData(convertObj(data.baseline_setting).MessageType, convertObj(data.baseline_setting));
                        }
                        if (!isEmpty(data.provider_setting)) {
                            processData(convertObj(data.provider_setting).MessageType, convertObj(data.provider_setting));
                        }
                        if (!isEmpty(data.reconnect_setting)) {
                            processData(convertObj(data.reconnect_setting).MessageType, convertObj(data.reconnect_setting));
                        }
                        if (!isEmpty(data.delay_setting)) {
                            processData(convertObj(data.delay_setting).MessageType, convertObj(data.delay_setting));
                        }

                    })
                    .catch(err => {
                        console.log('err: ', err);
                        dispatch(pageActions.onSetPageLoading(false));
                        if (err.response && err.response.status === 401)
                            dispatch(userActions.logoutUser());

                        dispatch(userActions.setCurrentUser({}))
                    })
            })
    }



    function logoutUser() {
        return createAsyncThunk(
            `${name}/logoutUser`,
            async function (message = false, { dispatch }) {
                if (store.getState().user.isAuthenticated) {
                    axios.post(api_url + "/api/Users/logout", {
                        ipADD: localStorage.getItem('client-ip'),
                        adminToken: localStorage.getItem('adminToken'),
                    })
                        .then(data => {
                        })
                        .catch(err => {
                            console.log('err: ', err);
                        })
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('adminToken');
                    localStorage.removeItem('bettingItem');
                    setAuthToken(false);
                    if (window.windowHandle) {
                        window.windowHandle.close();
                    }
                    if (store.getState().user.isAuthenticated && !message) {
                        toastr.warning('로그아웃 되었습니다.');
                    }
                    dispatch(userActions.setCurrentUser({}))
                }
            })
    }



    function getPublicPopup() {
        return createAsyncThunk(
            `${name}/getPublicPopup`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Popup/get", {})
                        .then(data => {
                            dispatch(userActions.getPopupData(data.popups));
                            dispatch(userActions.setShowAdvertisement(true));
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.error(error);
                }
            }
        );
    }

    function getPrivatePopup() {
        return createAsyncThunk(
            `${name}/getPrivatePopup`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Popup/getpopup", {})
                        .then(data => {
                            dispatch(userActions.getPopupData(data.popups));
                            dispatch(userActions.setShowAdvertisement(true));
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.error(error);
                }
            }
        );
    }

    function getBankName() {
        return createAsyncThunk(
            `${name}/getBankName`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Users/getBankData", {})
                        .then(data => {
                            dispatch(userActions.setBankList(data.bankList));
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.error(error);
                }
            }
        );
    }


}

