import React from 'react';

const PointMethod = () => {
    return (
        <div className="white_box_h" >
            {/* <div className="s_title1">출금방법</div> */}
            <div className="write_tr">
                {/* <div className="write_title">출금안내</div> */}
                <div className="write_basic" style={{background:"rgb(48, 48, 48)"}}>
                ※ 출금하기전에 전체머니회수 처리하시고 신청하기 바랍니다.<br />
                ※ 포인트를 전환하면 보유금액으로 이동됩니다.<br />
                ※ 전환 최소 포인트는 <span style={{color:"rgb(255, 114, 0)",fontWeight:"900"}}>1,000</span>  포인트입니다.<br />
                </div>
            </div>
        </div>
    );
};

export default PointMethod;