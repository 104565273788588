import { isEmpty } from 'Common';
import { resource_url } from 'Common/keys';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

const imgUrl = resource_url + "/images/popups/";

const Advertisement = (props) => {

    const dispatch = useDispatch();
    const {ToggleImage, data, showed} = props
    const { _id: id, url, type } = data;
    const actived = showed.findIndex(item => item === id) >= 0;

    const ClickCloseButton = (id, checked) => {
        if (checked) {
            const popupStr = localStorage.getItem("popup")
            const popupExp = Number(localStorage.getItem("popupExp"));
            const date = new Date();
            const popups = !isEmpty(popupStr) && (popupExp >= Date.UTC(date.getUTCFullYear(), (date.getUTCMonth() + 1), date.getUTCDate()))
                ? JSON.parse(popupStr) : [];
            localStorage.setItem("popup", JSON.stringify([...popups, id]));

            localStorage.setItem("popupExp", Date.UTC(date.getUTCFullYear(), (date.getUTCMonth() + 1), date.getUTCDate()))
            // }
        }
    }


    return (
        <section
            id={id}
            className={"popup_window popup_advertisement " + (actived ? 'active' : '')}
        >

            <div className="main_pop_box" id="popup_280">
                <div className="main_pop_title">
                    <img src="../images/logo.png" style={{ height: "100%", paddingBottom: "5px" }} />
                    <a className="close_btn close_btn_cu" onClick={e => { e.preventDefault(); ClickCloseButton(id, false); ToggleImage(id) }}>
                        <i className="fa-solid fa-xmark" aria-hidden="true"></i>
                    </a>
                </div>
                {(type == 0 || isEmpty(type)) ?
                    <div className="main_pop_con adv_banner" >
                        {/* <img src={imgUrl + url} alt=""  style="width: 400px; height: 500px;" /> */}
                        <img src={imgUrl + url} alt="" />
                    </div>
                    :
                    <div className="main_pop_con adv_banner" >
                        <div className="" dangerouslySetInnerHTML={{ '__html': !isEmpty(url) ? url : "" }} style={{ color: "#fff", background: "#5c5858", padding: "15px" }}></div>
                    </div>

                }
                <div className="main_popup_close">
                    <a className="close_btn" onClick={e => { e.preventDefault(); ClickCloseButton(id, true); ToggleImage(id) }}>
                        오늘하루 열지 않기
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Advertisement;