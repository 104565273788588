import BHistoriesContents from 'Components/SportsInfo/BettingHistories/BHistoriesContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment } from 'react';

const BettingHistories = () => {
    return (
        <Fragment>
            <LeftBar/>
            <BHistoriesContents/>
            <Right type="bh"/>
        </Fragment>
    );
};

export default BettingHistories;