import Lottery from "Components/Events/Lottery";
import Deposit from "Containers/Account/Deposit";
import Help from "Containers/Account/Help";
import Message from "Containers/Account/Message";
import MyPage from "Containers/Account/MyPage";
import Notice from "Containers/Account/Notice";
import Point from "Containers/Account/Point";
import PointHistories from "Containers/Account/PointHistories";
import Withdraw from "Containers/Account/Withdraw";
import Bet365 from "Containers/Betgame/Bet365";
import Casino from "Containers/CasinoSlot/Casino";
import Slot from "Containers/CasinoSlot/Slot";
import Dashboard from "Containers/DashBoard/Dashboard";
import Attendance from "Containers/Events/Attendance";
import Coupon from "Containers/Events/Coupon";
import Event from "Containers/Events/Event";
import Roulette from "Containers/Events/Roulette";
import LoginPage from "Containers/Layout/LoginPage";
import SignupPage from "Containers/Layout/SignupPage";
import Minigame from "Containers/Minigame/Minigame";
import SportsDomestic from "Containers/Sports/SportsDomestic";
import SportsLive from "Containers/Sports/SportsLive";
import SportsPrematch from "Containers/Sports/SportsPrematch";
import BettingHistories from "Containers/SportsInfo/BettingHistories";
import Gameresult from "Containers/SportsInfo/Gameresult";

export const routers = [
    {
        pathname: '/',
        component: Dashboard,
        exact: true,
        isPublic: true
    },
    {
        pathname: '/login',
        component: LoginPage,
        exact: true,
        isPublic: false,
        value:"login"
    },
    {
        pathname: '/signup',
        component: SignupPage,
        exact: true,
        isPublic: false,
        value:"signup"
    },
    {
        pathname: '/sportsprematch',
        component: SportsPrematch,
        exact: true,
        isPublic: false,
        value:"sport_prematch"
    },
    {
        pathname: '/sportslive',
        component: SportsLive,
        exact: true,
        isPublic: false,
        value:"sport_live"
    },
    {
        pathname: '/sportsdomestic',
        component: SportsDomestic,
        exact: true,
        isPublic: false,
        value:"sport_prematch"
    },
    {
        pathname: '/casino',
        component: Casino,
        exact: true,
        isPublic: false,
        value:"casino"
    },
    {
        pathname: '/slot',
        component: Slot,
        exact: true,
        isPublic: false,
        value:"slot"
    },
    {
        pathname: '/minigame',
        component: Minigame,
        exact: true,
        isPublic: false,
        value:"minigame"
    },
    {
        pathname: '/deposit',
        component: Deposit,
        exact: true,
        isPublic: false
    },
    {
        pathname: '/withdraw',
        component: Withdraw,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/point',
        component: Point,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/pointdetail',
        component: PointHistories,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/attendance',
        component: Attendance,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/mypage',
        component: MyPage,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/roulette',
        component: Roulette,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/coupon',
        component: Coupon,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/lottery',
        component: Lottery,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/notice',
        component: Notice,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/events',
        component: Event,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/message',
        component: Message,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/help',
        component: Help,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/bettinghistory',
        component: BettingHistories,
        exact: true,
        isPublic: false,
    },
    {
        pathname: '/gameresult',
        component: Gameresult,
        exact: true,
        isPublic: false,
    },
    // {
    //   pathname: '/esports',
    //   component: Esports,
    //   exact: true,
    //   value: "esport_prematch"
    // },
    {
      pathname: '/bet365',
      component: Bet365,
      exact: true,
      isPublic: false,
      value:"betgame"
    },
]