import React from 'react';
import classnames from 'classnames'

const Pagenation = (props) => {

    const { type } = props;
    const { page, totalPage, getPage } = props;
    const pageIndexCount = 5;
    let leftLimit = Number(page) - Math.floor(pageIndexCount / 2);
    let rightLimit = Number(page) + Math.floor(pageIndexCount / 2);
    if (leftLimit < 1) {
        rightLimit = rightLimit - leftLimit + 1;
        leftLimit = 1;
    }
    if (rightLimit > totalPage) {
        leftLimit -= (rightLimit - totalPage + 1);
        rightLimit = totalPage;
    }
    if (leftLimit < 1) leftLimit = 1;
    let pageIndexes = [];
    for (let i = leftLimit; i <= rightLimit; i++) pageIndexes.push(i);
    return (
        <div className="con_box10">
            <div className="page_wrap">
                <ul>
                    <li onClick={(e) => { e.preventDefault(); getPage(1, type) }}><a><span className="page">처음</span></a></li>
                    <li onClick={(e) => { e.preventDefault(); getPage(Number(page) - 1, type) }}><a><span className="page">이전</span></a></li>
                    {
                        pageIndexes.map(pageIndex => {
                            return (
                                <li onClick={(e) => { e.preventDefault(); getPage(pageIndex, type) }} key={pageIndex} ><a><span className={classnames('page', { 'pageon': pageIndex === Number(page) })}>{pageIndex}</span></a></li>
                            )
                        })
                    }
                    <li onClick={(e) => { e.preventDefault(); getPage(Number(page) + 1, type) }}><a><span className="page">다음</span></a></li>
                    <li onClick={(e) => { e.preventDefault(); getPage(Number(totalPage), type) }}><a><span className="page">끝</span></a></li>
                </ul>
            </div>
        </div>
    );
};

export default Pagenation;