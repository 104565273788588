import React, { useState,useMemo     } from 'react';
import WithdrawMethod from './WithdrawMethod';
import WithdrawRequest from './WithdrawRequest';
import WithdrawHistory from './WithdrawHistory';
import { useDispatch, useSelector } from 'react-redux'
import { moreActions } from 'Common/store';
import { isEmpty, no_comma, toastr } from 'Common';



const WithdrawContents = () => {

    const dispatch = useDispatch();
    const [money, setMoney] = useState(0);
    const [accountInfo, setAccountInfo] = useState({});
    const [withdrawPassword, setWithdrawPassword] = useState("");
    const [ids, setIds] = useState([]);  //선택삭제시 아이디
    const [popup, setPopup] = useState(false);
    const getMoneySetting = (data) => {
        return data;
    }
    const exchangeSetting = useSelector(state => state.more.exchangeSetting);
    const setMoneySetting = useMemo(() => getMoneySetting(exchangeSetting), [exchangeSetting]);
    const minMoney = !isEmpty(setMoneySetting) ? setMoneySetting.charge_min_money.toLocaleString() : "10,000";
    const chargeUnit = !isEmpty(setMoneySetting) ? setMoneySetting.charge_unit : 1000;


    const onChange = (e) => {
        const { value, checked } = e.target;
        if (checked) setIds([...ids, value]);
        else setIds(ids.filter(item => item !== value))
        if (!isNaN(Number(no_comma(value))))
            setMoney(Number(no_comma(value)));
        else setMoney(0);
    };

    const reset = () => {
        setMoney(0);
    }
    const password = e => {
        setWithdrawPassword(e.target.value);
      };
    
    const DoWithdraw = () => {
        if (money <= 0) {
            toastr.warning("머니를 입력해주세요.")
          } else if (isEmpty(withdrawPassword)) {
            toastr.warning("환전비번을 입력해주세요.")
          } else {
            let withdrawMoney = Math.floor(money / chargeUnit) * chargeUnit;
            const params = {
              money: withdrawMoney,
              typeStr: "withdraw",
              withdrawPassword: withdrawPassword,
            }
            dispatch(moreActions.doFiveWithdraw(params));
            setWithdrawPassword('');

          }
    }
   
    const onPopup = (val) => {
        setPopup(!popup);
        setIds([val]);
    };

    const historyDelete = (value) => {
        dispatch(moreActions.delWithdrawList({
            ids,
            all: value,
            typeStr: 'withdraw'}));
        setPopup(!popup);
    };


    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">출금신청</div>
                <div className="write_box">
                    <WithdrawMethod />
                    <WithdrawRequest onChange={onChange} reset={reset} money={money} setMoney={setMoney} password={password} withdrawPassword={withdrawPassword}/>
                </div>
                <div className="con_box10" style={{margin:" 0 0 12px 0"}}>
                    <div className="btn_wrap_center">
                        <ul>
                            <li onClick={DoWithdraw}><a><span className="btn3_1">출금신청하기</span></a></li>
                        </ul>
                    </div>
                </div>
                <WithdrawHistory historyDelete={historyDelete} popup={popup} onPopup={onPopup}/>
            </div>
        </div>
    );
};

export default WithdrawContents;