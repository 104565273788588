import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getAllCount, history, isEmpty } from 'Common';
import { pageActions, sportsActions } from 'Common/store';
import qs from 'querystringify';


const sports = [
    {
        name: "allcnt",
        kind: "전체",
        gameCount: 0,
        imgPath: "../images/swip/icon_all.png",
        id: "all",
    },
    {
        name: "soccer",
        kind: "축구",
        gameCount: 0,
        imgPath: "../images/swip/icon_football.png",
        id: 6046,
    },
    {
        name: "hockey",
        kind: "아이스하키",
        gameCount: 0,
        imgPath: "../images/swip/icon_ice_hockey.png",
        id: 35232
    },
    {
        name: "basketball",
        kind: "농구",
        gameCount: 0,
        imgPath: "../images/swip/icon_basketball.png",
        id: 48242,
    },
    {
        name: "volleyball",
        kind: "배구",
        gameCount: 0,
        imgPath: "../images/swip/icon_volleyball.png",
        id: 154830,
    },
    {
        name: "baseball",
        kind: "야구",
        gameCount: 0,
        imgPath: "../images/swip/icon_baseball.png",
        id: 154914,
    },
    {
        name: "mma",
        kind: "MMA",
        gameCount: 0,
        imgPath: "../images/swip/icon_mma.png",
        id: 154919,
    },
    {
        name: "tabletennis",
        kind: '탁구',
        gameCount: 0,
        id: 265917,
        imgPath: '../images/swip/jongmok_Tabletennis-ico.png'
    },
    {
        name: "football",
        kind: '미식축구',
        id: 131506,
        gameCount: 0,
        imgPath: '../images/swip/rugby-league-ico.png'
    },
    {
        name: "tennis",
        kind: '테니스',
        id: 54094,
        gameCount: 0,
        imgPath: '../images/swip/jongmok_Tennis.png'
    },
    {
        name: "cricket",
        kind: '크리케트',
        id: 452674,
        gameCount: 0,
        imgPath: '../images/swip/jongmok_Baseball.png'
    },
]
const esports = [
    {
        name: "allcnt",
        kind: "전체",
        gameCount: 0,
        imgPath: "../images/swip/icon_all.png",
        id: "all",
    },
    {
        kind: "도타 2",
        gameCount: 0,
        imgPath: "../images/swip/icon_dota_2.png",
        id: "dota2",
        name: "dota2",
    },
    {
        kind: "리그 오브 레전드",
        gameCount: 0,
        imgPath: "../images/swip/icon_league_of_legends.png",
        id: "leagueoflegends",
        name: "leagueoflegends",
    },
    {
        kind: "오버워치",
        gameCount: 0,
        imgPath: "../images/swip/icon_overwatch_2.png",
        id: "overwatch",
        name: "overwatch",
    },
    {
        kind: "스타크래프트 2",
        gameCount: 0,
        imgPath: "../images/swip/icon_starcraft_2.png",
        id: "starcraft2",
        name: "starcraft2",
    },
    {
        kind: "워크래프트",
        gameCount: 0,
        imgPath: "../images/swip/icon_warcraft_iii.png",
        id: "warcraft",
        name: "warcraft",
    },
    {
        id: 'ageofempire',
        name: 'ageofempire',
        gameCount: 0,
        kind: '에이지 오브 엠파이어',
        imgPath: "../images/swip/game_ico_ageofempire.png",

    },
    {
        id: 'counterstrike',
        name: 'counterstrike',
        gameCount: 0,
        kind: '카운터 스트라이크',
        imgPath: "../images/swip/game_ico_counterstrike.png",
    },
    {
        id: 'leagueoflegends_wildlift',
        name: 'leagueoflegends_wildlift',
        kind: '리그오브레전드 와일드리프트',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_leagueoflegends_wildlift.png",
    },
    {
        id: 'pentastorm',
        name: 'pentastorm',
        kind: '펜타스톰',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_pentastorm.png",
    },
    {
        id: 'rainbowsix',
        name: 'rainbowsix',
        kind: '레인보우6',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_rainbowsix.png",
    },
    {
        id: 'soccerbattle',
        name: 'soccerbattle',
        kind: '축구배틀',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_soccerbattle.png",
    },
    {
        id: 'valorant',
        name: 'valorant',
        kind: '발로란트',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_valorant.png",
    },
    {
        id: 'nbabattle',
        name: 'nbabattle',
        kind: 'NBA배틀',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_nbabattle.png",
    },
]



const SwipBox = (props) => {

    const dispatch = useDispatch()
    const sportId = useSelector(state => state.sports.sportId)
    const baselineSetting = useSelector(state => state.sports.baselineSetting)
    // const allCount = useSelector(state => state.sports.allCount)
    const [selected, setSelected] = useState("all");
    const { type } = props;
    const allCount = getAllCount(type);

    const query = qs.parse(history.location.search);
    const queryType = query.type

    const onSelect = (id) => {
        setSelected(id);
        dispatch(sportsActions.setLocation("all"));
        dispatch(sportsActions.setLeague("all"))
        dispatch(pageActions.setPageData());
        dispatch(sportsActions.setSportId(id));
        if (sportId !== id) {
            dispatch(sportsActions.clearSportsList());
        }
    }

    useEffect(() => {
        setSelected(sportId)
    }, [sportId])

    useEffect(() => {
        if (Number(queryType) !== Number(1)) {
            setSelected("all");
            dispatch(sportsActions.setLocation("all"));
            dispatch(sportsActions.setLeague("all"))
            dispatch(pageActions.setPageData());
            dispatch(sportsActions.setSportId("all"));
        }
    }, [type])

    let swip = [];

    let extraArray = sports.slice(1, sports.length)
    switch (type) {
        case "esports":
            swip = esports;
            break;

        case "live":
            if (!isEmpty(baselineSetting) && !isEmpty(baselineSetting.sportLive)) {
                for (let i = 0; i < extraArray.length; i++) {
                    if (baselineSetting.sportLive.indexOf(extraArray[i].id.toString()) < 0) {
                        swip.push(extraArray[i])
                    }
                }
                swip.splice(0, 0, sports[0])
            } else {
                swip = sports;
            }
            break;
        default:
            if (!isEmpty(baselineSetting) && !isEmpty(baselineSetting.sportPrematch)) {
                for (let i = 0; i < extraArray.length; i++) {
                    if (baselineSetting.sportPrematch.indexOf(extraArray[i].id.toString()) < 0) {
                        swip.push(extraArray[i])
                    }
                }
                swip.splice(0, 0, sports[0])
            } else {
                swip = sports;
            }
            break;
    }
    if (!isEmpty(allCount)) {
        for (let i = 0; i < swip.length; i++) {
            if (swip[i].id === "all") {
                swip[i].gameCount = allCount.count;
            }
            allCount.data.forEach(element => {
                if (element.id == swip[i].id) {
                    swip[i].gameCount = element.count;
                }
            })
        }
    }
    return (
        <ul className='overflowx'>
            {
                swip.map((item, index) => {
                    return (
                        <li className={selected != item.id ? "" : "active"} key={index} onClick={() => onSelect(item.id)}>
                            <a>
                                <img src={item.imgPath} />{item.kind}
                                <span className="gameCount">{Number(item.gameCount).toLocaleString()}</span>
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    );
};

export default SwipBox;