import EventContents from 'Components/Events/EventContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Event = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar/>
            <EventContents/>
            <Right type="event"/>
        </Fragment>
    );
};

export default Event;