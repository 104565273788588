import { checkMarket, dateFormation, fixBetsData, getMatchInfo, isEmpty, onErrorImg, sortMarkets } from 'Common';
import { betActions, sportsActions } from 'Common/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import InteriorBetItem from './InteriorBetItem';
const marketNames = require('../../Common/JSON/market.json')
const eventPartNames = require('../../Common/JSON/eventpart')


const InteriorMatch = (props) => {

    const dispatch = useDispatch()
    const activeId = useSelector(state => state.sports.activeId)
    const bettedItems = useSelector(state => state.bet.bettedItems)
    const bet = useSelector(state => state.bet)
    let { type, gamelist, onShowMarket } = props;
    const [showMarkets, setShowMarkets] = useState(false);

    let sortedMarket = sortMarkets(gamelist);
    let matchInfo = getMatchInfo(sortedMarket);
    let {
        key,
        sportImg,
        leagueName,
        locationName,
        imgPath,
        homeTeam,
        awayTeam,
        time,
        rateHome,
        rateDraw,
        rateAway,
        countMarket,
        liveScoreHome,
        liveScoreAway
    } = matchInfo;
    // console.log(CrossBets);
    // console.log(key)


    const setActiveId = key => {
        dispatch(sportsActions.setActiveId(key))
    };

    const onActiveId = key => {
        setActiveId(key);
        onShowMarket();
    };

    const checkBetted = bet => {
        return bettedItems.findIndex(item => item.betId === bet.Id) >= 0;
    };

    const onShowMarkets = () => {
        setShowMarkets(!showMarkets);
        // console.log('showMarkets: ', showMarkets, getDate());
    }
 
    const onBetItem = (bet, betList, index, market) => {
        const Fixture = sortedMarket.Fixture;
        if (isEmpty(bet.Id)) return;
        if (!checkBetted(bet)) {
            const result = dispatch(betActions.onAddOneBet({ index, market, Fixture, bet, betList, eventKey: key }));
            if (!result) {
                return;//toastr.warning("배팅규정에 어긋납니다.");
            }
        } else dispatch(betActions.removeOneBet(bet.Id));
    };

    const getBet = index => {
        //   debugger
        //   console.log(sortedMarket)
        //   console.log(gamelist)
        if (isEmpty(sortedMarket.Markets[0])) return;
        let betList = { data: sortedMarket.Markets[0].Providers[0].Bets };
        // console.log(betList)
        let key = index;
        if (rateDraw === "VS" && index === 2) key = 1;
        // console.log(key, rateDraw, index)
        switch (key) {
            case 0:
                return betList.data[0];

            case 1:
                return betList.data[1];

            case 2:
                return betList.data[2];

            default:
                break;
        }
    };

    // console.log(sortedMarket);
    // console.log("Match Time: ", getDate());
    let interiorUNcnt = 0;
    let interiorHcnt = 0;
    return (
        <div className="odds_item">
            <div className="odds_item_league">
                <div className="odds_item_league_l">
                    <div className="otll_img"><img className="otll_img11" src={sportImg} alt={leagueName} /><img className="otll_img22" src={imgPath} alt={locationName} onError={onErrorImg} /></div>
                    <div className="otll_text">
                        <span>{locationName} / {leagueName}</span>
                        <span className="otll_text_date">{dateFormation(time, 10)}</span>
                    </div>
                </div>
                <div className="odds_item_league_r" onClick={onShowMarkets}>
                    <span className="odd_bet_toggle">
                        {
                            countMarket > 1 &&
                            (showMarkets ?
                                <span data-id="close"><i className="fa-solid fa-minus"></i> 닫기</span>
                                :
                                <span data-id="open"><i className="fa-solid fa-plus"></i> 펼치기</span>)
                        }
                    </span>
                </div>
            </div>
            <div className="odds_item_game_box">
                {sortedMarket.Markets.map((market, index) => {
                    const fixedList = fixBetsData(
                        market.Providers[0],
                        market.Id,
                        type,
                        false,
                        sortedMarket
                    );
                    if (market.Id == 47) interiorUNcnt++;
                    if (market.Id == 48) interiorHcnt++;
                    const marketNameIndex = marketNames.findIndex(name => name.id == market.Id);
                    const eventPartNameIndex = eventPartNames.findIndex(name => name.id == market.eventPartId);
                    // if ((interiorUNcnt > 3 && market.Id == 47) || (interiorHcnt > 3 && market.Id == 48)) return;
                    return (
                        checkMarket(market) && (
                            <div className="odds_item_game" key={index} style={{ display: (!showMarkets && (index !== 0)) ? "none" : "flex", margin: (!showMarkets && (index !== 0)) ? "0px" : "0 0 3px 0" }}>
                                <div className="odds_item_game_type"><div style={{ justifyContent: "center" }}>{marketNames[marketNameIndex].translate}<br />{eventPartNames[eventPartNameIndex].kor_name}</div></div>
                                <div className="odds_item_game_bet">
                                    {!isEmpty(fixedList[0]) && fixedList[0].data.map((item, itemIndex) => {
                                        return (<InteriorBetItem
                                            key={market.MarketID + " " + index + " " + itemIndex}
                                            item={item}
                                            itemIndex={itemIndex}
                                            betList={fixedList[0]}
                                            market={market}
                                            originSportslist={sortedMarket}
                                            countMarket={countMarket}
                                            showMarkets={showMarkets}
                                            onShowMarkets={onShowMarkets}
                                            checkBetted={checkBetted}
                                            onBetItem={onBetItem} />);
                                    })}
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default InteriorMatch;