import { isEmpty, toastr } from 'Common';
import React from 'react';
import { useSelector } from 'react-redux'

const MoneyBtn = [
    {
        name:"1만원",
        value:10000
    },
    {
        name:"5만원",
        value:50000
    },
    {
        name:"10만원",
        value:100000
    },
    {
        name:"30만원",
        value:300000
    },
    {
        name:"50만원",
        value:500000
    },
    {
        name:"100만원",
        value:1000000
    },
]

const WithdrawRequest = (props) => {

    const {onChange, reset, setMoney, money, password, withdrawPassword} = props;
    const user = useSelector(state => state.user.data.user) 

    return (
        <div className="white_box_h">
            {/* <div className="s_title1">출금요청</div> */}
            <div className="write_tr">
                <div className="write_title">보유머니</div>
                <div className="write_basic"><span className="font11" >{isEmpty(user) ? 0 : Math.floor(user.balance.money).toLocaleString()}</span></div>
            </div>
            <div className="write_tr">
                <div className="write_title">출금비밀번호</div>
                <div className="write_basic">
                    <input className="input1" type='password' name='withdrawPassword' placeholder="출금비밀번호" onChange={password} />
                    {/* <div className="write_text"><span className="font05">가입시 설정한 충환전 비밀번호를 입력하세요.</span></div> */}
                </div>
            </div>
            <div className="write_tr">
                <div className="write_title">신청금액</div>
                <div className="write_basic">
                  
                    <input className="input1" name='amount' placeholder='직접입력' value={(isEmpty(money) || money === 0) ? "" : Number(money).toLocaleString()} onChange={onChange} />
                    <div className="write_basic_btn">
                        {
                            MoneyBtn.map((item, index) => {
                                return(
                                    <a key={index} onClick={() => setMoney(money + item.value)}><span className="btn1_2">{item.name}</span></a>
                                )
                            })
                        }
                        <a onClick={reset}><span className="btn1_2">정정하기</span></a>
                    </div>
                </div>
            </div>
            {/* <div className="write_tr">
                <div className="write_title">예금주</div>
                <div className="write_basic">{!isEmpty(user) && user.accountInfo.masterName}</div>
            </div>
             <div className="write_tr">
                <div className="write_title">출금계좌</div>
                <div className="write_basic">3333****82630</div>
            </div> 
            <div className="write_tr">
                <div className="write_title">은행명</div>
                <div className="write_basic">{!isEmpty(user) && user.accountInfo.bankName}</div>
            </div> */}
            
        </div>
    );
};

export default WithdrawRequest;