import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api_url } from 'Common/keys';
import { request } from 'Common/request';
import { pageActions, store, userActions } from '.';
import { isEmpty, showMessage, toastr } from 'Common';
import { sportPerPage } from 'Utils';
import axios from "axios"


const name = 'sports';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const sportsActions = { ...slice.actions, ...extraActions };
export const sportsReducer = slice.reducer;

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        data: [],         // sports list data
        score: [],        // sports score data
        activeId: '',     // using in live, esports, betgame pages active game id
        eventCount: {},
        filterId: 'all',  // swipbox
        filterSort: 'time',
        location: 'all',
        league: "all",
        sportId: "all",
        searchValue: "",
        searchType: "",
        allCount: {},
        url: "",
        providerId: "",
        store_events: [],
        bet_setting: [],
        levelBettingData: [],
        marketSetting: {},
        market_setting: {},
        baselineSetting: {},
        locations: [],
        leagues: [],
        gameList: [],
        betgametype: "bet365",
        sportSetting: -1,
        sportsMarketInfo: {},
    }
}


function createReducers() {
    return {
        setActiveId,
        setSportsList,
        setUrl,
        setSportId,
        setLocation,
        setLeague,
        getEventCount,
        setEventCount,
        setSportsSetting,
        setBetSetting,
        setLevelBettingData,
        marketSetting,
        market_setting,
        setBaselineSetting,
        setProviderId,
        setResultInfo,
        setGameResultList,
        setSportsMarketInfo,
        setBetgameType,
        setSearchType,
        setSearchValue
    };

    function setSportsMarketInfo(state, action) {
        // debugger
        state.sportsMarketInfo = action.payload;
    }

    function setActiveId(state, action) {
        state.activeId = action.payload;
    }

    function setSportsList(state, action) {
        state.data = action.payload;
    }

    function setUrl(state, action) {
        state.url = action.payload;
    }

    function setSportId(state, action) {
        state.sportId = action.payload;
    }

    function setLocation(state, action) {
        state.location = action.payload;
    }

    function setLeague(state, action) {
        state.league = action.payload;
    }

    function getEventCount(state, action) {
        state.eventCount = action.payload;
    }

    function setEventCount(state, action) {
        state.allCount = action.payload;
    }
    function setSportsSetting(state, action) {
        state.sportSetting = action.payload;
    }
    function setBetSetting(state, action) {
        state.bet_setting = action.payload;
    }
    function setLevelBettingData(state, action) {
        state.levelBettingData = action.payload
    }
    function marketSetting(state, action) {
        state.marketSetting = action.payload
    }
    function market_setting(state, action) {
        state.market_setting = action.payload
    }
    function setBaselineSetting(state, action) {
        state.baselineSetting = action.payload
    }
    function setProviderId(state, action) {
        state.providerId = action.payload
    }
    function setResultInfo(state, action) {
        state.locations = action.payload.location
        state.leagues = action.payload.league
    }
    function setGameResultList(state, action) {
        state.gameList = action.payload
    }
    function setBetgameType(state, action) {
        state.betgametype = action.payload
    }
    function setSearchType(state, action) {
        state.searchType = action.payload
    }
    function setSearchValue(state, action) {
        state.searchValue = action.payload
    }
}

function createExtraActions() {
    return {
        addSportsList: addSportsList(),
        getIframeUrl: getIframeUrl(),
        getSportsList: getSportsList(),
        clearSportsList: clearSportsList(),
        onSetSportsSetting: onSetSportsSetting(),
        onSetMarketSetting: onSetMarketSetting(),
        onSetBaselineSetting: onSetBaselineSetting(),
        onSetProviderId: onSetProviderId(),
        onSetBetSetting: onSetBetSetting(),
        ResultInfo: ResultInfo(),
        getGameResultList: getGameResultList(),
        GetMarketInfo: GetMarketInfo(),
        onGetEventCount: onGetEventCount()
    }

    function GetMarketInfo() {
        return createAsyncThunk(
            `${name}/GetMarketInfo`,
            async function ({ params, loading = true }, { dispatch }) {
                try {
                    if (loading) {
                        dispatch(pageActions.onSetPageLoading(true));
                    }
                    const url = api_url + '/api/Sports/getGameInfo'
                    request.post(url, params)
                        .then(res => {
                            if (res.status) {
                                return; // showMessage(data.message);
                            }
                            dispatch(pageActions.onSetPageLoading(false));
                            if (!isEmpty(res.data)) {
                                dispatch(sportsActions.setSportsMarketInfo(res.data))
                            }
                        })
                        .catch(err => {
                            console.error('err: ', err);
                            dispatch(pageActions.onSetPageLoading(false));
                            //console.log('error:', err);
                            if (err.response && err.response.status === 401)
                                dispatch(userActions.logoutUser());
                            else
                                toastr.warning('잠시후 다시 시도해주세요.');
                        })
                }
                catch (err) {
                    console.log(err);
                    dispatch(pageActions.onSetPageLoading(false));
                }
            }
        )
    }




    function addSportsList() {
        return createAsyncThunk(
            `${name}/addSportsList`,
            async function ({ params, clear = false, noActive = true, loading = true }, { dispatch }) {
                if (clear === true) {
                    dispatch(sportsActions.setActiveId(''));
                }
                if (loading) {
                    dispatch(pageActions.onSetPageLoading(true));
                }

                const url = api_url + '/api/' + (params.pageType === 'toto' ? 'Sports' : 'Betgame') + '/getGameList';
                try {
                    request.post(url, params)
                        .then(data => {
                            const sportsList = store.getState().sports.data;
                            dispatch(pageActions.onSetPageLoading(false));
                            if (data.status)
                                return showMessage(data.message);

                            if (params.page > data.totalPage) {
                                return;
                            }

                            if ((clear === true) && (isEmpty(data.events))) {
                                return dispatch(sportsActions.clearSportsList());
                            }
                            // //console.log('events: ', events);
                            if (isEmpty(data.events)) {
                                dispatch(sportsActions.setUrl(""));
                                dispatch(pageActions.setPageData(data))
                                dispatch(sportsActions.setSportsMarketInfo({}))
                                return dispatch(sportsActions.setSportsList(sportsList));
                            }
                            else if (!clear) {
                                for (let i = 0; i < data.events.length; i++) {
                                    const index = sportsList.findIndex(list => list.FixtureId === data.events[i].FixtureId);
                                    if (index > -1) {
                                        data.events.splice(i, 1);
                                        i--;
                                    }
                                }
                            }

                            // 필요한 게임목록 요청
                            // const events = clear ? data.events : sportsList.concat(data.events);
                            // let events;
                            // if (params.isUp == true) {
                            //     events = clear ? data.events : data.events.concat(sportsList.slice(0, sportPerPage * 2));
                            // } else {
                            //     events = clear ? data.events : sportsList.concat(data.events);
                            //     events.splice(0, (events.length - sportPerPage * 3));
                            // }
                            const events = clear ? data.events : sportsList.concat(data.events);

                            let gameList = [];
                            events.forEach((item, index) => {
                                return gameList.push(item.FixtureId)
                            });
                            const game_listData = { MessageType: 'GameList', data: gameList }
                            if (window.ws.readyState === 1) {
                                window.ws.send(JSON.stringify(game_listData));
                            }

                            switch (params.type) {
                                case 0:
                                    window.apiType = "lsports"
                                    break;
                                case 1:
                                    window.apiType = "odds"
                                    break;

                                default:
                                    break;
                            }
                            data = {
                                ...data,
                                direction: params.direction,
                            }
                            dispatch(pageActions.setPageData(data))

                            if (params.pageType === 'toto') {
                                events.sort((a, b) => {
                                    if (!isEmpty(a.Fixture) && !isEmpty(a.Fixture.League) && !isEmpty(a.Fixture.League.name) &&
                                        !isEmpty(b.Fixture) && !isEmpty(b.Fixture.League) && !isEmpty(b.Fixture.League.name)) {

                                        if (a.Fixture.League.name.charCodeAt() > b.Fixture.League.name.charCodeAt()) {
                                            return 100
                                        }
                                        return a.Fixture.League.name.charCodeAt() - b.Fixture.League.name.charCodeAt()
                                    }
                                    else {
                                        return 0
                                    }
                                })
                                events.sort((a, b) => {
                                    if (!isEmpty(a.Fixture) && !isEmpty(a.Fixture.StartDate) &&
                                        !isEmpty(b.Fixture) && !isEmpty(b.Fixture.StartDate)) {

                                        if ((new Date(a.Fixture.StartDate)).getTime() > (new Date(b.Fixture.StartDate)).getTime()) {
                                            return 100;
                                        }
                                        else if ((new Date(a.Fixture.StartDate)).getTime() === (new Date(b.Fixture.StartDate)).getTime()) {
                                            if (Number(a.Fixture.League.Id) > Number(b.Fixture.League.Id)) {
                                                return 50;
                                            }
                                            else if (Number(a.Fixture.League.Id) === Number(b.Fixture.League.Id)) {
                                                return 0;
                                            }
                                            else {
                                                return -50;
                                            }
                                            // return a.Fixture.League.Id.charCodeAt() - b.Fixture.League.Id.charCodeAt()
                                        }
                                        else {
                                            return -100;
                                        }
                                    }
                                    else {
                                        return 0
                                    }
                                })
                            }

                            dispatch(sportsActions.setSportsList(events));
                            if (params.pageType !== 'toto') {
                                dispatch(sportsActions.setUrl(data.gameUrl));
                            }

                            dispatch(pageActions.onSetPageInfo(params));

                            if (!noActive) {
                                dispatch(sportsActions.setActiveId(isEmpty(events) ? '' : events[0].key));
                                if (params.pageType === 'toto' && !isEmpty(events[0])) {
                                    dispatch(sportsActions.getIframeUrl(events[0].FixtureId));
                                    let resData = {
                                        sportType: params.sportType,
                                        gameType: params.gameType,
                                        fixtureId: events[0].FixtureId
                                    }
                                    dispatch(sportsActions.GetMarketInfo({ params: resData }))
                                }
                            }
                        })
                } catch (error) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.error(error);
                }
            }
        );
    }

    function getIframeUrl() {
        return createAsyncThunk(
            `${name}/getIframeUrl`,
            async function (params, { dispatch }) {
                const pageInfo = store.getState().page.pageInfo;
                let url = store.getState().sports.url;
                dispatch(sportsActions.setUrl(params.gameUrl));
                // if(pageInfo.sportType === "sport" && pageInfo.gameType === "prematch")
                if (pageInfo.gameType === "prematch") {
                    return dispatch(sportsActions.setUrl(params.gameUrl));
                }
                try {
                    // dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Games/getFrontUrl", { fixtureId: params.toString() })
                        .then(data => {
                            // dispatch(pageActions.onSetPageLoading(false));
                            if (data.status) {
                                return; // showMessage(data.message);
                            }
                            // //console.log(data)

                            if (!isEmpty(data.frontUrl)) {
                                dispatch(sportsActions.setUrl(data.frontUrl + '?token=' + data.token + "&fixtureId=" + data.toString()));
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    }

    function getSportsList() {
        return createAsyncThunk(
            `${name}/getSportsList`,
            async function ({ param, clear = false, noActive = false, loading = true }, { dispatch }) {
                if (loading) {
                    dispatch(pageActions.onSetPageLoading(true));
                }
                if (clear === true) {
                    dispatch(sportsActions.clearSportsList());
                    dispatch(sportsActions.setActiveId(''));
                }

                const url = api_url + '/api/' + (param.pageType === 'toto' ? 'Sports' : 'Betgame') + '/getGameList';
                try {
                    request.post(url, param)
                        .then(data => {
                            // debugger
                            dispatch(pageActions.onSetPageLoading(false));

                            if (data.status)
                                return showMessage(data.message);
                            if (!isEmpty(data.type)) {
                                dispatch(sportsActions.setBetgameType(data.type))
                            }
                            // 필요한 게임목록 요청
                            const events = data.events;
                            if (isEmpty(events)) {
                                dispatch(sportsActions.setSportsMarketInfo({}))
                                dispatch(sportsActions.setUrl(""));
                                dispatch(pageActions.setPageData(data))
                                dispatch(sportsActions.setSportsList(data.events));
                            }
                            let gameList = [];
                            events.forEach((item, index) => {
                                gameList.push(item.FixtureId)
                            });
                            const game_listData = { MessageType: 'GameList', data: gameList }
                            if (window.ws.readyState === 1) {
                                window.ws.send(JSON.stringify(game_listData));
                            }

                            switch (data.type) {
                                case 0:
                                    window.apiType = "lsports"
                                    break;
                                case 1:
                                    window.apiType = "odds"
                                    break;

                                default:
                                    break;
                            }
                            dispatch(pageActions.setPageData(data))

                            dispatch(sportsActions.setSportsList(data.events));
                            if (param.pageType !== 'toto') {
                                dispatch(sportsActions.setUrl(data.gameUrl));
                            }

                            dispatch(pageActions.onSetPageInfo(param));

                            if (!noActive) {
                                dispatch(sportsActions.setActiveId(isEmpty(events) ? '' : events[0].key));
                                if (param.pageType === 'toto' && !isEmpty(events[0])) {
                                    dispatch(sportsActions.getIframeUrl(events[0].FixtureId));
                                    let resData = {
                                        sportType: param.sportType,
                                        gameType: param.gameType,
                                        fixtureId: events[0].FixtureId
                                    }
                                    dispatch(sportsActions.GetMarketInfo({ params: resData }))
                                }
                            }

                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    }

    function ResultInfo() {
        return createAsyncThunk(
            `${name}/ResultInfo`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Sports/getGameResultInfo", {})
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            dispatch(sportsActions.setResultInfo(data))
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    }

    function getGameResultList() {
        return createAsyncThunk(
            `${name}/getGameResultList`,
            async function (params, { dispatch }) {
                try {
                    dispatch(pageActions.onSetPageLoading(true));
                    request.post(api_url + "/api/Sports/getGameResultList", params)
                        .then(data => {
                            dispatch(pageActions.onSetPageLoading(false));
                            if (data.status) {
                                return showMessage(data.message);
                            }
                            else {
                                dispatch(sportsActions.setGameResultList(data.events))
                                dispatch(pageActions.setPageData(data))
                            }
                        })
                }
                catch (err) {
                    dispatch(pageActions.onSetPageLoading(false));
                    console.log(err)
                }
            }
        )
    }

    function clearSportsList() {
        return createAsyncThunk(
            `${name}/clearSportsList`,
            async function (params, { dispatch }) {
                dispatch(sportsActions.setSportsList([]))
            }
        )
    }

    function onSetSportsSetting() {
        return createAsyncThunk(
            `${name}/onSetSportsSetting`,
            async function (data, { dispatch }) {
                dispatch(sportsActions.setSportsSetting(data))
            }
        )
    }
    function onSetBetSetting() {
        return createAsyncThunk(
            `${name}/onSetBetSetting`,
            async function (data, { dispatch }) {
                dispatch(sportsActions.setBetSetting(data))
            }
        )
    }
    function onSetMarketSetting() {
        return createAsyncThunk(
            `${name}/onSetMarketSetting`,
            async function (data, { dispatch }) {
                dispatch(sportsActions.marketSetting(data))
            }
        )
    }
    function onSetBaselineSetting() {
        return createAsyncThunk(
            `${name}/onSetBaselineSetting`,
            async function (data, { dispatch }) {
                dispatch(sportsActions.setBaselineSetting(data))
            }
        )
    }
    function onSetProviderId() {
        return createAsyncThunk(
            `${name}/onSetProviderId`,
            async function (data, { dispatch }) {
                dispatch(sportsActions.setProviderId(data))
            }
        )
    }
    function onGetEventCount() {
        return createAsyncThunk(
            `${name}/onGetEventCount`,
            async function (data, { dispatch }) {
                dispatch(sportsActions.getEventCount(data))
            }
        )
    }
}