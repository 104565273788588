import { ChangeNum_Letter } from 'Common';
import { moreActions } from 'Common/store';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const AttnedanceContents = (props) => {

    const [dateArray, setDateArray] = useState([]);
    const [date, setDate] = useState(new Date(Date.now()));
    const dispatch = useDispatch();
    const dailyCheck = useSelector(state => state.more.dailyCheck);
    const histories = dailyCheck.histories;
    const depositLimit = ChangeNum_Letter(dailyCheck.depositLimit)

    const GetDateArray = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const nextDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const lastDay = nextDay.getDate();

        // GetDateArray(date);

        let array = [], i, j, cnt = 1;
        for (i = 0; cnt <= lastDay; i++) {
            let temporary = [];
            if (i === 0) {
                for (j = 0; j < firstDay.getDay(); j++) temporary.push(0);
                for (; j < 7; j++) temporary.push(cnt++);
            } else for (j = 0; j < 7 && cnt <= lastDay; j++) temporary.push(cnt++);
            for (j = temporary.length; j < 7; j++) temporary.push(0);
            array.push(temporary);
        }
        setDateArray(array);
    }

    const ChangeDate = (param) => {
        const curDate = new Date(date);
        const now = new Date();
        curDate.setMonth(date.getMonth() + param);
        if (curDate > now)
            return;
        GetDateArray(curDate);
        setDate(curDate);
        dispatch(moreActions.getDailyCheckHistory({
            year: curDate.getFullYear().toString(),
            month: (curDate.getMonth() + 1).toString()
        }))
    }

    useEffect(() => {
        ChangeDate(0);
    }, [])

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">출석부</div>
                <div className="con_box10">
                    <div className="att_month_title">
                        <a onClick={e => { e.preventDefault(); ChangeDate(-1) }}>
                            <i className="fa-regular fa-circle-left"></i>
                        </a>
                        {date.getFullYear()}년 {date.getMonth() + 1} 월 출석
                        <a onClick={e => { e.preventDefault(); ChangeDate(1) }}>
                            <i className="fa-regular fa-circle-right"></i>
                        </a>
                        <span> 일입금 ★ {depositLimit}원 이상 충전 시 자동 체크됩니다.</span>
                    </div>
                </div>
                <div className="con_box00 att_box">
                    <table width="100%" border="0" cellSpacing="1" cellPadding="0">
                        <thead>
                            <tr>
                                <td width="10%" className="att_title1">일</td>
                                <td width="10%" className="att_title1">월</td>
                                <td width="10%" className="att_title1">화</td>
                                <td width="10%" className="att_title1">수</td>
                                <td width="10%" className="att_title1">목</td>
                                <td width="10%" className="att_title1">금</td>
                                <td width="10%" className="att_title1">토</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dateArray.map((array, index) => (
                                    <tr key={index}>
                                        {
                                            array.map((day, dayIndex) => (
                                                <td key={dayIndex} className="att1" style={
                                                    date.getDate() === day
                                                      ? { border: "2px solid rgb(255 116 14)" }
                                                      : {}
                                                  }>
                                                    {
                                                        !day ? '' : (
                                                            <Fragment>
                                                                <div className="txt_label"></div>
                                                                <div className={
                                                                    "txt_date " +
                                                                    (histories.findIndex(item => item.day === day) >= 0 ? 'green-text' : (dayIndex === 0 || dayIndex === 6 ? 'red-text' : ''))
                                                                }>
                                                                    {(day < 10) ? '0' : ''}{day}

                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "center", }}>{histories.findIndex(item => item.day === day) >= 0 ? <img src="../images/dailycheck/check.png" alt="" /> : ''}</div>

                                                            </Fragment>
                                                        )
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AttnedanceContents;