import React, { useState } from 'react';

const Footer = (props) => {

	const {notShow} = props
	const [active, setActive] = useState(false);

	const onActive = () => {
		setActive(!active);
	}

    // return !notShow && (
    //     <div className={`footer_wrap ${active ? "active" : ""}`}>
	// 	<div className={`f_toggle ${active ? "active" : ""}`} onClick={onActive}><img src="images/icon_arrow.png" alt='footer' /></div>
	// 	<div className="footer_img"><img src="images/f_coin.png" alt='footer' /></div>
	// 	<div className="f_text">자본 안전 성숙하고 완전한 관리 모델로 그룹은 강력하고 안정적으로 운영됩니다.우리는 가장 안전한 자금 보호를 보장하기 위해 가장 진보된 SSL 보안 암호화 메커니즘을 사용합니다.<br className="f_br"/>독점개발, 128비트 암호화 기술과 엄격한 보안관리 시스템을 사용하여 고객 자금이 가장 완벽한 보호를 받으므로 걱정없이 이용 하실수 있습니다.<br className="f_br"/>최신 기술로 독자적으로 연구 개발한 금융처리 시스템은 진정으로 검색 및 전송을 구현하며 독점적인 네트워크 최적화 기술은 최고의 게임 경험을 제공하고 네트워크 대기 시간을 극대화 합니다.<br className="f_br"/>전문적으로 훈련된 고객 서비스 팀을 연중무휴 24시간 1:1 텔레그램 서비스를 제공하고 있습니다.</div>
	// 	<div className="f_copy">© Copyrights - ACE</div>
	// </div>
    // );
};

export default Footer;