import PointHistoriesContents from 'Components/Account/PointHistories/PointHistoriesContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const PointHistories = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar />
            <PointHistoriesContents />
            <Right type="pointHistories" />
        </Fragment>
    );
};

export default PointHistories;