import GameResultContents from 'Components/SportsInfo/GameResult/GameResultContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment } from 'react';

const Gameresult = () => {
    return (
        <Fragment>
            <LeftBar type="result"/>
            <GameResultContents/>
            <Right type="result"/>
        </Fragment>
    );
};

export default Gameresult;