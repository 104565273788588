import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'


const NewMessage = (props) => {
    const messagePage = useSelector(state => state.page.messagePage)
    const [goMessagePage, setGoMessagePage] = useState(false)
    // const messagePage = false;
    // const messagePage = true;
    const onMessagePage = () => {
        // props.SetMessagePage(false);
        setGoMessagePage(false)
        // <Redirect to='/account/inquiries'/>
    }

    useEffect(() => {
        // console.log(messagePage);
        if (messagePage)
            setGoMessagePage(true)
    }, [messagePage])
    
    return (
        <div className="swal2-container swal2-center swal2-backdrop-show" style={{ overflowY: "auto", display: goMessagePage ? "" : "none" }} >
            <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
                className="swal2-popup swal2-modal swal2-icon-info swal2-show" tabIndex="-1" role="dialog" aria-live="assertive"
                aria-modal="true" style={{ display: "grid" }}>
                <div className="swal2-icon swal2-info swal2-icon-show" style={{ display: "flex" }}>
                    <div className="swal2-icon-content" style={{ color: "#cccccc" }}>i</div>
                </div>
                <h2 className="swal2-title" id="swal2-title" style={{ display: "block" }}>새로운 쪽지가 도착했습니다.</h2>
                <div className="swal2-actions" style={{ display: "flex" }}>
                    <button type="button" className="swal2-confirm swal2-styled swal2-default-outline" aria-label=""
                        style={{ display: "inline-block", backgroundColor: "rgb(48, 133, 214)" }}
                        onClick={onMessagePage}>쪽지보기</button>
                </div>
            </div>
        </div>
    );
};

export default NewMessage;