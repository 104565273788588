import { ConvertMinigameType, convertMinigameName, getMiniType, isEmpty } from 'Common';
import { pageActions } from 'Common/store';
import { miniActions } from 'Common/store/mini.slice';
import { getgameGrps, prices } from 'Utils/names';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const MinigameBetting = (props) => {

    const { type } = props
    const dispatch = useDispatch();
    const rates = useSelector(state => state.mini.rates)
    const page = useSelector(state => state.page.pageInfo)
    const leftTime = useSelector(state => state.mini.leftTime)
    const miniCheck = useSelector(state => state.mini.miniCheck)
    const state = useSelector(state => state.mini.state)
    const minidata = useSelector(state => state.mini.data)

    const roomObj = {
        pageType: getMiniType(type),
        RoomType: getMiniType(type),
        sportType: 'all',
        gameType: 'all',
        sportId: 'all'
    }

    useEffect(() => {

        const roomData = {
            MessageType: "RoomType",
            data: roomObj
        };
        if (window.ws.readyState === 1)
            window.ws.send(JSON.stringify(roomData));
        dispatch(miniActions.setBetType(type));
        dispatch(miniActions.setMiniCheck(false))
    }, [ ])

    useEffect(() => {

        const roomData = {
            MessageType: "RoomType",
            data: roomObj
        };
        if (window.ws.readyState === 1)
            window.ws.send(JSON.stringify(roomData));
        dispatch(pageActions.setPageInfo(roomObj))
        dispatch(miniActions.setBetType(type));
        dispatch(miniActions.setMiniCheck(false))
    }, [type])

    const onClickBetButton = (data) => {
        let params = {
            odd_key: data.odd_key,
            rate: data.price,
            name: isEmpty(data.name_other) ? data.name : data.name_other
        }
        dispatch(miniActions.setBetData(params))
    }

    const gameGrps = getgameGrps(type);
    gameGrps.forEach(element => {
        element.length_ = element.buttons.length
    })

    const ballPrice = prices(type, rates);
    let cnt = 0;
    const buttion = (data) => {
        return (
            <div className={`mini_btn1 w${data.length_}  ${data.odd_key === minidata.odd_key && "on"}`} key={data.index} onClick={() => onClickBetButton(data)}>
                <div className={`mini_bet_text ${data.color}`}>{data.name}</div>
                <div className="mini_bet_odd">{isNaN(data.price) ? 1 : data.price}</div>
            </div>
        )
    }

    return (
        <div className="mini_game_right p-rel">
            <div className="right_game_title">
                <span>{ConvertMinigameType(type)} 6월 1일 - {isNaN(state.round) ? 0 : Number(state.round).toLocaleString()}회차</span>
                <span><span className="closingTime"><span>베팅마감</span><span className="closingClock">{leftTime}초</span></span></span></div>
            <ul className="dropdown3">
                {
                    gameGrps.map((gamegrp, grpkey) => (
                        <li className="bet_list1_wrap" key={grpkey}>
                            <a>
                                <div className="bet_list1_wrap_in_title">{gamegrp.label}</div>
                            </a>
                            <div className="mini_bet_box">
                                {
                                    gamegrp.buttons.map((button, buttonkey) => {
                                        return buttion({ ...button, price: Number(ballPrice[cnt++]), index: buttonkey, length_: gamegrp.length_ })
                                    }
                                    )
                                }
                            </div>
                        </li>
                    ))
                }
            </ul>
            <div className="mini_bet_close" style={{ display:  (miniCheck && leftTime !== 0) ? "none" : "" }}></div>
        </div>
    );
};

export default MinigameBetting;