import { ResizeGopickFrame, ResizePowerballFrame, getId } from 'Common';
import React, { useEffect } from 'react';

const MinigameLive = (props) => {

    const { url, type } = props;
    useEffect(()=>{
        if(getId(type) === "powerball_iframe_parent")
        {
            ResizePowerballFrame();
            return;

        }else{
            ResizeGopickFrame();
            return;
        }
    }, [type])


    return (
        <div id={getId(type)} className="mini_game_left">
            <iframe
                className="frameScale"
                scrolling="no"
                frameBorder="0"
                // width="50%"
                // height="50%"
                src={url}
                title="powerballIframe"
            />
            {/* <div className="mgl_movie"></div> */}
        </div>
    );
};

export default MinigameLive;