import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { dateFormation, isEmpty, toastr } from 'Common';
import { moreActions } from 'Common/store';
import { Carousel } from 'rsuite';
import CarouselComponent from 'Components/common/CarouselComponent';
// import 'rsuite/dist/rsuite.css'; 

const m_menu1 = [
    {
        name: "입금",
        path: "/deposit",
        src: "../images/m_m01.png"
    },
    {
        name: "출금",
        path: "/withdraw",
        src: "../images/m_m02.png"
    },
    {
        name: "고객센터",
        path: "/help",
        src: "../images/m_m03.png"
    },
    {
        name: "경기결과",
        path: "/",
        src: "../images/m_m04.png"
    },
    {
        name: "베팅내역",
        path: "/",
        src: "../images/m_m04.png"
    },
    {
        name: "이벤트",
        path: "/events",
        src: "../images/m_m06.png"
    }
]
const bannerList = [
    {
        img: "../images/banner/banner1.png"
    },
    {
        img: "../images/banner/banner2.png"
    },
    {
        img: "../images/banner/banner3.png"
    },
    {
        img: "../images/banner/banner4.png"
    },
]

const m_menu2 = [
    {
        name: "스포츠",
        path: "/sportsprematch",
        src: "../images/mobile/1.png"
    },

    {
        name: "라이브 스포츠",
        path: "/sportslive",
        src: "../images/mobile/2.png"
    },
    {
        name: "정식카지노",
        path: "/casino",
        src: "../images/mobile/3.png"
    },
    {
        name: "슬롯머신",
        path: "/slot",
        src: "../images/mobile/4.png"
    },
    {
        name: "가상게임",
        path: "/bet365?type=Soccer",
        src: "../images/mobile/5.png"
    },

    {
        name: "미니게임",
        path: "/minigame?type=0",
        src: "../images/mobile/6.png"
    },
    {
        name: "입금",
        path: "/deposit ",
        src: "../images/mobile/7.png"
    },
    {
        name: "출금",
        path: "/withdraw",
        src: "../images/mobile/8.png"
    },
    {
        name: "포인트전환",
        path: "/point",
        src: "../images/mobile/9.png"
    },
    {
        name: "이벤트",
        path: "/events",
        src: "../images/mobile/10.png"
    },
    {
        name: "공지사항",
        path: "/notice",
        src: "../images/mobile/11.png"
    },
    {
        name: "배팅내역",
        path: "/bettinghistory",
        src: "../images/mobile/12.png"
    },
    {
        name: "경기결과",
        path: "/gameresult",
        src: "../images/mobile/13.png"
    },
    {
        name: "고객센터",
        path: "/help",
        src: "../images/mobile/14.png"
    },

]

const MainmMenu = [
    {
        name: "국내형 & 유럽형",
        src: "../images/main_game/off/middle/img_01.png",
        hover_src: "../images/main_game/on/middle/img_01_hover.png",
        path: "/sportsprematch"
    },
    {
        name: "스포츠 라이브",
        src: "../images/main_game/off/middle/그룹 8.png",
        hover_src: "../images/main_game/on/middle/그룹 8.png",
        path: "/sportslive"
    },
    {
        name: "라이브카지노",
        src: "../images/main_game/off/middle/라이브카지노.png",
        hover_src: "../images/main_game/on/middle/라이브카지노.png",
        path: "/casino"
    },
    {
        name: "슬롯게임",
        src: "../images/main_game/off/middle/슬롯게임.png",
        hover_src: "../images/main_game/on/middle/슬롯게임.png",
        path: "/slot"
    },
    {
        name: "가상스포츠",
        src: "../images/main_game/off/middle/e-sport.png",
        hover_src: "../images/main_game/on/middle/e-sport_hover.png",
        path: "/bet365?type=Soccer"
    },
    {
        name: "미니게임",
        src: "../images/main_game/off/middle/미니게임.png",
        hover_src: "../images/main_game/on/middle/미니게임.png",
        path: "/minigame?type=0"
    },

]

let timeHandler;

const Home = () => {
    const dispatch = useDispatch();
    const notices = useSelector(state => state.more.notice.notices)
    const events = useSelector(state => state.more.event.posts)
    const depositWithdrawStatusList = useSelector(state => state.more.depositWithdrawStatusList)
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    useEffect(() => {
        // if (isAuthenticated) {
        dispatch(moreActions.getNoticeList({
            page: 1,
            perPage: 10
        }))
        dispatch(moreActions.onGetEventList({
            page: 1,
            perPage: 10
        }))
        getDepositWithdrawStatus();
        // }
        return () => {
            clearTimeout(timeHandler);
        }
    }, [])

    const getDepositWithdrawStatus = () => {
        dispatch(moreActions.getDepositWithdrawStatus());
        timeHandler = setTimeout(() => {
            getDepositWithdrawStatus()
        }, 8000)
    }


    const ErrorNotice = (e) => {
        if (!isAuthenticated) {
            e.preventDefault()
            return toastr.warning("로그인하시고 이용하여주세요")
        }
    }
    return (
        <div className="contents_center" id='contents_center'>
            <div className="contents_center_info_full">
                <div className='dashboard_line_notice'>
                    <marquee className="pc"> 🏠🏠[ 평생SC카지노.com]  🏠🏠 [🚨지인추천 이벤트 최고의혜택 !🚨 ] [🚨 카지노 연승 연패 이벤트🚨] [🚨 슬롯 프리스핀이벤트🚨]  [상세내용 이벤트 게시판확인 ! 🏠🏠 평생SC카지노.com]   🏠🏠 </marquee>
                </div>


                <CarouselComponent />
                <div className="models-carousel-top owl-carousel owl-theme" id="models-carousel-top">
                </div>
                <div className="m_main_quick_game">
                    <ul>
                        {
                            m_menu2.map((menu, index) => {
                                return (
                                    <li key={index}><Link to={menu.path}><img src={menu.src} style={{ width: "50px", filter: "hue-rotate(124deg) brightness(1.5)" }} />{menu.name}</Link></li>

                                )
                            })
                        }
                    </ul>
                </div>
                <div className="main_board mobile_main_board">
                    <div className="borad_box">
                        <div className="board_title"><span>공지사항</span><span style={{ paddingLeft: "10px", color: "#482c00" }}>NOTICE</span><p><Link onClick={ErrorNotice} to={"/notice"}>더보기</Link></p></div>
                        <div className="board">
                            <ul>
                                {
                                    !isEmpty(notices) && notices.map((notice, index) => {
                                        return (
                                            <li key={index}>
                                                <a dangerouslySetInnerHTML={{ '__html': notice.title }}></a>
                                                <div ><span>{dateFormation(notice.created_date, 10)}</span></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="borad_box deposit_board_box">
                        <div className="board_title"><span>실시간 입출금 현황</span><p><Link onClick={ErrorNotice} to={"/deposit"}>더보기</Link></p></div>
                        <div className="board">
                            <ul id='tb_main_cash'>
                                {
                                    !isEmpty(depositWithdrawStatusList) && depositWithdrawStatusList.map((status, index) => {
                                        return (
                                            <li key={index}>
                                                <div><span>{status.userid}</span></div>
                                                <div><span>{status.type === 1 ? "입금" : (status.type === 2 ? "출금" : "-")}</span></div>
                                                <div><span>{status.amount}</span></div>
                                                <div><span>{dateFormation(status.created_date, 10)}</span></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="borad_box">
                        <div className="board_title"><span>이벤트</span><span style={{ paddingLeft: "10px", color: "#482c00" }}>EVENT</span><p><Link onClick={ErrorNotice} to="/events">더보기</Link></p></div>
                        <div className="board t-center">
                            <ul>
                                {
                                    !isEmpty(events) && events.map((list, index) => {
                                        return (
                                            <li key={index}>
                                                <a dangerouslySetInnerHTML={{ '__html': list.title }}></a>
                                                <div><span>-</span></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='m_footer' style={{ display: "none", float: "left", marginTop: "5px" }}>
                    <img src='../images/right/right01.png' style={{ width: "100%" }} />
                    <img src='../images/right/right02.png' style={{ width: "100%" }} />
                    <img src='../images/right/right03.png' style={{ width: "100%" }} />
                </div>
                {/******************************************************************Mobile End********************************************************** */}
                <div className="main_board">
                    <div className="borad_box">
                        <div className="board_title"><span>공지사항</span><span style={{ paddingLeft: "10px", color: "#482c00" }}>NOTICE</span><p><Link onClick={ErrorNotice} to={"/notice"}>더보기</Link></p></div>
                        <div className="board">
                            <ul>
                                {
                                    !isEmpty(notices) && notices.map((notice, index) => {
                                        return (
                                            <li key={index}>
                                                <a dangerouslySetInnerHTML={{ '__html': notice.title }}></a>
                                                <div><span>{dateFormation(notice.created_date, 10)}</span></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="borad_box deposit_board_box">
                        <div className="board_title"><span>실시간 입출금 현황</span><p><Link onClick={ErrorNotice} to={"/deposit"}>더보기</Link></p></div>
                        <div className="board">
                            <ul id='tb_main_cash'>
                                {
                                    !isEmpty(depositWithdrawStatusList) && depositWithdrawStatusList.map((status, index) => {
                                        return (
                                            <li key={index}>
                                                <div><span>{status.userid}</span></div>
                                                <div>{status.type === 1 ?
                                                    <span style={{ color: "var(--border)" }}>입금</span>
                                                    :
                                                    (status.type === 2 ?
                                                        <span style={{ color: "#c46655" }}>출금</span>
                                                        :
                                                        <span>-</span>)}</div>
                                                <div><span style={{ color: "var(--border)" }}>{status.amount}</span></div>
                                                <div><span>{dateFormation(status.created_date, 10)}</span></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="borad_box">
                        <div className="board_title"><span>이벤트</span><span style={{ paddingLeft: "10px", color: "#482c00" }}>EVENT</span><p><Link onClick={ErrorNotice} to="/events">더보기</Link></p></div>
                        <div className="board t-center">
                            <ul>
                                {
                                    !isEmpty(events) && events.map((list, index) => {
                                        return (
                                            <li key={index}>
                                                <a dangerouslySetInnerHTML={{ '__html': list.title }}></a>
                                                <div><span>-</span></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="main_game">
                    <ul>
                        {
                            MainmMenu.map((menu, index) => {
                                return (

                                    <li key={index}>
                                        <Link onClick={ErrorNotice} to={menu.path}>
                                            <img className='custom_image' src={menu.src} />
                                            <img className='custom_hover_image' src={menu.hover_src} />
                                        </Link></li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="footer">
                    <img src="../images/footer.png" />
                    {/* <p>COPYRIGHT © 2023 SC CASINO. ALL RIGHTS RESERVED.</p> */}
                </div>
            </div>

        </div>
    );
};

export default Home;