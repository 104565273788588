import Modal from 'Components/Modal/Modal';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { pageActions, userActions } from 'Common/store';
import { toastr } from 'Common';
import Login from 'Components/Layout/Login';
import SignupPage from 'Containers/Layout/SignupPage';
import { isEmpty } from 'Common';


const UpMenus = [

    // {
    //     name: "경기결과",
    //     path: "/gameresult",
    //     awesome:"icon-eventc"
    // },
    // {
    //     name: "베팅내역",
    //     path: "/bettinghistory"
    // },
    // {
    //     name: "포인트내역",
    //     path: "/pointdetail"
    // },
    {
        name: "쪽지",
        path: "/message",
        iconImage: "../images/top_icon/message_off.png",
        iconHoverImage: "../images/top_icon/message_on.png"
    },
    {
        name: "입금",
        path: "/deposit",
        iconImage: "../images/top_icon/deposit_off.png",
        iconHoverImage: "../images/top_icon/deposit_on.png"
    },
    {
        name: "출금",
        path: "/Withdraw",
        iconImage: "../images/top_icon/withdraw_off.png",
        iconHoverImage: "../images/top_icon/withdraw_on.png"
    },
    {
        name: "포인트 전환",
        path: "/point",
        iconImage: "../images/top_icon/pointChange_off.png",
        iconHoverImage: "../images/top_icon/pointChange_on.png"
    },

    {
        name: "고객센터",
        path: "/help",
        iconImage: "../images/top_icon/customer_off.png",
        iconHoverImage: "../images/top_icon/customer_on.png"
    },
    {
        name: "공지사항",
        path: "/notice",
        iconImage: "../images/top_icon/notice_off.png",
        iconHoverImage: "../images/top_icon/notice_on.png"
    },

    {
        name: "이벤트",
        path: "/events",
        iconImage: "../images/top_icon/event_off.png",
        iconHoverImage: "../images/top_icon/event_on.png"
    },

    // {
    //     name: "쿠폰함",
    //     path: "/coupon"
    // },
    // {
    //     name: "룰렛",
    //     path: "/roulette"
    // },
    // {
    //     name: "복권",
    //     path: "/lottery"
    // }
]

const DownMenu = [
    {
        name: "스포츠",
        path: "/sportsprematch"
    },
    // {
    //     name: "스포츠 (국내형)",
    //     path: "/sportsdomestic"
    // },
    {
        name: "라이브 스포츠",
        path: "/sportslive"
    },
    {
        name: "정식카지노",
        path: "/casino"
    },
    {
        name: "슬롯머신",
        path: "/slot"
    },
    {
        name: "가상게임",
        path: "/bet365?type=Soccer"
    },
    {
        name: "미니게임",
        path: "/minigame?type=0"
    },

]

const Header = (props) => {

    const { notShow } = props;

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const user = useSelector(state => state.user.data.user);
    const { showleft, showright } = useSelector(state => state.user);
    const [popup, setPopup] = useState(false);


    const [login, setlogin] = useState(false)
    const [signin, setsignin] = useState(false)

    useEffect(() => {
        if (!isAuthenticated) {
            setlogin(true)
        }
    }, [isAuthenticated])

    const onPopup = () => {
        setPopup(!popup);
    }

    const onShowLeft = () => {
        dispatch(userActions.setShowLeft(!showleft))
    }

    const onShowRight = () => {
        dispatch(userActions.setShowRight(!showright))
    }

    const Logout = () => {
        dispatch(userActions.logoutUser());
        onPopup();
    }

    const onActivebtn = (e) => {
        if (!isAuthenticated) {
            e.preventDefault();
            return toastr.warning("로그인하시고 이용해주세요.");
        }
    }

    const pageFomat = () => {
        Error_modal()
        dispatch(pageActions.setPageData({
            curPage: 1,
            totalPage: 1
        }))

    }

    const onLogin = () => {
        setlogin(true)
    }
    const hideLogin = () => {
        setlogin(false)
    }
    const onSignin = () => {
        setsignin(true)
    }
    const hideSignin = () => {
        setsignin(false)
    }
    const Error_modal = () => {
        if (isAuthenticated == false) {
            return toastr.warning("로그인하시고 이용하여주세요")
        }
    }


    return !notShow && (
        <div className={`header_wrap ${showleft ? "left" : ""} ${showright ? "right" : ""}`}>
            <div className="header_top">
                <div className="m_left_menu" onClick={onShowLeft}><a><i className="fa-solid fa-bars"></i></a></div>
                <div className="logo"><Link to="/"><img src="images/logo.gif" alt='dashboard' /></Link></div>
                {/* <div className="notice d-flex">
                    <img src="images/notice_title.png" alt="" style={{ width: "75px" }} />
                    <marquee>입금시 계좌문의는 필수 입니다. 꼭 고객센터로 계좌 확인 후 연락주세요</marquee>
                </div> */}
                <div className="lnb" style={{ display: "flex" }}>
                    <ul>
                        {
                            UpMenus.map((menu, index) => {
                                return (
                                    <li key={index} style={{ display: "flex", alignItems: "center" }} >
                                        <Link onClick={() => Error_modal()} to={isAuthenticated ? menu.path : ""}>
                                            <img className='icon-image' src={menu.iconImage} style={{ marginRight: "5px" }} />
                                            <img className='icon-hover-image' src={menu.iconHoverImage} style={{ marginRight: "5px" }} />{menu.name}
                                        </Link>
                                    </li>

                                )
                            })
                        }
                    </ul>
                </div>
                {
                    isAuthenticated &&
                    <div className="util" style={{ display: "flex", alignItems: "center" }}>
                        <ul>
                            <Link to="/mypage">
                                <span className="login-btn util_btn1" style={{ minWidth: "120px", borderRadius: "40px", border: "unset", background: "unset", padding: "0px" }}>
                                    <img className='login-btn-img' src="../images/more/userinfo-btn.png" />
                                    <img className='login-btn-img-hover' src="../images/more/userinfo-btn-on.png" />
                                </span>
                            </Link>
                        </ul>
                        <ul>
                            <li onClick={onPopup}>
                                <span className="register-btn util_btn1" style={{ minWidth: "120px", borderRadius: "40px", border: "unset", background: "unset", marginLeft: "5px", padding: "0px" }} >
                                    <img className='register-btn-img' src="../images/more/logout-btn.png" />
                                    <img className='register-btn-img-hover' src="../images/more/logout-btn-on.png" />
                                </span>
                            </li>
                        </ul>
                    </div>

                }
                {
                    !isAuthenticated &&
                    <div className="util" style={{ display: "flex", alignItems: "center" }}>
                        <ul>
                            <li onClick={onLogin}>
                                <span className="login-btn util_btn1" style={{ minWidth: "120px", borderRadius: "40px", border: "unset", background: "unset", padding: "0px" }}>
                                    <img className='login-btn-img' src="../images/more/login-btn.png" />
                                    <img className='login-btn-img-hover' src="../images/more/login-btn-on.png" />
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li onClick={onSignin}>
                                <span className="register-btn util_btn1" style={{ minWidth: "120px", borderRadius: "40px", border: "unset", background: "unset", marginLeft: "5px", padding: "0px" }} >
                                    <img className='register-btn-img' src="../images/more/register-btn.png" />
                                    <img className='register-btn-img-hover' src="../images/more/register-btn-on.png" />
                                </span>
                            </li>
                        </ul>
                    </div>


                }
                <div className="m_right_menu" onClick={onShowRight}><a><i className="fa-regular fa-circle-user"></i></a></div>
            </div>
            <div className="bg_line"></div>
            <div className="header_bottom">
                <div className="gnb">
                    <ul>
                        {
                            DownMenu.map((menu, index) => {
                                return (
                                    <li key={index} ><Link to={isAuthenticated ? menu.path : ""} onClick={pageFomat}>{menu.name}</Link></li>
                                )
                            })
                        }
                    </ul>
                </div>
                {/*  ----------------------------------------------------------Mobile( 로그인 및 회원가입보이기 (max 600px) )-------------------------------------------------------------- */}
                {!isAuthenticated &&
                    <div className='M_header_bottom' style={{ display: "none" }}>
                        <div className='login_btn' onClick={onLogin}> <span className="icon-login1"></span>로그인 </div>
                        <div className='sign_btn' onClick={onSignin}> <span className="icon-user-plus"></span>회원가입</div>
                    </div>
                }
                {isAuthenticated &&
                    <div className='M_header_bottom' style={{ display: "none" }}>
                        <div className="my_info_money" style={{ justifyContent: "center", background: "unset", color: "white" }}><i className="fa-solid fa-won-sign"></i> 보유머니 <span style={{ margin: "unset", color: "rgb(225, 173, 255)" }}>{isEmpty(user) ? 0 : Math.floor(user.balance.money).toLocaleString()}원</span><img src='../images/common/return.png' height={"16px"} /></div>
                        <div className="my_info_money" style={{ justifyContent: "center", background: "unset", color: "white" }}><i className="fa-brands fa-product-hunt"></i> 포인트<span style={{ margin: "unset", color: "rgb(225, 173, 255)" }} >{isEmpty(user) ? 0 : Math.floor(user.balance.rolling).toLocaleString()}P</span></div>
                    </div>
                }
            </div>
            <div className="bg_line"></div>
            {
                popup && <Modal contents="로그아웃 하시겠습니까?" popup={popup} onAction={Logout} onPopup={onPopup} />
            }
            {
                <Login
                    view={login}
                    sign={onSignin}
                    hide={hideLogin}
                />
            }
            {
                <SignupPage
                    view={signin}
                    login={onLogin}
                    hide={hideSignin}
                />
            }
        </div >
    );
};

export default Header;