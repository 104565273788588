import { convertNameKor, convertSportsResultClass, convertSportsResultType, dateFormation, fixedNumber, getMarketDetail, getMatchInfo, isEmpty, sortResultMarket } from 'Common';
import { sportsActions } from 'Common/store';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'



const Detail = (props) => {

    const dispatch = useDispatch();
    const { gameList } = props
    const [showMarket, setShowMarket] = useState(-1)
    const [inputs, setInputs] = useState({
        sport_type: 'all',
        sportId: 'all',
        location: 'all',
        league: 'all',
        teamName: '',
        sportType: 'all',
        gameType: 'all'
    });

    useEffect(() => {
        dispatch(sportsActions.ResultInfo())
    }, [])

    const onShowMarket = (val) => {
        if (val == showMarket)
            val = -1;
        setShowMarket(val);
    }

    return (
        <div className="game_result_list">
            {
                !isEmpty(gameList) && gameList.map((game, index) => {
                    const originList = sortResultMarket(game);
                    const matchInfo = getMatchInfo(originList);
                    const {
                        sportImg,
                        leagueName,
                        locationName,
                        imgPath,
                        sportName,
                        homeTeam,
                        awayTeam,
                        time,
                        liveScoreHome,
                        liveScoreAway,
                    } = matchInfo;
                    let showDetail = false;
                    return (
                        <Fragment key={index}>
                            <div className="grl_box">
                                <div className="grl">{dateFormation(time, 11)}</div>
                                <div className="grl"><img src={imgPath} className="result_img" />{locationName} / {leagueName}</div>
                                <div className="grl"><img src={sportImg} className="result_img" />{homeTeam} <span className="font05">vs</span> {awayTeam}</div>
                                <div className="grl">{liveScoreHome + " : " + liveScoreAway}</div>
                                <div className="grl">
                                    <a className="result_info_btn" onClick={() => onShowMarket(index)}>{index != showMarket ? <i className="fa-solid fa-sort-down"></i> : <i className="fa-solid fa-sort-up" ></i>}상세</a>
                                </div>
                            </div>
                            <div className="grl_hide" style={{ display: index != showMarket ? "" : "block" }}>
                                <div className="grl_odd_list_box">
                                    {
                                        originList.Markets.map((market, marketIndex) => {
                                            return (
                                                <div className="grl_odd_list" key={marketIndex} style={{alignItems:"center"}}>
                                                    <div className="grl_odd_list_1">{convertNameKor(market)}</div>
                                                    {
                                                        market.Providers[0].Bets.map((bet, index) => {
                                                            return (
                                                                <span className="text margin-right-15" key={index}>
                                                                                    {getMarketDetail(game, bet.Name)}
                                                                                &ensp;(
                                                                                    <span className="text" style={{ color: "#f6c33a" }}>{fixedNumber(bet.Price[0].Price)}</span>
                                                                                )
                                                                                :
                                                                                    <span className={"text " + convertSportsResultClass(bet.Status)}>
                                                                                        &ensp;{convertSportsResultType(bet.Status)}
                                                                                    </span>&ensp; &ensp; &ensp;
                                                                                </span> 
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Fragment>
                    )
                })
            }

        </div>
    );
};

export default Detail;