import { dateFormation, isEmpty, messageMusic } from 'Common';
import { moreActions } from 'Common/store';
import Modal from 'Components/Modal/Modal';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

const MessageContents = () => {
    const dispatch = useDispatch();
    const page = useSelector(state => state.page)
    const messages = useSelector(state => state.more.message)
    const lists = messages.notes;
    const unreadNotes = messages.unreadNotes;
    const [selected, setSelected] = useState(-1);
    const [popup, setPopup] = useState(false);
    const [showHis, setShowHis] = useState(false);
    const [popupType, setPopupType] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
        if (!isEmpty(unreadNotes)) {
            messageMusic.start();
        }
        else {
            messageMusic.stop();
        }
    }, [unreadNotes])



    const getMessage = (page) => {
        dispatch(moreActions.getMessageData({
            page: page,
            perPage: perPage
        }))
        setSelected(-1)
    }

    useEffect(() => {
        getMessage(1);
    }, [perPage])

    const onSelect = (index, id) => {
        if (selected === index) {
            setSelected(-1);
        }
        else {
            setSelected(index);
        }
        dispatch(moreActions.confirmMessage(id))
    }

    const deleteAll = () => {
        dispatch(moreActions.DeleteMessage())
    }

    const onPopup = (value) => {
        setPopup(!popup);
        setPopupType(value);
        if (value === "read") {
            if (popup) {
                onSelect(-1, "all");
            }
            setMessage("전체 읽음 처리를 하시겠습니까?");
        }
        if (value === "delete") {
            if (popup) {
                deleteAll();
            }
            setMessage("전체 삭제 처리를 하시겠습니까?");
        }
    }
    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title" style={{ display:"flex", alignItems:"center",justifyContent:"space-between",marginBottom:"5px"}}>쪽지함 { !isEmpty(lists) && <ul className="display_flex">
                    <li onClick={() => onPopup("read")}><a><span className="btn2_1">전체읽기</span></a></li>
                    <li onClick={() => onPopup("delete")}><a><span className="btn2_2" style={{border:"unset"}}>전체삭제</span></a></li>
                </ul>}
                </div>
                <div className="con_box10" style={{ margin: "unset" }}>
                    <div className="list_box">
                        <div className="note_tr_title">
                            <span>번호</span>
                            <span className="titleTd" style={{ display: "flex", justifyContent: "center" }}>제목</span>
                            <span>작성일</span>
                            <span>상태</span>
                        </div>
                        {
                            !isEmpty(lists) && lists.map((list, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div className="note_tr_list" onClick={() => onSelect(index, list._id)}>
                                            <span>{Number(page.curPage) * 10 + index - 9}</span>
                                            <span className="titleTd" >{list.title}</span>
                                            <span>{dateFormation(list.date, 8)}</span>
                                            <span><span className={` ${list.new ? "division2" : "division1"}`}>{list.new ? "미열람" : "열람"}</span></span>

                                        </div>
                                        <div className="hide_note" style={selected != index ? { display: "none" } : { display: "block" }}>
                                            <div className="" dangerouslySetInnerHTML={{ '__html': list.description }} style={{ width: "100%", display: "block" }}></div>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    !isEmpty(lists) ?
                        <Pagenation
                            page={page.curPage}
                            totalPage={page.totalPage}
                            getPage={getMessage}
                        />
                        :
                        <div className="no_data" style={{ textAlign: "center" }}>내역이 없습니다.</div>
                }
                {/* <div className="con_box10" style={{marginTop:"10px"}}>
                    <div className="btn_wrap_right">
                        
                    </div>
                </div> */}
            </div>
            {
                <div className="dialog" style={{ display: popup ? "flex" : "none", backgroundColor: !popup && "#00000000", transition: "0.5s", scale: popup ? "1" : "0" }} >
                    <div className="container popup_color" style={{ display: "block" }}>
                        <div className="d-flex img_question">
                            <img src="../images/question.png" style={{ margin: "20px auto 0 auto" }} alt="" />
                        </div>
                        <div className="justify-center  d-flex betting_modal">
                            <span style={{ width: "100%", textAlign: "center", fontSize: "16px" }}>{message}</span>
                        </div>
                        <div className=" d-flex justify-center betting_modal_text betting_modal_text_yes_no">
                            <span className="popup-btn2" onClick={() => onPopup(popupType)}>네</span>
                            <span className="popup-btn1" onClick={() => onPopup("")}>아니오</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default MessageContents;