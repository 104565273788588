import { convertBetMarketName, toastr } from 'Common';
import { betActions } from 'Common/store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

const Bet365MarketItem = (props) => {

    const market = props.market;
    const dispatch = useDispatch();
    const bettedItems = useSelector(state => state.bet.bettedItems)
    let sports = useSelector(state => state.sports)

    // let sportsChange = sports.map(list => list)
    const { key, betgametype } = props;
    const checkBetted = (item, index, number) => {
        if (betgametype === "bet365") {

            return bettedItems.findIndex(list => list.betId === (sports.activeId + "_" + item.cate1 + "_" + index + "_" + number)) >= 0;
        } else {
            return bettedItems.findIndex(list => list.betId === (`${sports.activeId.split("_")[0]}_${sports.activeId.split("_")[2]}_${sports.activeId.split("_")[1]}` + "_" + item.cate1 + "_" + index + "_" + number)) >= 0;
        }
    }

    const onBet = (item, index, number) => {
        let gameDate = new Date(props.date.date);
        let dateNow = new Date()


        // if(gameDate < dateNow) {
        //     return toastr.warning("배팅시간이 지났습니다.");
        // }

        if (checkBetted(item, index, number)) {
            if (betgametype === "bet365") {

                dispatch(betActions.removeOneBet(sports.activeId + "_" + item.cate1 + "_" + index + "_" + number))
                return;
            } else {
                dispatch(betActions.removeOneBet((`${sports.activeId.split("_")[0]}_${sports.activeId.split("_")[2]}_${sports.activeId.split("_")[1]}` + "_" + item.cate1 + "_" + index + "_" + number)))
                return;
            }
        }
        let isbet = dispatch(betActions.AddOneBetGame({
            ...item,
            index,
            number,
            betId: betgametype === "bet365" ? sports.activeId + "_" + item.cate1 + "_" + index + "_" + number : `${sports.activeId.split("_")[0]}_${sports.activeId.split("_")[2]}_${sports.activeId.split("_")[1]}` + "_" + item.cate1 + "_" + index + "_" + number,
            marketId: sports.activeId + "_" + item.cate1 + "_" + index,
            eventKey: sports.activeId,
            date: props.date.date,
            type: betgametype
        }))

        console.log('isbet: ', isbet);
        if (!isbet) toastr.warning('배팅규정에 어긋납니다.');
    }

    return market.markets.length > 0 && (
        <li className="market" key={key} style={{ width: "100%" }}>
            <a>
                <div className="bet_list1_wrap_in_title">{convertBetMarketName(market.name)}</div>
            </a>
            <div className="mini_bet365_list_in" >
                {
                    market.markets.map((item, index) => {
                        return (
                            <>
                                <span className={`${market.name === "oneteam" ? "col-" : "w-100_"} ${checkBetted(item, index, 0) && "bet_on"}`} onClick={() => onBet(item, index, 0)} style={{ minWidth: "33.33%", padding: "0 1px" }} key={index}>
                                    <span className="home" >{item.home + " "} {(item.cate1 === "underover" || item.cate1 === "handicap") && `(${item.ratio2})`}</span>
                                    <em> {item.ratio1}</em>
                                </span>
                                {((item.cate1 !== "underover" && item.cate1 !== "handicap" && market.name !== "oneteam" && market.name !== "underover" && market.name !== "homeaway" && market.name !== "handicap") && item.ratio2 != 0) &&
                                    <span className={` ${checkBetted(item, index, 1) && "bet_on"}`} onClick={() => onBet(item, index, 1)}>
                                        <span className="">무승부</span>
                                        <em className="match-team-rate">{item.ratio2}</em>
                                    </span>
                                }
                                <span className={`bet-team teams ${checkBetted(item, index, 2) && "bet_on"}`} style={{ flexDirection: "row", display: market.name === "oneteam" && "none" }} onClick={() => onBet(item, index, 2)}>
                                    <span className="away">{item.away + " "} {(item.cate1 === "underover" || item.cate1 === "handicap") && `(${item.ratio2})`}</span><em>{item.ratio3}</em>
                                </span>
                            </>
                        )
                    })
                }
            </div>
        </li>
    );
};

export default Bet365MarketItem;