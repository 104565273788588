import { convertDepositString, dateFormation, isEmpty } from 'Common';
import Modal from 'Components/Modal/Modal';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const DepositHistory = (props) => {

    const user = useSelector(state => state.user.data.user)
    const lists = useSelector(state => state.more.Fivedeposit.data)
    const page = useSelector(state => state.page)
    const { getLists, popup, onPopup, historyDelete } = props;
    useEffect(
        () => {
            getLists(1);
        },
        [perPage]
    );

    const onAction = () => {
        historyDelete(false);
    }

    return (
        <Fragment>

            <div className="con_box00">
                <div className="list_box">
                    <div className="over_list">
                        <div className="list_tr2 trfirst">
                            <div className="list_title2">번호</div>
                            <div className="list_title2">입금금액</div>
                            <div className="list_title2">날짜</div>
                            <div className="list_title2">상태</div>
                            <div className="list_title2">삭제</div>
                        </div>
                        {
                            !isEmpty(lists) && lists.map((list, index) => {
                                return (
                                    <div className="list_tr2" key={index}>
                                        <div className="list2">{(page.curPage - 1) * 10 + index + 1}</div>
                                        <div className="list2"><span className="font11">{!list.amount ? "" : Number(list.amount).toLocaleString()}</span></div>
                                        <div className="list2">{dateFormation(list.created_date, 10)}</div>
                                        <div className="list2"><span className="division2">{convertDepositString(list.status)}</span></div>
                                        <div className="list2" ><a  ><span className="btn1_2" onClick={() => onPopup(list._id)} style={{ width: "100%" }}>삭제</span></a></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    isEmpty(lists) ?
                        <>
                            <div className='no_data'>내역이 없습니다</div>
                        </>
                        :
                        <Pagenation
                            page={page.curPage}
                            totalPage={page.totalPage}
                            getPage={getLists}
                        />
                }
            </div>
            {
                popup && <Modal contents="삭제하시겠습니까?" popup={popup} onAction={onAction} onPopup={onPopup} />
            }
        </Fragment>
    );
};

export default DepositHistory;