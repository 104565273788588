import { isEmpty, toastr } from 'Common';
import { userActions } from 'Common/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const MyPageContents = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data.user)

    const [loginOldPwdErr, setLoginOldPwdErr] = useState("");
    const [loginPwdErr, setLoginPwdErr] = useState("");
    const [loginPwd_confirmErr, setLoginPwd_ConfirmErr] = useState("");
    const [error, setError] = useState({});
    const [inputs, setInputs] = useState({
        oldPwd: "",
        loginPwd: "",
        loginPwd_confirm: ""
    })

    const { oldPwd, loginPwd, loginPwd_confirm } = inputs;
    const onChange = (e) => {
        const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
        setInputs({
            ...inputs, // 기존의 input 객체를 복사한 뒤
            [name]: value // name 키를 가진 값을 value 로 설정
        });
        setLoginOldPwdErr('');
        setLoginPwdErr('');
        setLoginPwd_ConfirmErr('');
    }

    const onChangePassword = () => {

        let err = {};
        if (isEmpty(oldPwd)) {
            toastr.warning("현존 비밀번호를 입력해주세요.");
        }
        if (isEmpty(loginPwd)) {
            toastr.warning('신규 비밀번호를 입력해주세요.');
        }
        if (isEmpty(loginPwd_confirm)) {
            toastr.warning('신규 비밀번호확인을 입력해주세요.')
        }
        if (loginPwd !== loginPwd_confirm) {
            toastr.warning('신규 비밀번호확인이 맞지않습니다.')
        }

        setError(err);
        if (!isEmpty(err)) return;

        let params = {
            oldPwd,
            loginPwd,
            loginPwd_confirm,
        }
        // updateUser(params)
        dispatch(userActions.changePassword(params))
        // onReset();
    }

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">회원정보</div>
                <div className="write_box">
                    <div className="white_box_h" style={{width:"100%"}}>
                        {/* <div className="s_title1">회원정보</div> */}
                        <div className="write_tr">
                            <div className="write_title">아이디</div>
                            <div className="write_basic">{isEmpty(user) ? "" : user.loginId}</div>
                        </div>
                        <div className="write_tr">
                            <div className="write_title">닉네임</div>
                            <div className="write_basic">{isEmpty(user) ? "" : user.name}</div>
                        </div>
                        <div className="write_tr">
                            <div className="write_title">생년월일</div>
                            <div className="write_basic">{isEmpty(user) ? "" : user.birthday}</div>
                        </div>
                        <div className="write_tr">
                            <div className="write_title">은행명</div>
                            <div className="write_basic">{isEmpty(user) ? "" : user.accountInfo.bankName}</div>
                        </div>
                        <div className="write_tr">
                            <div className="write_title">계좌번호</div>
                            <div className="write_basic">{isEmpty(user) ? "" : user.accountInfo.accountNumber}</div>
                        </div>
                        <div className="write_tr">
                            <div className="write_title">연락처</div>
                            <div className="write_basic">{isEmpty(user) ? "" : user.tel}</div>
                        </div>
                    </div>
                    {/* <div className="white_box_h">
                        <div className="s_title1">비번변경</div>
                        <div className="write_box join">
                            <div className="write_tr">
                                <div className="write_title">현존 비밀번호</div>
                                <div className="write_basic">
                                    <input type='password' name='oldPwd' value={oldPwd} className="input1" placeholder="현존 비밀번호." onChange={onChange} />
                                </div>
                            </div>
                            <div className="write_tr">
                                <div className="write_title">신규 비밀번호</div>
                                <div className="write_basic">
                                    <input type='password' name='loginPwd' value={loginPwd} className="input1" placeholder="신규 비밀번호" onChange={onChange} />
                                </div>
                            </div>
                            <div className="write_tr">
                                <div className="write_title">신규 비밀번호 확인</div>
                                <div className="write_basic">
                                    <input type='password' name='loginPwd_confirm' value={loginPwd_confirm} className="input1" placeholder="신규 비밀번호 확인" onChange={onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="con_box10">
                        <div className="btn_wrap_center">
                            <ul>
                                <li onClick={onChangePassword}><a><span className="btn3_1">비밀번호 변경</span></a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default MyPageContents;