import axios from "axios";
import { setAuthToken, toastr } from "Common";
import { pageActions, store, userActions } from "./store";


export const request = {
  get: (url, params) => {
    return new Promise(resolve => {
      axios.get(url, params)
        .then(res => {
          const data = res.data;
          resolve(data);
        })
        .catch(err => {
          store.dispatch(pageActions.onSetPageLoading(false));
          if (err.response && (err.response.status === 401)) {
            store.dispatch(userActions.logoutUser())
          } else {
            toastr.warning("잠시후 다시 시도해주세요.");
          }
        });
    })
  },

  post: (url, params) => {
    return new Promise(resolve => {
      axios.post(url, params)
        .then(res => {
          const data = res.data;
          resolve(data)
        })
        .catch(err => {
          store.dispatch(pageActions.onSetPageLoading(false));
          console.error(err);
          if (err.response && (err.response.status === 401)) {
            store.dispatch(userActions.logoutUser())
          } else {
            toastr.warning("잠시후 다시 시도해주세요.");
          }
        });
    })
  },

  put: (url, params) => {
    return new Promise(resolve => {
      axios.put(url, params)
        .then(res => {
          const data = res.data;
          resolve(data);
        })
        .catch(err => {
          store.dispatch(pageActions.onSetPageLoading(false));
          if (err.response && (err.response.status === 401)) {
            store.dispatch(userActions.logoutUser())
          } else {
            toastr.warning("잠시후 다시 시도해주세요.");
          }
        });
    })
  },
  delete: (url, params) => {
    return new Promise(resolve => {
      axios.delete(url, params)
        .then(res => {
          const data = res.data;
          store.dispatch(pageActions.onSetPageLoading(false));
          resolve(data);
        })
        .catch(err => {
          store.dispatch(pageActions.onSetPageLoading(false));
          if (err.response && (err.response.status === 401)) {
            store.dispatch(userActions.logoutUser())
          } else {
            toastr.warning("잠시후 다시 시도해주세요.");
          }
        });
    })
  },
}