import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment } from 'react';
import LotteryContents from './LotteryContents';

const Lottery = () => {
    return (
        <Fragment>
            <LeftBar />
            <LotteryContents />
            <Right type="lottery" />
        </Fragment>
    );
};

export default Lottery;