import React, { useState, useEffect,useMemo } from 'react';
import DepositMethod from './DepositMethod';
import DepositRequest from './DepositRequest';
import DepositHistory from './DepositHistory';
import { useDispatch, useSelector } from 'react-redux'
import { moreActions } from 'Common/store';
import { no_comma, toastr,isEmpty } from 'Common';
import { perPage } from 'Utils';

const DepositContent = () => {

    const dispatch = useDispatch();
    const [money, setMoney] = useState(0);
    const [depositBonus, setDepositBonus] = useState(1);
    const [accountInfo, setAccountInfo] = useState({});
    const [ids, setIds] = useState([]);  //선택삭제시 아이디
    const [popup, setPopup] = useState(false);
    const getMoneySetting = (data) => {
        return data;
    }
    const exchangeSetting = useSelector(state => state.more.exchangeSetting);
    const setMoneySetting = useMemo(() => getMoneySetting(exchangeSetting), [exchangeSetting]);
    const minMoney = !isEmpty(setMoneySetting) ? setMoneySetting.charge_min_money.toLocaleString() : "10,000";
    const chargeUnit = !isEmpty(setMoneySetting) ? setMoneySetting.charge_unit : 1000;




    const onChange = (e) => {
        const { value, checked } = e.target;
        if (checked) setIds([...ids, value]);
        else setIds(ids.filter(item => item !== value))
        if (!isNaN(Number(no_comma(value))))
            setMoney(Number(no_comma(value)));
        else setMoney(0);
    };

    const reset = () => {
        setMoney(0);
    }

    const DoQuestion = () => {
        let qTitle = "빠른계좌문의합니다";
        let qQuestion = "빠른계좌문의합니다";
        dispatch(moreActions.DoQuestion({ title: qTitle, question: qQuestion }))
    }

    const onBonus = (e) => {
        const { value, name } = e.target;
        setDepositBonus(value)
    };

    const onDeposit = () => {
        if (money <= 0) {
            return toastr.warning("머니를 입력해주세요.");
        } else {
            let depositMoney = Math.floor(money / chargeUnit) * chargeUnit;
            const { secretKey } = accountInfo;
            const params = {
                money: depositMoney,
                typeStr: "deposit",
                bonus: depositBonus,
                secretKey: secretKey
            }
            dispatch(moreActions.doFiveDeposit(params));
        }
    };

    const getLists = page => {
        const params = {
            typeStr: "deposit",
            page: page,
            perPage: perPage
        }
        dispatch(moreActions.getFivedayDepositList(params));
    };

    const onPopup = (val) => {
        setPopup(!popup);
        setIds([val]);
    };

    const historyDelete = (value) => {
        dispatch(moreActions.delDepositList({
            ids,
            all: value,
            typeStr: 'deposit'
        }));
        setPopup(!popup);
    };
    useEffect(() => {
        dispatch(moreActions.GetBonusList())
        dispatch(moreActions.GetExchangeSetting())
    }, [])


    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">입금신청</div>
                <div className="write_box">
                    <DepositMethod />
                    <DepositRequest depositBonus={depositBonus} setDepositBonus={setDepositBonus} money={money} setMoney={setMoney} onChange={onChange} reset={reset} onBonus={onBonus} />
                </div>
                <div className="con_box10">
                    <div className="btn_wrap_center">
                        <ul className="display_flex">
                            <li onClick={DoQuestion}><a><span className="btn3_2">계좌문의</span></a></li>
                            <li onClick={onDeposit}><a><span className="btn3_1">입금신청하기</span></a></li>
                        </ul>
                    </div>
                </div>
                <DepositHistory getLists={getLists} popup={popup} onPopup={onPopup} historyDelete={historyDelete} />
            </div>
        </div>
    );
};

export default DepositContent;