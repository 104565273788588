import NoticeContents from 'Components/Account/Notice/NoticeContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Notice = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar/>
            <NoticeContents/>
            <Right type="notice"/>
        </Fragment>
    );
};

export default Notice;