import SlotCasinoContent from 'Components/Slot-Casino/SlotCasinoContent';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Casino = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment >
            <LeftBar  />
            <SlotCasinoContent type="Casino"/>
            <Right type="main" />
        </Fragment>
    );
};

export default Casino;