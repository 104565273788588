import { history } from 'Common';
import { pageActions } from 'Common/store';
import SportsCenter from 'Components/Sports/SportsCenter';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux'

const SportsPrematch = () => {

    const dispatch = useDispatch();
    const sportId = useSelector(state => state.sports.sportId);
    const [ showLeft, setShowLeft] = useState(false);
    const roomObj = {
        pageType: "toto",
        sportType: "sport",
        RoomType: "sport",
        gameType: "prematch",
        sportId: sportId,
        esportType: "all",
    }
    const roomData = {
        MessageType: "RoomType",
        data: roomObj
      };
      if(window.ws.readyState === 1)
        window.ws.send(JSON.stringify(roomData));
        dispatch(pageActions.onSetPageInfo(roomObj));

    const onSetShowLeft = () => {
        setShowLeft(!showLeft);
    }

    useEffect(() => {
        window.sportsScroll();
    },[])

    return (
        <Fragment>
            <LeftBar onSetShowLeft={onSetShowLeft} type="prematch" title="스포츠 (해외형)" showLeft={showLeft} history={history} roomObj={roomObj}/>
            <SportsCenter roomObj={roomObj} type="prematch" onSetShowLeft={onSetShowLeft} title="스포츠 (해외형)"/>
            <Right type="prematch"/>
        </Fragment>
    );
};

export default SportsPrematch;