import CouponContents from 'Components/Events/CouponContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const Coupon = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar />
            <CouponContents />
            <Right type="coupon" />
        </Fragment>
    );
};

export default Coupon;