import moment from "moment";
import { NotificationManager } from 'react-notifications';
import axios from "axios";
import { betActions, pageActions, sportsActions, store, userActions } from "./store";
import { request } from "./request";
import { api_url, resource_url } from "./keys";
import $ from "jquery";
import {
  getDefaultEsportsMarketIds, getDefaultProvidedMarketIds, getDotaMarkets, getIceHockeylProvidedMarketIds,
  getLeagueOfLegendsaMarkets,
  getMMAProvidedMarketIds,
  getbaseballProvidedMarketIds,
  getbasketballProvidedMarketIds,
  getfootballProvidedMarketIds,
  getsoccerProvidedMarketIds,
  getvolleyballProvidedMarketIds
} from "./group";
import { MainMarketData } from "./oddsGroup";
import { betGames, lotusgame, sportIds } from "Utils/names";
const locationUrl = resource_url + "/images/locations/";





/*********************************************************************************** */

export const isEmpty = (value) => {
  return value === null ||
    value === undefined ||
    (typeof (value) === "string" && value.trim().length === 0) ||
    (typeof (value) === "object" && Object.keys(value).length === 0)
}

export const onErrorImg = (e) => {
  e.target.src = "../images/248.png"
}

export const toastr = {
  info: message => {
    NotificationManager.info(message, "", 5000);
  },
  success: message => {
    NotificationManager.success(message, "", 5000);
  },
  warning: message => {
    NotificationManager.warning(message, "", 5000);
  },
  error: message => {
    NotificationManager.error(message, "", 5000);
  }
};

// Show message after get the response from backend.
export const showMessage = message => {
  if (!message) return;
  if (message.warning) NotificationManager.warning(message.warning, "", 5000);
  if (message.info) NotificationManager.info(message.info, "", 5000);
  if (message.success) NotificationManager.success(message.success, "", 5000);
  if (message.error) NotificationManager.error(message.error, "", 5000);
};

export const dateFormation = (date, type = 1) => {
  switch (type) {
    case 0:
      return moment(new Date(date)).format("YY-MM-DD HH:mm:ss");
    case 1:
      return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
    case 2:
      return moment(new Date(date)).format("YY-MM-DD");
    case 3:
      return moment(new Date(date)).format("YYYY-MM-DD");
    case 4:
      return moment(new Date(date)).format("MM-DD");
    case 5:
      return moment(new Date(date)).format("HH:mm");
    case 6:
      return moment(new Date(date)).format("HH:mm:ss");
    case 7:
      return moment(new Date(date)).format("hh:mm a");
    case 8:
      return moment(new Date(date)).format("YYYY-MM-DD HH:mm");
    case 9:
      return moment(new Date(date)).format("MM/DD HH:mm");
    case 10:
      return moment(new Date(date)).format("MM-DD HH:mm");
    case 11:
      return moment(new Date(date)).format("MM월 DD일 HH:mm");
    default:
      return "";
  }
};

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    // Delete Auth header
    delete axios.defaults.headers.common['Authorization']
  }
}

export const history = {
  navigate: null,
  location: null
};

export const convertObj = value => {
  if (typeof value === "object") return value;
  if (typeof value === "string") return JSON.parse(value);
  return value;
};

export const getLocalStringNumber = (value) => {
  if (isNaN(Number(value))) {
    return 0;
  }
  else {
    return Number(value).toLocaleString();
  }
}

export const no_comma = data => {
  var tmp = "";
  var comma = ",";
  var i;

  for (i = 0; i < data.length; i++) {
    if (data.charAt(i) != comma) tmp += data.charAt(i);
  }
  return tmp;
};

// These four functions are declared in index.html
export const isMobilePage = window.isMobilePage; // Check mobile mode true if it's mobile
export const ResizePowerballFrame = window.ResizePowerballFrame;
export const ResizeGopickFrame = window.ResizeGopickFrame;
export const ResizeVirtualIframe = window.ResizeVirtualIframe;
export const ResizeLotusIframe = window.ResizeLotusIframe;
export const ResizeSportsLiveIframe = window.ResizeSportsLiveIframe;
export const ResizeESportsIframe = window.ResizeESportsIframe;
export const ResizeFooter = window.ResizeFooter; // Keep footer position as the bottom of the page
export const setLeftBar = window.setLeftBar;// left_bar

//Check LoginId
export const checkLoginIdstr = (data) => {

  var re = /[^a-z^A-Z^0-9]/g;
  if (re.test(data)) {
    return true;
  }
  else {
    return false;
  }
}
//숫자에따라서 문자 입력
export const ChangeNum_Letter = (data) => {

  let unit = [100000000, 10000, 1000, 100, 10], letter = ['억', '만', '천', '백', '십']
  let result = ``
  let index = 0;
  while (data) {
    if (index == 5) {
      if (data > 0) {
        result += data
      }
      break;
    }
    if (data / unit[index] >= 1) {
      result += (parseInt(data / unit[index]) + letter[index]).toString()
      data %= unit[index]
    }
    index++;
  }
  return result;
}

//auto Logout
export const autoLogout = () => {
  $(document).ready(() => {
    $('#root').mousemove(() => {
      localStorage.setItem("isMove", true);
    });

    $('#root').mousedown(() => {
      localStorage.setItem("isMove", true);
    });

    $('#root').keydown(() => {
      localStorage.setItem("isMove", true);
    });

    $('#root')[0].addEventListener("touchstart", () => {
      localStorage.setItem("isMove", true);
    });

    const AutoLogoutWarning = () => {
      let isMove = localStorage.getItem("isMove")
      if (isMove == "true" && store.getState().user.isAuthenticated) {
        if (window.ws.readyState === 1) {
          let data = JSON.stringify({ is_move: isMove })
          window.ws.send(JSON.stringify({ MessageType: 'HeartBit', data: data }));

        }
      }
      // isMove = false;
      localStorage.setItem("isMove", false);
      const sendTime = setTimeout(() => {
        AutoLogoutWarning();
      }, 5000);
      if (!store.getState().user.isAuthenticated) {
        clearTimeout(sendTime);
      }
    }

    AutoLogoutWarning();
  })
}

export const getIpAddr = () => {
  try {
    let theUrl = 'https://www.cloudflare.com/cdn-cgi/trace'
    // let theUrl = 'https://172.16.88.156:14998'
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, false) // false for synchronous request
    xmlHttp.send(null);

    let responseText = xmlHttp.responseText;
    let ip = responseText.split('ip=')[1].split('ts=')[0];
    localStorage.setItem('client-ip', ip);
    localStorage.setItem('client-ip', responseText);
  } catch (e) {
    //console.log('couldFlareCatch==>', e)
    ipify()
  }


  function ipify() {
    try {
      $.getJSON("https://api.ipify.org?format=json", function (data) {
        //console.log('ipify===>', data)
        if (data.ip)
          localStorage.setItem('client-ip', data.ip)
        else
          amazon()
      })
        .catch(err => {
          console.error('err: ', err);
          //console.log('ipifyCatch==>', err)
          amazon()
        })
    } catch (e) {
      //console.log('ipifyCatch==>', e)
      amazon()
    }
  }

  function amazon() {
    try {
      fetch("https://checkip.amazonaws.com/").then(res => res.text()).then(data => {
        //console.log('amazon', data);
        localStorage.setItem('client-ip', data)
      })
        .catch(err => {
          console.error('err: ', err);
          //console.log('amazonCatch==>', err)
        })
    } catch (e) {
      //console.log('amazonCatch==>', e)
    }
  }

}

export const convertDepositString = type => {
  switch (type) {
    case 0:
      return "대기중";
    case 1:
      return "승인됨";
    case 2:
      return "거절됨";
    default:
      return "_";
  }
};

//Coupon
export const getCouponObject = data => {
  //console.log(data);
  const type = data.coupon.type;

  const getCouponNameKor = param => {
    switch (param) {
      case 1:
        return '포인트지급';
      case 2:
        return '충전 % 보너스';
      default:
        return ''
    }
  }

  const getCouponTypeKor = param => {
    switch (param) {
      case 1:
        return '포인트 지급쿠폰';
      case 2:
        return '충전보너스 지급쿠폰';
      default:
        return ''
    }
  }

  const getCouponAmount = coupon => {
    switch (coupon.type) {
      case 1:
        return Number(coupon.bonus.balance.rolling).toLocaleString() + ' P';
      case 2:
        return coupon.bonus.charge.percent + ' %';
      default:
        return ''
    }
  }

  return {
    _id: data._id,
    name: getCouponNameKor(type),
    type: getCouponTypeKor(type),
    startTime: dateFormation(data.created_date, 8),
    endTime: dateFormation(data.end_date, 8),
    amount: getCouponAmount(data.coupon)
  };
}

//Music
export const loginMusic = () => {
  try {
    $("#noneMusic")[0].play();
  }
  catch (err) {
    //console.log("err", err)
  }
}

export const messageMusic = {
  start: () => {
    try {
      $("#messageMusic")[0].play();
    }
    catch (err) {
      //console.log("err", err)
    }
  },
  stop: () => {
    try {
      $("#messageMusic")[0].pause();
    }
    catch (err) {
      //console.log("err", err)
    }
  }

}

export const fixBetGameData = events => {
  let resData = [];
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    resData.push({
      Fixture: {
        StartDate: event.data.korea_Time,
        Status: 1,
        Participants: [
          {
            name_kor: event.data.k_HomeTeam,
            name_front: event.data.k_HomeTeam
          },
          {
            name_kor: event.data.k_AwayTeam,
            name_front: event.data.k_AwayTeam
          }
        ],
        League: {
          name_kor: `${event.data.sports} [${event.data.stadium}]`,
          name_front: `${event.data.sports} [${event.data.stadium}]`
        }
      },
      key: event.key
    });
  }
  return resData;
};

export const fixBetsData = (originData, marketId, type, isBetGame, originSportslist = {}) => {
  if (isEmpty(originData) || isEmpty(originData.Bets)) return [];

  // const providerId = getProviderId();
  // if (isEmpty(providerId)) {
  //   return [];
  // }
  // // //console.log("providerId", providerId);
  // let checkMarket = false;
  // originData.Bets.forEach(element => {
  //   if (isEmpty(element.Price)) return (checkMarket = true);

  //   const priceElement = element.Price.filter(betprice => providerId == betprice.providerId);
  //   element.Price = element.Price.filter(betprice => providerId == betprice.providerId);
  //   if (isEmpty(priceElement)) return (checkMarket = true);
  //   // if (fixedNumber(element.Price[0].Price) < 1) return (checkMarket = true);
  // });

  // if (checkMarket) return [];

  if (isEmpty(originData)) return [];

  let providedIndex;
  for (
    let providedIndex = 0;
    providedIndex < providedMarketIds.length;
    providedIndex++
  )
    if (providedMarketIds[providedIndex].data.indexOf(marketId) >= 0) break;
  if (providedIndex === providedMarketIds.length && isBetGame !== true)
    return [];
  if (originData.Bets.length < 2) return [];

  let data = $.extend(true, {}, originData);


  while (data.Bets.length > 0) {
    // const originIndex = data.Bets.findIndex(item => type === "prematch" ? item.Status !== 1 : item.Status > 2 );
    const originIndex = data.Bets.findIndex(item => item.Status !== 1 && item.Status != 10);
    if (originIndex < 0) break;
    data.Bets.splice(originIndex, 1);
  }
  if (data.Bets.length === 0) return [];

  // //console.log(data);

  const GetFixedList = (data, baseLine, special) => {
    // //console.log(special)
    // //console.log(data)
    // if(data[0].Id === "216194571251483648")
    // //console.log(data[0], data[1], data)
    if (data.length < 2) return [];
    const lastIndex = data.length === 2 ? 1 : 2;
    let fixedBaseLine = baseLine ? data[0].BaseLine.toString() : "VS";
    if (!isNaN(Number(fixedBaseLine.split(" ")[0])))
      fixedBaseLine = Number(fixedBaseLine.split(" ")[0]);

    // ----------------- 승무패와 같이 베트가 3개인 마켓에서 베트가 2개일때 하나를 넣어준다. --------------
    if ((marketId == "69" || marketId == "8" || marketId == "357" || marketId == "163") && (data.length === 2)) {
      let homeIndex = -1, drawIndex = -1, awayIndex = -1;
      let homeTemaName = originSportslist.Fixture.Participants[0].name.toLowerCase();
      let awayTemaName = originSportslist.Fixture.Participants[1].name.toLowerCase();
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.Name.toLowerCase().indexOf("draw") === 0) {
          drawIndex = index;
        }
        if (!isEmpty(originSportslist.Fixture.Participants) &&
          (originSportslist.Fixture.Participants.length === 2) &&
          element.Name.toLowerCase().indexOf(homeTemaName) === 0) {
          homeIndex = index;
        }
        if (!isEmpty(originSportslist.Fixture.Participants) &&
          (originSportslist.Fixture.Participants.length === 2) &&
          element.Name.toLowerCase().indexOf(awayTemaName) === 0) {
          awayIndex = index;
        }
      }
      // if(data[0].Name.indexOf("Draw") > -1)
      return {
        length: data.length,
        marketId: marketId,
        special: special,
        data: [
          homeIndex > -1 ? { ...data[homeIndex], Price: [{ ...data[homeIndex].Price[0] }] }
            :
            {
              Price: [{ Price: "-" }],
              Name: homeTemaName +
                ` ${!isEmpty(data[0].BaseLine) ?
                  (Number(data[0].BaseLine) > 0 ?
                    "(+" + data[0].BaseLine + ")"
                    : "(" + data[0].BaseLine + ")")
                  : ""} `
            },
          drawIndex > -1 ? { ...data[drawIndex], Price: [{ ...data[drawIndex].Price[0] }] }
            :
            {
              Price: [{ Price: "-" }],
              Name: "무 " +
                `- ${!isEmpty(data[0].BaseLine) ?
                  (Number(data[0].BaseLine) > 0 ?
                    "(" + homeTemaName + " +" + data[0].BaseLine + ")"
                    : "(" + data[0].BaseLine + ")")
                  : ""} `
            },
          awayIndex > -1 ? { ...data[awayIndex], Price: [{ ...data[awayIndex].Price[0] }] }
            :
            {
              Price: [{ Price: "-" }],
              Name: awayTemaName +
                ` ${!isEmpty(data[0].BaseLine) ?
                  (Number(data[0].BaseLine) > 0 ?
                    "(-" + data[0].BaseLine + ")"
                    : "(+" + Math.abs(data[0].BaseLine) + ")")
                  : ""} `
            }
        ]
      };
    }
    // ----------------- 승무패와 같이 베트가 3개인 마켓에서 베트가 2개일때 하나를 넣어주기 끝 --------------
    else {
      return {
        length: data.length,
        marketId: marketId,
        special: special,
        data: [
          { ...data[0], Price: [{ ...data[0].Price[0] }] },
          lastIndex === 1
            ? { Price: [{ Price: baseLine ? fixedBaseLine : "VS" }] }
            : { ...data[1], Price: [{ ...data[1].Price[0] }] },
          { ...data[lastIndex], Price: [{ ...data[lastIndex].Price[0] }] }
        ]
      };
    }
  };

  if (isBetGame !== true) {
    // if(window.apiType !== 'odds')
    data.Bets.sort((a, b) => nameNumber(a.Name, originSportslist) - nameNumber(b.Name, originSportslist));
    // if(data.Bets[0].Name.includes("Over") || data.Bets[0].Name.includes("Under"))
    // if(data.Bets[0].Name.includes("X"))
  }

  let updatedData = [];

  if (isEmpty(data.Bets[0].BaseLine)) {
    if (data.Bets.length < 3) updatedData = [GetFixedList(data.Bets)];
    else if (data.Bets.length === 3) {
      if (type === "prematch")
        // 프리매치페이지에서는 ...
        updatedData = [GetFixedList(data.Bets)];
      updatedData = [GetFixedList(data.Bets, false, data.Bets[0].Id)];
      // //console.log(data.Bets[0].Id)
    } else {
      // if (type !== 'prematch') // 프리매치페이지에서는 정확한 스코어 현시 해주지 않죠.
      // ----------- 정확한 스코어에서 3개 구간으로 나뉘어 홈팀이 승리할 스코어, 무승부, 원정팀이 승리할 스코어로 보여주기 ----------------
      if (marketId == "45") {
        let homes = [], draws = [], aways = [];
        if (!isEmpty(originSportslist.Fixture.Participants) &&
          (originSportslist.Fixture.Participants.length === 2)) {
          homes = data.Bets.filter(item => item.Name.toLowerCase().indexOf(originSportslist.Fixture.Participants[0].name.toLowerCase()) === 0);
          homes.sort((a, b) => {
            let aStrings = a.Name.split(" ");
            let aValue = 0, bValue = 0;
            if (!isEmpty(aStrings[aStrings.length - 1])) {
              aValue = Number(aStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(aStrings[aStrings.length - 1].split("-")[1]);
            }
            let bStrings = b.Name.split(" ");
            if (!isEmpty(bStrings[aStrings.length - 1])) {
              bValue = Number(bStrings[bStrings.length - 1].split("-")[0]) * 1000 + Number(bStrings[bStrings.length - 1].split("-")[1]);
            }
            return aValue - bValue;
          })
          draws = data.Bets.filter(item => item.Name.toLowerCase().indexOf("draw") === 0);
          draws.sort((a, b) => {
            let aStrings = a.Name.split(" ");
            let aValue = 0, bValue = 0;
            if (!isEmpty(aStrings[aStrings.length - 1])) {
              aValue = Number(aStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(aStrings[aStrings.length - 1].split("-")[1]);
            }
            let bStrings = b.Name.split(" ");
            if (!isEmpty(bStrings[bStrings.length - 1])) {
              bValue = Number(bStrings[bStrings.length - 1].split("-")[0]) * 1000 + Number(bStrings[bStrings.length - 1].split("-")[1]);
            }
            return aValue - bValue;
          })
          aways = data.Bets.filter(item => item.Name.toLowerCase().indexOf(originSportslist.Fixture.Participants[1].name.toLowerCase()) === 0);
          aways.sort((a, b) => {
            let aStrings = a.Name.split(" ");
            let aValue = 0, bValue = 0;
            if (!isEmpty(aStrings[aStrings.length - 1])) {
              aValue = Number(aStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(aStrings[aStrings.length - 1].split("-")[1]);
            }
            let bStrings = b.Name.split(" ");
            if (!isEmpty(bStrings[bStrings.length - 1])) {
              bValue = Number(bStrings[bStrings.length - 1].split("-")[0]) * 1000 + Number(bStrings[bStrings.length - 1].split("-")[1]);
            }
            return aValue - bValue;
          })
          homes = homes.splice(0, 10).sort((a, b) => b.Price[0].Price - a.Price[0].Price);
          draws = draws.splice(0, 10).sort((a, b) => b.Price[0].Price - a.Price[0].Price);
          aways = aways.splice(0, 10).sort((a, b) => b.Price[0].Price - a.Price[0].Price);
          let maxLength = 0;
          maxLength = Math.max(homes.length, draws.length, aways.length);
          let betsData = [];
          for (let index = 0; index < maxLength; index++) {
            let homeNameArray = isEmpty(homes[index]) ? [] : homes[index].Name.split(" ");
            let drawNameArray = isEmpty(draws[index]) ? [] : draws[index].Name.split(" ");
            let awayNameArray = isEmpty(aways[index]) ? [] : aways[index].Name.split(" ");

            let homeName = homeNameArray[homeNameArray.length - 1]
            let drawName = drawNameArray[drawNameArray.length - 1]
            let awayName = awayNameArray[awayNameArray.length - 1]

            let awayNameRevert = isEmpty(awayName) ? "" : awayName.split("-")[1] + "-" + awayName.split("-")[0]

            let betData = homes[index] || draws[index] || aways[index]
            updatedData.push(
              GetFixedList([
                index < homes.length ? { ...homes[index], Name: homeName } : { Price: [{ Price: "-" }], Name: "-" },
                index < draws.length ? { ...draws[index], Name: drawName } : { Price: [{ Price: "-" }], Name: "-" },
                index < aways.length ? { ...aways[index], Name: awayNameRevert } : { Price: [{ Price: "-" }], Name: "-" },
              ], false, betData.Id))
          }
        }
      }
      // else if(marketId == "879" || marketId == "11" || marketId == "630" || marketId == "881"){ //marketId == "886" || 
      //   let homes = [], draws = [], aways = [];
      //   if(!isEmpty(originSportslist.Fixture.Participants) && 
      //       (originSportslist.Fixture.Participants.length === 2)){
      //       homes =  data.Bets.filter(item => item.Name.toLowerCase().indexOf(originSportslist.Fixture.Participants[0].name.toLowerCase()) > -1);
      //       homes.sort((a, b) => {
      //         let aStrings = a.Name.split(" ");
      //         let aValue = 0, bValue = 0;
      //         if(!isEmpty(aStrings[aStrings.length - 1])){
      //           aValue = Number(aStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(aStrings[aStrings.length - 1].split("-")[1]);
      //         }
      //         let bStrings = b.Name.split(" ");
      //         if(!isEmpty(bStrings[aStrings.length - 1])){
      //           bValue = Number(bStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(bStrings[bStrings.length - 1].split("-")[1]);
      //         }
      //         return aValue - bValue;
      //       })
      //       draws =  data.Bets.filter(item => item.Name.toLowerCase().indexOf("draw") > -1);
      //       draws.sort((a, b) => {
      //         let aStrings = a.Name.split(" ");
      //         let aValue = 0, bValue = 0;
      //         if(!isEmpty(aStrings[aStrings.length - 1])){
      //           aValue = Number(aStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(aStrings[aStrings.length - 1].split("-")[1]);
      //         }
      //         let bStrings = b.Name.split(" ");
      //         if(!isEmpty(bStrings[aStrings.length - 1])){
      //           bValue = Number(bStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(bStrings[bStrings.length - 1].split("-")[1]);
      //         }
      //         return aValue - bValue;
      //       })
      //       aways =  data.Bets.filter(item => item.Name.toLowerCase().indexOf(originSportslist.Fixture.Participants[1].name.toLowerCase()) > -1);
      //       aways.sort((a, b) => {
      //         let aStrings = a.Name.split(" ");
      //         let aValue = 0, bValue = 0;
      //         if(!isEmpty(aStrings[aStrings.length - 1])){
      //           aValue = Number(aStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(aStrings[aStrings.length - 1].split("-")[1]);
      //         }
      //         let bStrings = b.Name.split(" ");
      //         if(!isEmpty(bStrings[aStrings.length - 1])){
      //           bValue = Number(bStrings[aStrings.length - 1].split("-")[0]) * 1000 + Number(bStrings[bStrings.length - 1].split("-")[1]);
      //         }
      //         return aValue - bValue;
      //       })
      //       let maxLength = 0;
      //       maxLength = Math.max(homes.length, draws.length, aways.length);
      //       let betsData = [];
      //       for (let index = 0; index < maxLength; index++) { 
      //         let betData = homes[index] || draws[index] || aways[index]
      //         updatedData.push(
      //           GetFixedList([ 
      //             index < homes.length ? homes[index] : { Price: [{ Price: "-" }], Name: "-" }, 
      //             index < draws.length ? draws[index] : { Price: [{ Price: "-" }], Name: "-" }, 
      //             index < aways.length ? aways[index] : { Price: [{ Price: "-" }], Name: "-" }, 
      //          ], false, betData.Id))
      //       }
      //   }
      // }
      // ----------- 정확한 스코어에서 3개 구간으로 나뉘어 홈팀이 승리할 스코어, 무승부, 원정팀이 승리할 스코어로 보여주기 끝 ----------------
      else {
        for (let i = 2; i < data.Bets.length; i += 3) {
          // 정확한 스코어 ... 마켓개수 4개이상
          updatedData.push(
            GetFixedList(
              [data.Bets[i - 2], data.Bets[i - 1], data.Bets[i]],
              false,
              data.Bets[0].Id
            )
          );
        }
        // //console.log(updatedData)
      }
    }
  }

  if (isEmpty(data.Bets[0].BaseLine)) {
    if (data.Bets.length <= 3) updatedData = [GetFixedList(data.Bets)];
  } else {
    const sortFunc = (a, b) => {
      let BaseLineA = a.BaseLine;
      let BaseLineB = b.BaseLine;
      // //console.log(BaseLineA.toString().split(" "), BaseLineB)
      // debugger
      if (
        (!isNaN(Number(BaseLineA)) ||
          !isNaN(Number(BaseLineA.split(" ")[0]))) &&
        (!isNaN(Number(BaseLineB)) || !isNaN(Number(BaseLineB.split(" ")[0])))
      ) {
        BaseLineA = Number(BaseLineA.toString().split(" ")[0]);
        BaseLineB = Number(BaseLineB.toString().split(" ")[0]);
        return BaseLineA - BaseLineB;
      } else return a.BaseLine > b.BaseLine ? 1 : -1;
    };

    data.Bets.sort(sortFunc);

    let currentBase,
      baseArray = [];
    for (let i = 0; i < data.Bets.length; i++) {
      if (baseArray.findIndex(item => item === data.Bets[i].BaseLine) >= 0)
        continue;
      else currentBase = data.Bets[i].BaseLine;

      // updatedData.push(GetFixedList(dataGroup, true));

      // 오즈에서는 마켓 baseline이 여러개인거 없음
      updatedData.push(GetFixedList(data.Bets, true));
      baseArray.push(currentBase);
    }
  }

  return updatedData;
};

export const countBetsData = data => {
  if (isEmpty(data) || isEmpty(data.Markets)) return 0;

  let cnt = 0;

  const eachMarkets = bet =>
    !bet.Bets.length
      ? 0
      : bet.Bets[0].BaseLine === null
        ? 1
        : bet.Bets.length / 2;

  for (let i = 0; i < data.Markets.length; i++)
    cnt += eachMarkets(data.Markets[i].Providers[0]);

  return cnt;
};

export const getCrossOdd = data => {
  if (isEmpty(data) || isEmpty(data.Markets)) return [];

  const marketIndex = data.Markets.findIndex(
    item => item.Id === 52 || item.Id === 1
  );
  if (marketIndex < 0) return {};
  const Bets = data.Markets[marketIndex].Providers[0].Bets;

  // let resBets
  if (window.apiType === "lsports")
    Bets.sort((a, b) => nameNumber(a.Name) - nameNumber(b.Name));

  if (Bets.length === 3) return Bets;
  else if (Bets.length === 2) return [Bets[0], { Price: "VS" }, Bets[1]];
  else return //console.log("getCrossOdd error: ", data.key);
};

export const GetFilteredData = (data, filterId) => {
  if (filterId === "all") return data;

  let resData = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].Fixture.League.sport.Id === filterId) resData.push(data[i]);
  }

  return resData;
};

export const fixedDateString = date => {
  return moment(new Date(date)).format("YY-MM-DD HH:mm");
};

export const getFixedScore = score => {
  if (
    isEmpty(score) ||
    isEmpty(score.Scoreboard) ||
    isEmpty(score.Scoreboard.Results)
  )
    return [0, 0];
  const results = score.Scoreboard.Results;
  const homeIndex = results.findIndex(item => item.Position === "1");
  const awayIndex = results.findIndex(item => item.Position === "2");
  return [
    homeIndex >= 0 ? results[homeIndex].Value : 0,
    awayIndex >= 0 ? results[awayIndex].Value : 0
  ];
};

// ----------------------------- Infinite Scroll --------------------------------------------------  //

let isloadList = false;
let preScrollPos = 0;
export const pcSportScrollHandler = () => {
  const pageData = store.getState().page;
  if (($('#scroll_main')[0].scrollHeight - 20) <= $('#scroll_parent')[0].clientHeight + $('#scroll_parent')[0].scrollTop) {
    if (!pageData.loading &&
      pageData.totalPage > 1 &&
      !isloadList
    ) {
      isloadList = true;
      if (pageData.curPage < pageData.totalPage) {
        store.dispatch(pageActions.setScrollDirection(false));
        store.dispatch(pageActions.setPageData({ page: pageData.curPage + 1, totalPage: pageData.totalPage }));
        if (pageData.curPage >= 3) {
          $('#scroll_parent').css({ "overflow": "hidden" });
          setTimeout(function () {
            $('#scroll_parent')[0].scrollTop = $('#scroll_main')[0].scrollHeight * 1 / 2 - $('#scroll_parent')[0].clientHeight / 2;
            $('#scroll_parent').css({ "overflow": "auto" });
          }, 1000);
        }
      }
    }
  }
  else if ($('#scroll_parent')[0].scrollTop <= 20 && preScrollPos > 20) {
    if (!pageData.loading &&
      pageData.totalPage > 1 &&
      !isloadList
    ) {
      isloadList = true;
      if (pageData.curPage < pageData.totalPage) {
        let curPage = pageData.curPage - 1;
        if (curPage < 1)
          return;
        if (!pageData.isUp) {
          curPage = pageData.curPage - 3;
        }
        store.dispatch(pageActions.setPageData({ page: curPage, totalPage: pageData.totalPage }));
        store.dispatch(pageActions.setScrollDirection(true));
        if (pageData.curPage >= 3) {
          $('#scroll_parent').css({ "overflow": "hidden" });
          setTimeout(function () {
            $('#scroll_parent')[0].scrollTop = $('#scroll_parent')[0].scrollHeight * 1 / 3;
            $('#scroll_parent').css({ "overflow": "auto" });
          }, 1000);
        }
      }
    }
  }
  else {
    isloadList = false;
  }
  preScrollPos = $('#scroll_parent')[0].scrollTop;
}

export const mobileSportsWindowScrollHandler = () => {
  const pageData = store.getState().page
  if (window.document.body.scrollHeight > 938 &&
    (document.body.scrollHeight - 20) <= window.scrollY + window.innerHeight) {
    if (!pageData.loading &&
      pageData.totalPage > 1 &&
      !isloadList) {
      isloadList = true;
      if (pageData.curPage < pageData.totalPage) {
        store.dispatch(pageActions.setScrollDirection(false));
        store.dispatch(pageActions.setPageData({ page: pageData.curPage + 1, totalPage: pageData.totalPage }));
        if (pageData.curPage >= 3) {
          setTimeout(function () { window.scrollTo(0, document.body.scrollHeight * 2 / 3 - window.innerHeight / 2) }, 1000);
          //window.scrollBy(0, document.body.scrollHeight * -1 / 4);
        }
      }
    }
  }
  else if (window.scrollY <= 20 && preScrollPos > 20) {
    if (!pageData.loading &&
      pageData.totalPage > 1 &&
      !isloadList
    ) {
      isloadList = true;
      if (pageData.curPage < pageData.totalPage) {
        let curPage = pageData.curPage - 1;
        if (!pageData.isUp) {
          curPage = pageData.curPage - 3;
        }
        if (curPage < 1)
          return;
        store.dispatch(pageActions.setPageData({ page: curPage, totalPage: pageData.totalPage }));
        store.dispatch(pageActions.setScrollDirection(true));
        if (pageData.curPage >= 3) {
          //window.scrollY = window.scrollY * 1 / 4 ;
          setTimeout(function () { window.scrollTo(0, document.body.scrollHeight * 1 / 3) }, 1000);
          //window.scrollBy(0, document.body.scrollHeight * 1 / 4);
        }
      }
    }
  }
  else {
    isloadList = false;
  }
  preScrollPos = window.scrollY;
  // }
  // export const mobileSportsWindowScrollHandler = () => {
  //   const pageData = store.getState().page
  //   if (window.document.body.scrollHeight > 938 &&
  //     (document.body.scrollHeight - 20) <= window.scrollY + window.innerHeight) {
  //     if (!pageData.loading &&
  //       pageData.totalPage > 1 &&
  //       !isloadList) {
  //       isloadList = true;
  //       if (pageData.curPage < pageData.totalPage) {
  //         store.dispatch(SetCurrentPage({ page: pageData.curPage + 1, totalPage: pageData.totalPage }));
  //       }
  //     }
  //   }
  //   else {
  //     isloadList = false;
  //   }
}

export const windowscrollHandler = () => {
  const pageData = store.getState().page
  //console.log('document.body.scrollHeight: ', document.body.scrollHeight);
  if (window.document.body.scrollHeight > 938 &&
    (document.body.scrollHeight - 20) <= window.scrollY + window.innerHeight) {
    if (!pageData.loading &&
      pageData.totalPage > 1 &&
      !isloadList) {
      isloadList = true;
      //   if (pageData.curPage < pageData.totalPage) {
      //     store.dispatch(SetPerPage(pageData.perPage + 10));
      // }
    }
  }
  else {
    isloadList = false;
  }
}

export const pcScrollHandler = () => {
  if (($('#scroll_main')[0].scrollHeight - 20) <= $('#scroll_parent')[0].clientHeight + $('#scroll_parent')[0].scrollTop) {
    const pageData = store.getState().page;
    if (!pageData.loading &&
      pageData.totalPage > 1 &&
      !isloadList
    ) {
      isloadList = true;
      // if (pageData.curPage < pageData.totalPage) {
      //   store.dispatch(SetPerPage(pageData.perPage + 10));
      // }
    }
  }
  else {
    isloadList = false;
  }
}

export const infiniteScroll = () => {
  $(document).ready(() => {
    window.addEventListener("scroll", windowscrollHandler);

    if (!isEmpty($('#scroll_parent')[0])) {
      $('#scroll_parent')[0].addEventListener("scroll", pcScrollHandler);
    }
  })

}


export const clearInfiniteScroll = (type) => {
  //console.log('type: ', type);
  $(document).ready(() => {
    $('html').scrollTop(0)
    if (!isEmpty($('#scroll_parent')[0])) {
      $('#scroll_parent').scrollTop(0)
    }
  });
}


// ----------------------------------- Infinite End ----------------------------------- //

//--------------총판페이지이동--------------------------//
export const getAdmin = () => {
  axios
    .post(api_url + "/api/Users/getAdminInfo", {})
    .then(res => {
      // //console.log(res.data);
      showMessage(res.data.message)
      // const token = res.data.token;
      if (res.data.status == 0) {
        // const token = localStorage.getItem("adminToken");
        const token = res.data.token;
        const adminUrl = res.data.adminUrl + "/login_client?token=" + token;
        // //console.log(token)
        setTimeout(() => {
          var newWindow = window.open(adminUrl, '_blank');
          //console.log(newWindow);
        })
        // var newWindow = window.open();
        // // newWindow.document.cookie = `toto_L_token=${token}; expires=Thu, 18 Dec 2100 12:00:00 UTC`;
        // newWindow.location.href = adminUrl;
        // //console.log(newWindow);
      }

    })
    .catch(err => {
      console.error('err: ', err);
      if (store.getState().user.isAuthenticated)
        return store.dispatch(userActions.logoutUser());
      //console.log(err);
    });
};

//----------------------------로그인아이디중복체그---------------------------------//

export const checkLoginId = params => {
  // //console.log(params);
  return new Promise(resolve =>
    axios
      .post(api_url + "/api/Users/checkLoginId", { loginId: params })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error('err: ', err);
        //console.log(err);
        if (store.getState().user.isAuthenticated)
          return store.dispatch(userActions.logoutUser());
        //console.log(err);
        // if (err.response && err.response.status === 401) {
        //     props.logoutUser();
        // } else {
        toastr.warning("잠시후 다시 시도해주세요.");
        // }
      })
  );
};


//----------------------------------Slot-Casino---------------------------------------

export const getLobbylist = (params, isshowMessage = true) => {
  return new Promise(resolve => {
    request
      .post(api_url + `/api/${params.type}/getLobbyList`, params)
      .then(res => {
        if (!isEmpty(res.message)) {
          store.dispatch(pageActions.onSetPageLoading(false));
          if (isshowMessage) {
            showMessage(res.message);
          }
        }
        if (res.status === 1) {
          if (!isEmpty(res.lobbyList)) {
            resolve(res);
            store.dispatch(pageActions.onSetPageLoading(false));
          }
        } else if (res.status == 2) {
          store.dispatch(pageActions.onSetPageLoading(false));
          gameOpen(res, params.apiName);
          window.location.href = window.location.origin;
        }
      })
  });
};

export const getLoginLobbylist = (params, isshowMessage = true) => {
  return new Promise(resolve => {
    axios
      .post(api_url + `/api/${params.type}/getLoginLobbyList`, { "status": 0 })
      .then(res => {
        if (!isEmpty(res.message)) {
          store.dispatch(pageActions.onSetPageLoading(false));
          if (isshowMessage) {
            showMessage(res.message);
          }
        }
        if (!isEmpty(res.lobbyList)) {
          resolve(res);
          store.dispatch(pageActions.onSetPageLoading(false));
        }
      })
  });
};

export const getLoginLobbySlotlist = (params, isshowMessage = true) => {
  return new Promise(resolve => {
    request
      .post(api_url + `/api/${params.type}/getLoginLobbyList`, { "status": 0 })
      .then(res => {
        if (!isEmpty(res.message)) {
          store.dispatch(pageActions.onSetPageLoading(false));
          if (isshowMessage) {
            showMessage(res.message);
          }
        }
        if (!isEmpty(res.lobbyList)) {
          resolve(res);
          store.dispatch(pageActions.onSetPageLoading(false));
        }
      })
  });
};


export const getGameList = params => {
  return new Promise(resolve => {
    request
      .post(api_url + `/api/${params.type}/getGameList`, params)
      .then(res => {
        if (res.status) {
          if (!isEmpty(res.result)) {
            resolve(res);
          }
        }
        else {
          if (!isEmpty(res.message)) {
            store.dispatch(pageActions.onSetPageLoading(false));
            toastr.warning(res.message);
          }
        }
      })
  });
};

export const getGameLaunch = params => {
  request
    .post(api_url + `/api/${params.type}/gameLaunch`, {
      ...params,
      platform: isMobilePage() ? "MOBILE" : "PC"
    })
    .then(res => {
      store.dispatch(pageActions.onSetPageLoading(false));

      if (!isEmpty(res.status) && res.status == 1) return;

      if (!isEmpty(res.message)) {
        toastr.warning(res.message)
      };

      if (!isEmpty(res.err)) {
        toastr.warning(res.err)
      };

      if (isEmpty(res.result)) return;

      gameOpen(res, params.apiName);
    })
};

export const getSlotCasinoTopName = type => {
  switch (type) {
    case "Casino":
      return "LIVE CASINO";

    case "hotelcasino":
      return "HOTEL CASINO";

    case "Slot":
      return "SLOT GAME";

    default:
      return type;
  }
};

export const getTitle = (list) => {
  if (!isEmpty(list.names)) {
    return list.names.ko;
  }
  else {
    return "";
  }
};

export const getImages = (lists, imgApi) => {
  return new Promise(resolve => {
    let images = [];
    lists.forEach(list => {
      let slotImg = new Image();
      slotImg.src = getSlotImgPath(list, imgApi);
      images.push(slotImg);
    });
    //console.log(images);
    resolve(images);
  });
};

export const getlistImgPath = (list, api) => {
  //console.log("-----------------------Union List--------------------------", list);
  return `${resource_url}/images/casino-slot/${list.img}?version=${Date.now()}`;
};

export const getlistOverImgPath = (list, api) => {
  // console.log("-----------------------Union List--------------------------", list);
  return `${resource_url}/images/casino-slot-on/${list.img}?version=${Date.now()}`;
};

export const getSlotImgPath = (list, api) => {
  // //console.log("-----------------------Union List--------------------------", list);
  if (!isEmpty(list)) {
    return list.image;
  }
  else {
    return "";
  }
};

export const gameOpen = (list, api) => {
  const user = store.getState().user.data;
  if (isEmpty(user)) return toastr.warning("로그인하시고 이용해주세요.");

  var width = 1920;
  var height = 1219;
  var toppx = window.screen.height / 2 - height / 2;
  var leftpx = window.screen.width / 2 - width / 2;

  var popup_name = "newWindow";
  //console.log("========팝업 링크 :", list.result.link);
  window.windowHandle = window.open(list.result.link, popup_name, "top=" + toppx + ", left=" + leftpx + ", width=" + width + ", height=" + height + " popup");
  //console.log("========팝업 :", window.windowHandle);
  if (!window.windowHandle) {
    toastr.warning("팝업이 블록되었습니다.");
  }
};

//-------------------------------Minigame--------------------------------------------//

export const getMinigameUrl = type => {
  // //console.log(type)
  let url = "";
  let params = { gameName: "" };
  switch (type) {
    case 1:
      params = { gameName: `eos_powerball${type}` };
      break;

    case 2:
      params = { gameName: `eos_powerball${type}` };
      break;

    case 3:
      params = { gameName: `eos_powerball${type}` };
      break;

    case 4:
      params = { gameName: `eos_powerball${type}` };
      break;

    case 5:
      params = { gameName: `eos_powerball${type}` };
      break;

    case "1":
      params = { gameName: `eos_powerball${type}` };
      break;

    case "2":
      params = { gameName: `eos_powerball${type}` };
      break;

    case "3":
      params = { gameName: `eos_powerball${type}` };
      break;

    case "4":
      params = { gameName: `eos_powerball${type}` };
      break;

    case "5":
      params = { gameName: `eos_powerball${type}` };
      break;

    default:
      params = { gameName: type };
      break;
  }
  // //console.log(params)

  return new Promise(resolve => {
    axios
      .post(api_url + "/api/minigame/getGameList", params) // eos_powerball${powerBalltype}
      .then(res => {
        //console.log(params, res.data.gameUrl);
        url = res.data.gameUrl;
        // store.dispatch(setMiniCheck(res.data.status));
        // if(!res.data.status) toastr.warning("점검중입니다.")
        //console.log(url);
        resolve(url);
      })
      .catch(err => {
        console.error('err: ', err);
        if (store.getState().user.isAuthenticated)
          return store.dispatch(userActions.logoutUser());
        if (err.response && err.response.status === 401) return;
        // store.dispatch(logoutUser());
        else toastr.warning("잠시후 다시 시도해주세요.");
        //console.log(err);
      });
  });

  // return url;
};

export const getId = type => {
  switch (type) {
    case 1:
      return "powerball_iframe_parent";

    case 2:
      return "powerball_iframe_parent";

    case 3:
      return "powerball_iframe_parent";

    case 4:
      return "powerball_iframe_parent";

    case 5:
      return "powerball_iframe_parent";

    case "1":
      return "powerball_iframe_parent";

    case "2":
      return "powerball_iframe_parent";

    case "3":
      return "powerball_iframe_parent";

    case "4":
      return "powerball_iframe_parent";

    case "5":
      return "powerball_iframe_parent";

    default:
      return "gopick_iframe_parent";
  }
};

export const getMiniType = type => {
  switch (type) {
    case 1:
      return "EOSPowerball1";

    case 2:
      return "EOSPowerball2";

    case 3:
      return "EOSPowerball3";

    case 4:
      return "EOSPowerball4";

    case 5:
      return "EOSPowerball5";

    case "1":
      return "EOSPowerball1";

    case "2":
      return "EOSPowerball2";

    case "3":
      return "EOSPowerball3";

    case "4":
      return "EOSPowerball4";

    case "5":
      return "EOSPowerball5";

    default:
      return type;
  }
};

export const ConvertMinigameType = type => {
  switch (type) {
    case "1":
      return "EOS 1분";
    case "2":
      return "EOS 1분";
    case "3":
      return "EOS 1분";
    case "4":
      return "EOS 1분";
    case "5":
      return "EOS 1분";
    case "EVOPowerball":
      return "EVO파워볼";
    case "nuguri":
      return "너구리";
    case "soccer":
      return "축구";
    case "baseball":
      return "야구";
    case "squidgame":
      return "오징어";
  }
}
export const convertGameSetting = (value, type = window.apiType) => {
  // if(type)
  switch (value) {
    case "승무패":
      return "home draw away";
    case "승패":
      return "home away";
    case "오버언더":
      return "over/under";
    case "핸디캡":
      return "handicap";
    case "홀짝":
      return "odd/even";
    default:
      return value;
  }
};

export const fixedNumber = number => {
  if (isNaN(Number(number))) return "VS";
  return Number(number).toFixed(2);
};

export const convertTypeString = type => {
  switch (type) {
    case "waiting":
      return "대기중";
    case "bet":
      return "대기중"; // slot & casino
    case "lose":
      return "낙첨";
    case "win":
      return "당첨";
    case "refund":
      return "취소됨";
    case "cancel":
      return "취소됨"; // slot & casino
    case "jok":
      return "적특";
    case "draw":
      return "무승부";
    case "tie":
      return "타이";
    default:
      return type;
  }
};

export const convertResultType = type => {
  switch (type) {
    case 0:
      return "대기중";
    case 1:
      return "낙첨";
    case 2:
      return "취소됨";
    case 3:
      return "당첨";
    case 4:
      return "적특";
    case 5:
      return "반당첨";
    case 6:
      return "반낙첨";
    case 7:
      return "무승부";
    default:
      return "대기중";
  }
};

export const convertSportsResultType = type => {
  switch (type) {
    case 0:
    case 5:
      return "적특";
    case 1:
      return "대기";
    case 2:
      return "당첨";
    case 3:
      return "낙첨";
    default:
      return "대기중";
  }
};

export const convertSportsResultClass = type => {
  switch (type) {
    case 0:
    case 5:
      return "yellow-color";
    case 1:
      return "green-color";
    case 2:
      return "blue-color";
    case 3:
      return "red-color";
    default:
      return "green-color";
  }
};

export const convertClass = type => {
  switch (type) {
    case "waiting":
      return "green-color";
    case "bet":
      return "green-color"; // slot & casino
    case "lose":
      return "red-color";
    case "win":
      return "blue-color";
    case "refund":
      return "grey-color";
    case "cancel":
      return "grey-color"; // slot & casino
    case "jok":
      return "yellow-color";
    case "draw":
      return "yellow-color";
    case 0:
      return "green-color";
    case 1:
      return "red-color";
    case 2:
      return "grey-color";
    case 3:
      return "blue-color";
    case 4:
      return "yellow-color";
    case 5:
      return "half-blue-color";
    case 6:
      return "half-red-color";
    case 7:
      return "draw-color";
    default:
      return "_";
  }
};

export const convertBetGameClass = type => {
  switch (type) {
    case "waiting":
      return "green-color";
    case "bet":
      return "green-color"; // slot & casino
    case "lose":
      return "red-color";
    case "win":
      return "blue-color";
    case "refund":
      return "grey-color";
    case "cancel":
      return "grey-color"; // slot & casino
    case "jok":
      return "yellow-color";
    case "draw":
      return "yellow-color";
    case 0:
      return "green-color";
    case 1:
      return "red-color";
    case 2:
      return "blue-color";
    case 3:
      return "grey-color";
    case 4:
      return "yellow-color";
    case 5:
      return "half-blue-color";
    case 6:
      return "half-red-color";
    case 7:
      return "draw-color";
    default:
      return "_";
  }
};

export const convertBetGameResultType = type => {
  switch (type) {
    case 0:
      return "대기중";
    case 1:
      return "낙첨";
    case 2:
      return "당첨";
    case 3:
      return "취소됨";
    default:
      return type;
  }
};

//--------------------------------Sprots---------------------------------------//

export const convertNameKor = list => {
  // //console.log(list);
  if (isEmpty(list)) return "";
  if (!isEmpty(list.name_kor)) return list.name_kor;
  else if (!isEmpty(list.name_front)) return list.name_front;
  else if (!isEmpty(list.name)) return list.name;
};

export const getMarketRate = list => {
  // //console.log(name)
  switch (list.number) {
    case 0:
      return Number(list.ratio1).toFixed(2);

    case 1:
      return Number(list.ratio2).toFixed(2);

    case 2:
      return Number(list.ratio3).toFixed(2);

    default:
      return 1;
  }
};

export const getBetCard = (list, type) => {
  switch (type) {
    case "virtual":
      return {
        gameType: convertGameType(type),
        sportName: convertBetNameKor(list.sportType),
        sportImg: "",
        leagueName: convertBetLeagueNameKor(list.sportType, list.league),
        leagueImg: "",
        time: dateFormation(list.date, 5),
        homeTeam: list.home,
        awayTeam: list.away,
        marketName: convertBetMarketName(list.cate1),
        marketDetail: convertBetMarketDetail(list),
        baseline:
          list.cate1 === "underover" || list.cate1 === "handicap"
            ? `(${list.ratio2})`
            : "",
        rate: fixedNumber(getMarketRate(list)),
        bet_id: list.betId,
        eventKey: list.eventKey
      };

    default:
      let priceValue = 0;
      // console.log(list)
      const sportsList = store.getState().sports.data;
      const activeId = store.getState().sports.activeId;
      // console.log('activeId: ', activeId, list.eventKey);
      const sportsMarketInfo = store.getState().sports.sportsMarketInfo;
      const keyIndex = sportsList.findIndex(item => item.key === list.eventKey)
      if (keyIndex > -1) {
        let sportsMarket = ((activeId !== list.eventKey) || isEmpty(sportsMarketInfo)) ? sportsList[keyIndex].Markets : sportsMarketInfo.Markets;
        // console.log('sportsMarket: ', sportsMarket);
        const marketIndex = sportsMarket.findIndex(markets => markets.MarketID === list.market.MarketID);
        if (marketIndex > -1) {
          const betIndex = sportsMarket[marketIndex].Providers[0].Bets.findIndex(Betslist => Betslist.Id === list.betId)
          if (betIndex > -1) {
            let selectPrice = sportsMarket[marketIndex].Providers[0].Bets[betIndex].Price[0];
            if (!isEmpty(selectPrice)) {
              priceValue = sportsMarket[marketIndex].Providers[0].Bets[betIndex].Price[0].Price
              if (priceValue !== list.bet.Price[0].Price) {
                let betList = store.getState().bet.bettedItems;
                let newBetList = [...betList]
                list = {
                  ...list,
                  bet: {
                    ...list.bet,
                    Price: [{ Price: priceValue }]
                  }
                }
                let betIndex = newBetList.findIndex(item => item.betId === list.betId)
                newBetList[betIndex] = list;
                store.dispatch(betActions.setBettedItem(newBetList))
              }
            }
          }
        }
        else {
          priceValue = list.bet.Price[0].Price
        }
      }
      else {
        priceValue = list.bet.Price[0].Price
      }
      return {
        gameType: convertGameType(list.Fixture.gameType),
        sportName: convertNameKor(list.Fixture.League.sport),
        sportImg: getImgPath(list),
        leagueName: convertNameKor(list.Fixture.League),
        leagueImg:
          resource_url +
          "/images/locations/" +
          list.Fixture.League.location.image,
        time: dateFormation(list.Fixture.StartDate, 8),
        homeTeam: isEmpty(list.Fixture.Participants)
          ? ""
          : convertNameKor(list.Fixture.Participants[0]),
        awayTeam: isEmpty(list.Fixture.Participants)
          ? ""
          : convertNameKor(list.Fixture.Participants[1]),
        marketName: convertNameKor(list.market),
        marketDetail: getMarketDetail(list, list.bet.Name),
        baseline: "",
        rate: fixedNumber(list.bet.Price[0].Price),

        sportId: list.Fixture.League.sport.sportId,
        eventKey: list.eventKey,
        market_id: list.market.Id,
        provider_id: list.market.Providers[0].Id,
        bet_id: list.betId,
        oddId: list.Fixture.League.sport.oddsId.toString()
      };
  }
};

export const escapeHTML = (str) => {
  let val = '';
  if (typeof str === 'undefined') {
    val = '';
  }
  else {
    val = str;
  }

  const entityMap = {
    "(": "&amleftp;",
    ")": "&amrightp;",
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;',
    // "\\": '\\\\'
  };
  return String(val).replace(/[()]/g, function (s) {
    return entityMap[s];
  });
};

export const unEscapeHTML = (str) => {
  let val = '';
  if (typeof str === 'undefined') {
    val = '';
  }
  else {
    val = str;
  }

  const entityMap = {
    "&amleftp;": "(",
    "&amrightp;": ")",
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    '&quot;': '"',
    '&#39;': "'",
    // "\\": '\\\\'
  };
  Object.keys(entityMap).forEach((key, index) => {
    let patt = new RegExp(key, "gi");
    str = String(str).replace(patt, entityMap[key]);
  });
  return str;
};

// --------------------- 베트 네임 번역 ----------------------- //
export const getMarketDetail = (sportslist = {}, name) => {
  if (isEmpty(name)) return "";

  name = escapeHTML(name);

  let names = {
    under: "언더",
    over: "오버",
    draw: "무",
    " or ": " 또는 ",
    " and ": " 과(와) ",
    even: "짝",
    odd: "홀"
    // "yes": "네",
    // "no": "아니오",
  };

  // ----------------------- 팀 네임 번역 ---------------------- //
  if (
    !isEmpty(sportslist.Fixture.Participants) &&
    !isEmpty(sportslist.Fixture.Participants[0].name) &&
    !isEmpty(sportslist.Fixture.Participants[0].name_kor) &&
    !isEmpty(sportslist.Fixture.Participants[1].name) &&
    !isEmpty(sportslist.Fixture.Participants[1].name_kor)
  ) {
    names[escapeHTML(sportslist.Fixture.Participants[0].name).trim()] =
      sportslist.Fixture.Participants[0].name_kor;
    names[escapeHTML(sportslist.Fixture.Participants[1].name).trim()] =
      sportslist.Fixture.Participants[1].name_kor;
  }

  switch (name) {
    case "1":
      return isEmpty(sportslist)
        ? "홈팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[0].name_kor;

    case "2":
      return isEmpty(sportslist)
        ? "원정팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[1].name_kor;

    case "1x":
      return isEmpty(sportslist)
        ? "홈팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[0].name_kor + " 또는 무승부";

    case "1X":
      return isEmpty(sportslist)
        ? "홈팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[0].name_kor + " 또는 무승부";

    case "12":
      return isEmpty(sportslist)
        ? "홈팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[0].name_kor +
            " 또는 " +
            isEmpty(sportslist)
            ? "원정팀"
            : sportslist.Fixture.Participants[1].name_kor;

    case "x2":
      return isEmpty(sportslist)
        ? "원정팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[1].name_kor + " 또는 무승부";

    case "X2":
      return isEmpty(sportslist)
        ? "원정팀"
        : isEmpty(sportslist.Fixture.Participants)
          ? ""
          : sportslist.Fixture.Participants[1].name_kor + " 또는 무승부";

    case "Yes":
      return "네";

    case "No":
      return "아니오";

    case "X":
      return "무";
    default:
      let patt = "";
      Object.keys(names).forEach((key, index) => {
        let patt = new RegExp(key, "i");
        name = String(name).replace(patt, names[key]);
      });
      name = unEscapeHTML(name);
      return name;
  }
};

export const getProvidedMarketIds = sport => {
  // //console.log(Number(sport.Fixture.League.sport.Id))
  switch (Number(sport.Fixture.League.sport.Id)) {
    case 6046: // 축구
      // providerMarkets = soccerProvidedMarketIds.concat();
      providerMarkets = getsoccerProvidedMarketIds(window.apiType);
      providedMarketIds = getsoccerProvidedMarketIds(window.apiType);
      break;
    case 35232: // 아이스 하키
      providerMarkets = getIceHockeylProvidedMarketIds(window.apiType);
      providedMarketIds = getIceHockeylProvidedMarketIds(window.apiType);
      break;
    case 48242: //농구
      providerMarkets = getbasketballProvidedMarketIds(window.apiType);
      providedMarketIds = getbasketballProvidedMarketIds(window.apiType);
      break;
    case 154830: //배구
      providerMarkets = getvolleyballProvidedMarketIds(window.apiType);
      providedMarketIds = getvolleyballProvidedMarketIds(window.apiType);
      break;
    case 154914: //야구
      providerMarkets = getbaseballProvidedMarketIds(window.apiType);
      providedMarketIds = getbaseballProvidedMarketIds(window.apiType);
      break;
    case 154919: //MMA
      providerMarkets = getMMAProvidedMarketIds(window.apiType);
      providedMarketIds = getMMAProvidedMarketIds(window.apiType);
      break;
    case 265917: //탁구
      providerMarkets = getvolleyballProvidedMarketIds(window.apiType);
      providedMarketIds = getvolleyballProvidedMarketIds(window.apiType);
      break;
    case 131506: //미식축구
      providerMarkets = getfootballProvidedMarketIds(window.apiType);
      providedMarketIds = getfootballProvidedMarketIds(window.apiType);
      break;
    case 54094: //테니스
      providerMarkets = getvolleyballProvidedMarketIds(window.apiType);
      providedMarketIds = getvolleyballProvidedMarketIds(window.apiType);
      break;
    case 452674: //크리케트
      providerMarkets = getbaseballProvidedMarketIds(window.apiType);
      providedMarketIds = getbaseballProvidedMarketIds(window.apiType);
      break;

    case 687890: //이스포츠
      providerMarkets = getDefaultEsportsMarketIds(window.apiType);
      providedMarketIds = getDefaultEsportsMarketIds(window.apiType);
      if (sport.Fixture.League.name.includes("Dota")) {
        providerMarkets = getDotaMarkets(window.apiType);
      }
      if (sport.Fixture.League.name.includes("League of Legends")) {
        providerMarkets = getLeagueOfLegendsaMarkets(window.apiType);
      }
      break;

    default:
      providerMarkets = getDefaultProvidedMarketIds(window.apiType);
      providedMarketIds = getDefaultProvidedMarketIds(window.apiType);
      break;
  }
};


export const getMarketLevel = (market, event) => {
  const currentPartId = event.Fixture.currentPartId
  // console.log(providedMarketIds, market);
  for (let i = (providedMarketIds.length - 1); i >= 0; i--) {
    const groupName = providedMarketIds[i].name;
    const groupArr = groupName.replace("/", "|");
    const patt = new RegExp(groupArr, "i")
    let index = providedMarketIds[i].data.indexOf(Number(market.Id));
    if (patt.test(market.name)) {
      if (index < 0) {
        index = MainMarketData.findIndex(mainMarket => mainMarket == market.Id);
      }
      const { live } = store.getState().sports.baselineSetting;
      const liveIndex = isEmpty(live) ? -1 : live.findIndex(element => {
        return element == event.Fixture.League.sport.Id
      });

      if (liveIndex > -1) {
        if (!isEmpty(currentPartId) && (event.Fixture.gameType === "live")) {
          const nowStatus = convertStatusEng(currentPartId);
          const newPatt = new RegExp(nowStatus, "i");
          if (!newPatt.test(market.name)) {
            return "none"
          }
        }
      }
    }
    else {
      if (index < 0) {
        if (groupName === "allMarket") {
          index = MainMarketData.findIndex(mainMarket => mainMarket == market.Id);
        }
      }
    }
    if (index < 0) continue;
    // //console.log(i * 6000 + index, i )
    return i * 6000 + index;
  }
};


export const OrderSortMarkets = (destEvent) => {
  const { pageInfo } = store.getState().page;
  const marketSettings = store.getState().sports.marketSetting;
  const getMarketSetting = (data) => {
    let setting = [];
    if (isEmpty(data)) {
      return []
    }
    else if (pageInfo.sportType === "sport") {
      if (pageInfo.gameType === 'prematch') {
        if (pageInfo.prematchType === 'interior') {
          setting = data.sportKor;
        }
        else {
          setting = data.sportPrematch;
        }
      }
      else {
        setting = data.sportLive;
      }
    }
    else {
      if (pageInfo.gameType === 'prematch') {
        setting = data.esportPrematch;
      }
      else {
        setting = data.esportLive;
      }
    }
    let index = setting.findIndex((item, index) => {
      if (pageInfo.sportType === "sport") {
        return item.sportGameId == destEvent.Fixture.League.sport.Id;
      }
      else {
        return item.sportGameId == destEvent.Fixture.League.sport.oddsId;
      }
    })
    if (index < 0) {
      return [];
    }
    return setting[index].search;
  }

  const marketSetting = getMarketSetting(marketSettings);
  if (isEmpty(marketSetting)) {
    return destEvent;
  }
  else {
    destEvent.Markets.sort((a, b) => {
      const aIndex = marketSetting.findIndex(setting => setting.market_id == a.Id);
      const bIndex = marketSetting.findIndex(setting => setting.market_id == b.Id);
      if (aIndex < 0 || bIndex < 0) {
        return 0;
      }
      else {
        return marketSetting[aIndex].order_value - marketSetting[bIndex].order_value;
      }
    })
  }
  return destEvent;
}

export const getTeamInfo = (sportList, type) => {
  switch (type) {
    case "sports":
      let index = getFirstMarket(sportList.Markets, sportList.Fixture.League.sport.Id);
      let kind_Id = "";
      if (!isEmpty(sportList)) {
        kind_Id = sportList.Fixture.League.sport.type !== "esport" ? sportList.Fixture.League.sport.Id : sportList.Fixture.League.sport.oddsId
      }
      // if (index < 0) {
      //   index = 0;
      // }
      //    debugger
      //    //console.log(sportList);
      // const { rateHome, rateDraw, rateAway } = getHomeDrawAway(
      //   sportList,
      //   window.apiType
      // );
      return {
        marketName: convertNameKor(sportList.Markets[index]),
        //    marketName: convertNameKor(sportList.Markets[0]),
        rateHome: isEmpty(sportList.Markets[index])
          ? ""
          : isEmpty(sportList.Markets[index].Providers[0])
            ? ""
            : isEmpty(sportList.Markets[index].Providers[0].Bets[0])
              ? ""
              : fixedNumber(sportList.Markets[index].Providers[0].Bets[0].Price[0].Price),
        rateDraw: isEmpty(sportList.Markets[index])
          ? ""
          : isEmpty(sportList.Markets[index].Providers[0])
            ? ""
            : sportList.Markets[index].Providers[0].Bets.length === 3
              ? !isEmpty(sportList.Markets[index].Providers[0].Bets[1])
                ? fixedNumber(sportList.Markets[index].Providers[0].Bets[1].Price[0].Price)
                : ""
              : "VS",
        rateAway: isEmpty(sportList.Markets[index])
          ? ""
          : isEmpty(sportList.Markets[index].Providers[0])
            ? ""
            : sportList.Markets[index].Providers[0].Bets.length === 3
              ? !isEmpty(sportList.Markets[index].Providers[0].Bets[2])
                ? fixedNumber(sportList.Markets[index].Providers[0].Bets[2].Price[0].Price)
                : ""
              : !isEmpty(sportList.Markets[index].Providers[0].Bets[1])
                ? fixedNumber(sportList.Markets[index].Providers[0].Bets[1].Price[0].Price)
                : "",
        // rateHome: rateHome,
        // rateDraw: rateDraw,
        // rateAway: rateAway,
        key: sportList.key,
        FixtureId: sportList.FixtureId,
        leagueName: convertNameKor(sportList.Fixture.League),
        sportName: convertNameKor(sportList.Fixture.League.sport),
        locationName: convertNameKor(sportList.Fixture.League.location),
        imgPath: locationUrl + sportList.Fixture.League.location.image,
        sportImg: getImgPath(sportList),
        homeTeam: isEmpty(sportList.Fixture.Participants)
          ? ""
          : convertNameKor(sportList.Fixture.Participants[0]),
        awayTeam: isEmpty(sportList.Fixture.Participants)
          ? ""
          : convertNameKor(sportList.Fixture.Participants[1]),
        time: sportList.Fixture.StartDate,
        countMarket: isEmpty(sportList.MarketCount) ? 0 : sportList.MarketCount,
        liveScoreHome:
          !isEmpty(sportList.Livescore) &&
            !isEmpty(sportList.Livescore.Scoreboard)
            ? sportList.Livescore.Scoreboard.Results[0].Position == "1"
              ? sportList.Livescore.Scoreboard.Results[0].Value
              : sportList.Livescore.Scoreboard.Results[1].Value
            : "",
        liveScoreAway:
          !isEmpty(sportList.Livescore) &&
            !isEmpty(sportList.Livescore.Scoreboard)
            ? sportList.Livescore.Scoreboard.Results[1].Position == "2"
              ? sportList.Livescore.Scoreboard.Results[1].Value
              : sportList.Livescore.Scoreboard.Results[0].Value
            : "",
        liveState: getLiveStatus(sportList),
        marketIndex: index
      };

    case "virtual":
      return {
        homeTeam: "",
        awayTeam: "",
        time: "",
        rateHome: "",
        rateDraw: "",
        rateAway: "",
        countMarket: ""
      };

    default:
      return {
        key: "",
        leagueName: "",
        sportName: "",
        locationName: "",
        imgPath: "",
        homeTeam: "",
        awayTeam: "",
        time: "",
        rateHome: "",
        rateDraw: "",
        rateAway: "",
        countMarket: "",
        liveScoreHome: "",
        liveScoreAway: "",
        liveState: ""
      };
  }
};

export const getBetGameName = (val, type) => {
  if (type === "bet365") {
    let bet = betGames[betGames.findIndex(item => item.id.toLowerCase() == val)];
    if (isEmpty(bet)) return "";
    return bet.name;
  }
  else {
    let bet = lotusgame[lotusgame.findIndex(item => item.id.toLowerCase() == val.toLowerCase())];
    if (isEmpty(bet)) return "";
    return bet.name;
  }
};

export const getBetGameLeague = (val, name, type) => {
  if (type === "bet365") {
    let bet = betGames[betGames.findIndex(item => item.id.toLowerCase() == val)];
    if (isEmpty(bet)) return "";
    return bet.leagues[
      bet.leagues.findIndex(item => item.id.toLowerCase() == name)
    ].name;
  } else {
    let bet = lotusgame[lotusgame.findIndex(item => item.id.toLowerCase() == val.toLowerCase())];
    if (isEmpty(bet)) return "";
    return bet.leagues[
      bet.leagues.findIndex(item => item.id.toLowerCase() == name.toLowerCase())
    ].name;
  }
  // debugger

};

export const convertBetMarketName = name => {
  // //console.log(name)
  switch (name) {
    case "wintielose":
      return "승무패";

    case "underover":
      return "오버언더";

    case "handicap":
      return "핸디캡";

    case "homeaway":
      return "승패";

    case "oneteam":
      return "플레이어이름";

    default:
      return name;
  }
};

export const convertBetHistoryDetail = list => {
  // //console.log(name)
  switch (list.oddIndex) {
    case 0:
      return list.market_name_kor;

    case 1:
      return "무승부";

    case 2:
      return list.market_name_front;

    default:
      return "_";
  }
};

export const sortResultMarket = (event) => {
  if (isEmpty(event) || isEmpty(event.Markets) || event.Markets.length === 0) {
    return event;
  }

  let destEvent = $.extend(true, {}, event);

  for (let i = 0; i < destEvent.Markets.length; i++) {
    const marketId = destEvent.Markets[i].Id;
    const BaseLine = (isEmpty(destEvent.Markets[i].BaseLine) || isNaN(Number(destEvent.Markets[i].BaseLine))) ? 0 : Number(destEvent.Markets[i].BaseLine);
    const MarketName = destEvent.Markets[i].name;
    const destMarket = destEvent.Markets[i];


    let index, homeId, awayId;
    homeId = isEmpty(destEvent.Fixture.Participants)
      ? ""
      : destEvent.Fixture.Participants[0].participantId;
    awayId = isEmpty(destEvent.Fixture.Participants)
      ? ""
      : destEvent.Fixture.Participants[1].participantId;

    let bets = [];
    let home = {};
    let draw = {};
    let away = {};
    if (destEvent.Markets[i].Providers[0].Bets.length === 2) {
      destEvent.Markets[i].Providers[0].Bets.forEach(element => {
        if (element.participantIdString == homeId) {
          home = element;
        } else if (element.participantIdString == awayId) {
          away = element;
        }
      });
      if (!isEmpty(home) && !isEmpty(away)) {
        bets = [home, away];
        destEvent.Markets[i].Providers[0].Bets = bets;
      }
    }
    if (destEvent.Markets[i].Providers[0].Bets.length === 3) {
      destEvent.Markets[i].Providers[0].Bets.forEach(element => {
        // if (
        //   typeof element.participantIdString !== "object" &&
        //   isEmpty(typeof element.participantIdString)
        // )
        //   toastr.warning(`participantId 없음`);
        if (element.outcomeTypeId === 11 || element.Name === "Draw") {
          draw = element;
          // draw.Name = "X";
          // element.Name = "X";
        } else {
          if (element.outcomeTypeId === 10) {
            if (element.participantIdString == homeId) {
              home = element;
              // home.Name = "1";
              // element.Name = "1";
            }
            if (element.participantIdString == awayId) {
              away = element;
              // away.Name = "2";
              // element.Name = "2";
            }
          }

          // else{
          //     bets.push(element);
          // }
        }
      });

      if (!isEmpty(home) && !isEmpty(draw) && !isEmpty(away)) {
        bets = [home, draw, away];
        destEvent.Markets[i].Providers[0].Bets = bets;
        // //console.log(bets)
      }
    }
  }

  // 기준점으로 정렬
  destEvent.Markets.sort((a, b) => {
    if (a.Providers[0].Bets.length === 2 && b.Providers[0].Bets.length === 2) {
      let aValue = a.BaseLine;
      let bValue = b.BaseLine;
      return aValue - bValue;
    } else return 0;
  });

  // 마켓종류별로 정렬
  destEvent.Markets.sort((a, b) => {
    let aValue = a.eventPartId;
    let bValue = b.eventPartId;
    return aValue - bValue;
  });

  destEvent.Markets.sort((a, b) => getMarketLevel(a, destEvent) - getMarketLevel(b, destEvent));


  let index = getFirstMarket(destEvent.Markets, destEvent.Fixture.League.sport.Id);

  if (index < 0) {
    index = destEvent.Markets.findIndex(
      element => element.Id == 69 || element.Id == 466
    );
  }

  if (index > -1) {
    let windrawAway = destEvent.Markets[index];
    destEvent.Markets = [
      windrawAway,
      ...destEvent.Markets.filter(
        item => item.MarketID !== destEvent.Markets[index].MarketID
      )
    ];
  }
  return destEvent;
}

let providedMarketIds = [];
export let providerMarkets = [];

const entityMap = {
  "ä": "a",
};

const convertSpecialNames = (name) => {
  // ------------------- ä => a 변환하기 --------------- 
  Object.keys(entityMap).forEach((key, index) => {
    let patt = new RegExp(key, "gi");
    name = String(name).replace(patt, entityMap[key]);
  });
  return name;
}

const nameNumber = (name, originSportslist = {}) => {
  name = convertSpecialNames(name);
  let homeName = "";
  let awayName = "";
  const nameList = [
    {
      value: 1,
      data: ["1", "Over", "Yes", "Even", "1X", "1x", "1st"]
    },
    {
      value: 1.5,
      data: ["X", "x", "No Goal", "12", "All", "Draw", "draw"]
    },
    {
      value: 2,
      data: ["2", "Under", "No", "Odd", "X2", "x2", "2nd"]
    }
  ];
  const index = nameList.findIndex(
    item => item.data.findIndex(subname => name.indexOf(subname) >= 0) >= 0
  );
  // if(name === "X") //console.log(index, name)
  // if(name === "1") //console.log(index, name)
  // if(name === "2") //console.log(index, name)


  if (name.includes("Over")) {
    return 1;
  } else if (name.includes("Under")) {
    return 2;
  }

  if (!isEmpty(originSportslist)) {
    homeName = convertSpecialNames(originSportslist.Fixture.Participants[0].name);
    awayName = convertSpecialNames(originSportslist.Fixture.Participants[1].name);
    if (name.includes(homeName.trim())) {
      if (name.includes('Draw')) {
        return 1.5;
      }
      else {
        return 1;
      }
    } else if (name.includes(awayName.trim())) {
      if (name.includes('Draw')) {
        return 1.5;
      }
      else {
        return 2;
      }
    }
  }
  if (index < 0 || (name.includes('(')) && !name.includes('Draw')) {
    return 0;
  }
  if (name.includes('Draw')) {
    return 1.5;
  }
  // //console.log(index, name, nameList[index].value)
  return nameList[index].value;
};

export const convertTeamName = name => {
  switch (name) {
    case "1":
      return "홈팀";
    case "X":
      return "무승부";
    case "2":
      return "원정팀";
    case "12":
      return "홈팀 또는 원정팀";
    case "1X":
      return "홈팀 또는 무승부";
    case "X2":
      return "무승부 또는 원정팀";
    default:
      return name;
  }
};

export const convertGameType = type => {
  switch (type) {
    case "prematch":
      return "프리매치";
    case "live":
      return "라이브";
    case "esports":
      return "이스포츠";
    case "casino":
      return "카지노";
    case "slot":
      return "슬롯";
    case "minigame":
      return "미니게임";
    case "betgame365":
      return "벳게임365";
    case "virtual":
      return "벳게임";
    default:
      return "___";
  }
};

export const convertMinigameName = type => {
  // //console.log(type)
  switch (type) {
    case "EOSPowerball1":
      return "EOS파워볼 1분";
    case "EOSPowerball2":
      return "EOS파워볼 2분";
    case "EOSPowerball3":
      return "EOS파워볼 3분";
    case "EOSPowerball4":
      return "EOS파워볼 4분";
    case "EOSPowerball5":
      return "EOS파워볼 5분";
    case "EVOPowerball":
      return "EVO파워볼";
    case "Nuguri":
      return "너구리";
    case "Soccer":
      return "축구";
    case "Baseball":
      return "야구";
    case "Squid":
      return "오징어";
    case "nuguri":
      return "너구리";
    case "soccer":
      return "축구";
    case "baseball":
      return "야구";
    case "squidgame":
      return "오징어";
    default:
      return `EOS${type}분파워볼`;
  }
};

export const getSlotApiType = type => {
  switch (type) {
    case "1":
      return "아너링크";
    case "2":
      return "유년";
    case "3":
      return "케플";
    default:
      return "___";
  }
};

export const convertSportId = data => {
  let sportId = "";
  switch (data.toString()) {
    case "6046":
      sportId = "6046";
      break;

    case "35232":
      sportId = "35232";
      break;

    case "48242":
      sportId = "48242";
      break;

    case "154830":
      sportId = "154830";
      break;

    case "154914":
      sportId = "154914";
      break;

    case "154919":
      sportId = "154919";
      break;

    case "265917":
      sportId = "265917";
      break;

    case "131506":
      sportId = "131506";
      break;

    case "54094":
      sportId = "54094";
      break;

    case "452674":
      sportId = "452674";
      break;

    case "98":
      sportId = "counterstrike";
      break;

    case "99":
      sportId = "dota2";
      break;

    case "100":
      sportId = "leagueoflegends";
      break;

    case "106":
      sportId = "nbabattle";
      break;

    case "107":
      sportId = "overwatch";
      break;

    case "110":
      sportId = "starcraft2";
      break;

    case "112":
      sportId = "rainbowsix";
      break;

    case "117":
      sportId = "warcraft";
      break;

    case "121":
      sportId = "soccerbattle";
      break;

    case "134":
      sportId = "valorant";
      break;

    case "171":
      sportId = "leagueoflegends_wildlift";
      break;

    default:
      break;
  }
  return;
};

export const getBetList = list => {
  return dateFormation(list.date, 5);
};

export const convertBetNameKor = (name, type) => {
  if (type === "bet365") {
    return betGames[betGames.findIndex(item => item.id === name)].name;
  }
  else {
    return lotusgame[lotusgame.findIndex(item => item.id === name)].name;
  }
};

export const convertBetLeagueNameKor = (name, league, type) => {
  if (type === "bet365") {
    let betgame = betGames[betGames.findIndex(item => item.id === name)].leagues;
    return betgame[betgame.findIndex(item => item.id === league)].name;
  } else {
    let lotusgames = lotusgame[lotusgame.findIndex(item => item.id === name)].leagues;
    return lotusgames[lotusgames.findIndex(item => item.id === league)].name;
  }
};
export const convertBetMarketDetail = list => {
  // //console.log(name)
  switch (list.number) {
    case 0:
      return list.home;

    case 1:
      return "무승부";

    case 2:
      return list.away;

    default:
      return "_";
  }
};
export const getImgPath = list => {
  // //console.log(list)
  const sportsid = getSportId(list);
  const imgPath = isEmpty(sportsid)
    ? "../images/swip/icon_all.png"
    : sportsid.imgPath;
  return imgPath;
};
export const convertStatusEng = (data) => {
  switch (data) {
    case 5:
    case 33:
    case 53:
    case 63:
    case 93:
    case 143:
    case 153:
    case 163:
    case 183:
    case 193:
    case 203:
    case 333:
    case 353:
    case 582:
    case 594:
    case 690:
    case 707:
    case 799:
    case 814:
    case 1473:
    case 2256:
    case 2385:
    case 2477:
    case 2811:
      return "1st Half";

    case 6:
    case 34:
    case 54:
    case 64:
    case 94:
    case 144:
    case 154:
    case 164:
    case 184:
    case 194:
    case 204:
    case 334:
    case 354:
    case 583:
    case 595:
    case 692:
    case 708:
    case 800:
    case 817:
    case 1478:
    case 2258:
    case 2479:
    case 2813:
      return "2nd Half";

    case 35:
    case 65:
    case 335:
    case 355:
    case 596:
    case 693:
    case 801:
    case 1361:
    case 1474:
      return "1st Quarter";

    case 36:
    case 66:
    case 336:
    case 356:
    case 597:
    case 695:
    case 802:
    case 1363:
    case 1476:
      return "2nd Quarter";

    case 37:
    case 67:
    case 337:
    case 357:
    case 598:
    case 696:
    case 803:
    case 1364:
    case 1479:
      return "3rd Quarter";

    case 38:
    case 68:
    case 338:
    case 358:
    case 599:
    case 698:
    case 804:
    case 1366:
    case 1481:
      return "4th Quarter";

    case 43:
    case 173:
    case 223:
    case 878:
    case 2344:
      return "1st Period";

    case 44:
    case 174:
    case 224:
    case 884:
    case 2346:
      return "2nd Period";

    case 45:
    case 175:
    case 225:
    case 890:
    case 2348:
      return "3rd Period";

    case 21:
    case 111:
    case 211:
    case 241:
    case 311:
    case 341:
    case 683:
    case 805:
    case 1215:
    case 2269:
    case 2368:
    case 2401:
    case 2489:
    case 2875:
      return "1st Set";

    case 401:
    case 798:
    case 2270:
    case 2402:
    case 2490:
    case 2878:
      return "1st Set";

    case 402:
    case 2271:
    case 2403:
    case 2491:
    case 2879:
      return "1st Set";

    case 403:
    case 2272:
    case 2404:
    case 2492:
    case 2880:
      return "1st Set";

    case 404:
    case 2273:
    case 2405:
    case 2493:
    case 2881:
      return "1st Set";

    case 405:
    case 2274:
    case 2406:
    case 2494:
    case 2882:
      return "1st Set";

    case 406:
    case 2275:
    case 2407:
    case 2495:
    case 2883:
      return "1st Set";

    case 407:
    case 2276:
    case 2408:
    case 2496:
    case 2884:
      return "1st Set";

    case 408:
    case 2277:
    case 2409:
    case 2497:
    case 2885:
      return "1st Set";

    case 409:
    case 2278:
    case 2410:
    case 2498:
    case 2886:
      return "1st Set";

    case 410:
    case 2279:
    case 2411:
    case 2499:
    case 2887:
      return "1st Set";

    case 411:
    case 2280:
    case 2412:
    case 2500:
    case 2888:
      return "1st Set";

    case 412:
    case 2281:
    case 2413:
    case 2501:
    case 2889:
      return "1st Set";

    case 22:
    case 112:
    case 212:
    case 242:
    case 312:
    case 342:
    case 684:
    case 806:
    case 1216:
    case 2284:
    case 2370:
    case 2415:
    case 2503:
    case 2876:
      return "2nd Set";

    case 421:
    case 2285:
    case 2416:
    case 2504:
    case 2891:
      return "2nd Set";

    case 422:
    case 2286:
    case 2417:
    case 2505:
    case 2892:
      return "2nd Set";

    case 423:
    case 2287:
    case 2418:
    case 2506:
    case 2893:
      return "2nd Set";

    case 424:
    case 2288:
    case 2419:
    case 2507:
    case 2894:
      return "2nd Set";

    case 425:
    case 2289:
    case 2420:
    case 2508:
    case 2895:
      return "2nd Set";

    case 426:
    case 2290:
    case 2421:
    case 2509:
    case 2896:
      return "2nd Set";

    case 427:
    case 2291:
    case 2422:
    case 2510:
    case 2897:
      return "2nd Set";

    case 428:
    case 2292:
    case 2423:
    case 2511:
    case 2898:
      return "2nd Set";

    case 429:
    case 2293:
    case 2424:
    case 2512:
    case 2899:
      return "2nd Set";

    case 430:
    case 2294:
    case 2425:
    case 2513:
    case 2900:
      return "2nd Set";

    case 431:
    case 2295:
    case 2426:
    case 2514:
    case 2901:
      return "2nd Set";

    case 432:
    case 2296:
    case 2427:
    case 2515:
    case 2902:
      return "2nd Set";

    case 23:
    case 113:
    case 213:
    case 243:
    case 313:
    case 343:
    case 685:
    case 807:
    case 1217:
    case 2299:
    case 2372:
    case 2430:
    case 2517:
    case 2877:
      return "3rd Set";

    case 441:
    case 2300:
    case 2431:
    case 2518:
    case 2904:
      return "3rd Set";

    case 442:
    case 2301:
    case 2432:
    case 2519:
    case 2905:
      return "3rd Set";

    case 443:
    case 2302:
    case 2433:
    case 2520:
    case 2906:
      return "3rd Set";

    case 444:
    case 2303:
    case 2434:
    case 2521:
    case 2907:
      return "3rd Set";

    case 445:
    case 2304:
    case 2435:
    case 2522:
    case 2908:
      return "3rd Set";

    case 446:
    case 2305:
    case 2436:
    case 2523:
    case 2909:
      return "3rd Set";

    case 447:
    case 2306:
    case 2437:
    case 2524:
    case 2910:
      return "3rd Set";

    case 448:
    case 2307:
    case 2438:
    case 2525:
    case 2911:
      return "3rd Set";

    case 449:
    case 2308:
    case 2439:
    case 2526:
    case 2912:
      return "3rd Set";

    case 450:
    case 2309:
    case 2440:
    case 2527:
    case 2913:
      return "3rd Set";

    case 451:
    case 2310:
    case 2441:
    case 2528:
    case 2914:
      return "3rd Set";

    case 452:
    case 2311:
    case 2442:
    case 2529:
    case 2915:
      return "3rd Set";

    case 24:
    case 114:
    case 244:
    case 808:
    case 1218:
    case 2313:
    case 2374:
    case 2444:
    case 2559:
      return "4th Set";

    case 461:
    case 2314:
    case 2445:
    case 2560:
      return "4th Set";

    case 462:
    case 2315:
    case 2446:
    case 2561:
      return "4th Set";

    case 463:
    case 2316:
    case 2447:
    case 2562:
      return "4th Set";

    case 464:
    case 2317:
    case 2448:
    case 2563:
      return "4th Set";

    case 465:
    case 2318:
    case 2449:
    case 2564:
      return "4th Set";

    case 466:
    case 2319:
    case 2450:
    case 2565:
      return "4th Set";

    case 467:
    case 2320:
    case 2451:
    case 2566:
      return "4th Set";

    case 468:
    case 2321:
    case 2452:
    case 2567:
      return "4th Set";

    case 469:
    case 2322:
    case 2453:
    case 2568:
      return "4th Set";

    case 470:
    case 2323:
    case 2454:
    case 2569:
      return "4th Set";

    case 471:
    case 2324:
    case 2455:
    case 2570:
      return "4th Set";

    case 472:
    case 2325:
    case 2456:
    case 2571:
      return "4th Set";

    case 25:
    case 115:
    case 245:
    case 809:
    case 1219:
    case 2328:
    case 2376:
    case 2458:
    case 2573:
      return "5th Set";

    case 481:
    case 2329:
    case 2459:
    case 2574:
      return "5th Set";

    case 482:
    case 2330:
    case 2460:
    case 2575:
      return "5th Set";

    case 483:
    case 2331:
    case 2461:
    case 2576:
      return "5th Set";

    case 484:
    case 2332:
    case 2462:
    case 2577:
      return "5th Set";

    case 485:
    case 2333:
    case 2463:
    case 2578:
      return "5th Set";

    case 486:
    case 2334:
    case 2464:
    case 2579:
      return "5th Set";

    case 487:
    case 2335:
    case 2465:
    case 2580:
      return "5th Set";

    case 488:
    case 2336:
    case 2466:
    case 2581:
      return "5th Set";

    case 489:
    case 2337:
    case 2467:
    case 2582:
      return "5th Set";

    case 490:
    case 2338:
    case 2468:
    case 2583:
      return "5th Set";

    case 491:
    case 2339:
    case 2469:
    case 2584:
      return "5th Set";

    case 492:
    case 2340:
    case 2470:
    case 2585:
      return "5th Set";

    case 73:
    case 103:
      return "1st Inning";

    case 74:
    case 104:
      return "2nd Inning";

    case 75:
    case 105:
      return "3rd Inning";

    case 76:
    case 106:
      return "4th Inning";

    case 77:
    case 107:
      return "5th Inning";

    case 78:
    case 108:
      return "6th Inning";

    case 79:
    case 109:
      return "7th Inning";

    case 80:
      return "8th Inning";

    case 81:
      return "9th Inning";

    case 131:
    case 676:
    case 2355:
      return "1st Round";

    case 132:
    case 2356:
      return "2nd Round";

    case 133:
    case 2357:
      return "3rd Round";

    case 134:
    case 2358:
      return "4th Round";

    case 135:
    case 2359:
      return "5th Round";

    case 136:
    case 2360:
      return "6th Round";

    case 1403:
    case 2361:
      return "7th Round";

    case 1404:
    case 2362:
      return "8th Round";

    case 1405:
    case 2363:
      return "9th Round";

    case 1406:
    case 2364:
      return "10th Round";

    case 1407:
    case 2365:
      return "11th Round";

    case 1408:
    case 2366:
      return "12th Round";

    case 2842:
      return "13th Round";

    case 2844:
      return "14th Round";

    case 2845:
      return "15th Round";

    default:
      return "";
  }
};

export const checkMarket = (market, id) => {
  // const providerId = getProviderId();
  // if (isEmpty(providerId)) {
  //   return false;
  // }
  // try {
  //   if (isEmpty(market.Providers) || isEmpty(market.Providers[0])) {
  //     return false;
  //   }
  //   // //console.log(market.Providers[0].Bets)
  //   if (isEmpty(market.Providers[0].Bets)) {
  //     return false;
  //   }
  // }
  // catch (ex) {
  //   return false;
  // }

  //------------------설정된 마켓만 현시--------------------------
  // const { pageInfo } = store.getState().page;
  // const marketSettings = store.getState().sports.marketSetting;
  // //console.log('handicap_set: ', handicap_set);

  // const getMarketSetting = (data) => {
  //   if(isEmpty(data)){
  //     return []
  //   }
  //   else if(pageInfo.sportType === "sport"){
  //     if(pageInfo.gameType === 'prematch'){
  //       return data.sportPrematch;
  //     }
  //     else{
  //       return data.sportLive;
  //     }
  //   }
  //   else {
  //     if(pageInfo.gameType === 'prematch'){
  //       return data.esportPrematch;
  //     }
  //     else{
  //       return data.esportLive;
  //     }
  //   }
  // }

  // const marketSetting = getMarketSetting(marketSettings);
  // if(isEmpty(marketSetting)){
  //   return false;
  // }
  // else{
  //   const index = marketSetting.findIndex(setting => setting.market_id == market.Id);
  //   if(index < 0){
  //     return false
  //   }
  //   else{
  //     if(marketSetting[index].value != 0){
  //       if(market.Providers[0].Bets.length >= Number(marketSetting[index].value)){
  //         market.Providers[0].Bets.slice(0, Number(marketSetting[index].value - 1));
  //       }
  //     }
  //   }
  // }


  //-----------------BAaeLine 0.25, 0.75... 없애기---------------
  const baselineSetting = store.getState().sports.baselineSetting;
  const { pageInfo } = store.getState().page;
  const { sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting } = baselineSetting;

  const getRateValue = () => {
    let sportsRateIndex = -1;
    let sportRate = {}
    if (pageInfo.RoomType === "sport") {
      if (pageInfo.gameType === "live") {
        sportsRateIndex = isEmpty(sportLiveRateSetting) ? -1 : sportLiveRateSetting.findIndex(setting => setting.sportId == id);
        sportRate = sportsRateIndex > -1 ? sportLiveRateSetting[sportsRateIndex] : {};
      }
      else {
        sportsRateIndex = isEmpty(sportPrematchRateSetting) ? -1 : sportPrematchRateSetting.findIndex(setting => setting.sportId == id);
        sportRate = sportsRateIndex > -1 ? sportPrematchRateSetting[sportsRateIndex] : {};
      }


      if (!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)) {
        return {
          min: Number(sportRate.min),
          max: Number(sportRate.max),
        }
      }
      else {
        return {}
      }
    }
    else {
      if (pageInfo.gameType === "live") {
        sportsRateIndex = isEmpty(esportLiveRateSetting) ? -1 : esportLiveRateSetting.findIndex(setting => setting.sportId == id);
        sportRate = sportsRateIndex > -1 ? esportLiveRateSetting[sportsRateIndex] : {};
      }
      else {
        sportsRateIndex = isEmpty(esportPrematchRateSetting) ? -1 : esportPrematchRateSetting.findIndex(setting => setting.sportId === id);
        sportRate = sportsRateIndex > -1 ? esportPrematchRateSetting[sportsRateIndex] : {};
      }


      if (!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)) {
        return {
          min: Number(sportRate.min),
          max: Number(sportRate.max),
        }
      }
      else {
        return {}
      }
    }
  }
  const rateValue = getRateValue();

  let selectBaseline;
  if (pageInfo.RoomType === "sport") {
    if (pageInfo.gameType === "live" || pageInfo.gameType === "prematch") {
      if (isEmpty(baselineSetting.baselineShow)) {
        let baseLine = Number(market.BaseLine) * 100;
        if (baseLine % 50 != 0) {
          return false;
        }
      } else {
        selectBaseline = !isEmpty(id) ? baselineSetting.baselineShow[baselineSetting.baselineShow.findIndex(item => item.sportId == id)] : {};
        if (!isEmpty(selectBaseline) && selectBaseline.status === false) {
          let baseLine = Number(market.BaseLine) * 100;
          if (baseLine % 50 != 0) {
            return false;
          }
        }
      }
    }
  }
  else {
    if (pageInfo.gameType === "live" || pageInfo.gameType === "prematch") {
      if (isEmpty(baselineSetting.ebaselineShow)) {
        let baseLine = Number(market.BaseLine) * 100;
        if (baseLine % 50 != 0) {
          return false;
        }
      }
      else {
        selectBaseline = !isEmpty(id) ? baselineSetting.ebaselineShow[baselineSetting.ebaselineShow.findIndex(item => item.sportId == id)] : {};
        if (isEmpty(selectBaseline)) {
          let baseLine = Number(market.BaseLine) * 100;
          if (baseLine % 50 != 0) {
            return false;
          }
        }
        else {
          if (selectBaseline.status === false) {
            let baseLine = Number(market.BaseLine) * 100;
            if (baseLine % 50 != 0) {
              return false;
            }
          }
        }
      }
    }
  }

  //-----------------BAaeLine 0.25, 0.75... 없애기 끝---------------//


  //--------------- 라이브 최소배당율------------------------//
  let checkMarket = false;
  let deleteLockedMarket = true;
  let minRate = 1;
  let maxRate = 10;
  if (pageInfo.gameType === "live") {
    if (pageInfo.sportType === "esport") {
      minRate = !isEmpty(baselineSetting.eliveminrate) ? baselineSetting.eliveminrate : 1
      maxRate = !isEmpty(baselineSetting.eliveminrate) ? baselineSetting.elivemaxrate : 1
    }
    else {
      minRate = !isEmpty(baselineSetting.liveminrate) ? baselineSetting.liveminrate : 1
      maxRate = !isEmpty(baselineSetting.liveminrate) ? baselineSetting.livemaxrate : 1
    }
  }
  market.Providers[0].Bets.forEach(element => {
    // //console.log(element.Status )
    if (element.Status !== 1 && element.Status != 10) return (checkMarket = true);
    if (isEmpty(element.Price)) return (checkMarket = true);

    const priceIndex = 0; // element.Price.findIndex(betprice => providerId == betprice.providerId);

    if (priceIndex < 0) return (checkMarket = true);
    // if(!isEmpty(element.Price) && element.Price[priceIndex].status >= 4 )  return(checkMarket = true)

    // if (fixedNumber(element.Price[priceIndex].Price) < minRate) {
    //   if (market.Id != "466" && market.Id != "69") {
    //     return (checkMarket = true);
    //   }
    // }

    if (!((element.Price[0].status > 3) ||
      (element.Status === 10) ||
      (!isEmpty(rateValue) &&
        ((element.Price[0].Price > rateValue.max) || (element.Price[0].Price < rateValue.min))
      ) ||
      (fixedNumber(element.Price[priceIndex].Price) < minRate) ||
      (
        (pageInfo.gameType === "live") &&
        (fixedNumber(element.Price[priceIndex].Price) > maxRate)
      )) || (market.Id == "466" || market.Id == "69")
    ) {
      deleteLockedMarket = false;
    }
  });

  if (deleteLockedMarket) {
    return false;
  }
  if (checkMarket) return false;
  // //console.log(market.Providers[0].Bets.length, market.name_kor )
  return market.Providers[0].Bets.length > 1;
};

export const countMarket = (sportList, kind_Id) => {
  let count = 0;
  sportList.Markets.forEach(element => {
    if (checkMarket(element, kind_Id)) count++;
  });

  return count;
};

export const getLiveStatus = sportList => {
  // //console.log(sportList);
  if (sportList.Fixture.gameType === "live") {
    const sportsid = getSportId(sportList);
    const imgPath = isEmpty(sportsid)
      ? "../images/swip/icon_all.png"
      : sportsid.imgPath;

    let RoundInfo = convertStatus(sportList.Fixture.currentPartId, sportsid, sportList.Fixture.Status);
    sportList.Livescore.eventDetails.forEach((e) => {
      if (e.eventPartId == 40) {
        if (e.typeId == 92) {
          if (!isEmpty(e.paramEventPartId1)) {
            RoundInfo = convertStatus(e.paramEventPartId1);
          }
        }
      }
      if (e.eventPartId == 2) {
        if (e.typeId == 92) {
          if (!isEmpty(e.paramEventPartId1)) {
            RoundInfo = convertStatus(e.paramEventPartId1);
          }
        }
      }
      if (e.eventPartId == 60) {
        if (e.typeId == 92) {
          if (!isEmpty(e.paramEventPartId1)) {
            RoundInfo = convertStatus(e.paramEventPartId1);
          }
        }
      }
      if (e.eventPartId == 30) {
        if (e.typeId == 92) {
          if (!isEmpty(e.paramEventPartId1)) {
            RoundInfo = convertStatus(e.paramEventPartId1);
          }
        }
      }
      if (e.eventPartId == 2251) {
        if (e.typeId == 92) {
          if (!isEmpty(e.paramEventPartId1)) {
            RoundInfo = convertStatus(e.paramEventPartId1);
          }
        }
      }
      if (e.eventPartId == 1471) {
        if (e.typeId == 92) {
          if (!isEmpty(e.paramEventPartId1)) {
            RoundInfo = convertStatus(e.paramEventPartId1);
          }
        }
      }
    })
    const TimeInfo = convertTime(sportList);
    return RoundInfo + " " + TimeInfo;
  } else {
    return "";
  }
};
export const getSportId = (list) => {
  const sportsid = sportIds[sportIds.findIndex(item =>
    item.id.toString() === list.Fixture.League.sport.Id.toString() ||
    (list.Fixture.League.sport.Id.toString() === "687890" &&
      (list.Fixture.League.name.toLowerCase().includes(item.name)) ||
      list.Fixture.League.name.includes(item.name_other) ||
      list.Fixture.League.sport.name.toLowerCase().includes(item.name) ||
      list.Fixture.League.sport.name.includes(item.name_other) ||
      (list.Fixture.League.sport.oddsId == item.oddsId))
  )
  ];
  return sportsid;
}
export const convertStatus = (data, sportsid, status) => {
  // console.log('data: ', data);
  switch (status) {
    case 1: {
      return "경기전"
    }
    case 3: {
      return "경기완료"
    }
    case 4: {
      return "중단"
    }

    case 5: {
      return "취소"
    }

    case 6: {
      return "낙승"
    }

    case 7: {
      return "Abandoned"
    }

    case 8: {
      return "Retired"
    }
    default:
      break;
  }

  switch (data) {
    case 5:
    case 33:
    case 53:
    case 63:
    case 93:
    case 143:
    case 153:
    case 163:
    case 183:
    case 193:
    case 203:
    case 333:
    case 353:
    case 582:
    case 594:
    case 690:
    case 707:
    case 799:
    case 814:
    case 1473:
    case 2256:
    case 2385:
    case 2477:
    case 2811:
      return "전반전";

    case 6:
    case 34:
    case 54:
    case 64:
    case 94:
    case 144:
    case 154:
    case 164:
    case 184:
    case 194:
    case 204:
    case 334:
    case 354:
    case 583:
    case 595:
    case 692:
    case 708:
    case 800:
    case 817:
    case 1478:
    case 2258:
    case 2479:
    case 2813:
      return "후반전";

    case 621:
    case 627:
    case 631:
    case 637:
    case 656:
    case 658:
    case 660:
    case 663:
    case 672:
    case 691:
    case 816:
    case 883:
    case 1378:
    case 1477:
    case 2257:
    case 2387:
    case 2478:
    case 2775:
    case 2777:
    case 2812:
      return "중간 휴식";

    case 4:
    case 32:
    case 42:
    case 52:
    case 62:
    case 72:
    case 92:
    case 102:
    case 142:
    case 152:
    case 162:
    case 172:
    case 182:
    case 192:
    case 202:
    case 282:
    case 332:
    case 352:
    case 581:
    case 589:
    case 593:
    case 689:
    case 818:
    case 1484:
    case 2350:
    case 2392:
    case 2786:
    case 2815:
      return "연장전";

    case 623:
    case 628:
    case 632:
    case 638:
    case 647:
    case 657:
    case 659:
    case 661:
    case 662:
    case 664:
    case 688:
    case 889:
    case 1380:
    case 1483:
    case 2260:
    case 2349:
    case 2391:
    case 2480:
    case 2780:
    case 2783:
    case 2814:
      return "Ordinary Time Over";

    case 622:
    case 633:
    case 1411:
    case 2264:
    case 2395:
    case 2484:
    case 2818:
      return "Halftime (Overtime)";

    case 624:
    case 634:
    case 665:
    case 1414:
    case 2266:
    case 2352:
    case 2397:
    case 2486:
    case 2788:
    case 2822:
    case 2968:
      return "Halftime (Overtime)";

    case 35:
    case 65:
    case 335:
    case 355:
    case 596:
    case 693:
    case 801:
    case 1361:
    case 1474:
      return "1쿼터";

    case 36:
    case 66:
    case 336:
    case 356:
    case 597:
    case 695:
    case 802:
    case 1363:
    case 1476:
      return "2쿼터";

    case 37:
    case 67:
    case 337:
    case 357:
    case 598:
    case 696:
    case 803:
    case 1364:
    case 1479:
      return "3쿼터";

    case 38:
    case 68:
    case 338:
    case 358:
    case 599:
    case 698:
    case 804:
    case 1366:
    case 1481:
      return "4쿼터";

    case 43:
    case 173:
    case 223:
    case 878:
    case 2344:
      return "1 피리어드";

    case 44:
    case 174:
    case 224:
    case 884:
    case 2346:
      return "2 피리어드";

    case 45:
    case 175:
    case 225:
    case 890:
    case 2348:
      return "3 피리어드";

    case 21:
    case 111:
    case 211:
    case 241:
    case 311:
    case 341:
    case 683:
    case 805:
    case 1215:
    case 2269:
    case 2368:
    case 2401:
    case 2489:
    case 2875:
      return "1 세트";

    case 401:
    case 798:
    case 2270:
    case 2402:
    case 2490:
    case 2878:
      return "1세트 첫게임";

    case 402:
    case 2271:
    case 2403:
    case 2491:
    case 2879:
      return "1세트 두번째게임";

    case 403:
    case 2272:
    case 2404:
    case 2492:
    case 2880:
      return "1세트 3번째게임";

    case 404:
    case 2273:
    case 2405:
    case 2493:
    case 2881:
      return "1세트 4번째게임";

    case 405:
    case 2274:
    case 2406:
    case 2494:
    case 2882:
      return "1세트 다섯번째게임";

    case 406:
    case 2275:
    case 2407:
    case 2495:
    case 2883:
      return "1세트 여섯번째게임";

    case 407:
    case 2276:
    case 2408:
    case 2496:
    case 2884:
      return "1세트 7번째게임";

    case 408:
    case 2277:
    case 2409:
    case 2497:
    case 2885:
      return "1세트 여덟번째 게임";

    case 409:
    case 2278:
    case 2410:
    case 2498:
    case 2886:
      return "1세트 아홉번째게임";

    case 410:
    case 2279:
    case 2411:
    case 2499:
    case 2887:
      return "1세트 열번째게임";

    case 411:
    case 2280:
    case 2412:
    case 2500:
    case 2888:
      return "1세트 열한번째게임";

    case 412:
    case 2281:
    case 2413:
    case 2501:
    case 2889:
      return "1세트 열두번째 게임";

    case 22:
    case 112:
    case 212:
    case 242:
    case 312:
    case 342:
    case 684:
    case 806:
    case 1216:
    case 2284:
    case 2370:
    case 2415:
    case 2503:
    case 2876:
      return "2 세트";

    case 421:
    case 2285:
    case 2416:
    case 2504:
    case 2891:
      return "2세트 첫게임";

    case 422:
    case 2286:
    case 2417:
    case 2505:
    case 2892:
      return "2세트 두번째게임";

    case 423:
    case 2287:
    case 2418:
    case 2506:
    case 2893:
      return "2세트 3번째게임";

    case 424:
    case 2288:
    case 2419:
    case 2507:
    case 2894:
      return "2세트 4번째게임";

    case 425:
    case 2289:
    case 2420:
    case 2508:
    case 2895:
      return "2세트 다섯번째게임";

    case 426:
    case 2290:
    case 2421:
    case 2509:
    case 2896:
      return "2세트 여섯번째게임";

    case 427:
    case 2291:
    case 2422:
    case 2510:
    case 2897:
      return "2세트 7번째게임";

    case 428:
    case 2292:
    case 2423:
    case 2511:
    case 2898:
      return "2세트 여덟번째 게임";

    case 429:
    case 2293:
    case 2424:
    case 2512:
    case 2899:
      return "2세트 아홉번째게임";

    case 430:
    case 2294:
    case 2425:
    case 2513:
    case 2900:
      return "2세트 열번째게임";

    case 431:
    case 2295:
    case 2426:
    case 2514:
    case 2901:
      return "2세트 열한번째게임";

    case 432:
    case 2296:
    case 2427:
    case 2515:
    case 2902:
      return "2세트 열두번째 게임";

    case 23:
    case 113:
    case 213:
    case 243:
    case 313:
    case 343:
    case 685:
    case 807:
    case 1217:
    case 2299:
    case 2372:
    case 2430:
    case 2517:
    case 2877:
      return "3 세트";

    case 441:
    case 2300:
    case 2431:
    case 2518:
    case 2904:
      return "3세트 첫게임";

    case 442:
    case 2301:
    case 2432:
    case 2519:
    case 2905:
      return "3세트 두번째게임";

    case 443:
    case 2302:
    case 2433:
    case 2520:
    case 2906:
      return "3세트 3번째게임";

    case 444:
    case 2303:
    case 2434:
    case 2521:
    case 2907:
      return "3세트 4번째게임";

    case 445:
    case 2304:
    case 2435:
    case 2522:
    case 2908:
      return "3세트 다섯번째게임";

    case 446:
    case 2305:
    case 2436:
    case 2523:
    case 2909:
      return "3세트 여섯번째게임";

    case 447:
    case 2306:
    case 2437:
    case 2524:
    case 2910:
      return "3세트 7번째게임";

    case 448:
    case 2307:
    case 2438:
    case 2525:
    case 2911:
      return "3세트 여덟번째 게임";

    case 449:
    case 2308:
    case 2439:
    case 2526:
    case 2912:
      return "3세트 아홉번째게임";

    case 450:
    case 2309:
    case 2440:
    case 2527:
    case 2913:
      return "3세트 열번째게임";

    case 451:
    case 2310:
    case 2441:
    case 2528:
    case 2914:
      return "3세트 열한번째게임";

    case 452:
    case 2311:
    case 2442:
    case 2529:
    case 2915:
      return "3세트 열두번째 게임";

    case 24:
    case 114:
    case 244:
    case 808:
    case 1218:
    case 2313:
    case 2374:
    case 2444:
    case 2559:
      return "4 세트";

    case 461:
    case 2314:
    case 2445:
    case 2560:
      return "4세트 첫게임";

    case 462:
    case 2315:
    case 2446:
    case 2561:
      return "4세트 두번째게임";

    case 463:
    case 2316:
    case 2447:
    case 2562:
      return "4세트 3번째게임";

    case 464:
    case 2317:
    case 2448:
    case 2563:
      return "4세트 4번째게임";

    case 465:
    case 2318:
    case 2449:
    case 2564:
      return "4세트 다섯번째게임";

    case 466:
    case 2319:
    case 2450:
    case 2565:
      return "4세트 여섯번째게임";

    case 467:
    case 2320:
    case 2451:
    case 2566:
      return "4세트 7번째게임";

    case 468:
    case 2321:
    case 2452:
    case 2567:
      return "4세트 여덟번째 게임";

    case 469:
    case 2322:
    case 2453:
    case 2568:
      return "4세트 아홉번째게임";

    case 470:
    case 2323:
    case 2454:
    case 2569:
      return "4세트 열번째게임";

    case 471:
    case 2324:
    case 2455:
    case 2570:
      return "4세트 열한번째게임";

    case 472:
    case 2325:
    case 2456:
    case 2571:
      return "4세트 열두번째 게임";

    case 25:
    case 115:
    case 245:
    case 809:
    case 1219:
    case 2328:
    case 2376:
    case 2458:
    case 2573:
      return "5 세트";

    case 481:
    case 2329:
    case 2459:
    case 2574:
      return "5세트 첫게임";

    case 482:
    case 2330:
    case 2460:
    case 2575:
      return "5세트 두번째게임";

    case 483:
    case 2331:
    case 2461:
    case 2576:
      return "5세트 3번째게임";

    case 484:
    case 2332:
    case 2462:
    case 2577:
      return "5세트 4번째게임";

    case 485:
    case 2333:
    case 2463:
    case 2578:
      return "5세트 다섯번째게임";

    case 486:
    case 2334:
    case 2464:
    case 2579:
      return "5세트 여섯번째게임";

    case 487:
    case 2335:
    case 2465:
    case 2580:
      return "5세트 7번째게임";

    case 488:
    case 2336:
    case 2466:
    case 2581:
      return "5세트 여덟번째 게임";

    case 489:
    case 2337:
    case 2467:
    case 2582:
      return "5세트 아홉번째게임";

    case 490:
    case 2338:
    case 2468:
    case 2583:
      return "5세트 열번째게임";

    case 491:
    case 2339:
    case 2469:
    case 2584:
      return "5세트 열한번째게임";

    case 492:
    case 2340:
    case 2470:
    case 2585:
      return "5세트 열두번째 게임";
    case 73:
    case 103:
      return "1 회";

    case 74:
    case 104:
      return "2 회";

    case 75:
    case 105:
      return "3 회";

    case 76:
    case 106:
      return "4 회";

    case 77:
    case 107:
      return "5 회";

    case 78:
    case 108:
      return "6 회";

    case 79:
    case 109:
      return "7 회";

    case 80:
      return "8 회";

    case 81:
      return "9 회";

    case 625:
    case 629:
    case 635:
    case 639:
    case 648:
    case 652:
    case 654:
    case 666:
    case 694:
    case 1362:
    case 1377:
    case 1475:
    case 2345:
    case 2369:
    case 2774:
    case 2778:
    case 2970:
    case 2978:
      return "1 휴식시간";

    case 630:
    case 640:
    case 649:
    case 653:
    case 655:
    case 667:
    case 2347:
    case 2371:
    case 2971:
    case 2979:
      return "2 휴식시간";

    case 626:
    case 636:
    case 641:
    case 650:
    case 668:
    case 697:
    case 1365:
    case 1379:
    case 1480:
    case 2373:
    case 2776:
    case 2779:
    case 2980:
      return "3 휴식시간";

    case 642:
    case 651:
    case 669:
    case 2375:
    case 2981:
      return "4 휴식시간";

    case 643:
    case 670:
      return "5 휴식시간";

    case 644:
    case 671:
      return "6 휴식시간";

    case 645:
      return "7 휴식시간";

    case 646:
      return "8 휴식시간";

    case 131:
    case 676:
    case 2355:
      return "1 회전";

    case 132:
    case 2356:
      return "2 회전";

    case 133:
    case 2357:
      return "3 회전";

    case 134:
    case 2358:
      return "4 회전";

    case 135:
    case 2359:
      return "5 회전";

    case 136:
    case 2360:
      return "6 회전";

    case 1403:
    case 2361:
      return "7 회전";

    case 1404:
    case 2362:
      return "8 회전";

    case 1405:
    case 2363:
      return "9 회전";

    case 1406:
    case 2364:
      return "10 회전";

    case 1407:
    case 2365:
      return "11 회전";

    case 1408:
    case 2366:
      return "12 회전";

    case 2842:
      return "13 회전";

    case 2844:
      return "14 회전";

    case 2845:
      return "15 회전";

    case 9:
    case 122:
    case 528:
    case 584:
    case 819:
    case 892:
    case 923:
    case 1415:
    case 2267:
    case 2353:
    case 2398:
    case 2487:
    case 2789:
    case 2969: {
      return sportsid == "6046" ? "승부차기" : "승부샷";
    }

    case 524:
    case 527:
    case 920:
    case 1409:
    case 2262:
    case 2351:
    case 2393:
    case 2482:
    case 2787:
    case 2967: {
      return "연장전"; // (승부차기 포함)
    }

    case 57: {
      return sportsid == "6046" ? "승부차기" : "승부샷"; // (연장전 승부차기)
    }

    case 2131:
    case 2144:
    case 2157: {
      return "연장전"; // (1게임 연장전 승부차기 포함)
    }

    case 2136:     //  (1게임 승부차기 (1st Game))
    case 2149:     //  (2게임 승부차기 (2nd Game))
    case 2162:     //  (3게임 승부차기 (3rd Game) )
      {
        return sportsid == "6046" ? "승부차기" : "승부샷";
      }
    case 893:
      return '1회 초';

    case 894:
      return '휴식시간 1';

    case 895:
      return '1회 말';

    case 896:
      return '2회 초';

    case 897:
      return '2 휴식시간';

    case 898:
      return '2회 말';

    case 899:
      return '3회 초';

    case 900:
      return '3 휴식시간';

    case 901:
      return '3회 말';

    case 902:
      return '4회 초';

    case 903:
      return '4 휴식시간';

    case 904:
      return '4회 말';

    case 905:
      return '5회 초';

    case 906:
      return '5 휴식시간';

    case 907:
      return '5회 말';

    case 908:
      return '6회 초';

    case 909:
      return '6 휴식시간';

    case 910:
      return '6회 말';

    case 911:
      return '7회 초';

    case 912:
      return '7 휴식시간';

    case 913:
      return '7회 말';

    case 914:
      return '8회 초';

    case 915:
      return '8 휴식시간';

    case 916:
      return '8회 말';

    case 917:
      return '9회 초';

    case 918:
      return '9 휴식시간';

    case 919:
      return '9회 말';

    case 585:
      return '첫 3회';

    case 586:
      return '첫 7회';
    default:
      return "";
  }
};
const convertTime = (sportList) => {
  const { eventDetails } = sportList.Livescore;
  const index = eventDetails.findIndex(event => {
    return (event.eventPartId == 2 && event.typeId == 95) ||
      (event.eventPartId == 30 && event.typeId == 96) ||
      (event.eventPartId == 40 && event.typeId == 96) ||
      (event.eventPartId == 60 && event.typeId == 96) ||
      (event.eventPartId == 1471 && event.typeId == 96) ||
      (event.eventPartId == 2251 && event.typeId == 95)
  })
  if (index < 0) {
    return "";
  }
  else {
    if (sportList.Fixture.League.sport.Id == "6046") {
      return eventDetails[index].paramFloat1 + "분 " + "/ 90분"
    }
    else {
      return eventDetails[index].paramFloat1 + "분 "
    }
  }
}

export const getAllCount = type => {
  let allCount = {};
  const { sports, page } = store.getState();
  if (type === "prematch" || type === "interior") {
    allCount = !isEmpty(sports.allCount) ? sports.allCount.sport_prematch : {};
    // //console.log("------allCount-prematch------- ");
  }

  if (type === "live") {
    allCount = sports.allCount.sport_live;
    // //console.log("------allCount-live------- ");
  }

  if (type === "esports" && page.pageInfo.gameType === "prematch") {
    allCount = sports.allCount.esport_prematch;
    // //console.log("------allCount-esports-prematch------- ");
  }

  if (type === "esports" && page.pageInfo.gameType === "live") {
    allCount = sports.allCount.esport_live;
    // //console.log("------allCount-esports-live------- ");
  }
  // //console.log("------allCount------- :", allCount);
  store.dispatch(sportsActions.onGetEventCount(allCount));
  return allCount;
};

export const groupMarkets = originEvent => {
  // debugger
  if (

    isEmpty(originEvent) ||
    isEmpty(originEvent.Markets) ||
    originEvent.Markets.length === 0
  )
    return [];
  let resData = [];

  let event = $.extend(true, {}, originEvent);
  // //console.log(event)
  // let providerMarkets = [];

  getProvidedMarketIds(originEvent);

  // //console.log(providerMarkets);
  for (let i = 0; i < providerMarkets.length; i++) {
    resData.push({
      type: providerMarkets[i].name,
      name_kor: providerMarkets[i].name_kor,
      data: []
    });
  }
  for (let i = 0; i < event.Markets.length; i++) {
    const market = event.Markets[i];
    const marketLevel = getMarketLevel(market, event);
    if (isEmpty(marketLevel)) continue;
    const levelIndex = Math.floor(marketLevel / 6000.0);
    // //console.log(levelIndex, resData);
    let resSubData = resData.findIndex(
      item => item.type === providerMarkets[levelIndex].name
    );
    // //console.log(resData[resSubData]);
    resData[resSubData].data.push(market);
  }
  return resData;
};

export const getMatchInfo = sportList => {
  if (isEmpty(sportList)) return getTeamInfo();
  // sortMarkets(sportList);
  return getTeamInfo(sportList, "sports");
};

export const getFirstMarket = (markets, sportsId) => {
  let homeDrawAwayMarketId = 69;
  let homeAwayMarketId = 466;
  if (isEmpty(markets)) {
    return -1;
  }
  else {
    // if (sportsId === '35232') {
    //   const index = markets.findIndex(
    //     element => (element.Id == 69) && element.isWholeMatch
    //   );
    //   return index;
    // }
    const index = markets.findIndex(
      element => (element.Id == 69 || element.Id == 466) && element.isWholeMatch && element.name.includes("Whole Match")
    );
    if (index < 0) {
      const nextIndex = markets.findIndex(
        element => (element.Id == homeDrawAwayMarketId || element.Id == homeAwayMarketId) && (element.isWholeMatch || element.name.includes(" Ordinary Time"))
      );
      return nextIndex;
    }
    else {
      return index;
    }
  }
}


export const sortMarkets = (event, kind_Id) => {
  if (isEmpty(event) || isEmpty(event.Markets) || event.Markets.length === 0) {
    return event;
  }

  getProvidedMarketIds(event);
  // //console.log(providerMarkets, providedMarketIds)
  const { handicap_set } = store.getState().bet;
  const { baselineSetting } = store.getState().sports;
  const { pageInfo } = store.getState().page;

  let destEvent = $.extend(true, {}, event);
  // //console.log('destEvent.Markets: ', destEvent.FixtureId);
  let getHandicapType = "";
  let overunderBaseline = {};
  let handicapBaseline = {};
  let isHandicap = false;
  let isHomeDrawAway = false;

  //---------------핸디캡 현시/미현시 관련-------------------
  if (!isEmpty(handicap_set)) {
    if (pageInfo.sportType === "esport") {
      getHandicapType = destEvent.Fixture.League.sport.oddsId;
      let index = handicap_set.esport.findIndex(set => set.type == getHandicapType);
      if (index > -1) {
        isHandicap = handicap_set.esport[index].value.value == 1 ? true : false;
      }
    }
    else {
      getHandicapType = destEvent.Fixture.League.sport.Id;
      let index = handicap_set.sport.findIndex(set => set.type == getHandicapType);
      if (index > -1) {
        isHandicap = handicap_set.sport[index].value.value == 1 ? true : false;
      }
      // //console.log('isHandicap = handicap_set.sport[index].value.value: ', isHandicap, handicap_set.sport[index].value.value);
    }
  }
  //---------------핸디캡 현시/미현시 끝-------------------

  //---------------BaseLine 0인거 현시/미현시 관련-------------------

  //------------------------Handicap--------------------------//


  if (!isEmpty(baselineSetting)) {

    if (pageInfo.sportType === "esport" && !isEmpty(baselineSetting.esportHandicap)) {
      const Bselineindex = baselineSetting.esportHandicap.findIndex(setting => setting.sportId == destEvent.Fixture.League.sport.oddsId);
      if (Bselineindex == -1) {
        handicapBaseline = {};
      } else {
        handicapBaseline = baselineSetting.esportHandicap[Bselineindex];
      }
    }
    if (pageInfo.sportType === "sport" && !isEmpty(baselineSetting.sportHandicap)) {
      const Bselineindex = baselineSetting.sportHandicap.findIndex(setting => setting.sportId == destEvent.Fixture.League.sport.Id);
      if (Bselineindex == -1) {
        handicapBaseline = {};
      } else {
        handicapBaseline = baselineSetting.sportHandicap[Bselineindex];
      }
    }
  }
  //--------------------Handicap End-------------------------//

  //------------------------Under/Over--------------------------//
  if (!isEmpty(baselineSetting)) {
    if (pageInfo.sportType === "esport" && !isEmpty(baselineSetting.esportOverunder)) {
      const Bselineindex = baselineSetting.esportOverunder.findIndex(setting => setting.sportId == destEvent.Fixture.League.sport.oddsId);
      if (Bselineindex == -1) {
        overunderBaseline = {};
      } else {
        overunderBaseline = baselineSetting.esportOverunder[Bselineindex];

      }
    }
    if (pageInfo.sportType === "sport" && !isEmpty(baselineSetting.sportOverunder)) {
      const Bselineindex = baselineSetting.sportOverunder.findIndex(setting => setting.sportId == destEvent.Fixture.League.sport.Id);
      if (Bselineindex == -1) {
        overunderBaseline = {};
      } else {
        overunderBaseline = baselineSetting.sportOverunder[Bselineindex];

      }
    }
  }
  //--------------------Under/Over End-------------------------//
  //---------------BaseLine 0인거 현시/미현시 끝------------------- //


  for (let i = 0; i < destEvent.Markets.length; i++) {
    const marketId = destEvent.Markets[i].Id;
    const BaseLine = (isEmpty(destEvent.Markets[i].BaseLine) || isNaN(Number(destEvent.Markets[i].BaseLine))) ? 0 : Number(destEvent.Markets[i].BaseLine);
    const MarketName = destEvent.Markets[i].name;
    const destMarket = destEvent.Markets[i];

    const market = destEvent.Markets[i];
    const marketLevel = getMarketLevel(market, destEvent);
    // //console.log(marketLevel);
    if (marketLevel === "none") {
      destEvent.Markets.splice(i, 1);
      i--;
      continue;
    }

    //-------------------------프로바이더 필터----------------------------------//
    // if(destEvent.FixtureId == "207219585170173952"){
    //   //console.log('destEvent.Markets: ', destMarket.Providers[0].Bets , destEvent.FixtureId, checkMarket(destMarket));
    // }

    // ----------------------- 농구에서 전반전 후반전 없애기 --------------------------- //
    if ((destEvent.Fixture.League.sport.Id == "48242") && (destMarket.eventPartId == "63" || destMarket.eventPartId == "64")) {
      destEvent.Markets.splice(i, 1);
      i--;
    }
    else if (!checkMarket(destMarket, kind_Id)) {
      destEvent.Markets.splice(i, 1);
      i--;
    }
    else {
      // const providerId = getProviderId();
      // let isCheckMarket = false;
      // if (!isEmpty(providerId)) {
      //   destMarket.Providers[0].Bets.forEach(element => {
      //     const priceElement = element.Price.filter(betprice => providerId == betprice.providerId);
      //     element.Price = element.Price.filter(betprice => providerId == betprice.providerId);
      //     if (isEmpty(priceElement)) return (isCheckMarket = true);
      //     // if (fixedNumber(element.Price[0].Price) < 1) return (isCheckMarket = true);
      //   });
      // }
      // if (isCheckMarket) {
      //   destMarket.Providers[0].Bets = [];
      // }
      // if(destEvent.FixtureId == "210318504672514048"){
      //   //console.log('destEvent.Markets: ', destMarket, destEvent.FixtureId);
      // }
      //-------------------------프로바이더 필터 끝----------------------------------//

      //--------------------핸디캡 미현시---------------------
      // if(marketId == 48 && !isHandicap){  // 48인 마켓의 경우에만
      if (MarketName.includes('Handicap') && !isHandicap) {  // 마켓에 핸디캡이라는 문자가 들어간 경우
        destEvent.Markets.splice(i, 1);
        i--;
        continue;
      }
      //-----------------핸디캡 미현시 끝----------------------


      //--------------------BaseLine 0인거 현시 미현시---------------------

      //--------------------------Handicap--------------------------------


      // const index = data.sportKor.findIndex(setting => setting.sportGameId == destEvent.Fixture.League.sport.Id);

      if (MarketName.includes('Handicap')) {
        if (handicapBaseline.status) {

          // console.log('handicapBaseline: ', handicapBaseline);/
          if (BaseLine >= Number(handicapBaseline.min) && BaseLine <= Number(handicapBaseline.max)) {
            destEvent.Markets.splice(i, 1);
            i--;
            continue;

          }
        }
      }

      //--------------------------Under/Over-------------------------------
      if (MarketName.includes('Over/Under')) {
        if (overunderBaseline.status) {
          if (BaseLine >= overunderBaseline.min && BaseLine <= overunderBaseline.max) {
            destEvent.Markets.splice(i, 1);
            i--;
            continue;
          }
        }
      }

      if (marketId == "69") {
        if (market.Providers[0].Bets.length !== 3) {
          destEvent.Markets.splice(i, 1);
          i--;

          continue;
        }
      }

      // if(BaseLine == 0){
      //   destEvent.Markets.splice(i, 1);
      //   i--;
      //   continue;
      // }
      //-----------------BaseLine 0인거 현시 미현시 끝----------------------

      // //-----------------------국내형 마켓들 현시-----------------------
      // if (store.getState().page.pageInfo.prematchType == "interior") {
      //   if (marketId == 69) {
      //     isHomeDrawAway = true;
      //   }
      //   // if (
      //   //   // !(
      //   //   //   destEvent.Markets[i].isWholeMatch &&
      //   //   //   (marketId == 69 ||
      //   //   //     marketId == 466 ||
      //   //   //     marketId == 47 
      //   //   //     || (marketId == 48 && isHandicap)
      //   //   //     )
      //   //   // )
      //   //   !destEvent.Markets[i].isWholeMatch) {
      //   //   destEvent.Markets.splice(i, 1);
      //   //   i--;
      //   //   continue;
      //   // }
      //   // else {
      //   //   if (marketId == 69) {
      //   //     isHomeDrawAway = true;
      //   //   }
      //   // }
      //   // // //console.log('destEvent.Markets: ', destMarket, destEvent.FixtureId);
      //   // // if(destEvent.FixtureId == "210354494837510144"){
      //   // //   //console.log('destEvent.Markets: ', destMarket, destEvent.FixtureId);
      //   // // }
      //   // // //console.log('destMarket: ', destMarket);
      //   // //----------------국내형 마켓들 현시 끝---------------------
      // }
      // else {
      // const subMarkets = destEvent.Markets.slice(i + 1, destEvent.Markets.length);
      if (!checkMarket(destEvent.Markets[i], kind_Id)) {
        destEvent.Markets.splice(i, 1);
        i--;
      } else if (window.apiType === "odds") {
        let index, homeId, awayId;
        homeId = isEmpty(destEvent.Fixture.Participants)
          ? ""
          : destEvent.Fixture.Participants[0].participantId;
        awayId = isEmpty(destEvent.Fixture.Participants)
          ? ""
          : destEvent.Fixture.Participants[1].participantId;
        // homeId = isEmpty(destEvent.Fixture.Participants) ? "" : destEvent.Fixture.Participants[0].Id;
        // awayId = isEmpty(destEvent.Fixture.Participants) ? "" : destEvent.Fixture.Participants[1].Id;
        let bets = [];
        let home = {};
        let draw = {};
        let away = {};
        if (destEvent.Markets[i].Providers[0].Bets.length === 2) {
          destEvent.Markets[i].Providers[0].Bets.forEach(element => {
            // if (
            //   typeof element.participantIdString !== "object" &&
            //   isEmpty(typeof element.participantIdString)
            // )
            //   toastr.warning(`participantId 없음`);
            // debugger
            // if(destEvent.FixtureId === "212132127439998976" ) //console.log(element.participantIdString, homeId)
            if (element.participantIdString == homeId) {
              home = element;
              // home.Name = "1";
              // element.Name = "1";
            } else if (element.participantIdString == awayId) {
              away = element;
              // away.Name = "2";
              // element.Name = "2";
            }
            // else bets.push(element);
          });
          if (!isEmpty(home) && !isEmpty(away)) {
            bets = [home, away];
            destEvent.Markets[i].Providers[0].Bets = bets;
          }
          // //console.log(bets)
        }
        if (destEvent.Markets[i].Providers[0].Bets.length === 3) {
          destEvent.Markets[i].Providers[0].Bets.forEach(element => {
            // if (
            //   typeof element.participantIdString !== "object" &&
            //   isEmpty(typeof element.participantIdString)
            // )
            //   toastr.warning(`participantId 없음`);
            if (element.outcomeTypeId === 11 || element.Name === "Draw") {
              draw = element;
              // draw.Name = "X";
              // element.Name = "X";
            } else {
              if (element.outcomeTypeId === 10) {
                if (element.participantIdString == homeId) {
                  home = element;
                  // home.Name = "1";
                  // element.Name = "1";
                }
                if (element.participantIdString == awayId) {
                  away = element;
                  // away.Name = "2";
                  // element.Name = "2";
                }
              }

              // else{
              //     bets.push(element);
              // }
            }
          });

          if (!isEmpty(home) && !isEmpty(draw) && !isEmpty(away)) {
            bets = [home, draw, away];
            destEvent.Markets[i].Providers[0].Bets = bets;
            // //console.log(bets)
          }
        }
        destEvent.Markets[i].Providers[0].Bets.sort((a, b) => nameNumber(a.Name, destEvent) - nameNumber(b.Name, destEvent));
      }
      // }
    }
  }

  // // 승무패일때, 승패없애기
  // if (isHomeDrawAway) {
  //   destEvent.Markets = destEvent.Markets.filter(market => market.Id != 466);
  //   // destEvent.Markets.splice(index, 1);
  // }



  const marketSettings = store.getState().sports.marketSetting;
  // console.log('marketSettings: ', marketSettings);
  // //console.log('handicap_set: ', handicap_set);

  const getMarketSetting = (data) => {


    if (isEmpty(data)) {
      return []
    }
    else if (pageInfo.sportType === "sport") {
      if (pageInfo.gameType === 'prematch') {
        if (pageInfo.prematchType === 'interior') {
          // switch (destEvent.Fixture.League.sport.name) {
          //   case "Football":
          //     return data.sportKor["soccer"];

          //   case "Ice Hockey":
          //     return data.sportKor["icehockey"];

          //   case "Basketball":
          //     return data.sportKor["basketball"];

          //   case "Volleyball":
          //     return data.sportKor["volleyball"];

          //   case "Baseball":
          //     return data.sportKor["baseball"];

          //   case "Table Tennis":
          //     return data.sportKor["tabletennis"];

          //   case "Am. Football":
          //     return data.sportKor["football"];

          //   case "Tennis":
          //     return data.sportKor["tennis"];

          //   case "Cricket ":
          //     return data.sportKor["cricket"];

          //   default:
          //     return [];
          // }
          const index = data.sportKor.findIndex(setting => setting.sportGameId == destEvent.Fixture.League.sport.Id);
          if (index == -1) return [];
          else return data.sportKor[index].search
        }
        else {
          const index = data.sportPrematch.findIndex(setting => setting.sportGameId == destEvent.Fixture.League.sport.Id);
          if (index < 0) {
            return [];
          }
          else {
            return data.sportPrematch[index].search;
          }
        }
      }
      else {
        const index = data.sportLive.findIndex(setting => setting.sportGameId == destEvent.Fixture.League.sport.Id);
        if (index < 0) {
          return [];
        }
        else {
          return data.sportLive[index].search;
        }
      }
    }
    else {
      if (pageInfo.gameType === 'prematch') {
        const index = data.esportPrematch.findIndex(setting => setting.sportGameId == destEvent.Fixture.League.sport.oddsId);
        if (index < 0) {
          return [];
        }
        else {
          return data.esportPrematch[index].search;
        }
      }
      else {
        const index = data.esportLive.findIndex(setting => setting.sportGameId == destEvent.Fixture.League.sport.oddsId);
        if (index < 0) {
          return [];
        }
        else {
          return data.esportLive[index].search;
        }
      }
    }
  }

  const marketSetting = getMarketSetting(marketSettings);
  if (isEmpty(marketSetting)) {
    destEvent.Markets = [];
  }
  else {
    //------------------설정된 마켓의 설정된 갯수만 현시--------------------------
    let allowMarkets = []
    marketSetting.forEach(setting => {
      // //console.log('marketSetting: ', marketSetting);
      const allMarkets = destEvent.Markets.filter(item => item.Id == setting.market_id);
      let filteredMarkets = [];
      // //console.log("setting.value", setting, setting.value, allMarkets)
      // if(setting.value > 0){
      for (let i = 0; i < allMarkets.length; i++) {
        const element = allMarkets[i];
        // //console.log("element", element)
        filteredMarkets.push(element);
        let elementCnt = 1;

        for (let j = i + 1; j < allMarkets.length; j++) {
          const element1 = allMarkets[j];
          if (!isEmpty(element.eventPartId) && !isEmpty(element1.eventPartId)) {
            if (element.eventPartId === element1.eventPartId) {
              // if(element.eventPartId.toString() === element1.evenPartId.toString()){
              // if(elementCnt < setting.value){
              //   allowMarkets.push(element1);
              //   elementCnt++;
              // }
              filteredMarkets.push(element1);
              allMarkets.splice(j, 1);
              j--;
            }
          }
        }

        let rateSetting = false;
        // //console.log(baselineSetting,);
        if (!isEmpty(baselineSetting)) {
          if (pageInfo.sportType === "esport") {
            rateSetting = baselineSetting.eunderovertype;
          }
          if (pageInfo.sportType === "sport") {
            rateSetting = baselineSetting.underovertype;
          }
        }


        // //------------------------angel--------------------------------//
        // let baselinearray = [];
        // filteredMarkets.forEach((value, index) => {
        //   let baseArray = [];
        //   for (let i = 0; i < setting.value; i++) {
        //     baseArray.push(index + i);

        //   }
        //   baselinearray.push([ value ])

        //  });



        // 홈, 어웨이 배당율차이가 급격/비슷 순서로 정렬
        // //console.log("filteredMarkets", filteredMarkets, rateSetting)
        filteredMarkets.sort((a, b) => {
          if ((a.Providers[0].Bets.length === 2) && (b.Providers[0].Bets.length === 2)) {
            const aValue = Math.floor(Math.abs(
              Number(a.Providers[0].Bets[0].Price[0].Price) - Number(a.Providers[0].Bets[1].Price[0].Price)
            ) * 1000);
            const bValue = Math.floor(Math.abs(
              Number(b.Providers[0].Bets[0].Price[0].Price) - Number(b.Providers[0].Bets[1].Price[0].Price)
            ) * 1000);
            if (rateSetting) {
              return bValue - aValue;
            }
            else {
              return aValue - bValue;
            }

          } else return 0;
        });
        // //console.log("filteredMarkets", allMarkets, filteredMarkets, setting.value);

        // ------------ 배당율 차이가 적은 기준점 선택 --------------- //
        const baseline = filteredMarkets[0].BaseLine;
        filteredMarkets.sort((a, b) => {
          let aValue = a.BaseLine;
          let bValue = b.BaseLine;
          return aValue - bValue;
        })


        const baseLineIndex = filteredMarkets.findIndex(market => market.BaseLine === baseline);
        let upLimit = 0;
        let downLimit = 0;

        //*****************************************************************************//
        const eventPartIndex = setting.eventPartIds.findIndex(event => event.Id === element.eventPartId);
        if (setting.eventPartIds.length === 0) {
          upLimit = filteredMarkets.length;
          downLimit = 0;
        }
        else if (eventPartIndex < 0) {
          upLimit = 0;
          downLimit = 0;
        }
        else {
          const marketValue = setting.eventPartIds[eventPartIndex].value;
          if ((Number(marketValue) > filteredMarkets.length) || (Number(marketValue) === 0)) {
            upLimit = filteredMarkets.length;
            downLimit = 0;
          }
          else if ((baseLineIndex - Math.ceil(Number(marketValue) / 2)) < 0) {
            upLimit = Number(marketValue);
            downLimit = 0;
          }
          else if (Math.ceil(baseLineIndex + Number(marketValue) / 2) > filteredMarkets.length) {
            upLimit = filteredMarkets.length;
            downLimit = filteredMarkets.length - Number(marketValue);
          }
          else {
            upLimit = Math.ceil(baseLineIndex + Number(marketValue) / 2);
            downLimit = Math.ceil(baseLineIndex - Number(marketValue) / 2);
          }

        }

        if (eventPartIndex > -1) {
          const marketValue = setting.eventPartIds[eventPartIndex].value;
          if (((Number(marketValue) % 2) === 0) && (element.Providers[0].Bets.length === 2)) {
            const upLimitNextObject = filteredMarkets[Number(upLimit)];
            const downLimitNextObject = filteredMarkets[Number(downLimit)];
            if (!isEmpty(upLimitNextObject)) {
              const rateDifferentA = Math.floor(Math.abs(
                Number(upLimitNextObject.Providers[0].Bets[0].Price[0].Price) - Number(upLimitNextObject.Providers[0].Bets[1].Price[0].Price)
              ) * 1000);
              const rateDifferentB = Math.floor(Math.abs(
                Number(downLimitNextObject.Providers[0].Bets[0].Price[0].Price) - Number(downLimitNextObject.Providers[0].Bets[1].Price[0].Price)
              ) * 1000);
              if (rateDifferentA < rateDifferentB) {

                upLimit++;
                downLimit++;
              }
            }
          }
        }
        const slicedfilteredMarkets = filteredMarkets.slice(downLimit, upLimit);

        allowMarkets = [...allowMarkets, ...slicedfilteredMarkets];
        // if(store.getState().page.pageInfo.prematchType == "interior" && element.isWholeMatch){
        //   allMarkets.splice(i, allMarkets.length);
        // }
        filteredMarkets = [];
        // //console.log("allowMarkets", allowMarkets, setting.value)
      }





      // }
      // else{
      //   let rateSetting = false;
      //   // console.log(baselineSetting);
      //     if(!isEmpty(baselineSetting)){
      //       if(pageInfo.sportType === "esport"){
      //         rateSetting = baselineSetting.eunderovertype;
      //       }
      //       if(pageInfo.sportType === "sport"){
      //         rateSetting = baselineSetting.underovertype;
      //       }
      //     }

      //   allMarkets.sort((a, b) => {
      //     if ((a.Providers[0].Bets.length === 2) && (b.Providers[0].Bets.length === 2)) {
      //       const aValue = Math.floor(Math.abs(
      //         Number(a.Providers[0].Bets[0].Price[0].Price) - Number(a.Providers[0].Bets[1].Price[0].Price)
      //       ) * 1000);
      //       const bValue = Math.floor(Math.abs(
      //         Number(b.Providers[0].Bets[0].Price[0].Price) - Number(b.Providers[0].Bets[1].Price[0].Price)
      //       ) * 1000);
      //       if(rateSetting){
      //         return bValue - aValue;
      //       }
      //       else{
      //         return aValue - bValue
      //       }

      //     } else return 0;
      //   });
      //   // //console.log(allowMarkets)
      //   // //console.log(allMarkets)
      //   allowMarkets = [ ...allowMarkets, ...allMarkets ];
      //   // allowMarkets = [ ...allowMarkets, ...allMarkets.slice(0, 3) ];
      // }
      // if(destEvent.Markets.filter(item => item.Id == setting.market_id).length < setting.value){
      //   allowMarkets = [ 
      //     ...allowMarkets, 
      //     destEvent.Markets.filter(item => item.Id == setting.market_id)
      //   ]
      // }
      // else{
      //   allowMarkets = [ 
      //     ...allowMarkets, 
      //     destEvent.Markets.filter(item => item.Id == setting.market_id).splice(0, setting.value)
      //   ]
      // }

    });

    destEvent.Markets = allowMarkets;
    // //console.log('destEvent: ', destEvent.Markets);
  }
  // // 홈, 어웨이 배당율차이가 적은 순서로 정렬
  // destEvent.Markets.sort((a, b) => {
  //   if (a.Providers[0].Bets.length === 2 && b.Providers[0].Bets.length === 2) {
  //     let aValue = Math.abs(
  //       a.Providers[0].Bets[0].Price[0].Price - a.Providers[0].Bets[1].Price[0].Price
  //     );
  //     let bValue = Math.abs(
  //       b.Providers[0].Bets[0].Price[0].Price - b.Providers[0].Bets[1].Price[0].Price
  //     );
  //     return aValue * 1000 - bValue * 1000;
  //   } else return 0;
  // });

  //   });

  //   destEvent.Markets = allowMarkets;
  //   // //console.log('destEvent: ', destEvent.Markets);
  // }


  // 홈, 어웨이 배당율차이가 적은 순서로 정렬
  // destEvent.Markets.sort((a, b) => {
  //           if ((a.Providers[0].Bets.length === 2) && (b.Providers[0].Bets.length === 2)) {
  //             const aValue = Math.floor(Math.abs(
  //               Number(a.Providers[0].Bets[0].Price[0].Price) - Number(a.Providers[0].Bets[1].Price[0].Price)
  //             ) * 1000);
  //             const bValue = Math.floor(Math.abs(
  //               Number(b.Providers[0].Bets[0].Price[0].Price) - Number(b.Providers[0].Bets[1].Price[0].Price)
  //             ) * 1000);
  //               return aValue - bValue

  //           } else return 0;
  //         });


  // // 국내형에서 오버/언더, 핸디캡을 3개만

  // if (store.getState().page.pageInfo.prematchType == "interior") {
  //   // //console.log('destEvent.Markets: ', destEvent.Markets);

  //   let rateSetting = false;
  //   if (!isEmpty(baselineSetting)) {
  //     if (pageInfo.sportType === "esport" && !isEmpty(baselineSetting.ehandcap)) {
  //       rateSetting = baselineSetting.ehandcap;
  //     }
  //     if (pageInfo.sportType === "sport" && !isEmpty(baselineSetting.handcap)) {
  //       rateSetting = baselineSetting.handcap;
  //     }
  //   }

  //   //-----------------------------배당율 차이가 작은거 현시--------------------------------------------//
  //   if (!rateSetting) {
  //     //-------------------기준점찾기---------------------
  //     const overUnderIndex = destEvent.Markets.findIndex(market => market.Id == 47);
  //     const handicapIndex = destEvent.Markets.findIndex(market => market.Id == 48);
  //     //------------------기준점 찾기 끝------------------

  //     let overUnder = [];
  //     let handicap = [];

  //     if (overUnderIndex > -1) {
  //       //---------------기준점을 중심으로 3개 골라내기----------------------

  //       const overUnderBaseLine = destEvent.Markets[overUnderIndex].BaseLine;

  //       if (!isNaN(overUnderBaseLine)) {
  //         overUnder.push(destEvent.Markets[overUnderIndex]);
  //         const overUnderIndex1 = destEvent.Markets.findIndex(market => (market.Id == 47) && (market.BaseLine == Number(overUnderBaseLine) - 0.5));
  //         if (overUnderIndex1 > -1) {
  //           overUnder.push(destEvent.Markets[overUnderIndex1]);
  //         }
  //         const overUnderIndex2 = destEvent.Markets.findIndex(market => (market.Id == 47) && (market.BaseLine == Number(overUnderBaseLine) + 0.5));
  //         if (overUnderIndex2 > -1) {
  //           overUnder.push(destEvent.Markets[overUnderIndex2]);
  //         }
  //       }
  //       // //console.log('overUnder: ', overUnder);
  //       //--------------기준점을 중심으로 3개 골라내기 끝--------------------
  //     }



  //     if (handicapIndex > -1) {
  //       //---------------기준점을 중심으로 3개 골라내기----------------------

  //       const handicapBaseLine = destEvent.Markets[handicapIndex].BaseLine;

  //       if (!isNaN(handicapBaseLine)) {
  //         handicap.push(destEvent.Markets[handicapIndex]);
  //         const handicapIndex1 = destEvent.Markets.findIndex(market => (market.Id == 48) && (market.BaseLine == Number(handicapBaseLine) - 0.5));
  //         if (handicapIndex1 > -1) {
  //           handicap.push(destEvent.Markets[handicapIndex1]);
  //         }
  //         const handicapIndex2 = destEvent.Markets.findIndex(market => (market.Id == 48) && (market.BaseLine == Number(handicapBaseLine) + 0.5));
  //         if (handicapIndex2 > -1) {
  //           handicap.push(destEvent.Markets[handicapIndex2]);
  //         }
  //         // //console.log('handicap: ', handicap);
  //       }
  //       //--------------기준점을 중심으로 3개 골라내기 끝--------------------
  //     }

  //     destEvent.Markets = destEvent.Markets.filter(market => market.Id != '47' && market.Id != '48');
  //     destEvent.Markets = destEvent.Markets.concat(overUnder, handicap);
  //   }
  //   // else{
  //   //   //-------------------기준점찾기---------------------
  //   //   let overUnder = destEvent.Markets.filter(market => market.Id == 47);
  //   //   let handicap = destEvent.Markets.filter(market => market.Id == 48);
  //   //   //------------------기준점 찾기 끝------------------



  //   //   if(overUnderIndex > -1){
  //   //     //---------------기준점을 중심으로 3개 골라내기----------------------

  //   //     const overUnderBaseLine = destEvent.Markets[overUnderIndex].BaseLine;

  //   //     if(!isNaN(overUnderBaseLine)){
  //   //       overUnder.push(destEvent.Markets[overUnderIndex]);
  //   //       const overUnderIndex1 = destEvent.Markets.findIndex(market => (market.Id == 47) && (market.BaseLine == Number(overUnderBaseLine) - 0.5));
  //   //       if(overUnderIndex1 > -1){
  //   //         overUnder.push(destEvent.Markets[overUnderIndex1]);
  //   //       }
  //   //       const overUnderIndex2 = destEvent.Markets.findIndex(market => (market.Id == 47) && (market.BaseLine == Number(overUnderBaseLine) + 0.5));
  //   //       if(overUnderIndex2 > -1){
  //   //         overUnder.push(destEvent.Markets[overUnderIndex2]);
  //   //       }
  //   //     }
  //   //       // //console.log('overUnder: ', overUnder);
  //   //     //--------------기준점을 중심으로 3개 골라내기 끝--------------------
  //   //   }



  //   //   if(handicapIndex > -1){
  //   //     //---------------기준점을 중심으로 3개 골라내기----------------------

  //   //     const handicapBaseLine = destEvent.Markets[handicapIndex].BaseLine;

  //   //     if(!isNaN(handicapBaseLine)){
  //   //       handicap.push(destEvent.Markets[handicapIndex]);
  //   //       const handicapIndex1 = destEvent.Markets.findIndex(market => (market.Id == 48) && (market.BaseLine == Number(handicapBaseLine) - 0.5));
  //   //       if(handicapIndex1 > -1){
  //   //         handicap.push(destEvent.Markets[handicapIndex1]);
  //   //       }
  //   //       const handicapIndex2 = destEvent.Markets.findIndex(market => (market.Id == 48) && (market.BaseLine == Number(handicapBaseLine) + 0.5));
  //   //       if(handicapIndex2 > -1){
  //   //         handicap.push(destEvent.Markets[handicapIndex2]);
  //   //       }
  //   //         // //console.log('handicap: ', handicap);
  //   //     }
  //   //     //--------------기준점을 중심으로 3개 골라내기 끝--------------------
  //   //   }

  //   //   destEvent.Markets = destEvent.Markets.filter(market => market.Id != '47' && market.Id != '48');
  //   //   destEvent.Markets = destEvent.Markets.concat(overUnder, handicap);
  //   // }
  //   // //console.log("destEvent.Markets", destEvent.Markets, overUnder, handicap)
  //   // let interiorUNcnt = 0;
  //   // let interiorHcnt = 0;
  //   // for (let i = 0; i < destEvent.Markets.length; i++) {

  //   //   const marketId = destEvent.Markets[i].Id;
  //   //   if (marketId == 47) interiorUNcnt++;
  //   //   if (marketId == 48) interiorHcnt++;

  //   //   if (
  //   //     (marketId == 47 && interiorUNcnt > 3) ||
  //   //     (marketId == 48 && interiorHcnt > 3)
  //   //   ) {
  //   //     destEvent.Markets.splice(i, 1);
  //   //     i--;
  //   //     // continue;
  //   //   }
  //   // }


  // }


  // 기준점으로 정렬
  destEvent.Markets.sort((a, b) => {
    if (!isEmpty(a.BaseLine) && !isEmpty(b.BaseLine)) {
      let aValue = Number(a.BaseLine);
      let bValue = Number(b.BaseLine);
      return aValue - bValue;
    } else return 0;
  });

  // 
  destEvent.Markets.sort((a, b) => {
    let aValue = Number(a.eventPartId) + (isNaN(a.Providers[0].Bets[0].participantIdString) ? 0 : Number(a.Providers[0].Bets[0].participantIdString) * 0.1);
    let bValue = Number(b.eventPartId) + (isNaN(b.Providers[0].Bets[0].participantIdString) ? 0 : Number(b.Providers[0].Bets[0].participantIdString) * 0.1);
    return aValue - bValue;
  });

  // 마켓종류별로 정렬
  destEvent.Markets.sort((a, b) => {
    if (isEmpty(getMarketLevel(a, destEvent)) || isEmpty(getMarketLevel(b, destEvent))) {
      // console.log('getMarketLevel: ', getMarketLevel(a, destEvent), getMarketLevel(b, destEvent), a, b);
    }
    let aValue = getMarketLevel(a, destEvent);
    let bValue = getMarketLevel(b, destEvent);
    return aValue - bValue;
  });

  let index = getFirstMarket(destEvent.Markets, destEvent.Fixture.League.sport.Id);

  if (index < 0) {
    index = destEvent.Markets.findIndex(
      element => element.Id == 69 || element.Id == 466
    );
  }

  if (index > -1) {
    let windrawAway = destEvent.Markets[index];
    destEvent.Markets = [
      windrawAway,
      ...destEvent.Markets.filter(
        item => item.MarketID !== destEvent.Markets[index].MarketID
      )
    ];
  }
  //console.log("destEvent", destEvent);
  destEvent = OrderSortMarkets(destEvent);
  // //console.log('getDate: ', getDate());
  return destEvent;
};
