import React, { useState } from 'react';
import PointRequest from './PointRequest';
import PointConvertHistory from './PointConvertHistory';
import { no_comma } from 'Common';
import { useDispatch, useSelector } from 'react-redux'
import { moreActions } from 'Common/store';
import PointMethod from './PointMethod';


const PointContents = () => {

    const dispatch = useDispatch();
    const [popup, setPopup] = useState(false);
    const [money, setMoney] = useState(0);
    const [ids, setIds] = useState([]);
    const onChange = (e) => {
        const { value, checked } = e.target;
        if (checked) setIds([...ids, value]);
        else setIds(ids.filter(item => item !== value))
    
        if (!isNaN(Number(no_comma(value))))
          setMoney(Number(no_comma(value)));
        else setMoney(0);
      };

      const reset = () => {
        setMoney(0);
      }

      const DoPointConvert = () => {
        dispatch(moreActions.doExchange(money));
      }

      const onPopup = (val) => {
        setPopup(!popup);
        setIds([val]);
      };

      const onDeletPointExchagneHistory = (val) => {
        dispatch(moreActions.deleteExchange({
            ids,
            all:val
        }));
        setPopup(!popup);
      }

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title">포인트전환</div>
                <div className="write_box">
                  <PointMethod />
                    <PointRequest onChange={onChange} money={money} setMoney={setMoney} reset={reset} />
                </div>
                <div className="con_box10">
                    <div className="btn_wrap_center">
                        <ul>
                            <li onClick={DoPointConvert}><a ><span className="btn3_1">포인트전환</span></a></li>
                        </ul>
                    </div>
                </div>
                <PointConvertHistory popup={popup} onPopup={onPopup} onDeletPointExchagneHistory={onDeletPointExchagneHistory} />
            </div>
        </div>
    );
};

export default PointContents;