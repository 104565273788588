import { dateFormation, getMatchInfo, isEmpty, onErrorImg, sortMarkets, fixedNumber } from 'Common';
import { betActions, sportsActions } from 'Common/store';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
const marketNames = require('../../Common/JSON/market.json')
const eventPartNames = require('../../Common/JSON/eventpart')



const Match = (props) => {


    const dispatch = useDispatch();
    const { type, gamelist, onShowMarket } = props;
    const bettedItems = useSelector(state => state.bet.bettedItems);
    const baselineSetting = useSelector(state => state.sports.baselineSetting);
    const activeId = useSelector(state => state.sports.activeId);
    const pageInfo = useSelector(state => state.page.pageInfo);
    const bet = useSelector(state => state.bet)

    const { sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting } = baselineSetting;
    let sportId = type !== "esports" ? gamelist.Fixture.League.sport.Id : gamelist.Fixture.League.sport.oddsId;
    const rateValue = useMemo(() => {
        //  const sportId = gamelist.Fixture.League.sport.Id;
        let sportsRateIndex = -1;
        let sportRate = {}
        if (pageInfo.RoomType === "sport") {
            if (pageInfo.gameType === "live") {
                sportsRateIndex = isEmpty(sportLiveRateSetting) ? -1 : sportLiveRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? sportLiveRateSetting[sportsRateIndex] : {};
            }
            else {
                sportsRateIndex = isEmpty(sportPrematchRateSetting) ? -1 : sportPrematchRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? sportPrematchRateSetting[sportsRateIndex] : {};
            }


            if (!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)) {
                return {
                    min: Number(sportRate.min),
                    max: Number(sportRate.max),
                }
            }
            else {
                return {}
            }
        }
        else {
            if (pageInfo.gameType === "live") {
                sportsRateIndex = isEmpty(esportLiveRateSetting) ? -1 : esportLiveRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? esportLiveRateSetting[sportsRateIndex] : {};
            }
            else {
                sportsRateIndex = isEmpty(esportPrematchRateSetting) ? -1 : esportPrematchRateSetting.findIndex(setting => setting.sportId == sportId);
                sportRate = sportsRateIndex > -1 ? esportPrematchRateSetting[sportsRateIndex] : {};
            }


            if (!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)) {
                return {
                    min: Number(sportRate.min),
                    max: Number(sportRate.max),
                }
            }
            else {
                return {}
            }
        }
    }, [pageInfo.gameType, sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting]);


    // getMatchInfo(gamelist);
    const sortedMarket = sortMarkets(gamelist, sportId);
    const matchInfo = getMatchInfo(sortedMarket);

    const { key,
        FixtureId,
        sportImg,
        leagueName,
        locationName,
        imgPath,
        homeTeam,
        awayTeam,
        time,
        rateHome,
        rateDraw,
        rateAway,
        countMarket,
        liveScoreHome,
        liveScoreAway,
        marketIndex,
        liveState,
        marketName,
        marketId
    } = matchInfo

    const [nowHomeRate, setNowHomeRate] = useState(rateHome);
    const [nowDrawRate, setNowDrawRate] = useState(rateDraw);
    const [nowAwayRate, setNowAwayRate] = useState(rateAway);

    const setTimeHomRate = () => {
        setTimeout(() => {
            setNowHomeRate(rateHome);
        }, 4600)
    }

    const setTimeDrawRate = () => {
        setTimeout(() => {
            setNowDrawRate(rateDraw);
        }, 4600)
    }

    const setTimeAwayRate = () => {
        setTimeout(() => {
            setNowAwayRate(rateAway);
        }, 4600)
    }

    useEffect(() => {
        if (nowHomeRate !== rateHome) {
            setTimeHomRate();
        }
        if (nowDrawRate !== rateDraw) {
            setTimeDrawRate();
        }
        if (nowAwayRate !== rateAway) {
            setTimeAwayRate();
        }
    }, [gamelist]);

    const getMarketInfo = (Id) => {
        let data = {
            sportType: pageInfo.sportType,
            gameType: pageInfo.gameType,
            fixtureId: Id
        }
        // debugger
        dispatch(sportsActions.GetMarketInfo({ params: data }))
    }

    useEffect(() => {
        clearTimeout(setTimeHomRate());
        clearTimeout(setTimeDrawRate());
        clearTimeout(setTimeAwayRate());
    }, [])


    const setActiveId = (key) => {
        dispatch(sportsActions.getIframeUrl(sortedMarket.FixtureId))
        dispatch(sportsActions.setActiveId(key))
    }

    const onActiveId = (key, FixtureId) => {
        getMarketInfo(FixtureId);
        setActiveId(key)
        onShowMarket();
    }

    const checkBetted = (index) => {
        // console.log(bettedItems.findIndex(item => item.betId === bet.Id) >= 0)
        let bet = getBet(index);
        if (isEmpty(bet)) return
        return bettedItems.findIndex(item => item.betId === bet.Id) >= 0;
    }

    const onBetItem = (index) => {
        setActiveId(key);
        getMarketInfo(FixtureId);
        if (isEmpty(sortedMarket.Markets[marketIndex])) return;
        const market = sortedMarket.Markets[marketIndex]
        let betList = { data: sortedMarket.Markets[marketIndex].Providers[0].Bets, marketId: market.Id };
        let bet = getBet(index)
        const Fixture = sortedMarket.Fixture;
        const verifyId = betList.special;
        // console.log(bettedItems)
        if (!checkBetted(index)) {

            const result = dispatch(betActions.onAddOneBet({ index, market, Fixture, bet, betList }));
            //   console.log(result)
            if (!result) return; // toastr.warning('배팅규정에 어긋납니다.');
        }
        else {
            dispatch(betActions.removeOneBet(bet.Id));
        }

    }

    const getBet = (index) => {
        //   debugger
        //   console.log(sortedMarket)
        //   console.log(gamelist)
        if (isEmpty(sortedMarket.Markets[marketIndex])) return;
        let betList = { data: sortedMarket.Markets[marketIndex].Providers[0].Bets };
        if (rateDraw === 'VS' && index === 2) index = 1;
        switch (index) {
            case 0:
                return betList.data[0]

            case 1:
                return betList.data[1]

            case 2:
                return betList.data[2]

            default:
                break;
        }
    }

    const bets = marketIndex > -1 ? sortedMarket.Markets[marketIndex].Providers[0].Bets : [];

    let minRate = 1;
    let maxRate = 10;
    if (pageInfo.gameType === "live") {
        if (pageInfo.sportType === "esport") {
            minRate = !isEmpty(baselineSetting.eliveminrate) ? baselineSetting.eliveminrate : 1
            maxRate = !isEmpty(baselineSetting.eliveminrate) ? baselineSetting.elivemaxrate : 1
        }
        else {
            minRate = !isEmpty(baselineSetting.liveminrate) ? baselineSetting.liveminrate : 1
            maxRate = !isEmpty(baselineSetting.liveminrate) ? baselineSetting.livemaxrate : 1
        }
    }
    return (
        <div className={`odds_item odd_item_country ${activeId === key && "on"}`} onClick={() => onActiveId(key, FixtureId)}>
            <div className="odds_item_league" >
                <div className="odds_item_league_l">
                    <div className="otll_img">
                        <img className="otll_img11" src={sportImg} alt={leagueName} />
                        <img className="otll_img22" src={imgPath} alt={locationName} onError={onErrorImg} />
                    </div>
                    <div className="otll_text">
                        <span className="otll_text_country">{locationName} / {leagueName}</span>
                    </div>
                </div>
                <div className="odds_item_league_r">
                    {type != "live" && <div className="oiil_time">{dateFormation(time, 10)}</div>}
                </div>
            </div>

            {
                type != "live" ?
                    <Fragment>
                        <div className="odds_item_info">
                            <div className="odds_item_info_l">
                                <div className="oiil_team">
                                    <span className="team" style={{ color: "#ff5d5d" }}>{homeTeam}</span>
                                    <span className="vs">VS</span>
                                    <span className="team" style={{ color: "#4bffff" }}>{awayTeam}</span>
                                </div>
                            </div>
                            <div className="odds_item_info_r">
                                <span className="btn_toggle_country">+{Number(countMarket).toLocaleString()}</span>
                            </div>
                        </div>
                        <div className="odds_item_game_box1">
                            <div className="odds_item_game">
                                <div className="odds_item_game_type oig_country"><div>{marketName}</div></div>
                                <div className="odds_item_game_bet oig_country">
                                    <div className={`oigb_btn ${checkBetted(0) && "on"}`} >
                                        <div className='gameLists' style={{ background: "unset", border: "unset" }} onClick={() => onBetItem(0)}>
                                            <span className="home">{homeTeam}</span>
                                            <em className={`${((rateHome > nowHomeRate) && "rate_up_effect ")} ${((rateHome < nowHomeRate) && " rate_down_effect ")}`}>{rateHome}</em>
                                        </div>

                                        {
                                            (marketIndex > -1) &&
                                            (
                                                ((bets[0].Price[0].status > 3) ||
                                                    (bets[0].Status == 10)) ||
                                                (!isEmpty(rateValue) &&
                                                    ((rateHome > rateValue.max) || (rateHome < rateValue.min))
                                                ) ||
                                                ((pageInfo.gameType === "live")
                                                    &&
                                                    ((fixedNumber(bets[0].Price[0].Price) < minRate) ||
                                                        (fixedNumber(bets[0].Price[0].Price) > maxRate)))
                                            ) &&
                                            <div className="betting-block" style={{ display: "flex" }}>
                                                <div className="lock">
                                                    <span className="icon-lock"></span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        rateDraw != "VS" ?
                                            <div className={`oigb_btn ${checkBetted(1) && "on"}`} >
                                                <div className='gameLists' style={{ border: "unset", background: "unset" }} onClick={() => onBetItem(1)}>
                                                    <em className={`${((rateDraw > nowDrawRate) && "rate_up_effect ")} ${((rateDraw < nowDrawRate) && " rate_down_effect ")}`}>{rateDraw}</em>
                                                </div>
                                                {
                                                    (marketIndex > -1) &&
                                                    (
                                                        (bets[1].Price[0].status > 3) ||
                                                        (bets[1].Status == 10) ||
                                                        (!isEmpty(rateValue) &&
                                                            ((rateDraw > rateValue.max) || (rateDraw < rateValue.min))
                                                        ) ||
                                                        ((pageInfo.gameType === "live") &&
                                                            ((fixedNumber(bets[1].Price[0].Price) < minRate) ||
                                                                (fixedNumber(bets[1].Price[0].Price) > maxRate)))
                                                    ) &&
                                                    <div className="betting-block" style={{ display: "flex" }}>
                                                        <div className="lock">
                                                            <span className="icon-lock"></span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className="oigb_btn">
                                                <em style={{color: "#fff"}}>{rateDraw}</em>
                                            </div>
                                    }

                                    <div className={`oigb_btn ${checkBetted(2) && "on"}`} >
                                        <div className='gameLists' style={{ flexDirection: "row-reverse", border: "unset", background: "unset" }} onClick={() => onBetItem(2)}>
                                            <span className="away">{awayTeam}</span>
                                            <em className={`${((rateAway > nowAwayRate) && "rate_up_effect ")} ${((rateAway < nowAwayRate) && " rate_down_effect ")}`}>{rateAway}</em>
                                        </div>
                                        {
                                            (marketIndex > -1) &&
                                            (
                                                (
                                                    (bets.length == 3) ?
                                                        (
                                                            ((bets[2].Price[0].status > 3) || (bets[2].Status == 10)) ||
                                                            ((pageInfo.gameType === "live") && ((fixedNumber(bets[2].Price[0].Price) < minRate) ||
                                                                (fixedNumber(bets[2].Price[0].Price) > maxRate)))
                                                        )
                                                        : ((bets[1].Price[0].status > 3) || (bets[1].Status == 10)) ||
                                                        ((pageInfo.gameType === "live") && ((fixedNumber(bets[1].Price[0].Price) < minRate) ||
                                                            (fixedNumber(bets[1].Price[0].Price) > maxRate)))
                                                ) ||
                                                (!isEmpty(rateValue) &&
                                                    ((rateAway > rateValue.max) || (rateAway < rateValue.min))
                                                )
                                            ) &&
                                            <div className="betting-block" style={{ display: "flex" }}>
                                                <div className="lock">
                                                    <span className="icon-lock"></span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <div className='odds_item_live_box'>
                        <div className="odds_item_live">
                            <span className="oill_info_live">{dateFormation(time, 10)}</span>
                            <div className="odds_item_live_l">
                                <div className="oill_team">
                                    <span className="team" style={{ color: "#ff5d5d", fontSize: "30px" }}>{liveScoreHome}</span>
                                </div>
                            </div>
                            <div className="odds_item_live_c">
                                <span className="vs">VS</span>
                            </div>
                            <div className="odds_item_live_r">
                                <span className="team" style={{ color: "#4bffff", fontSize: "30px" }}>{liveScoreAway}</span>
                            </div>
                        </div>
                        <div className="odds_item_game_box1">
                            <div className="odds_item_game">
                                <div className="title02">
                                    <div className='time_live'>상태: {liveState}</div>
                                    <div className="more_live">
                                        <span className="btn_toggle_country_">+{Number(countMarket).toLocaleString()} 추가 배팅옵션</span>
                                    </div>
                                </div>
                                <div className="odds_item_game_bet oig_live">
                                    <div className={`oigb_btn ${checkBetted(0) && "on"}`} >
                                        <div className='gameLists' onClick={() => onBetItem(0)}>
                                            <span className="home">{homeTeam}</span>
                                            <em className={`${((rateHome > nowHomeRate) && "rate_up_effect ")} ${((rateHome < nowHomeRate) && " rate_down_effect ")}`}>{rateHome}</em>
                                        </div>
                                        {
                                            (marketIndex > -1) &&
                                            (
                                                (bets[0].Price[0].status > 3) ||
                                                (bets[0].Status == 10) ||
                                                (!isEmpty(rateValue) &&
                                                    ((rateHome > rateValue.max) || (rateHome < rateValue.min))
                                                )
                                            ) &&
                                            <div className="betting-block" style={{ display: "flex" }}>
                                                <div className="lock">
                                                    <span className="icon-lock"></span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        rateDraw != "VS" ?
                                            <div className={`oigb_btn ${checkBetted(1) && "on"}`} >
                                                <div className='gameLists' onClick={() => onBetItem(1)}>
                                                    <em className={`${((rateDraw > nowDrawRate) && "rate_up_effect ")} ${((rateDraw < nowDrawRate) && " rate_down_effect ")}`}>{rateDraw}</em>
                                                </div>
                                                {
                                                    (marketIndex > -1) &&
                                                    (
                                                        (bets[1].Price[0].status > 3) ||
                                                        (bets[1].Status == 10) ||
                                                        (!isEmpty(rateValue) &&
                                                            ((rateDraw > rateValue.max) || (rateDraw < rateValue.min))
                                                        )
                                                    ) &&
                                                    <div className="betting-block" style={{ display: "flex" }}>
                                                        <div className="lock">
                                                            <span className="icon-lock"></span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className="oigb_btn">
                                                <em style={{color: "#fff"}}>{rateDraw}</em>
                                            </div>
                                    }

                                    <div className={`oigb_btn ${checkBetted(2) && "on"}`} >
                                        <div className='gameLists' style={{ flexDirection: "row-reverse" }} onClick={() => onBetItem(2)}>
                                            <span className="away">{awayTeam}</span>
                                            <em className={`${((rateAway > nowAwayRate) && "rate_up_effect ")} ${((rateAway < nowAwayRate) && " rate_down_effect ")}`}>{rateAway}</em>
                                        </div>
                                        {
                                            (marketIndex > -1) &&
                                            (
                                                (
                                                    (bets.length == 3) ?
                                                        (
                                                            ((bets[2].Price[0].status > 3) || (bets[2].Status == 10))
                                                        )
                                                        : (
                                                            (bets[1].Price[0].status > 3) || (bets[1].Status == 10))
                                                ) ||
                                                (!isEmpty(rateValue) &&
                                                    ((rateAway > rateValue.max) || (rateAway < rateValue.min))
                                                )
                                            ) &&
                                            <div className="betting-block" style={{ display: "flex" }}>
                                                <div className="lock">
                                                    <span className="icon-lock"></span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Match;