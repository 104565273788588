import $ from "jquery";
import { miniActions, moreActions, pageActions, sportsActions, store, userActions } from "./store";
import { ws_api_url } from "./keys";
import { perPage, sportPerPage } from "Utils";
import { autoLogout, convertObj, isEmpty, showMessage, toastr } from "Common";
import { useDispatch } from 'react-redux'
import { betActions } from "./store/bet.slice";



// const dispatch = useDispatch();

const io = token => {
  let newsocket;
  if (!isEmpty(token))
    newsocket = new WebSocket(ws_api_url + "?token=" + token);
  else newsocket = new WebSocket(ws_api_url);

  return newsocket;
};

export const socket = window.ws;

let reConnectedCnt = 1;
let socketQueue = [];
let sportsQueue = {};
let requestProcessed = false;
let work_fixtrueIds = {};
let HeartBitTime = 0;

/****************************접속상태 확인하는 허트비트************************* */

export const ConnectionStatus = () => {

  // console.log('HeartBitTime: ', HeartBitTime);
  if ((HeartBitTime !== 0) && ((Date.now() - HeartBitTime) > 20000)) {
    // clearTimeout(connectTime);
    try {
      window.ws.close();
      // toastr.success("소켓이 타임아웃");
    } catch (err) {
      console.log('err: ', err);
    }
    // window.ws = null;
    HeartBitTime = 0;
    return;
  }
  else {
    // console.log('HeartBitTime: ', HeartBitTime, window.ws.readyState);
    try {
      if (window.ws.readyState === 1 || window.ws.readyState === 0) {

        window.ws.send(JSON.stringify({ MessageType: 'Connect', data: "Status" }));
        setTimeout(() => {
          ConnectionStatus();
        }, 5000)
        // console.log("8*******")
      }

    } catch (err) {

      console.log('err: ', err);
      // toastr.error(" 에로 " + window.ws.readyState || "");
    }
  }
}

export const initSocket = token => {

  // if (!isEmpty(token)){
  //   window.ws = new WebSocket(ws_api_url + "?token=" + token);
  // }
  // else
  // toastr.success("소켓이 접속시도.");
  window.ws = new WebSocket(ws_api_url);
  window.ws.binaryType = 'arraybuffer';
  // console.log('window.ws: ', window.ws);

  // //console.log(window.ws);
  // addWarning(true);
  // addWarning(true);
  window.ws.onopen = event => {
    // //console.log("onopen", event);
    while (window.ws.readyState !== 2 && window.ws.readyState !== 3) {
      if (window.ws.readyState === 1) {
        store.dispatch(pageActions.setPageLoad(false))



        ConnectionStatus();

        // toastr.success("소켓이 접속되었습니다.");
        // send page information to the server
        const pageData = store.getState().page;
        const pageInfo = pageData.pageInfo;
        const per_page = pageData.perPage;
        const userData = store.getState().user;

        if (isEmpty(pageInfo) || pageInfo.pageType === "unset") {
          // //console.log("pageInfo is undifined");
        } else {
          const roomdata = { MessageType: "RoomType", data: pageInfo };
          window.ws.send(JSON.stringify(roomdata));
        }

        // //console.log(userData.isAuthenticated);
        // //console.log('PublicLogin', localStorage.jwtToken)
        // debugger
        window.ws.send(
          JSON.stringify({
            MessageType: "PublicLogin",
            token: localStorage.jwtToken
          })
        );
        if (localStorage.jwtToken) {
          store.dispatch(userActions.getUserInfo())
          autoLogout();
          reConnectedCnt = 0;
        }

        const sportsData = store.getState().sports;

        // send sports list to the server for the price rate
        const sportsList = sportsData.data;
        if (userData.isAuthenticated) {
          if (!isEmpty(sportsList)) {
            let gameList = [];
            sportsList.forEach(sport => {
              gameList.push(sport.FixtureId);
            });
            const game_listdata = { MessageType: "GameList", data: gameList };
            window.ws.send(JSON.stringify(game_listdata));

            const pageStore = store.getState().page;
            const pageInfo = pageStore.pageInfo;
            store.dispatch(sportsActions.addSportsList({
              params: {
                ...pageInfo,
                page: pageStore.curPage,
                perPage: sportPerPage
              },
              clear: false,
              noActive: true,
              loading: false
            }
            )
            );
          }
          if (pageInfo.pageType === "toto" && pageInfo.pageType === "virtual")
            store.dispatch(sportsActions.addSportsList({
              params: { ...pageInfo, page: pageData.curPage, perPage: per_page },
              clear: true
            }
            )
            );
        }

        // setInterval(() => {
        //   let data = {
        //     type: "connectMessage",
        //     data: "connectMessage"
        //   };
        //   if (window.ws.readyState === 1){
        //     window.ws.send(JSON.stringify(data));
        //   }
        // }, 5000);
        // addWarning(true);
        break;
      }
    }
  };

  window.ws.onerror = event => {
    // console.log("error", event);
  };

  window.ws.onclose = event => {
    // clearTimeout(connectTime);
    // toastr.info("소켓이 끊어졌습니다.");
    const reconnectSetting = store.getState().page.reconnectSetting;
    const reConnectTime = !isEmpty(reconnectSetting) ? Number(reconnectSetting.reconnection_time) * 1000 : 10000;
    const reConnectCnt = !isEmpty(reconnectSetting) ? reconnectSetting.reconnection_count : 1;
    // store.dispatch(SetPageLoading(true));

    // if (store.getState().user.isAuthenticated && window.ws.readyState !== 1 && reConnectedCnt > reConnectCnt) {
    //   setTimeout(() => {
    //     toastr.info("서버와 연결이 끊어졌습니다.");
    //     reConnectedCnt = 0;
    //     store.dispatch(logoutUser());
    //   }, 5000);
    // }

    //console.log("close", event);
    if (window.ws.readyState === 1 || window.ws.readyState === 0) {
      return;
    } else {
      // //console.log(window.ws.readyState);
      if (window.ws.readyState === 1 || window.ws.readyState === 0) {
        return;
      }
      setTimeout(() => {
        reConnectedCnt++;
        initSocket();
      }, reConnectTime);
    }
  };
  let requestSeed = 0;
  window.ws.onmessage = async (event) => {
    requestSeed++;
    let requestId = requestSeed;
    // console.log("소켓 들어옴------------------------", getDate(), requestId);
    try {
      let result = await window.decompressBinaryData(event.data);
      if (isEmpty(result)) {
        return;
      }
      let data = convertObj(result);
      if (isEmpty(data)) {
        return;
      }
      // const data = convertObj(event.data);
      // console.log('data: 자료 ', data);
      if (window.ws.readyState === 1) {
        // console.log("소켓 data--- 넣음", data.MessageType, getDate(), requestId);
        requestProcessed = true
        socketQueue.push({ MessageType: data.MessageType, data: convertObj(data), requestId: requestId });
        requestProcessed = false
        // processData(data.MessageType, convertObj(data), requestId);
      }

    }
    catch (err) {
      console.error('err: ', err);
    }
  };
};

export const processData = (messageType, data, requestId) => {
  // console.log('messageType: ', messageType);
  // console.log("소켓 data--- 처리시작", getDate(), requestId);
  // console.log(messageType, convertObj(data))
  // console.log("----------->", data.data)
  if (!isEmpty(data.data)) {
    data = convertObj(data.data);
  }
  // toastr.info(data)
  switch (messageType) {
    case "ForceLogout":
      //console.log(messageType, convertObj(data));
      logout();
      break;
    case "Logout":
      window.ws.send(
        JSON.stringify({
          MessageType: "PublicLogin",
          token: localStorage.jwtToken
        })
      );
      forcelogout();
      break;
    case "AutoLogoutWarning":
      AutoLogoutWarning();
      break;
    case "StopLogout":
      StopLogout();
      break;
    case "PublicLogin":
      publicLoginLogout(data);
      break;

    case "repair_site":
      repair_site(data);
      break;
    case "game_list":
      game_list(data);
      break;
    case "live_status":
      live_status(data);
      break;
    case "powerball_status":
      powerball_status(data);
      break;
    case "powerball_finished_bets":
      powerball_finished_bets(data);
      break;
    case "price_rate":
      price_rate(data);
      break;

    case "price_rate_virtual":
      price_rate_virtual(data);
      break;

    case "noticeList":
      noticeList(data);
      break;
    case "rewordbonusList":
      rewordbonusList(data);
      break;

    case "questionList":
      questionList(data);
      break;

    case "notice":
      notice(data);
      break;

    case "gameCnt":
    case "gameCntArr":
      gameCnt(data);
      break;

    case "game_setting":
      game_setting(data);
      break;

    case "bet_setting":
      bet_setting(data);
      break;

    // case "DuplicateSocketConnected":
    //   // //console.log(data)
    //   addWarning(true);
    //   break;

    case "Sport_Data":
      // console.log(data)
      // data.forEach(item => {
      //   changeGame(item, requestId);
      // })
      //changeGame(data, requestId);
      break;

    case "market_setting":
      MarketSetting(data);
      break;

    case "baseline_setting":
      BaselineSetting(data);
      break;

    case "provider_setting":
      ProviderSetting(data);
      break;

    case "reconnect_setting":
      ReconnectSetting(data);
      break;

    case "delay_setting":
      DelaySetting(data);
      break;
    case "betgame_status":
      BetGameSetting(data);
      break;
    case "Connect":
      OnConnectCheck(data);
      break;
    case "DuplicateSocketWarning":
      DuplicateCheck(data);
      break;
    case "market_data":
      updateMarketName(data);
      break;
    case "userInfo":
      upDateUserinfo(data);
      break;



    default:
      break;
  }
};
// let limitTime = new Date(0);
// let prev = new Date(0)
setInterval(() => {

  const pageStore = store.getState().page;
  const pageInfo = pageStore.pageInfo; //prematchType // "interior"
  // socketQueue.push({MessageType: data.MessageType, data: convertObj(data), requestId: requestId});
  if (socketQueue.length > 0 && !requestProcessed) {
    let data = [...socketQueue];
    socketQueue = [];
    data.forEach(element => {
      switch (element.MessageType) {
        case "Sport_Data":
          let item_object = convertObj(element.data.data);
          // console.log('item_object: ', item_object);
          if (isEmpty(sportsQueue[item_object.fixtureId])) {
            sportsQueue[item_object.fixtureId] = {
              data: []
            }
          }
          // console.log('sportsQueue[item_object.fixtureId]: ', sportsQueue[item_object.fixtureId]);
          sportsQueue[item_object.fixtureId] = {
            data: [
              ...sportsQueue[item_object.fixtureId].data,
              ...item_object.changes
            ],
            marketCount: (pageInfo.prematchType === "interior") ? item_object.interiorMarketCount : item_object.marketCount,
            requestId: element.requestId
          };
          // console.log('sportsQueue: ', sportsQueue);
          break;

        default:
          processData(element.MessageType, convertObj(element.data), element.requestId);
          break;

      }
    });
    let sportKeys = Object.keys(sportsQueue);
    let sportQueueCount = sportKeys.length;
    let storeUpdateFlag = false;
    if (sportQueueCount > 0) {

      const sportsData = store.getState().sports;
      const storeData = $.extend(true, {}, sportsData);
      const activeId = storeData.activeId;
      const sportsMarketInfo = storeData.sportsMarketInfo;
      let gameList = storeData.data;

      for (let i = 0; i < sportQueueCount; i++) {
        if (isEmpty(gameList) || isEmpty(sportsQueue[sportKeys[i]].data)) {
          if (isEmpty(gameList)) {
            sportsQueue = {};
            break;
          }
          else if (isEmpty(sportsQueue[sportKeys[i]].data)) {
            continue
          }
        }
        const gameIndex = gameList.findIndex(
          list => list.FixtureId == sportKeys[i]
        );
        if (gameIndex < 0) {
          if (!isEmpty(sportsQueue[sportKeys[i]].data)) {
            delete (sportsQueue[sportKeys[i]]);
          }
          continue;
        }
        let old_element = {
          ...gameList[gameIndex],
          MarketCount: sportsQueue[sportKeys[i]].marketCount
        };

        if (gameList[gameIndex].key === activeId) {
          old_element = {
            ...sportsMarketInfo,
            MarketCount: sportsQueue[sportKeys[i]].marketCount
          };
          // console.log('old_element: ', sportsMarketInfo, old_element);
        }

        if (work_fixtrueIds[sportKeys[i]] == true) {
          continue;
        }
        work_fixtrueIds[sportKeys[i]] = true;

        const nowStartTime = old_element.Fixture.StartDate;
        let work = new Worker("../js/work.js");
        // console.log("create Request Id: " + sportsQueue[sportKeys[i]].requestId)
        work.postMessage({ changeElement: sportsQueue[sportKeys[i]].data, element: old_element, fixtureId: sportKeys[i], requestId: sportsQueue[sportKeys[i]].requestId });

        work.addEventListener("message", function (evt) {

          // console.log("결과 requestId: " + evt.data.requestId)
          // console.log("결과 requestId:", evt)
          work.terminate();
          work = null;
          const pageStore = store.getState().page;
          const pageInfo = pageStore.pageInfo;
          if (storeUpdateFlag) {
            while (storeUpdateFlag) {

            }
          }
          // console.log("결과업뎃 requestId: ", evt.data.requestId)
          storeUpdateFlag = true;
          const nowSportsData = store.getState().sports.data;
          let sportsList = $.extend(true, [], nowSportsData);
          // console.log(`결과업뎃시 스토에 저장된 스포츠 리스트 RequestId: ${evt.data.requestId}. list: `, sportsList)
          if (isEmpty(sportsList)) {
            delete (work_fixtrueIds[evt.data.fixtureId])
            storeUpdateFlag = false;
            return;
          }
          const sportIndex = sportsList.findIndex(
            list => list.FixtureId == evt.data.fixtureId
          );
          if (sportIndex < 0) {
            delete (work_fixtrueIds[evt.data.fixtureId])
            storeUpdateFlag = false;
            return;
          }
          const element = sportsList[sportIndex];
          // limitTime = prev
          // if ((new Date()).getTime() - limitTime.getTime() > 1000) {
          //   store.dispatch(SetSportsFlag(true));
          //     prev = new Date();
          // }
          switch (evt.data.type) {
            case -1: {
              delete (work_fixtrueIds[evt.data.fixtureId])
              storeUpdateFlag = false;
              return;
            }
            case 0: {
              const gameType = evt.data.data.data.gameType;
              const isMatchStarted = evt.data.data.data.isMatchStarted;
              const status = evt.data.data.data.status;
              const gamestatus = evt.data.data.data.gamestatus;
              //프리매치인 경우에는 isMatchStarted가 1이거나 gamestatus가 1이 아닌 경우 혹은 status가 1이 아닌 경우 다시 불러들인다
              //라이브인 경우에는 gamestatus가 1이 아닌 경우 혹은 status가 1이 아닌 경우 다시 불러들인다
              let refreshRequired = false;
              if (gameType == 0 && (isMatchStarted == 1 || gamestatus != 1 || status != 1)) {
                refreshRequired = true
              }
              if (gameType == 1 && (gamestatus != 1 || status != 1)) {
                refreshRequired = true
              }
              if (refreshRequired) {
                sportsList = sportsList.filter(list => list.FixtureId !== element.FixtureId);
                // if (sportsList.length > 10) {

                store.dispatch(sportsActions.setSportsList(sportsList));
                // }
                //  else {
                store.dispatch(sportsActions.addSportsList({
                  params: {
                    ...pageInfo,
                    page: pageStore.curPage,
                    perPage: sportPerPage
                  },
                  clear: false,
                  noActive: element.key === activeId ? false : true,
                  loading: false
                }
                )
                );
                // }
              }
              delete (work_fixtrueIds[evt.data.fixtureId])
              storeUpdateFlag = false;

              return;
            }
            case 1: {
              const status = evt.data.data.data.Status;
              // console.log("----->", evt.data, element)
              if (
                (pageInfo.gameType === "prematch" &&
                  status != 1) ||
                (pageInfo.gameType === "live" &&
                  (status != 1 && status != 2 && status != 4))
              ) {
                sportsList = sportsList.filter(list => list.FixtureId !== element.FixtureId);
                // console.log("sportDispatch==> FixtureId: " + element.FixtureId);
                // if (sportsList.length > 10) {
                store.dispatch(sportsActions.setSportsList(sportsList));
                // } else {
                store.dispatch(sportsActions.addSportsList(
                  {
                    params: {
                      ...pageInfo,
                      page: pageStore.curPage,
                      perPage: sportPerPage
                    },
                    clear: false,
                    noActive: element.key === activeId ? false : true,
                    loading: false
                  }
                )
                );
                // }
              }
              // else {
              //   const changeIndex = sportsList.findIndex(list => list.FixtureId == evt.data.fixtureId);
              //   sportsList[changeIndex].Fixture = evt.data.data.data;
              // store.dispatch(SetSportsList(sportsList));
              // }
              delete (work_fixtrueIds[evt.data.fixtureId])
              storeUpdateFlag = false;

              return;
            }
            default: {
              // debugger
              let marketInfo = $.extend(true, {}, evt.data.data);
              const changedStartTime = marketInfo.Fixture.StartDate;
              sportsList[sportIndex] = $.extend(true, {}, evt.data.data);
              sportsList[sportIndex].Markets.filter((market) => {
                if (!isEmpty(evt.data.data.AllowedMarketType)) {
                  // console.log('market.Id: ', market.Id);
                  if (evt.data.data.AllowedMarketType.indexOf(market.Id.toString()) > -1) {
                    return true
                  }
                  else {
                    return false;
                  }
                }
                else {
                  return false;
                }
              });
              // console.log('evt.data.data.Markets: ', marketInfo.Markets);
              // console.log('evt.data.data.Markets: ', marketInfo);

              if (gameList[gameIndex].key === activeId) {

                store.dispatch(sportsActions.setSportsMarketInfo(marketInfo));
              }
              if (nowStartTime !== changedStartTime) {
                sportsList.sort((a, b) => {
                  if (!isEmpty(a.Fixture) && !isEmpty(a.Fixture.StartDate) &&
                    !isEmpty(b.Fixture) && !isEmpty(b.Fixture.StartDate)) {

                    if ((new Date(a.Fixture.StartDate)).getTime() > (new Date(b.Fixture.StartDate)).getTime()) {
                      return 100;
                    }
                    else if ((new Date(a.Fixture.StartDate)).getTime() === (new Date(b.Fixture.StartDate)).getTime()) {
                      if (Number(a.Fixture.League.Id) > Number(b.Fixture.League.Id)) {
                        return 50;
                      }
                      else if (Number(a.Fixture.League.Id) === Number(b.Fixture.League.Id)) {
                        return 0;
                      }
                      else {
                        return -50;
                      }
                      // return a.Fixture.League.Id.charCodeAt() - b.Fixture.League.Id.charCodeAt()
                    }
                    else {
                      return -100;
                    }
                  }
                  else {
                    return 0
                  }
                })
              }
              store.dispatch(sportsActions.setSportsList(sportsList));
              delete (work_fixtrueIds[evt.data.fixtureId])
              storeUpdateFlag = false;
              return;
            }
          }
        })

        // work[work_item].postMessage();
        delete (sportsQueue[sportKeys[i]])
      }
      // console.log("sportsQueue end", sportsQueue)
    }
  }
}, 50)

export const forcelogout = () => {
  const message =
    "동일계정으로 다른곳에서 로그인되어 현 세션은 로그아웃되었습니다.";
  toastr.warning(message);
  store.dispatch(userActions.logoutUser(true));
};

export const AutoLogoutWarning = (data) => {
  toastr.warning("유저액션이 감지되지 않아 자동로그아웃되었습니다.");
  store.dispatch(userActions.logoutUser(true));
};

export const publicLoginLogout = data => {
  showMessage(data.message);
  if (data.status) store.dispatch(userActions.logoutUser());
};

export const StopLogout = data => {
  store.dispatch(userActions.logoutUser());
};

export const logout = () => {
  //console.log("강퇴", window.windowHandle);
  toastr.warning("강퇴되었습니다. ");
  // if ( window.windowHandle) {
  //   toastr.warning("배팅이 취소되었습니다.")
  //   window.windowHandle.close()
  // }
  store.dispatch(userActions.logoutUser(true));
};

export const repair_site = data => {
  //console.log("repair_site", data)
  localStorage.setItem('sportSetting', data.sport_setting);
  store.dispatch(sportsActions.setSportsSetting(data.sport_setting));
  store.dispatch(pageActions.setGameCheck(data.gameCheck));
  if (data.state === 0) {
    // //console.log("노멀.")
    store.dispatch(pageActions.setCheckPage({ ...data.data, check: false }));
    return;
  }
  // window.location.href = '/';
  if (store.getState().user.isAuthenticated) {
    store.dispatch(userActions.logoutUser());
    toastr.warning("점검중입니다.");
  }
  // //console.log("점검중입니다.")
  store.dispatch(pageActions.setCheckPage({ ...data.data, check: true }));
  // toastr.warning("점검중입니다.");
};

export const game_list = data => {
  // debugger
  const { fixtureId, gameType } = data;

  const pageStore = store.getState().page;
  const pageInfo = pageStore.pageInfo;
  const gameList = store.getState().sports.data;
  const index = gameList.findIndex(list => list.FixtureId == fixtureId);
  // //console.log( "변경되야 할 경기 아이디:-----", fixtureId, index)

  if (index < 0) return;
  else {
    if (gameType == gameList[index].Fixture.gameType) {
      // //console.log(pageInfo);
      //console.log("변경되는 경기아이디:-----", fixtureId, index);
      store.dispatch(sportsActions.addSportsList(
        {
          params: {
            ...pageInfo,
            page: pageStore.curPage,
            perPage: sportPerPage
          },
          clear: false,
          noActive: false,
          loading: false
        }
      )
      );
    }
  }
};

export const live_status = data => {
  //라이브자료
  // //console.log(data);
  // store.dispatch(SetScoreData(data));
  // store.dispatch(SetSportsFlag(true));
  const storeData = store.getState().sports.data;
  const sportsData = $.extend(true, {}, storeData);
  const list = data;
  let updatedData = [];

  if (isEmpty(sportsData) || isEmpty(list)) return;

  for (let i = 0; i < storeData.length; i++) {
    const eventIndex = list.findIndex(
      item => item.event_key === sportsData[i].key
    );
    if (eventIndex < 0) {
      updatedData.push(sportsData[i]);
      continue;
    }
    sportsData[i] = {
      ...sportsData[i],
      Livescore: list[eventIndex]
    };
    updatedData.push(sportsData[i]);
  }
  store.dispatch(sportsActions.setSportsList(updatedData));
};

export const powerball_status = data => {
  // console.log("미니게임===>", data)
  //파워볼자료
  data = { ...data, state: convertObj(data.state) };
  //console.log("powerball_status: ", convertObj(data));
  store.dispatch(miniActions.setMiniRates(data));
  const mini = store.getState().mini;
  // //console.log('Check: ', data.status, mini);
  if (mini.miniCheck != data.status) {
    if (data.status == 0) toastr.warning("점검중입니다.");
  }
  store.dispatch(miniActions.setMiniCheck(Number(data.status)));
};

export const powerball_finished_bets = data => {
  //console.log("powerball_finished_bets: ", data);
  const miniStore = store.getState().mini;
  const miniList = miniStore.bettingList;
  if (data.bets.length > 0) {
    toastr.info("" + data.type + "분게임이 끝났습니다.");
  }
  if (data.type == miniStore.type) {
    data.bets.forEach(bet => {
      const betIndex = miniList.findIndex(item => item._id === bet._id);
      if (betIndex >= 0) {
        const finishedBet = { ...miniList[betIndex], ...bet };
        store.dispatch(miniActions.getPowerBallBettingList(
          miniList
            .slice(0, betIndex)
            .concat(
              [finishedBet].concat(
                miniList.slice(betIndex + 1, miniList.length)
              )
            )
        )
        );
      }
    });
  }
};

export const price_rate = priceData => {
  //console.log("price_rate: ", priceData);
  // store.dispatch(SetSportsFlag(true));
  const storeData = store.getState().sports.data;
  const sportsData = $.extend(true, {}, storeData);
  const list = priceData.list;
  let updatedData = [];

  if (isEmpty(sportsData) || isEmpty(list)) return;

  for (let i = 0; i < storeData.length; i++) {
    const eventIndex = list.findIndex(item => item.key === sportsData[i].key);
    if (eventIndex < 0) {
      updatedData.push(sportsData[i]);
      continue;
    }
    const eventObj = sportsData[i];
    const eventObjPrice = list[eventIndex];
    for (let j = 0; j < eventObj.Markets.length; j++) {
      const marketObj = eventObj.Markets[j];
      const marketObjPrice = eventObjPrice.Markets[j];
      if (isEmpty(marketObj) || isEmpty(marketObjPrice)) break;
      for (let k = 0; k < marketObj.Providers.length; k++) {
        const providerObj = marketObj.Providers[k];
        const providerObjPrice = marketObjPrice.Providers[k];
        if (isEmpty(providerObj) || isEmpty(providerObjPrice)) break;
        for (let m = 0; m < providerObj.Bets.length; m++) {
          const betObj = providerObj.Bets[m];
          const betObjPrice = providerObjPrice.Bets[m];
          if (isEmpty(betObj) || isEmpty(betObjPrice)) break;
          sportsData[i].Markets[j].Providers[k].Bets[m] = {
            ...betObj,
            Price: betObjPrice.Price,
            Settlement: betObjPrice.Settlement,
            Status: betObjPrice.Status
          };
        }
      }
    }
    updatedData.push(sportsData[i]);
  }
  store.dispatch(sportsActions.setSportsList(updatedData));

  // const eventCounts = priceData.eventCounts;
  // store.dispatch(GetEventCount(eventCounts));
};

export const price_rate_virtual = params => {
  //console.log("price_rate_virtual: ", params);
  const KeyCompare = (originData, destData) => {
    if (
      isEmpty(originData) ||
      isEmpty(destData) ||
      originData.length !== destData.length
    )
      return false;
    for (let i = 0; i < destData.length; i++)
      if (destData[i].key !== originData[i].key) return false;
    return true;
  };
  if (KeyCompare(params.list, store.getState().sports.data)) {
    store.dispatch(sportsActions.setSportsList(params.list));
  }
};

export const noticeList = params => {
  if (params.status) {
    toastr.info(params.message);
    store.dispatch(pageActions.setMessagePage(true))
    store.dispatch(moreActions.getMessageData({ page: 1, perPage: perPage }));
  }
};
export const rewordbonusList = params => {
  store.dispatch(pageActions.SetRewordBonusPage(true));
};

export const questionList = params => {
  // //console.log("questionList: ", params);

  const pageData = store.getState().page;

  if (params.status) {
    toastr.info(params.message);
    store.dispatch(moreActions.getQuestionList({ page: 1, perPage: pageData.perPage }))
    // store.dispatch(SetMessagePage(true))
  }
};

export const notice = params => {
  // console.log("notice: ", params);

  const pageData = store.getState().page;

  if (!isEmpty(params)) {
    if (!isEmpty(params.bet)) {
      const betToastr = params.bet;
      for (let i = 0; i < betToastr.length; i++) {
        if (!isEmpty(betToastr[i])) {
          if (betToastr[i].indexOf("당첨") > -1) {
            toastr.success(betToastr[i]);
          } else {
            toastr.warning(betToastr[i]);
          }
        }
      }
      store.dispatch(userActions.getUserInfo());
    }

    if (!isEmpty(params.deposit)) {
      const depositToastr = params.deposit;
      for (let i = 0; i < depositToastr.length; i++) {
        if (!isEmpty(depositToastr[i])) {
          toastr.info(depositToastr[i]);
        }
      }
      store.dispatch(moreActions.getFivedayDepositList({
        typeStr: "deposit",
        page: 1,
        perPage: perPage
      }));

      store.dispatch(userActions.getUserInfo());
    }

    if (!isEmpty(params.note)) {
      const noteToastr = params.note;
      for (let i = 0; i < noteToastr.length; i++) {
        if (!isEmpty(noteToastr.length)) {
          toastr.info(noteToastr[i]);
        }
      }
      store.dispatch(moreActions.getNoticeList({ page: 1, perPage: pageData.perPage }))
    }

    if (!isEmpty(params.question)) {
      const questionToastr = params.question;
      for (let i = 0; i < questionToastr.length; i++) {
        if (!isEmpty(questionToastr[i])) {
          toastr.info(questionToastr[i]);
        }
      }
      store.dispatch(moreActions.getQuestionList({ page: 1, perPage: pageData.perPage }));
      store.dispatch(userActions.getUserInfo());
    }

    if (!isEmpty(params.withdraw)) {
      const withdrawToastr = params.withdraw;
      for (let i = 0; i < withdrawToastr.length; i++) {
        if (!isEmpty(withdrawToastr[i])) {
          toastr.info(withdrawToastr[i]);
        }
      }
      store.dispatch(moreActions.getFivedayDepositList({
        typeStr: "withdraw",
        page: 1,
        perPage: perPage
      }));

      store.dispatch(userActions.getUserInfo());
    }
  }
};



export const gameCnt = params => {
  let allCount = {};
  // //console.log('gameCnt: ', params);
  const type = store.getState().page.pageInfo.gameType;
  const sport = store.getState().page.pageInfo.sportType;
  if (!isEmpty(params)) {
    store.dispatch(sportsActions.setEventCount(params));

    // if (type === "prematch" && sport === "sport")
    //   allCount = params.eventCounts.prematchCnt;

    // if (type === "live" && sport === "sport")
    //   allCount = params.eventCounts.liveCnt;

    // if (type === "prematch" && sport === "esports")
    //   allCount = params.eventCounts.esportCntPre;

    // if (type === "live" && sport === "esports")
    //   allCount = params.eventCounts.esportCntLive;
  }
  // //console.log('allCount: ', allCount);

  // store.dispatch(GetEventCount(allCount));
};

export const bet_setting = params => {
  // //console.log('params: ', params);
  store.dispatch(sportsActions.setBetSetting(params.bet_setting));
  store.dispatch(betActions.setSameMarketAllow(params.same_market_allow));
  store.dispatch(betActions.setLiveMultiAllow(params.bool_value));
};

export const game_setting = params => {
  // //console.log(params);
  const { pageInfo } = store.getState().page;
  const bet = store.getState().bet;
  const { betMoneySetting, multiBettingSettingList, multiSetting } = params;
  // //console.log("game_setting", convertObj(betMoneySetting), convertObj(multiBettingSettingList), convertObj(multiSetting));
  if (!isEmpty(multiBettingSettingList)) {
    store.dispatch(betActions.setMulti(convertObj(multiBettingSettingList)));
  }
  if (!isEmpty(multiSetting)) {
    store.dispatch(betActions.setMaxValue(convertObj(multiSetting)));
  }
  if (!isEmpty(betMoneySetting)) {
    store.dispatch(sportsActions.setLevelBettingData(convertObj(betMoneySetting).data));
  }
};

export const addWarning = value => {
  window.ws.send(
    JSON.stringify({ MessageType: "PublicLogin", token: localStorage.jwtToken })
  );
  if (store.getState().user.isAuthenticated) {
    let htmlString = `<div className="swal2-container swal2-center swal2-backdrop-show dumpMsg" style='overflowY: auto;' >
        <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
            className="swal2-popup swal2-modal swal2-icon-info swal2-show" tabIndex="-1" role="dialog" aria-live="assertive"
            aria-modal="true" style='display: grid'>
            <div className="swal2-icon swal2-info swal2-icon-show" style='display: flex'>
                <div className="swal2-icon-content" style='color: #000'>i</div>
            </div>
            <h2 className="swal2-title" id="swal2-title" style='display: block'>이용중인 탭에서 플레이 해주세요.</h2>
            <div className="swal2-actions" style='display: flex'>
            <button type="button" className="swal2-confirm swal2-styled swal2-default-outline" aria-label=""
                  style='display: inline-block; background-color: rgb(48, 133, 214); font-size: 1.1rem!important;'
                  onClick="this.parentElement.parentElement.parentElement.remove()">
                  확인
              </button>
            </div>
          </div>
        </div>`;
    $(document).ready(() => {
      if (value) {
        if ($(".dumpMsg").length < 1) $(".App").append(htmlString);
      } else $(".dumpMsg").remove();
    });
  }
};

export const MarketSetting = (data) => {
  // console.log('MarketSetting: ', data.sportMarket);
  store.dispatch(sportsActions.onSetMarketSetting(data.sportMarket));
  store.dispatch(sportsActions.market_setting(data))
}

export const BaselineSetting = (data) => {
  // console.log('BaselineSetting: ', data);
  store.dispatch(sportsActions.setBaselineSetting(data));
}

export const ProviderSetting = (data) => {
  // console.log('ProviderSetting: ', data);
  store.dispatch(sportsActions.setProviderId(data));
}

export const ReconnectSetting = (data) => {
  // //console.log('ReconnectSetting: ', data);
  store.dispatch(pageActions.onSetReconnectSetting(data))
}

export const DelaySetting = (data) => {
  //console.log('DelaySetting: ', data);
  store.dispatch(betActions.setDelaySetting(data.delay_data));
}

export const BetGameSetting = (data) => {
  // console.log('data: ', data);
  const pageData = store.getState().page;
  const pageInfo = pageData.pageInfo;
  const dataIndex = data.events.findIndex(list => list.leagueKey === pageInfo.league);
  if (dataIndex > -1) {
    if (!isEmpty(data.events[dataIndex].data)) {
      store.dispatch(sportsActions.setSportsList(data.events[dataIndex].data));
      const activeId = store.getState().sports.activeId;
      const PresentActiveIndex = data.events[dataIndex].data.findIndex(item => { return item.key === activeId });
      if (PresentActiveIndex < 0) {
        store.dispatch(sportsActions.setActiveId(data.events[dataIndex].data[0].key))
      }
    } else {
      store.dispatch(sportsActions.setSportsList([]));
      store.dispatch(sportsActions.setActiveId(""));
      return;
    }
  }

}

export const OnConnectCheck = () => {
  // console.log('Date.now(): ', Date.now());
  HeartBitTime = Date.now();
}

export const DuplicateCheck = (data) => {
  window.ws.send(JSON.stringify({ MessageType: 'DuplicateWarningResponse', data: "" }));
}
export const upDateUserinfo = (data) => {
  store.dispatch(userActions.setCurrentUser(data))
}

export const updateMarketName = (data) => {
  store.dispatch(userActions.setMarketName(data))
}