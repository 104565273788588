import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'Common';
import { userActions } from 'Common/store';


const menus = [
    {
        name: "입금",
        path: "/deposit",
        icon: "/images/common/1.png"
    },
    {
        name: "출금",
        path: "/withdraw",
        icon: "/images/common/2.png"
    },
    {
        name: "포인트",
        path: "/point",
        icon: "/images/common/3.png"
    },

]

const UserInfo = (props) => {

    const dispatch = useDispatch();
    const { onPopup } = props;
    const userdata = useSelector(state => state.user.data);
    const user = useSelector(state => state.user.data.user);
    const showright = useSelector(state => state.user.showright)

    const view = () => {
        
        dispatch(userActions.setShowRight(!showright))
    }
    return (
        <Fragment>
            <div className="my_info_box">
                <div className="my_info_title">
                    <div><i className="fa-solid fa-user"></i>
                        <span className="name">{!isEmpty(user) && user.name}</span></div>
                    {/* <img src="images/lv2.png" className="lv_img" /> */}
                    <Link to="/mypage" className='my_info_btn_link'>회원정보</Link>
                </div>
                <div className="my_info_money"><i className="fa-solid fa-won-sign"></i> 보유머니 <span>{isEmpty(user) ? 0 : Math.floor(user.balance.money).toLocaleString()}</span>원</div>
                <div className="my_info_money"><i className="fa-brands fa-product-hunt"></i> 포인트<span>{isEmpty(user) ? 0 : Math.floor(user.balance.rolling).toLocaleString()}</span>P</div>
                <div className="my_info_money"><i className="fa-solid fa-ticket-simple"></i> 쿠폰 <span>{isEmpty(userdata.coupon_count) ? 0 : userdata.coupon_count}</span>개</div>
                <div className="my_info_money"><i className="fa-regular fa-envelope"></i> 쪽지 <span>{isEmpty(userdata.note_count) ? 0 : userdata.note_count}</span>통</div>
                <div className="my_info_btn">
                    <ul>
                        {
                            menus.map((menu, index) => {
                                return (

                                    <li key={index} >
                                        <Link to={menu.path} onClick={() => { view() }}>
                                            <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}> <img src={menu.icon} style={{ width: "16px" }} /></div>

                                            <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}> {menu.name}</div>
                                        </Link>
                                    </li>

                                )
                            })
                        }
                        <li >
                            <Link className="logout_btn" onClick={onPopup}>  <div style={{ width: "100%", display: 'flex', justifyContent: "center" }} onClick={() => {view()}}> <img src='/images/common/1.png' style={{ width: "16px" }} /></div>
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center" }} onClick={() => {view()}}>

                                    로그아웃
                                </div></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default UserInfo;