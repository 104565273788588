import { pageActions, sportsActions, userActions } from 'Common/store';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import SwipBox from './SwipBox';
import { ResizeESportsIframe, ResizeSportsLiveIframe, clearInfiniteScroll, isEmpty, isMobilePage, mobileSportsWindowScrollHandler, pcSportScrollHandler, toastr } from 'Common';
import { sportPerPage } from 'Utils';
import InteriorMatch from './InteriorMatch';
import Match from './Match';
import Market from './Market';
import $ from "jquery";


const SportsCenter = (props) => {

    const dispatch = useDispatch();
    const sportsData = useSelector(state => state.sports.data);
    const sports = useSelector(state => state.sports);
    const location = useSelector(state => state.sports.location);
    const league = useSelector(state => state.sports.league);
    const sportId = useSelector(state => state.sports.sportId);
    const bet_setting = useSelector(state => state.sports.bet_setting);
    const searchType = useSelector(state => state.sports.searchType);
    const searchValue = useSelector(state => state.sports.searchValue);
    const curPage = useSelector(state => state.page.curPage);
    const isUp = useSelector(state => state.page.isUp);
    const totalPage = useSelector(state => state.page.totalPage);
    const pageInfo = useSelector(state => state.page.pageInfo);
    const bet = useSelector(state => state.bet);
    const betList = bet.bettedItems;
    const minigame = useSelector(state => state.mini);
    const { showright } = useSelector(state => state.user);
    const { type, url, title } = props;

    const roomObj = props.roomObj;
    const [sort, setSort] = useState("time");
    const [esport, setEsport] = useState("prematch");
    const [pages, setPages] = useState(1);
    const [showMarkets, setShowMarkets] = useState(false);
    const [interiorShowMarket, setInteriorShowMarket] = useState(-1)
    const [showMatch, setShowMatch] = useState(true);
    // const [sportId, setSportId] = useState(type === "virtual" ? "soccer" : "all");

    const onInteriorShowMarket = (index) => {
        if (interiorShowMarket === index) {
            setInteriorShowMarket(-1)
        } else {
            setInteriorShowMarket(index)
        }
    }

    const firstScroll = useRef();
    // useEffect(() => {
    //     if (sportsData.length > sportPerPage * 3) {
    //         sportsData.splice(0, (sportsData.length - sportPerPage * 3));
    //     }
    // }, [sportsData])

    useEffect(() => {
        // infiniteScroll;

        dispatch(userActions.getMarketName())
        dispatch(userActions.getUserInfo())
        dispatch(userActions.getEventParttName())
        dispatch(pageActions.SetCurrentPage({ page: pages, ...totalPage }))
        window.addEventListener("scroll", mobileSportsWindowScrollHandler);

        if (!isEmpty($('#scroll_parent')[0])) {
            $('#scroll_parent')[0].addEventListener("scroll", pcSportScrollHandler);
        }
        return () => {
            clearInfiniteScroll();
        }
    }, [])

    useEffect(() => {
        pageActions.SetCurrentPage({ page: pages, ...totalPage });
    }, [pages])

    let gamelists = sportsData;
    const getSportsList = (data) => {
        const params = {
            ...roomObj,
            sportId: props.type === "esports" ? "all" : sportId.toString(),
            esportType: props.type === "esports" ? sportId.toString() : "all",
            sort: sort,
            location: location,
            league: league,
            searchType: searchType,
            searchValue: searchValue,
            page: curPage,
            perPage: sportPerPage,
            isUp: isUp,
            gameType: roomObj.gameType
        }
        dispatch(sportsActions.addSportsList({ params: params, clear: curPage == 1, noActive: curPage != 1 }))
    }

    const onSort = (sort) => {
        setSort(sort);
        setPages(1);
    }

    const FirstPage = () => {
        const params = {
            ...roomObj,
            sportId: props.type === "esports" ? "all" : sportId.toString(),
            esportType: props.type === "esports" ? sportId.toString() : "all",
            sort: sort,
            location: location,
            league: league,
            page: 1,
            perPage: sportPerPage,
            isUp: isUp,
            gameType: roomObj.gameType
        }
        dispatch(sportsActions.addSportsList({ params: params }));
        firstScroll.current.scrollTop = 0;
    }

    const onEsportTab = (value) => {
        setEsport(value);
        setPages(1);
        const roomObj = {
            pageType: "toto",
            RoomType: "esport",
            sportType: "esport",
            gameType: value,
            esportType: "all",
            sportId: 'all',
        }

        props.setRoomObj(roomObj);

    }

    useEffect(() => {
        getSportsList();
    }, [sort, curPage, sportId, location, league, pageInfo.gameType, searchType, searchValue]);


    const onShowMatch = useCallback(
        () => {
            if (isMobilePage()) {
                window.addEventListener("scroll", mobileSportsWindowScrollHandler)
            }
            setShowMatch(true)
            setShowMarkets(false)
        }, [showMatch, showMarkets]

    )

    const onShowMarket = useCallback(
        () => {
            if (isMobilePage()) {
                window.removeEventListener("scroll", mobileSportsWindowScrollHandler)
            }
            // console.log("--------")
            setShowMatch(false);
            setShowMarkets(!showMarkets);
        }, [showMarkets, showMatch]
    )
    // const inteShowMarket = useCallback(() => onShowMarket(),[]);
    useEffect(() => {
        ResizeSportsLiveIframe();
        ResizeESportsIframe();
    }, [showMarkets])


    const onCardClick = () => {
        dispatch(userActions.setShowRight(!showright))
    }

    return (
        <div className="contents_center">
            <div className="mini_game_tab">
                <SwipBox type={type} esport={esport} />
            </div>
            {/* <div className="con_box10">
                <div className="sports_b_title_box">
                    <div className="sports_title">{title}</div>
                    <div className='firstpage' onClick={FirstPage}>
                        <span>첫페이지로 가기</span>
                    </div>
                </div>
            </div> */}
            <div ref={firstScroll} id="scroll_parent" className={`sportsScroll ${type === "interior" ? "contents_center_info_full" : "contents_center_info_sports"}`} style={{ display: showMatch ? "block" : "" }}>
                <div id="scroll_main">
                    <div className="con_box00">
                        <div className="all_odds_box" >
                            <div className='bonus-folder-wrap'>
                                {
                                    bet_setting.map((setting, index) => {
                                        return (
                                            <div className="bonus-bet odds-80567" key={index}>
                                                <div className="left"> <div className="name" style={{ color: "#f90" }}>{setting.fold}폴더 이상</div> </div>
                                                <div className="odds-wrap right "> <div className="odds"> {setting.value}</div></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                gamelists.map((list, index) => {
                                    if (type === "interior") {
                                        return !isEmpty(list.Fixture) && (
                                            <InteriorMatch
                                                key={list.key}
                                                type={type}
                                                gamelist={list}
                                                keyIndex={index}
                                                onShowMarket={onShowMarket}
                                                onShowMatch={onShowMatch}
                                                onInteriorShowMarket={onInteriorShowMarket}
                                                interiorShowMarket={interiorShowMarket === index}
                                                showLeague={((index === 0)) ? true : (list.Fixture.League.Id !== gamelists[index - 1].Fixture.League.Id) || (list.Fixture.StartDate !== gamelists[index - 1].Fixture.StartDate)} />);
                                    }
                                    else {
                                        return (<Match
                                            type={type}
                                            gamelist={list}
                                            key={list.FixtureId}
                                            onShowMarket={onShowMarket}
                                            showLeague={((index === 0)) ? true : (list.Fixture.League.Id !== gamelists[index - 1].Fixture.League.Id) || (list.Fixture.StartDate !== gamelists[index - 1].Fixture.StartDate)}
                                        />)
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {type !== 'interior' && <Market type={type} showMarkets={showMarkets} onShowMatch={onShowMatch} />}
            {
                type !== "minigame" ?
                    <div id="cart" className={`bettingcard_Button d-none m-d-block ${betList.length > 0 && "active "}`} onClick={onCardClick}>
                        <div className="card_content">
                            <div className="bettingCard_itag">
                                <i className="fa fa-shopping-basket"></i>
                            </div>
                            <span className="betting_count">{betList.length}</span>
                        </div>
                    </div>
                    :
                    <div id="cart" className={`bettingcard_Button d-none m-d-block active `} onClick={onCardClick}>
                        <div className="card_content">
                            <div className="bettingCard_itag">
                                <i className="fa fa-shopping-basket"></i>
                            </div>
                            <span className="betting_count">{!isEmpty(minigame.data.odd_key) ? 1 : 0}</span>
                        </div>
                    </div>
            }
        </div>
    );
};

export default SportsCenter;