import { getIpAddr, history, isEmpty, setAuthToken } from 'Common';
import Footer from 'Components/common/Footer';
import Header from 'Components/common/Header';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { routers } from 'Utils/Routes';
import { store, userActions } from 'Common/store';
import jwt_decode from "jwt-decode";
import { Protected } from 'Components/common/Protected';
import Spiners from 'Components/Others/Spiners';
import { useEffect } from 'react';
import { initSocket } from 'Common/socket';
import NewMessage from 'Components/common/NewMessage';
import Check from 'Components/common/Check';



if (!isEmpty(localStorage.jwtToken)) {
  setAuthToken(localStorage.jwtToken);
  const currentTime = new Date() / 1000;
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(userActions.setCurrentUser(decoded));
  store.dispatch(userActions.getUserInfo());
  localStorage.setItem("time", currentTime);
}
initSocket();

function App() {
  useEffect(() => {
    window.scrollHandler();
    window.sportsScroll();
    getIpAddr();
  }, [])
  
  history.navigate = useNavigate();
  history.location = useLocation();


  return (
    <div className="App">
      <NotificationContainer />
      <div id='wrap'>
        <Routes>
          {
            routers.map((router, index) => {
              return (
                <Route key={index} path={router.pathname} element={
                  <>
                     <Protected isPublic={router.isPublic} isMessagePage={router.pathname === "/message"} messageRouter="/message" value={router.value} > 
                      <router.component />
                     </Protected> 
                  </>
                } />
              )
            })
          }
        </Routes>
        <Spiners />
        <NewMessage />
        <Check />
      </div>
    </div>
  );
}

export default App;
