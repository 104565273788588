import MyPageContents from 'Components/Account/MyPage/MyPageContents';
import LeftBar from 'Components/common/LeftBar';
import Right from 'Components/common/Right';
import React, { Fragment, useEffect } from 'react';

const MyPage = () => {
    useEffect(() => {
        window.scrollHandler();
      }, [])
    return (
        <Fragment>
            <LeftBar />
            <MyPageContents/>
            <Right type="mypage"/>
        </Fragment>
    );
};

export default MyPage;