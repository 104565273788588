import React from 'react';

const Modal = ({popup, contents, onAction, onPopup}) => {
    return (
        <div className="dialog" style={{ display: popup ? "flex" : "none", backgroundColor: !popup && "#00000000", transition: "0.5s", scale: popup ? "1" : "0" }} >
            <div className="container popup_color" style={{ display: "block" }}>
                <div className="d-flex img_question">
                    <img src="../images/question.png" style={{ margin: "20px auto 0 auto" }} alt="" />
                </div>
                <div className="justify-center  d-flex betting_modal">
                    <span style={{ width: "100%", textAlign: "center", fontSize: "16px" }}>{contents}</span>
                </div>
                <div className=" d-flex justify-center betting_modal_text betting_modal_text_yes_no">
                    <span className="popup-btn2" onClick={onAction}>네</span>
                    <span className="popup-btn1" onClick={onPopup}>아니오</span>
                </div>
            </div>
        </div>
    );
};

export default Modal;