import { betActions } from 'Common/store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import BetItem from './BetItem';
import { checkMarket, isEmpty } from 'Common';

const bettingStyle = { transition: "all 300ms", overflow: 'hidden', cursor: "pointer" };
const firstStyle = { transition: "all 300ms", overflow: 'hidden', cursor: "pointer", marginTop: "10px" };

const MarketItem = (props) => {


    const dispatch = useDispatch();
    const bettedItems = useSelector(state => state.bet.bettedItems);
    const { betItemList, originSportslist, market, hideTitle, markettitle,eventParttitle,title,src } = props;
    const market_setting = useSelector(state => state.sports.marketSetting)
    let marketNametitleFlag = !isEmpty(market_setting) ? market_setting.market_name_setting : "0"
    // debugger

    const checkBetted = (bet) => {
        return bettedItems.findIndex(item => item.betId === bet.Id) >= 0;
    }

    const onBetItem = (bet, betList, index) => {
        const Fixture = originSportslist.Fixture;

        if (!checkBetted(bet)) {
            const result = dispatch(betActions.onAddOneBet({ index, market, Fixture, bet, betList }));
            if (!result) {
                return;
            }
        }
        else
            dispatch(betActions.removeOneBet(bet.Id));;

    }
    return (checkMarket(market) && (!isEmpty(betItemList[0]))) && (
        <li className="bet_list1_wrap" style={{ overflow: "hidden" }}>
            {
                !hideTitle &&(
                    marketNametitleFlag !== "1" ?
                <div> 
                    <div className="bet_list1_wrap_in_title"><img src={src} className="otll_img11" />{markettitle},&nbsp;&nbsp;{eventParttitle}</div>
                </div>
                :
                <div> 
                    <div className="bet_list1_wrap_in_title"><img src={src} className="otll_img11" />[{eventParttitle}]&nbsp;&nbsp;{markettitle}</div>
                </div>
                )
            }
            {
                    betItemList.map((betList, index) => {
                        // console.log("betList", betList)
                        return (
                            <div className="mini_bet365_list_in" style={bettingStyle} key={index}>
                                {
                                    betList.data.map((item, itemIndex) => {
                                        return (<BetItem 
                                                    key={market.MarketID + " " + index + " " + itemIndex}
                                                    item={item} 
                                                    itemIndex={itemIndex} 
                                                    betList={betList} 
                                                    originSportslist={originSportslist} 
                                                    onBetItem={onBetItem} 
                                                    checkBetted={checkBetted}/>);
                                    })
                                }
                            </div>
                        )
                    })
                }
        </li>
    );
};

export default MarketItem;