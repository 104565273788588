import { isEmpty } from 'Common';
import { moreActions } from 'Common/store';
import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux'

const MoneyBtn = [
    {
        name:"1만원",
        value:10000
    },
    {
        name:"5만원",
        value:50000
    },
    {
        name:"10만원",
        value:100000
    },
    {
        name:"30만원",
        value:300000
    },
    {
        name:"50만원",
        value:500000
    },
    {
        name:"100만원",
        value:1000000
    },
    {
        name:"500만원",
        value:5000000
    }
]

const DepositRequest = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data.user)
    const {money, setMoney, onChange, reset,onBonus,depositBonus,setDepositBonus} = props


    const bonusList = useSelector(state => state.more.deposit.bonusList)
       console.log("bonusList ---------- ", bonusList)

      
    useEffect(() => {
        if (!isEmpty(bonusList)) {
          setDepositBonus(bonusList[0]._id)
          setDepositBonusTitle(bonusList[0].name)
        }
      }, [bonusList])
    const [depositBonusShow, setDepositBonusShow] = useState(false);
    const [depositBonusTitle, setDepositBonusTitle] = useState("선택시 포인트미지급");


    return (
        <div className="white_box_h">
            {/* <div className="s_title1">충전요청</div> */}
            <div className="write_tr">
                <div className="write_title">보유머니</div>
                <div className="write_basic"><span className="font11">{isEmpty(user) ? 0 : Math.floor(user.balance.money).toLocaleString()}</span></div>
            </div>
            <div className="write_tr">
                <div className="write_title">신청금액</div>
                <div className="write_basic">
                    {/* <div className="write_text">고객센터에서 알려드린 입금계좌로 이체 후 입금액을 입력란에 기입하시고 하단의 <span className="font05">[충전요청]</span> 버튼을 누르시면 됩니다.</div> */}
                    <input className="input1" name='amount' placeholder="직접입력"  value={(isEmpty(money) || money === 0) ? "" : Number(money).toLocaleString()} onChange={onChange} />
                    <div className="write_basic_btn">
                        {
                            MoneyBtn.map((item, index) => {
                                return(
                                    <a key={index} onClick={() => setMoney(money + item.value)}><span className="btn1_2">{item.name}</span></a>
                                )
                            })
                        }
                        <a onClick={reset}><span className="btn1_2">정정하기</span></a>
                    </div>
                </div>
            </div>
           
            <div className="write_tr cf">
                <div className="write_title">보너스 선택</div>
                <div className="write_basic">
                    <form className="radio_wrap" style={{width:"100%"}}>
                                        <select name="depositBonus" value={depositBonus} tabIndex="7" className="desport_bonus" onChange={onBonus}>
                                            <option data-v-80eae4ca="" value="null">선택시 포인트 미지급</option>
                                            {
                                                !isEmpty(bonusList) && bonusList.map((list, index) => {
                                                    return (<option data-item-type={list._id} value={list._id} defaultValue={list._id} key={index}>{list.name}</option>)
                                                })
                                            }
                                        </select>
                        {/* <label>
                            <input type="checkbox"  id="ck_no_bonus" className="input_check_1" name="radio" checked={depositBonus===1} readOnly />
                            <span htmlFor="ck_no_bonus" onClick={() => onBonus(1)}>충전보너스</span>
                        </label> */}
                        {/* <label>
                            <input type="radio" name="radio" />
                            <span>스포츠/미니게임/슬롯 - 포인트10% <span>9개</span></span>
                        </label>
                        <label>
                            <input type="radio" name="radio" />
                            <span>카지노 - 포인트 미지급&nbsp;&nbsp;<span>9개</span></span>
                        </label> */}
                    </form>
                </div>
            </div>
            
        </div>
    );
};

export default DepositRequest;