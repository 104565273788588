import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import CouponItem from './CouponItem';
import { moreActions } from 'Common/store';
import { getCouponObject, isEmpty } from 'Common';
import Modal from 'Components/Modal/Modal';


const CouponContents = () => {

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const coupon = useSelector(state => state.more.coupon)
    const couponList = isEmpty(coupon.list) ? [] : coupon.list;
    const [popup, setPopup] = useState(false)
    const [activeCouponId, setActiveCouponId] = useState("")

    const onPopup = () => {
        setPopup(!popup);
    };

    const GetCouponList = () => {
        dispatch(moreActions.getCouponList())
    }

    useEffect(() => {
        if (isAuthenticated) {
            GetCouponList();
        }
    }, [isAuthenticated]);

    const onActiveId = (id) => {
        setActiveCouponId(id);
        onPopup();
    }

    const onUseCoupon = () => {
        dispatch(moreActions.usingCoupon(activeCouponId))
        onPopup();
    }

    return (
        <div className='contents_center'>
            <div className='contents_center_info_full'>
                <div className="title">쿠폰함</div>
                <div className="coupon box_lottery " style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                    {
                        !isEmpty(couponList) ?
                            couponList.map((item, index) => {
                                const couponObj = getCouponObject(item);
                                return <CouponItem data={couponObj} onActiveId={onActiveId} key={index} />
                            })
                            :
                            <div className="box_coupon margin-30px-top">
                                <div className="position-relative h-100">
                                    <div className="lottery_result">
                                        <strong style={{ textAlign: "center", fontSize: "1rem" }}>
                                            쿠폰이 없습니다.
                                            <br />
                                        </strong>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                popup && <Modal contents="쿠폰을 사용하시겠습니까?" popup={popup} onAction={onUseCoupon} onPopup={onPopup} />
            }
        </div>
    );
};

export default CouponContents;