import { dateFormation, isEmpty } from 'Common';
import { moreActions } from 'Common/store';
import Pagenation from 'Components/common/Pagenation';
import { perPage } from 'Utils';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const resDetail = {
    title: "",
    content: ""
}
const NoticeContents = () => {
    const dispatch = useDispatch();
    const notices = useSelector(state => state.more.notice.notices)
    const page = useSelector(state => state.page)
    const [showHis, setShowHis] = useState(false);
    const [active, setActive] = useState("news");
    const showHistory = () => {
        setShowHis(!showHis);
    }
    const getMessage = (page = 1) => {
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(moreActions.getNoticeList(params));
    }

    const localNotices = !isEmpty(JSON.parse(localStorage.getItem("noticeList"))) ? JSON.parse(localStorage.getItem("noticeList")) : [];

    const onActive = (value, title, content) => {
        showHistory();
        resDetail.title = title;
        resDetail.content = content;
        if (active === value) {
            setActive(-1)
        }
        else {
            setActive(value)
        }

        if (!isEmpty(localNotices)) {
            const noticeIndex = localNotices.findIndex(localNotice => localNotice === notices[value]._id);
            if (noticeIndex < 0) {
                localStorage.setItem("noticeList", JSON.stringify([...localNotices, notices[value]._id]));
            }
        }
        else {
            localStorage.setItem("noticeList", JSON.stringify([notices[value]._id]));
        }
    }

    useEffect(() => {
        getMessage();
    }, [page.perPage]);

    // useEffect(() => {

    // }, [notices]);

    return (
        <div className="contents_center">
            <div className="contents_center_info_full">
                <div className="title" style={{marginBottom:"5px"}}>공지사항</div>
                {
                    !showHis ?
                        <Fragment>
                            <div className="con_box10" >
                                <div className="list_box">
                                    <div className="list_tr_title" >
                                        <span></span>
                                        <span>번호</span>
                                        <span className="titleTd" style={{display:"flex",justifyContent:"center"}}>제목</span>
                                        <span>작성일</span>
                                        <span>상태</span>
                                    </div>
                                    {
                                        !isEmpty(notices) && notices.map((notice, index) => {
                                            return (
                                                <div className="list_tr_list notice_list" onClick={() => onActive(index, notice.title, notice.content)} key={index} >
                                                    <span><img src='../images/common/notice.png'/></span>
                                                    <span><span className="division3">{Number(page.curPage) * 10 + index - 9}</span></span>
                                                    <span className="titleTd" style={{display:"flex",justifyContent:"center"}} dangerouslySetInnerHTML={{ '__html': notice.title }}></span>
                                                    <span>{!notice.created_date ? "" : dateFormation(notice.created_date)}</span>
                                                    <span><span className={` ${localNotices.findIndex(item => item === notice._id) < 0 ? "division2" : "division1"}`}>{localNotices.findIndex(item => item === notice._id) < 0 ? "미확인" : "확인"}</span></span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                isEmpty(notices) ?
                                    <div className="no_data" style={{ textAlign: "center" }}>내역이 없습니다.</div>
                                    :
                                    <Pagenation
                                        page={page.curPage}
                                        totalPage={page.totalPage}
                                        getPage={getMessage}
                                    />
                            }
                        </Fragment>
                        :
                        <Fragment>
                            <div className="con_box00">
                                <div className="list_box">
                                    <div className="list_tr_title" style={{display:"block"}}>
                                        <div className="list_title4" style={{ width: "100%" }} dangerouslySetInnerHTML={{ '__html': resDetail.title }}></div>
                                    </div>
                                    <div className="list_tr4">
                                        <div className="list_notice4" dangerouslySetInnerHTML={{ '__html': resDetail.content }} style={{ width: "100%" }}></div>
                                    </div>

                                </div>
                                <div className="con_box10">
                                    <div className="btn_wrap_center">
                                        <ul className="display_flex">
                                            <li onClick={showHistory}><a><span className="btn2_1">목록으로이동</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                }

            </div>
        </div>
    );
};

export default NoticeContents;