import { history } from 'Common';
import React from 'react';
import { Link } from 'react-router-dom';


const minigames = [
    {
        value: '1',
        name: "EOS 1분",
    },
    {
        value: '2',
        name: "EOS 2분",
    },

    {
        value: '3',
        name: "EOS 3분",
    },

    {
        value: '4',
        name: "EOS 4분",
    },

    {
        value: '5',
        name: "EOS 5분",
    },
    {
        value: 'EVOPowerball',
        name: "EVO파워볼",
    },
    {
        value: 'nuguri',
        name: "너구리",
    },

    {
        value: 'soccer',
        name: "축구",
    },

    {
        value: 'baseball',
        name: "야구",
    },

    {
        value: 'squidgame',
        name: "오징어",
    },
]
const MinigameMenu = (props) => {

    const {type} = props
    return (
        <div className="mini_game_tab_in minigame">
            <ul>
                {
                    minigames.map((item, index) => {
                        return (
                            <li className={`${type === item.value ? "active" : ""}`} key={index}>
                                <Link to={`?type=${item.value}`}>
                                    <div className="mgtt2">{item.name}</div>
                                </Link>
                            </li>
                        )
                    })
                }

            </ul>
        </div>
    );
};

export default MinigameMenu;