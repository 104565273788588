import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { convertGameSetting, fixedNumber, getBetCard, getMarketRate, isEmpty, showMessage, toastr } from 'Common';
import { api_url, ws_api_url } from 'Common/keys';
import { request } from 'Common/request';
import { moreActions } from './more.slice';
import { pageActions, store, userActions } from '.';




// create slice

const name = 'bet';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const betActions = { ...slice.actions, ...extraActions };
export const betReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        sportId: 0,
        eventKey: 0,
        bettedItems: [],
        currentBet: [],
        multiBetting: {},
        maxLength: 10,
        maxBetRate: 100,
        handicap_set: [],
        bet_multi: [],
        multiSetting: {},
        delaySetting: [],
        sameMarketAllow: false,
        liveMultiAllow: true,
    }
}

function createReducers() {
    return {
        addOneBet,
        setCurrentBetting,
        setMulti,
        setMaxValue,
        setDelay,
        setSameMarketAllow,
        setLiveMultiAllow,
        setDelaySetting,
        setBettedItem

    };

    function addOneBet(state, action) {
        state.sportId = action.payload.sportId
        state.eventKey = action.payload.eventKey
        state.bettedItems = action.payload.bettedItems
        state.multiBetting = action.payload.multiBetting
    }

    function setCurrentBetting(state, action) {
        state.currentBet = action.payload
    }

    function setMulti(state, action) {
        state.multiBetting = action.payload
    }

    function setMaxValue(state, action) {
        state.maxLength = action.payload.multi_cnt
        state.maxBetRate = action.payload.multi_max
        state.handicap_set = action.payload.handicap_set
        state.bet_multi = action.payload.bet_multi
    }

    function setDelay(state, action) {
        state.delaySetting = action.payload
    }

    function setSameMarketAllow(state, action) {
        state.sameMarketAllow = action.payload
    }

    function setLiveMultiAllow(state, action) {
        state.liveMultiAllow = action.payload
    }
    function setDelaySetting(state, action) {
        state.delaySetting = action.payload
    }
    function setBettedItem(state, action) {
        state.bettedItems = action.payload
    }


}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        clearBetStore: clearBetStore(),
        cancelBetting: cancelBetting(),
        onAddOneBet: onAddOneBet(),
        removeOneBet: removeOneBet(),
        doBetting: doBetting(),
        onSetDelaySetting: onSetDelaySetting(),
        AddOneBetGame: AddOneBetGame()

    };

    // Update user
    function clearBetStore() {
        return createAsyncThunk(
            `${name}/clearBetStore`,
            async function (params, { dispatch }) {
                const bet = store.getState().bet;
                dispatch(betActions.addOneBet({
                    ...bet,
                    sportId: 0,
                    eventKey: "",
                    bettedItems: []
                }))
            }
        );
    }

    function cancelBetting() {
        return createAsyncThunk(
            `${name}/cancelBetting`,
            async function ({ id, type }, { dispatch }) {
                const perPage = store.getState().page.perPage;
                const curPage = store.getState().page.curPage;
                try {
                    request.post(api_url + '/api/Sports/cancel', {
                        type: 'toto',
                        id: id
                    })
                        .then(data => {
                            showMessage(data.message);
                            if (!data.status)
                                dispatch(moreActions.getBettingHistories({
                                    type,
                                    page: curPage,
                                    perPage: perPage
                                }))
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    }

    function onAddOneBet() {
        return createAsyncThunk(
            `${name}/onAddOneBet`,
            async function (data, { dispatch }) {
                //console.log(data);
                const { bettedItems } = store.getState().bet;
                const { pageInfo } = store.getState().page;
                // console.log(pageInfo);
                const { bet_setting } = store.getState().sports;

                const { index, bet, betList, Fixture, market } = data
                let { eventKey } = data
                if (isEmpty(eventKey)) eventKey = store.getState().sports.activeId;

                // console.log(data, bettedItems, eventKey);
                // const eventKey = store.getState().sports.activeId
                // console.log(store.getState().sports.activeId);

                let multiBettings = [];
                let maxLengths = [];
                let maxBetRates = [];
                let bet_multis = [];
                let currentSportId = "";
                const { sameMarketAllow, liveMultiAllow } = store.getState().bet;

                if (pageInfo.sportType === "esport") {
                    const { multiBetting, maxLength, maxBetRate, bet_multi } =
                        store.getState().bet;
                    multiBettings = pageInfo.gameType === "prematch" ? multiBetting.esport : multiBetting.esportlive;
                    maxLengths = maxLength.esport;
                    maxBetRates = maxBetRate.esport;
                    bet_multis = bet_multi.esport;
                    currentSportId = Fixture.League.sport.oddsId;
                }
                else if (pageInfo.sportType === "sport") {
                    const { multiBetting, maxLength, maxBetRate, bet_multi } =
                        store.getState().bet;
                    multiBettings = pageInfo.gameType === "live" ? multiBetting.sportlive : (pageInfo.prematchType === "interior" ? multiBetting.interior : multiBetting.sport);
                    maxLengths = maxLength.sport;
                    maxBetRates = pageInfo.prematchType === "interior" ? maxBetRate.interior : maxBetRate.sport;
                    bet_multis = pageInfo.prematchType === "interior" ? bet_multi.interior : bet_multi.sport;
                    currentSportId = Fixture.League.sport.Id;
                }
                // console.log(' store.getState().bet.multiBettings: ',  store.getState().bet.multiBetting);
                // console.log('multiBettings: ', multiBettings);
                // console.log('multiBetting: ', multiBetting);
                if (isEmpty(multiBettings)) return;
                const multiBetting = multiBettings.filter(setting => setting.type == currentSportId)[0].value;
                const maxLength = maxLengths.filter(setting => setting.type == currentSportId)[0].value.value;
                const maxBetRate = maxBetRates.filter(setting => setting.type == currentSportId)[0].value.value;
                const bet_multi = bet_multis.filter(setting => setting.type == currentSportId)[0].value.value;
                // console.log('multiBetting: ', multiBetting, currentSportId);

                //-----------멀티배팅 제한---------------------
                let flag = 0;

                //---------------------------배당률 최대값 제한--------------------------------------------
                let price = 1;
                let bonusRate = 1;
                if (pageInfo.pageType === "virtual") {
                    bettedItems.forEach(element => {
                        // console.log(getMarketRate(element))
                        price = price * getMarketRate(element);
                    });
                } else {
                    const betLength = betList.length;
                    bet_setting.forEach(setting => {
                        if (betLength >= Number(setting.fold)) {
                            bonusRate = Number(setting.value);
                        }
                    })
                    bettedItems.forEach(element => {
                        let type = pageInfo.sportType === "esports" ? "esports" : pageInfo.gameType
                        let card = getBetCard(element, type);
                        // console.log( getBetCard(element, type), card)
                        price = price * Number(card.rate);
                        //   console.log(price)
                    });
                }
                // console.log(data,maxBetRate, price);
                if ((price * fixedNumber(data.bet.Price[0].Price) * bonusRate) > Number(maxBetRate)) {
                    toastr.warning(`최대배당율 초과되었습니다.`);
                    return 0;
                }
                //------------------------------------------------------------------------

                //--------------------------------조합배팅 불가인가 ----------------------------------------
                // console.log("----------------------------------");
                // console.log('bet_multi && bettedItems.findIndex(item => (item.eventKey === eventKey)) >= 0: ', bet_multi, bet_multi && bettedItems.findIndex(item => (item.eventKey === eventKey)) >= 0);
                if (bet_multi == 1 && bettedItems.findIndex(item => (item.eventKey === eventKey)) >= 0) {
                    toastr.warning(`조합배팅 불가 입니다.`);
                    return 0;
                }
                //------------------------------------------------------------------------

                // console.log(store.getState().bet)
                //console.log("-------------------", "item", multiBetting)
                multiBetting.forEach(item => {
                    let val1, val2;
                    val1 = convertGameSetting(item.type.split("_")[0]);
                    val2 = convertGameSetting(item.type.split("_")[1]);
                    if (
                        (market.name.toLowerCase().includes(val1) &&
                            (bettedItems.findIndex(item => (item.market.name.toLowerCase().includes(val2)) && (item.eventKey === eventKey)) > -1))
                        ||
                        (market.name.toLowerCase().includes(val2) &&
                            (bettedItems.findIndex(item => (item.market.name.toLowerCase().includes(val1)) && (item.eventKey === eventKey)) > -1))
                    ) {
                        // console.log("-------------------", 
                        // (market.name.toLowerCase().includes(val1) && (bettedItems.findIndex(item => item.market.name.toLowerCase().includes(val2)) > -1) && (bettedItems.findIndex(item => (item.eventKey === eventKey)) >= 0)),
                        // (market.name.toLowerCase().includes(val2) && (bettedItems.findIndex(item => item.market.name.toLowerCase().includes(val1)) > -1) && (bettedItems.findIndex(item => (item.eventKey === eventKey)) >= 0)))
                        toastr.warning(`${item.type.split("_")[0]} + ${item.type.split("_")[1]} 조합배팅 불가입니다.`)
                        // toastr.warning(`중복된 마켓이 존재합니다.`);
                        return flag = 1;
                    }
                })
                if (bettedItems.length >= maxLength) {
                    toastr.warning(`최대배팅갯수를 초과하셨습니다.`);
                    return 0;
                }

                if (flag === 1) {
                    // toastr.warning(`선택한 배팅은 조합배팅을 할수 없습니다.`);
                    return 0;
                }

                if (!sameMarketAllow && (bettedItems.findIndex(item => (item.eventKey === eventKey) && (item.betList.marketId === market.Id)) >= 0)) {
                    toastr.warning(`같은 마켓에 대해서는 한번만 배팅하실수 있습니다.`);
                    return 0;
                }
                //---------------------------------------------------------------------


                // console.log("-------------------", bettedItems, data)
                if (// ((data.bet.data[data.index].Status !== 1) ||
                    (
                        // (!bet.Id) ||  // unactivable
                        //  (eventKey && eventKey !== data.Fixture.key) || // different event
                        (bettedItems.findIndex(item => (item.betId === bet.Id) && (item.eventKey === eventKey)) >= 0) ||  // betted
                        (bettedItems.findIndex(item =>
                            (item.betList.verifyId === betList.special) && (item.eventKey === eventKey) && (item.betList.marketId === market.Id)) >= 0) || // double
                        (bettedItems.findIndex(item => (item.betList.verifyId === betList.data[0].Id) && (item.eventKey === eventKey) && (item.betList.marketId === market.Id)) >= 0)) &&
                    (bettedItems.findIndex(item => (item.eventKey === eventKey)) >= 0)  // different event
                ) {
                    toastr.warning(`같은 마켓에 대해서는 한번만 배팅하실수 있습니다.`);
                    return 0;
                }

                localStorage.setItem("eventkey", eventKey)
                localStorage.setItem("sportsId", currentSportId)
                dispatch(betActions.addOneBet({
                    sportId: isEmpty(Fixture.League.sport) ? '' : Fixture.League.sport.Id,
                    eventKey: store.getState().sports.activeId,
                    bettedItems: [
                        ...bettedItems,
                        {
                            ...data,
                            betList: {
                                ...betList,
                                verifyId: isEmpty(betList.special) ? betList.data[0].Id : betList.special
                            },
                            gameType: store.getState().page.pageInfo.gameType,
                            betId: bet.Id,
                            eventKey: eventKey,
                        }
                    ],
                    multiBetting: store.getState().bet.multiBetting
                })
                )

                return 1;
            }
        )
    }

    function removeOneBet() {
        return createAsyncThunk(
            `${name}/removeOneBet`,
            async function (data, { dispatch }) {
                let multiBetting = store.getState().bet.multiBetting;

                const { bettedItems, sportId, eventKey } = store.getState().bet;
                const index = bettedItems.findIndex(item => item.betId === data);
                if (index < 0) return 0;
                if (bettedItems.length === 1) {
                    dispatch(betActions.clearBetStore());
                    localStorage.removeItem("bettingItem")
                }
                else {
                    dispatch(betActions.addOneBet({
                        sportId, eventKey,
                        bettedItems: bettedItems.slice(0, index)
                            .concat(bettedItems.slice(index + 1, bettedItems.length)),
                        multiBetting: multiBetting
                    })
                    )
                }
            }
        )
    }

    function AddOneBetGame() {
        return createAsyncThunk(
            `${name}/AddOneBetGame`,
            async function (data, { dispatch }) {
                const { eventKey, bettedItems, maxLength } =
                    store.getState().bet;

                let multiBetting = store.getState().bet.multiBetting;

                if (bettedItems.length > 0) {

                    return 0;
                } //벳게임 조합배팅 금지

                // if ( bettedItems.findIndex(item => item.eventKey === data.eventKey) >= 0 ) //벳게임 한경기내에서 조합배팅 금지
                //    return 0;

                // if ( bettedItems.findIndex(item => item.marketId === data.marketId) >= 0 ) // 벳게임에서 조합배팅을 할때 같은 마켓에서 배팅금지
                //    return 0;

                // if ( (bettedItems.findIndex(item => (item.eventKey === data.eventKey) && (item.cate1 === data.cate1)) >= 0) && data.cate1 === "oneteam" ) // 원팀일때 배팅제한
                //    return 0;
                dispatch(betActions.addOneBet({
                    eventKey: data.eventKey,
                    bettedItems: [
                        ...bettedItems,
                        {
                            ...data,
                            ...store.getState().page.pageInfo,
                            eventKey: data.eventKey,
                        }
                    ],
                    multiBetting: multiBetting
                }))

                return 1;
            }
        )
    }

    function doBetting() {
        return createAsyncThunk(
            `${name}/doBetting`,
            async function ({ money, type }, { dispatch }) {
                const { sportId, eventKey, bettedItems, delaySetting, liveMultiAllow } = store.getState().bet;
                const { pageInfo } = store.getState().page;

                if (((pageInfo.gameType === "live") && liveMultiAllow) && (bettedItems.length === 1)) {
                    return toastr.warning(`단폴더 베팅은 불허되어있습니다.`);
                }

                let fixedBettedItems = [];
                bettedItems.forEach(element => {
                    fixedBettedItems.push(getBetCard(element, type)
                    )
                });

                dispatch(pageActions.onSetPageLoading(true));

                const getTimeOutSetting = () => {
                    if (isEmpty(delaySetting)) {
                        return 0;
                    } else {
                        if (pageInfo.sportType === "sport") {
                            if (pageInfo.gameType === "prematch") {
                                return delaySetting[0].value
                            }
                            else {
                                return delaySetting[1].value
                            }
                        }
                        else if (pageInfo.sportType === "esport") {
                            if (pageInfo.gameType === "prematch") {
                                return delaySetting[2].value
                            }
                            else {
                                return delaySetting[3].value
                            }
                        }
                        else {
                            return 0;
                        }
                    }
                }
                const timeOutSetting = Number(getTimeOutSetting()) * 1000;
                setTimeout(() => {
                    dispatch(betActions.clearBetStore())
                    try {
                        request.post(api_url + `/api/${type !== "virtual" ? "Sports" : "Betgame"}/betting`,
                            {
                                sportId: sportId,
                                eventKey: eventKey,
                                bets: fixedBettedItems,
                                amount: money,
                                national_type: type === 'interior' ? 1 : 0,
                                type: type === 'prematch' ? "prematch" : (type === 'interior' ? "home_prematch" : "")
                            }
                        )
                            .then(data => {
                                dispatch(pageActions.onSetPageLoading(false));
                                showMessage(data.message);
                                dispatch(userActions.getUserInfo());

                            })
                    } catch (error) {
                        dispatch(pageActions.onSetPageLoading(false));
                        console.error(error);
                    }
                }, timeOutSetting)
            }
        );
    }
    function onSetDelaySetting() {
        return createAsyncThunk(
            `${name}/onSetDelaySetting`,
            async function (data, { dispatch }) {
                dispatch(betActions.setDelaySetting(data))
            }
        )
    }

}