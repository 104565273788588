import { fixBetsData, fixedNumber, getMarketDetail, isEmpty } from 'Common';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'


const bettingStyle = { transition: "all 300ms", overflow: 'hidden', cursor: "pointer", justifyContent: "space-between", padding: "0 10px", flexWrap: "nowrap" };


const InteriorBetItem = (props) => {

    const dispatch = useDispatch();
    const baselineSetting = useSelector(state => state.sports.baselineSetting)
    const pageInfo = useSelector(state => state.page.pageInfo)
    const { item, itemIndex, betList, market, originSportslist, onBetItem, checkBetted, countMarket, showMarkets, onShowMarkets } = props;
    const { sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting } = baselineSetting;

    const rateValue = useMemo(() => {
      const sportId = originSportslist.Fixture.League.sport.Id;
      let sportsRateIndex = -1;
      let sportRate = {}
      if(pageInfo.RoomType === "sport"){ 
          if(pageInfo.gameType === "live"){
              sportsRateIndex = isEmpty(sportLiveRateSetting) ? -1 : sportLiveRateSetting.findIndex(setting => setting.sportId == sportId);
              sportRate = sportsRateIndex > -1 ? sportLiveRateSetting[sportsRateIndex] : {};
          }
          else{
              sportsRateIndex = isEmpty(sportPrematchRateSetting) ? -1 : sportPrematchRateSetting.findIndex(setting => setting.sportId == sportId);
              sportRate = sportsRateIndex > -1 ? sportPrematchRateSetting[sportsRateIndex] : {};
          }
  
  
          if(!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)){
            return {
              min: Number(sportRate.min), 
              max: Number(sportRate.max),
            }
          }
          else{
            return {}
          }
      }
      else{
          if(pageInfo.gameType === "live"){
              sportsRateIndex = isEmpty(esportLiveRateSetting) ? -1 : esportLiveRateSetting.findIndex(setting => setting.sportId == sportId);
              sportRate = sportsRateIndex > -1 ? esportLiveRateSetting[sportsRateIndex] : {};
          }
          else{
              sportsRateIndex = isEmpty(esportPrematchRateSetting) ? -1 : esportPrematchRateSetting.findIndex(setting => setting.sportId == sportId);
              sportRate = sportsRateIndex > -1 ? esportPrematchRateSetting[sportsRateIndex] : {};
          }
  
  
          if(!isEmpty(sportRate) && sportRate.status && !isEmpty(sportRate.min) && !isEmpty(sportRate.max)){
            return {
              min: Number(sportRate.min), 
              max: Number(sportRate.max),
            }
          }
          else{
            return {}
          }
      }
  }, [pageInfo.gameType, sportPrematchRateSetting, sportLiveRateSetting, esportPrematchRateSetting, esportLiveRateSetting]);

    const team = getMarketDetail(originSportslist, item.Name);

    const [nowRate, setNowRate] = useState(item.Price[0].Price);

    const setTimeRate = () => {
        setTimeout(() => {
            setNowRate(item.Price[0].Price);
        }, 4600)
    }
    useEffect(() => {
        if (nowRate !== item.Price[0].Price) {
            setTimeRate();
        }
    }, [item])

    useEffect(() => {
        return () => {
            clearTimeout(setTimeRate());
        }
    }, [])

    return (
        <div id={item.Id} key={itemIndex}
            className={`oigb_btn ${checkBetted(item) ? "on" : ""} ${(item.Price[0].Price > nowRate) && "rate_up_effect "} ${(item.Price[0].Price < nowRate) && " rate_down_effect "}`}
            >
            <div className='gameLists' style={{ justifyContent: "center" }}  onClick={() => onBetItem(item, betList, itemIndex, market)}>
                {itemIndex !== 1 && <span className="home">{team}</span>}
                {fixedNumber(item.Price[0].Price) !== "VS" ? (
                    <strong >
                        {itemIndex === 0 &&
                            (market.name.includes("Handicap") ? (
                                <s ><i className="fa-solid fa-square-h bet_h_i"></i></s>
                            ) : market.name.includes("Over/Under") ? (
                                <s ><img src="../images/common/arr1.gif" alt="증가" /></s>
                            ) : (
                                ""
                            ))}
                        <em className={`${!isEmpty(team) ? "score_color" : ""} 
                   ${(item.Price[0].Price > nowRate) && "rate_up_effect "} 
                   ${(item.Price[0].Price < nowRate) && " rate_down_effect "}`} >{fixedNumber(item.Price[0].Price)}</em>
                        {itemIndex === 2 &&
                            (market.name.includes("Handicap") ? (
                                <s><i className="fa-solid fa-square-h bet_h_i"></i></s>
                            ) : market.name.includes("Over/Under") ? (
                                <s><img src="../images/common/arr2.gif" alt="감소" /></s>
                            ) : (
                                ""
                            ))}
                    </strong>
                ) : (
                    <em >VS</em>
                )}
            </div>
            {
                (
                    (item.Price[0].status > 3) ||
                    (item.Status == 10) ||
                    (!isEmpty(rateValue) && !isEmpty(team) &&
                        ((item.Price[0].Price > rateValue.max) || (item.Price[0].Price < rateValue.min))
                    )
                ) &&
                <div className="betting-block" style={{ display: "flex" }}>
                    <div className="lock">
                        <span className="icon-lock"></span>
                    </div>
                </div>
            }
        </div>
        // <div className="odds_item_game_bet">
        //     <div className="oigb_btn on"><span className="home">함나시아 Y 티로 팀명이 길어요 길어요</span><em>1.69</em></div>
        //     <div className="oigb_btn"><em>1.69</em></div>
        //     <div className="oigb_btn"><span className="away">그레스포드</span><em>3.64</em></div>
        // </div>
    );
};

export default InteriorBetItem;