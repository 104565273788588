import { convertClass, convertMinigameName, convertTypeString, dateFormation, isEmpty } from 'Common';
import React from 'react';



const MinigameHistory = (props) => {

    const { history, index } = props
    return (
        <div className="odds_item odd_item_country" key={index}>
            <div className="odds_item_league">
                <div className="odds_item_league_l">
                    <div className="otll_img"></div>
                    <div className="otll_text">
                        <span className="otll_text_country">{convertMinigameName(history.gameType)}</span>
                    </div>
                </div>
                <div className="odds_item_league_r"></div>
            </div>
            <div className="odds_item_game_box1">
                <div className="odds_item_game">
                    <div className="odds_item_game_type"><div style={{justifyContent:"center"}}>베팅상세</div></div>
                    <div className="odds_item_game_bet hover_none">
                        <div className="history_detail"><span className="home">{isEmpty(history.odd) ? '' : `${history.odd.name} (${Number(history.odd.rate).toFixed(2)})`}</span></div>
                    </div>
                </div>
            </div>
            <div className="bet_history_total">
                <div className="m_bet_info_mini"><span className="icon_info">베팅시간</span>{isEmpty(history) ? '' : dateFormation(history.created_date, 10)}</div>
                <div className="m_bet_info_mini"><span className="icon_info">회차번호</span>{isEmpty(history) ? '' : history.round}</div>
                <div className="m_bet_info_mini"><span className="icon_info">베팅금액</span>{isEmpty(history.balance) ? '' : Number(history.balance.bet).toLocaleString()}</div>
                <div className="m_bet_info_mini"><span className="icon_info">적중금액</span>{isEmpty(history.balance) ? '' : Number(history.balance.win).toLocaleString()}</div>
                <div className="m_bet_info_mini"><span className="icon_info">적중여부</span><span className = {`font03 ${convertClass(history.type)}`}>{convertTypeString(history.type)}</span></div>
            </div>
        </div>
    );
};

export default MinigameHistory;