import React, { Fragment, useEffect, useState } from 'react';
import Locations from './Locations';
import { getAllCount, isEmpty } from 'Common';
import { useSelector } from 'react-redux'
import SportsSearch from 'Components/Sports/SportsSearch';


const sportsMenu = [
    {
        name: "soccer",
        kind: "축구",
        gameCount: 0,
        imgPath: "../images/swip/icon_football.png",
        id: 6046,
    },
    {
        name: "hockey",
        kind: "아이스하키",
        gameCount: 0,
        imgPath: "../images/swip/icon_ice_hockey.png",
        id: 35232
    },
    {
        name: "basketball",
        kind: "농구",
        gameCount: 0,
        imgPath: "../images/swip/icon_basketball.png",
        id: 48242,
    },
    {
        name: "volleyball",
        kind: "배구",
        gameCount: 0,
        imgPath: "../images/swip/icon_volleyball.png",
        id: 154830,
    },
    {
        name: "baseball",
        kind: "야구",
        gameCount: 0,
        imgPath: "../images/swip/icon_baseball.png",
        id: 154914,
    },
    {
        name: "mma",
        kind: "MMA",
        gameCount: 0,
        imgPath: "../images/swip/icon_mma.png",
        id: 154919,
    },
    {
        name: "tabletennis",
        kind: '탁구',
        gameCount: 0,
        id: 265917,
        imgPath: '../images/swip/jongmok_Tabletennis-ico.png'
    },
    {
        name: "football",
        kind: '미식축구',
        id: 131506,
        gameCount: 0,
        imgPath: '../images/swip/rugby-league-ico.png'
    },
    {
        name: "tennis",
        kind: '테니스',
        id: 54094,
        gameCount: 0,
        imgPath: '../images/swip/jongmok_Tennis.png'
    },
    {
        name: "cricket",
        kind: '크리케트',
        id: 452674,
        gameCount: 0,
        imgPath: '../images/swip/jongmok_Baseball.png'
    },
]
const esportsMenu = [
    {
        kind: "도타 2",
        gameCount: 0,
        imgPath: "../images/swip/icon_dota_2.png",
        id: "dota2",
        name: "Dota",
        name_other: "도타"
    },
    {
        id: 'leagueoflegends_wildlift',
        name: 'Wild Rift',
        name_other: "와일드리프트",
        kind: '리그오브레전드 와일드리프트',
        gameCount: 0,
        //   label1: '와일드리프트',
        imgPath: "../images/swip/game_ico_leagueoflegends_wildlift.png",
    },
    {
        kind: "리그 오브 레전드",
        gameCount: 0,
        imgPath: "../images/swip/icon_league_of_legends.png",
        id: "leagueoflegends",
        name: "League of legends",
        name_other: "League of legends"
    },
    {
        kind: "오버워치",
        gameCount: 0,
        imgPath: "../images/swip/icon_overwatch_2.png",
        id: "overwatch",
        name: "Overwatch",
        name_other: "오버워치"
    },
    {
        kind: "스타크래프트 2",
        gameCount: 0,
        imgPath: "../images/swip/icon_starcraft_2.png",
        id: "starcraft2",
        name: "Starcraft",
        name_other: "스타크래프트"
    },
    {
        kind: "워크래프트",
        gameCount: 0,
        imgPath: "../images/swip/icon_warcraft_iii.png",
        id: "warcraft",
        name: "Warcraft",
        name_other: "워크래프트"
    },
    {
        id: 'ageofempire',
        name: 'Age of Empires II',
        name_other: "에이지 오브 엠파이어",
        gameCount: 0,
        kind: '에이지 오브 엠파이어',
        imgPath: "../images/swip/game_ico_ageofempire.png",

    },
    {
        id: 'counterstrike',
        name: 'Counter-Strike',
        gameCount: 0,
        kind: '카운터 스트라이크',
        imgPath: "../images/swip/game_ico_counterstrike.png",
        name_other: "CS"
    },
    {
        id: 'pentastorm',
        name: 'Pentastorm',
        kind: '펜타스톰',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_pentastorm.png",
    },
    {
        id: 'rainbowsix',
        name: 'Rainbow',
        name_other: "Rainbow",
        kind: '레인보우6',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_rainbowsix.png",
    },
    {
        id: 'soccerbattle',
        name: 'Soccerbattle',
        name_other: "FIFA",
        kind: '축구배틀',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_soccerbattle.png",
    },
    {
        id: 'valorant',
        name: 'Valorant',
        name_other: "VALORANT",
        kind: '발로란트',
        gameCount: 1,
        imgPath: "../images/swip/game_ico_valorant.png",
    },
    {
        id: 'nbabattle',
        name: 'NBA',
        name_other: "NBA",
        kind: 'NBA배틀',
        gameCount: 0,
        imgPath: "../images/swip/game_ico_nbabattle.png",
    },
]

const SportsLeague = (props) => {

    const { type, title, view } = props;
    const page = useSelector(state => state.page)
    const sports = useSelector(state => state.sports)
    let sportslist = type !== "esports" ? sportsMenu : esportsMenu;  //스포츠종목별로 자료얻기
    let [sportsCnt, setSportsCnt] = useState(sportslist);
    const [active, setActive] = useState("");
    const [itemVal, setItemVal] = useState([]);
    const [showLeftBar, setShowLeftBar] = useState(true);
    useEffect(() => {
        setActive(page.pageInfo.gameType);
    }, [page.pageInfo.gameType])

    useEffect(() => {
        let allCount = getAllCount(type) //총경기갯수 시현

        if (isEmpty(allCount)) {
            // sportsCnt = sportslist;
            setSportsCnt(sportslist);
            return;
        }

        for (let i = 0; i < sportslist.length; i++) {
            allCount.data.forEach(element => {
                if (element.id == sportslist[i].id) {
                    sportslist[i].gameCount = element.count;
                    sportslist[i].data = element.data;
                }
            })
        }
        setSportsCnt(sportslist);
    }, [sports.allCount, sports.store_events, page.pageInfo.gameType])

    const onClickItem = (value) => {
        let valdata = itemVal;
        // //console.log(valdata);
        const data = {
            sportType: type === "esports" ? "esport" : type,
            gameType: active,
            sportId: value
        }
        if (valdata.findIndex(item => item === value) > -1) {
            setItemVal(valdata.filter(item => item !== value));
            return;
        }
        setItemVal([...valdata, value]);
    }

    return (
        <Fragment>
            <SportsSearch />
            <div className="left_game_tab_box">
                <div className="left_game_tab">
                    <ul>
                        {/* <li className="active"><a>{title}<span></span></a></li> */}
                        <li className="active"><a>주요경기<span></span></a></li>
                    </ul>
                </div>
                <div className="left_game_list">
                    {
                        !isEmpty(sportsCnt) && sportsCnt.map((item, itemIndex) => {
                            // if (isEmpty(item.gameCount) || (item.gameCount === 0)) return "";
                            return (
                                <Fragment key={itemIndex}>
                                    <div className="sports_events_menu" onClick={() => onClickItem(item.id)}>
                                        <img src={item.imgPath} className="sports_event_img" />
                                        <img src="../images/common/arrow_01.png" className="arrow" alt=""/>
                                        {item.kind}
                                        <span className="sports_event_odd">{Number(item.gameCount).toLocaleString()}</span>
                                        <span className="sports_event_arrow">
                                            <i className="fa-solid fa-angle-down"></i>
                                        </span>
                                    </div>
                                    {
                                        (itemVal.findIndex(val => item.id === val) > -1) &&
                                        (!isEmpty(item.data) && item.data.map((element, index) => {
                                            return (
                                                <Locations
                                                    list={element}
                                                    Id={element.id.toString()}
                                                    type={type}
                                                    sportId={item.id.toString()}
                                                    key={index}
                                                    itemVal={itemVal}
                                                    imgPath={item.imgPath}
                                                    history={props.history}
                                                    typeActive={active}
                                                    view={view}
                                                />
                                            )
                                        }))
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default SportsLeague;