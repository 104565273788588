import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { sportsActions } from 'Common/store';
import { isEmpty } from 'Common';

const searchData = [
    {
        name: "팀",
        value: "team"
    },
    {
        name: "리그",
        value: "league"
    },
    {
        name: "지역",
        value: "location"
    }
]

const SportsSearch = () => {

    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({
        searchType: 'team',
        searchValue: ''
    });
    const { searchType, searchValue } = inputs;


    const onChange = (e) => {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const onSearch = () => {
        dispatch(sportsActions.setSearchType(searchType));
        dispatch(sportsActions.setSearchValue(searchValue));
    }

    return (
        <div className="left_serch_box">
            <div className="left_serch">
                <select
                    name="searchType"
                    value={searchType}
                    onChange={onChange}
                    className="sports-search-input " >
                    {
                        searchData.map((data, index) => {
                            return (<option value={data.value} key={index}>{data.name}</option>)
                        })
                    }
                </select>
            </div>
            <div className="left_serch">
                <input type="text" name="searchValue" value={searchValue} onChange={onChange} className="left_serch_input" />
                <button className="left_serch_btn" onClick={onSearch}><img src="images/icon_serch.png" /></button>
            </div>
        </div>
    );
}

export default SportsSearch;