import { checkLoginIdstr, isEmpty, toastr } from 'Common';
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from 'Common/store';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



const Login = (props) => {

    const { view, hide, sign } = props

    const dispatch = useDispatch();
    const loginError = useSelector(state => state.user.loginError);
    const captchaData = useSelector(state => state.user.captchaData);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)

    let captchaId = !isEmpty(captchaData) && captchaData.captchaId;
    const [inputs, setInputs] = useState({
        loginId: "",
        loginPwd: "",
        captcha: ""
    })

    const { loginId, loginPwd, captcha } = inputs;

    const onChange = (e) => {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const onCaptcha = () => {
        dispatch(userActions.getCaptcha());
    }

    const onReset = () => {
        setInputs({
            loginId: "",
            loginPwd: "",
            captcha: ""
        })
    }

    useEffect(() => {
        if (!isAuthenticated) onCaptcha();
    }, [])




    const onSubmit = (e) => {
        e.preventDefault();
        if (checkLoginIdstr(loginId)) {
            return toastr.warning("아이디에 특수문자를 포함할수 없습니다.")
        }
        if (isEmpty(loginId)) {
            return toastr.warning("아이디를 입력하세요.")
        }
        if (isEmpty(loginPwd)) {
            return toastr.warning("비밀번호를 입력하세요.")
        }
        if (isEmpty(captcha)) {
            return toastr.warning("보안문자를 입력하세요.")
        }
        let params = {
            loginId,
            loginPwd,
            captchaId,
            captcha
        }
        dispatch(userActions.loginUser(params))

    }



    const onEnter = (e) => {
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    }

    const onClose = () => {
        hide && hide();
    }

    const goSign = () => {
        onClose();
        sign && sign()
    }
    const captchaRefresh = (e) => {
        e.preventDefault()
        onCaptcha();
    }
    useEffect(() => {
        if (isAuthenticated) {
            onClose()
        }
    }, [isAuthenticated])



    return (
        view && (<div className="background">
            <div className="login">
                <div className="pop_box" onKeyDown={onEnter}>
                    <span className="close_pop .fa-window-close" onClick={onClose}>
                        <img src='../images/close.png' style={{ width: "25px" }} />
                    </span>

                    <div className="title_login">
                        <img src="../images/logo.png" />
                    </div>
                    <ul className="input_area">
                        <li>
                            <div className="tit">아이디</div>
                            <div className="input_box">
                                <input name="loginId" type="text" className="login_input1" placeholder="아이디를 입력하세요." onChange={onChange} />
                            </div>
                        </li>
                        <li>
                            <div className="tit">비밀번호</div>

                            <div className="input_box">
                                <input name="loginPwd" type="password" className="login_input1" placeholder="비밀번호를 입력하세요." onChange={onChange} />
                            </div>
                        </li>
                        <li>
                            <div className="tit">보안문자</div>
                            <div className='d-flex'  ><img src={captchaData.image} className='captchaImg' style={{height:"45px",width:"100%"}} /><div className='captcha_refresh' onClick={captchaRefresh}><i className='fa fa-refresh'></i></div></div>
                            <div className="input_box" style={{marginTop:"10px"}}>
                                <input name="captcha" type="text" className="login_input1" placeholder="보안문자를 입력하세요." onChange={onChange} />
                            </div>
                        </li>
                    </ul>
                    <div className="btn_wrap">
                        <div onClick={onSubmit} className="login_btn1">로그인</div>
                        <div onClick={goSign} ><span className="login_btn2">회원가입</span></div>
                    </div>

                </div>
            </div>
        </div>)
    );
};

export default Login;