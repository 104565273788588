import SportsLeague from 'Components/Layout/SportsLeague';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { pageActions, userActions } from 'Common/store';
import { setLeftBar, toastr } from 'Common';


const LeftMenu = [
    {
        name: "입금",
        image: "../images/main_game/off/left/입금.png",
        hoverImage: "../images/main_game/on/left/입금.png",
        path: "/deposit"
    },
    {
        name: "출금",
        image: "../images/main_game/off/left/출금.png",
        hoverImage: "../images/main_game/on/left/출금.png",
        path: "/Withdraw"
    },
    {
        name: "고객센터",
        image: "../images/main_game/off/left/고객센터.png",
        hoverImage: "../images/main_game/on/left/고객센터.png",
        path: "/help"
    },
    {
        name: "스포츠 ",
        image: "../images/main_game/off/left/스포츠.png",
        hoverImage: "../images/main_game/on/left/스포츠.png",
        path: "/sportsprematch"
    },
    // {
    //     name: "스포츠 (국내형)",
    //     path: "/sportsdomestic"
    // },
    {
        name: "라이브 스포츠",
        image: "../images/main_game/off/left/라이브 스포츠.png",
        hoverImage: "../images/main_game/on/left/라이브 스포츠.png",
        path: "/sportslive"
    },
    {
        name: "가상게임",
        image: "../images/main_game/off/left/가상게임.png",
        hoverImage: "../images/main_game/on/left/가상게임_hover.png",
        path: "/bet365?type=Soccer"
    },
    {
        name: "미니게임",
        image: "../images/main_game/off/left/미니게임.png",
        hoverImage: "../images/main_game/on/left/미니게임.png",
        path: "/minigame?type=0"
    },
    {
        name: "베팅내역",
        image: "../images/main_game/off/left/베팅내역.png",
        hoverImage: "../images/main_game/on/left/베팅내역.png",
        path: "/bettinghistory"
    },
    {
        name: "공지사항",
        image: "../images/main_game/off/left/공지사항.png",
        hoverImage: "../images/main_game/on/left/공지사항.png",
        path: "/notice"
    },

    {
        name: "쪽지",
        image: "../images/main_game/off/left/쪽지.png",
        hoverImage: "../images/main_game/on/left/쪽지.png",
        path: "/message"
    },
    {
        name: "이벤트",
        image: "../images/main_game/off/left/이벤트.png",
        hoverImage: "../images/main_game/on/left/이벤트.png",
        path: "/events"
    },
    {
        name: "경기결과",
        image: "../images/main_game/off/left/경기결과.png",
        hoverImage: "../images/main_game/on/left/경기결과.png",
        path: "/gameresult"
    },
    //  {
    //      name: "룰렛",
    //      path: "/roulette"
    // },
    // {
    //     name: "슬롯",
    //     path: "/slot"
    // },
    // {
    //     name: "카지노",
    //     path: "/casino"
    // },



    // {
    //     name: "포인트내역",
    //     path: "/pointdetail"
    // },



    // {
    //     name: "고객센터",
    //     path: "/help"
    // },

    {
        name: "쿠폰",
        image: "../images/main_game/off/left/쿠폰.png",
        hoverImage: "../images/main_game/on/left/쿠폰_hover.png",
        path: "/coupon"
    },
    {
        name: "룰렛",
        image: "../images/main_game/off/left/입출금내역 복사.png",
        hoverImage: "../images/main_game/on/left/입출금내역 복사.png",
        path: "/roulette"
    },
    {
        name: "복권",
        image: "../images/main_game/off/left/복권.png",
        hoverImage: "../images/main_game/on/left/복권_hover.png",
        path: "/lottery"
    },

    {
        name: "출석체크",
        image: "../images/main_game/off/left/기타.png",
        hoverImage: "../images/main_game/on/left/기타.png",
        path: "/attendance"
    },
    {
        name: "회원정보",
        image: "../images/main_game/off/left/회원정보.png",
        hoverImage: "../images/main_game/on/left/회원정보_hover.png",
        path: "/mypage"
    },
    {
        name: "포인트 전환",
        image: "../images/main_game/off/left/포인트전화.png",
        hoverImage: "../images/main_game/on/left/포인트전환_hover.png",
        path: "/point"
    }

]


const LeftBar = (props) => {


    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const showleft = useSelector(state => state.user.showleft)
    const pageFomat = () => {
        Error_message()
        dispatch(userActions.setShowLeft(!showleft))
        dispatch(pageActions.setPageData({
            curPage: 1,
            totalPage: 1
        }))
    }

    const Error_message = () => {
        if (!isAuthenticated) {
            return toastr.warning("로그인하시고 이용해주세요")
        }
    }

    const { type, title } = props
    const view = () => {
        dispatch(userActions.setShowLeft(!showleft))
    }

    const goSlot = (e) => {
        view()
        if (!isAuthenticated) {
            e.preventDefault();
            return toastr.warning("로그인하시고 이용해주세요")
        }
    }
    return (
        <Fragment>
            <div className="contents_left" style={{ display: "block !important" }}>

                <div className="left_quick_game">
                    <ul>
                        <li>
                            <Link onClick={goSlot} to="/casino">
                                <img className='custom_image' src="../images/main_game/off/left/카지노.png" />
                                <img className='custom_hover_image' src="../images/main_game/on/left/카지노.png" />
                            </Link>
                        </li>
                        <li>
                            <Link onClick={goSlot} to="/slot">
                                <img className='custom_image' src="../images/main_game/off/left/슬롯.png" />
                                <img className='custom_hover_image' src="../images/main_game/on/left/슬롯.png" />
                            </Link>
                        </li>
                    </ul>
                </div>


                <div className="left_menu">
                    <ul className='top_menu'>
                        {
                            LeftMenu.map((menu, index) => {
                                return (

                                    <li key={index}>
                                        <Link to={isAuthenticated ? menu.path : ""} onClick={pageFomat}>
                                            <img className='custom_image' src={menu.image} />
                                            <img className='custom_hover_image' src={menu.hoverImage} />
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                {
                    // (type === "live" || type === "prematch" || type === "interior" || type === "esports") &&
                    <SportsLeague type={type} title={title} view={view} />
                }

            </div>
            <div id='left_bar' className="left_bar" onClick={() => { view() }}>
                <span className="icon-menu"></span>
                <img src="../images/side-button.png" />
            </div>
        </Fragment>
    );
};

export default LeftBar;