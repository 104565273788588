import React, { Fragment, useEffect, useState } from 'react';
import Advertisement from './Advertisement';
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from 'Common/store';
import { isEmpty } from 'Common';

const Popup = (props) => {

    const dispatch = useDispatch();
    const showAdvertisement = useSelector(state => state.user.showAdvertisement)
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const popup = useSelector(state => state.user.popup)
    const [showed, setShowed] = useState([]);

    const popupStr = localStorage.getItem("popup")
    const popups = !isEmpty(popupStr) ? JSON.parse(popupStr) : [];
    const popupExp = Number(localStorage.getItem("popupExp"));
    const date = new Date();


    const ToggleImage = (id) => {

        setTimeout(() => {
            const index = showed.findIndex(item => item === id);
            if (index >= 0)
                setShowed(showed.slice(0, index).concat(showed.slice(index + 1, showed.length)));
            else
                setShowed([...showed, id]);
        }, 300)
    }

    const ShowAllAdvertisements = (auth) => {
        if (auth) {
            let popupItems = [];
            for (let i = 0; i < popup.length; i++) {
                if (popupExp >= Date.UTC(date.getUTCFullYear(), (date.getUTCMonth() + 1), date.getUTCDate())) {
                    if (isEmpty(popups.find(item => item === popup[i]._id))) {
                        popupItems.push(popup[i]._id);
                    }
                }
                else {
                    popupItems.push(popup[i]._id);
                }
            }
            setShowed(popupItems);
        }
    }

    useEffect(() => {
        if (showAdvertisement) {
            ShowAllAdvertisements(true);
            dispatch(userActions.setShowAdvertisement(false));
        }
    }, [showAdvertisement])

    // console.log("팝업에서 내용", popup, showed)

    useEffect(() => {
        dispatch(userActions.getPublicPopup());
    }, [isAuthenticated])

    return isAuthenticated && (
        <Fragment>

            <div aria-hidden="true">
                <div className={`flexbox_popupadvertisement`} style={{ justifyContent: showed.length > 4 ? "start" : "center", display: isEmpty(showed) ? 'none' : '' }}>
                    {
                        popup.map((item, index) => {
                            return !(
                                (!isAuthenticated && !isEmpty(popups.find(popupItem => popupItem === item._id))) &&
                                (popupExp >= Date.UTC(date.getUTCFullYear(), (date.getUTCMonth() + 1), date.getUTCDate()))) &&
                                (
                                    <Advertisement key={index} data={item} showed={showed} ToggleImage={ToggleImage} />
                                )
                        })
                    }
                </div>
            </div>

            <section id="popup" style={isEmpty(showed) ? { display: 'none' } : { display: 'block' }}>
            </section>
        </Fragment>
    );
};

export default Popup;