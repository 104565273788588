import React from 'react';
import { useSelector } from 'react-redux'

const Spiners = () => {

    const loading = useSelector(state => state.page.loading);

    return (
        <div className="loading-back" style={{ display: loading ? "flex" : "none" }}>
            {/* <svg className="isoContentLoader" viewBox="0 0 50 50">
                <circle className="isoContentLoaderCircle" cx="25" cy="25" r="20" fill="none" stroke="yellow" strokeWidth="3.6"></circle>
            </svg> */}
            <div className="ring" >Loading
                <span></span>
            </div>
        </div>
    );
};

export default Spiners;