import { isEmpty } from 'Common';
import React from 'react';
import { useSelector } from 'react-redux'

const PointBtn = [
    {
        name:"1만P",
        value:10000
    },
    {
        name:"5만P",
        value:50000
    },
    {
        name:"10만P",
        value:100000
    },
    {
        name:"30만P",
        value:300000
    },
    {
        name:"50만P",
        value:500000
    },
    {
        name:"100만P",
        value:1000000
    }
]


const PointRequest = (props) => {
    const {money, setMoney, onChange, reset } = props;

    const user = useSelector(state => state.user.data.user)
    return (
        <div className="white_box_point">
            {/* <div className="s_title1">포인트전환요청</div> */}
            <div className="write_tr">
                <div className="write_title">보유머니</div>
                <div className="write_basic"><span >{isEmpty(user) ? 0 : Math.floor(user.balance.money).toLocaleString()}</span></div>
            </div>
            <div className="write_tr">
                <div className="write_title">보유포인트</div>
                <div className="write_basic"><span >{isEmpty(user) ? 0 : Math.floor(user.balance.rolling).toLocaleString()}</span></div>
            </div>
            <div className="write_tr">
                <div className="write_title">신청포인트</div>
                <div className="write_basic">
                    {/* <div className="write_text">전환하실 포인트를 입력란에 기입하시고 하단의 <span className="font05">[포인트전환]</span> 버튼을 누르시면 됩니다. 포인트전환은 만원단위로 가능합니다.</div> */}
                    <input className="input1" placeholder='직접입력' value={(isEmpty(money) || money === 0) ? "" : Number(money).toLocaleString()} onChange={onChange} />
                    <div className="write_basic_btn">
                        {
                            PointBtn.map((item, index) => {
                                return(
                                    <a key={index} onClick={() => setMoney(money + item.value)} ><span className="btn1_2">{item.name}</span></a>
                                )
                            })
                        }
                        <a onClick={reset}><span className="btn1_2">정정하기</span></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PointRequest;