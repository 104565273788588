import { 
    lsportsbaseballProvidedMarketIds,
    lsportsbasketballProvidedMarketIds,
    lsportsDefaultEsportsMarketIds,
    lsportsDefaultProvidedMarketIds,
    lsportsDotaMarkets, 
    lsportsfootballProvidedMarketIds, 
    lsportsIceHockeylProvidedMarketIds, 
    lsportsLeagueOfLegendsaMarkets, 
    lsportsMMAProvidedMarketIds, 
    lsportssoccerProvidedMarketIds, 
    lsportsvolleyballProvidedMarketIds, 
 } from "./lsportsGroup";
 
import { 
    oddsbaseballProvidedMarketIds, 
    oddsbasketballProvidedMarketIds, 
    oddsDefaultEsportsMarketIds, 
    oddsDefaultProvidedMarketIds, 
    oddsDotaMarkets, 
    oddsfootballProvidedMarketIds, 
    oddsIceHockeylProvidedMarketIds, 
    oddsLeagueOfLegendsaMarkets, 
    oddsMMAProvidedMarketIds, 
    oddssoccerProvidedMarketIds, 
    oddsvolleyballProvidedMarketIds 
} from "./oddsGroup";


export const getDotaMarkets = (type) => {
    switch (type) {
        case "odds":
            return oddsDotaMarkets;
        case "lsports":
            return lsportsDotaMarkets;
    
        default:
            break;
    }
}

export const getLeagueOfLegendsaMarkets = (type) => {
    switch (type) {
        case "odds":
            return oddsLeagueOfLegendsaMarkets;
        case "lsports":
            return lsportsLeagueOfLegendsaMarkets;
    
        default:
            break;
    }
}

export const getsoccerProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddssoccerProvidedMarketIds;
        case "lsports":
            return lsportssoccerProvidedMarketIds;
    
        default:
            break;
    }
}

export const getIceHockeylProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsIceHockeylProvidedMarketIds;
        case "lsports":
            return lsportsIceHockeylProvidedMarketIds;
    
        default:
            break;
    }
}

export const getbasketballProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsbasketballProvidedMarketIds;
        case "lsports":
            return lsportsbasketballProvidedMarketIds;
    
        default:
            break;
    }
}

export const getfootballProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsfootballProvidedMarketIds;
        case "lsports":
            return lsportsfootballProvidedMarketIds;
    
        default:
            break;
    }
}

export const getbaseballProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsbaseballProvidedMarketIds;
        case "lsports":
            return lsportsbaseballProvidedMarketIds;
    
        default:
            break;
    }
}

export const getvolleyballProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsvolleyballProvidedMarketIds;
        case "lsports":
            return lsportsvolleyballProvidedMarketIds;
    
        default:
            break;
    }
}

export const getMMAProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsMMAProvidedMarketIds;
        case "lsports":
            return lsportsMMAProvidedMarketIds;
    
        default:
            break;
    }
}

export const getDefaultProvidedMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsDefaultProvidedMarketIds;
        case "lsports":
            return lsportsDefaultProvidedMarketIds;
    
        default:
            break;
    }
}

export const getDefaultEsportsMarketIds = (type) => {
    switch (type) {
        case "odds":
            return oddsDefaultEsportsMarketIds;
        case "lsports":
            return lsportsDefaultEsportsMarketIds;
    
        default:
            break;
    }
}