import React from 'react';

const WithdrawMethod = () => {
    return (
        <div className="white_box_h" >
            {/* <div className="s_title1">출금방법</div> */}
            <div className="write_tr">
                {/* <div className="write_title">출금안내</div> */}
                <div className="write_basic" style={{background:"rgb(48, 48, 48)"}}>
                ※ 출금은 최소 10,000원이상 출금신청 해주시기 바랍니다.<br />
                ※ 출금은 입금 신청하실때 신청하신 계좌로만 출금이 가능합니다.<br />
                </div>
            </div>
        </div>
    );
};

export default WithdrawMethod;